import { Component, OnInit, Input } from '@angular/core';
import { Risk, TaskRisk } from '@app/benchmark/benchmark.model';

type RiskIcon = 'shield-check' | 'exclamation-circle' | 'shield-exclamation';
type RiskColour = 'text-rag-green' | 'text-rag-amber' | 'text-rag-red';

@Component({
  selector: 'cb-risk-label',
  templateUrl: './risk-label.component.html',
  styles: [],
})
export class RiskLabelComponent implements OnInit {
  @Input()
  risk: TaskRisk;

  @Input()
  iconOnly = false;

  styles = {};
  icon: RiskIcon;
  colour: RiskColour;
  includeSuffix = true;

  constructor() {
    this.risk = 'Unrated';
    this.icon = 'shield-exclamation';
    this.colour = 'text-rag-red';
  }

  ngOnInit(): void {
    switch (this.risk) {
      case 'VeryLow':
      case 'Low':
        this.changeRisk('shield-check', 'text-rag-green');
        break;
      case 'Medium':
        this.changeRisk('exclamation-circle', 'text-rag-amber');
        break;
      case 'High':
      case 'VeryHigh':
        this.changeRisk('shield-exclamation', 'text-rag-red');
        break;
      case 'Unrated':
        this.changeRisk('shield-exclamation', 'text-rag-red', false);
        break;
    }
  }

  private changeRisk(
    icon: RiskIcon,
    colour: RiskColour,
    suffix: boolean = true
  ): void {
    this.icon = icon;
    this.colour = colour;
    this.includeSuffix = suffix;
  }
}
