import { Component, OnInit, Input } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  HeaderControl,
  HeaderButton,
  HeaderSelectList,
  HeaderDatePicker,
  HeaderSearch,
  HeaderLinkButton,
  HeaderSelectListOption,
  HeaderMultiSelectList,
} from '@app/components/page-header/controls';
import { Feature, UserContextService } from '@app/user-context.service';
import { FiltersActiveService } from '../filters-active.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'cb-control-list',
  templateUrl: './control-list.component.html',
  styleUrls: ['./control-list.component.css'],
})
export class ControlListComponent implements OnInit {
  @Input() controls?: HeaderControl[] | null;
  @Input() space = false;

  searchSubject = new Subject<{ value: string; delay: boolean }>();

  constructor(
    private context: UserContextService,
    private filtersActive: FiltersActiveService
  ) {}

  ngOnInit(): void {}

  featureEnabled(feature: Feature | undefined): boolean {
    if (!feature) {
      return true;
    }
    return this.context.userSnapshot?.hasFeature(feature) ?? false;
  }

  controlClick(control: HeaderButton): void {
    if (control.action) {
      control.action();
    }
  }

  controlChange(control: HeaderControl): void {
    if (this.isSelectList(control)) {
      control.changeEvent?.emit(control.selectedOption);
      if (control.selectedOption) {
        this.filtersActive.setActive(control.id);
      } else {
        this.filtersActive.deactivate(control.id);
      }
    }

    if (this.isMultiSelect(control)) {
      control.changeEvent?.emit(control.selectedOptions);
    }
  }

  getSelectOptions(
    selectList: HeaderSelectList
  ): Observable<HeaderSelectListOption[]> {
    return selectList.options.pipe(
      map((options) => {
        if (selectList.selectedOption) {
          return options;
        }

        const defaultValue = options.find(
          (option) => option.isDefaultValue === true
        );

        if (defaultValue) {
          selectList.selectedOption = defaultValue.value;
        }

        return options;
      })
    );
  }

  isButton(control: HeaderControl): control is HeaderButton {
    return control.type === 'Button';
  }

  isLinkButton(control: HeaderControl): control is HeaderLinkButton {
    return control.type === 'LinkButton';
  }

  isSelectList(control: HeaderControl): control is HeaderSelectList {
    return control.type === 'Dropdown';
  }

  asMultiSelectList(control: HeaderControl): HeaderMultiSelectList {
    return control as HeaderMultiSelectList;
  }

  isMultiSelect(control: HeaderControl): control is HeaderMultiSelectList {
    return control.type === 'MultiSelect';
  }

  isDatePicker(control: HeaderControl): control is HeaderDatePicker {
    return control.type === 'Date';
  }

  dateSelected(date: Date | null, control: HeaderDatePicker): void {
    control.dateChangedEvent?.emit(date);
    if (date) {
      this.filtersActive.setActive(control.id);
    } else {
      this.filtersActive.deactivate(control.id);
    }
  }

  isSearchControl(control: HeaderControl): control is HeaderSearch {
    return control.type === 'Search';
  }

  textControlChange(
    control: HeaderSearch,
    value: string,
    debounce: boolean
  ): void {
    control.onChange(value, debounce);
    if (value) {
      this.filtersActive.setActive(control.config.id);
    } else {
      this.filtersActive.deactivate(control.config.id);
    }
  }
}
