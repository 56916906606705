<cb-authorized>
  <cb-body *ngIf="project$ | async as project">
    <div class="max-w-full px-2 py-2 lg:px-4 lg:mx-auto lg:max-w-screen-xl">
      <h3 class="text-2xl mb-5">
        {{ project.name }}
      </h3>
      <div
        class="lg:border lg:border-gray-200 rounded-md shadow-md bg-white lg:p-4"
      >
        <ng-container *ngIf="tabsActive$ | async; else formOnly">
          <mat-tab-group
            mat-align-tabs="left"
            dynamicHeight
            [selectedIndex]="tabIndex"
            (selectedIndexChange)="tabChange($event)"
            animationDuration="350ms"
          >
            <mat-tab label="Details">
              <div class="pt-6 flex justify-between">
                <div>
                  <ng-container *ngIf="manualTaskClosing(project)">
                    <div
                      class="py-4 flex items-center gap-2 text-rag-amber font-bold"
                    >
                      <div>
                        <hero-icon
                          name="exclamation"
                          type="outline"
                          class="w-5 h-5"
                        >
                        </hero-icon>
                      </div>
                      <div>
                        Tasks on this completed project remain open, please
                        close them
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div class="flex items-center gap-6">
                  <ng-container *ngIf="pushToPsaEnabled$ | async">
                    <div *ngIf="projectStatus?.value === 'Quoting'">
                      <ng-container
                        *ngIf="
                          (autoPushEnabled$ | async) === false &&
                          project.id &&
                          !project.dateSentToPsa
                        "
                      >
                        <button
                          type="button"
                          (click)="sendToPsa(project.id)"
                          cbButton
                        >
                          Create Quote
                        </button>
                      </ng-container>
                    </div>
                  </ng-container>

                  <div *ngIf="statusValues$ | async as statusOptions">
                    <p-dropdown
                      [options]="statusOptions"
                      [(ngModel)]="projectStatus"
                      optionLabel="text"
                      (onChange)="onStatusChanged(project)"
                      placeholder="Project Status"
                    >
                      <ng-template pTemplate="selectedItem">
                        <div
                          class="px-4 py-1 font-bold text-white"
                          [ngClass]="selectedStatusState()"
                        >
                          {{ projectStatus?.text }}
                        </div>
                      </ng-template>
                      <ng-template let-status pTemplate="item">
                        <div class="px-4 py-1">
                          {{ status?.text }}
                        </div>
                      </ng-template>
                    </p-dropdown>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="pushToPsaEnabled$ | async">
                <div
                  class="flex justify-end opacity-75 text-xs items-center pt-2 gap-2"
                  *ngIf="project.dateSentToPsa"
                >
                  <div class="text-brand-primary">
                    <hero-cloud-arrow-up></hero-cloud-arrow-up>
                  </div>

                  <div class="flex gap-2 items-center">
                    <div>
                      Pushed
                      {{ project.dateSentToPsa | date : "EEEE dd MMM YYYY" }}
                    </div>
                    <div *ngIf="project.id">
                      <button
                        type="button"
                        class="underline transition opacity-80 hover:opacity-100 hover:underline-offset-4 py-2 hover:text-brand-deep"
                        (click)="sendToPsa(project.id)"
                      >
                        Resend
                      </button>
                    </div>
                  </div>
                </div>
              </ng-container>

              <div class="pt-6">
                <cb-project-form
                  [project]="project"
                  (close)="done()"
                ></cb-project-form>
              </div>
            </mat-tab>
            <mat-tab [label]="getTasksLabel(project)">
              <ng-template matTabContent>
                <div class="pb-2 px-4">
                  <div class="h-full flex items-center justify-center">
                    <div class="flex-1">
                      <div
                        class="px-6 py-4 flex justify-between gap-4 mb-4 bg-bright-gray border border-mid-gray rounded-md"
                      >
                        <div class="flex items-center gap-4">
                          <div>
                            <input
                              #tf
                              type="search"
                              [(ngModel)]="taskFilter"
                              placeholder="filter unassigned tasks"
                              cbInput
                              (keyup)="taskFilterChanged(tf.value, true)"
                              (search)="taskFilterChanged(tf.value, false)"
                              aria-label="Filter unassigned tasks by title"
                            />
                          </div>
                          <div>
                            <span
                              pTooltip="Unassigned tasks are available to other projects until they have been assigned"
                              class="cursor-pointer text-brand-deep"
                            >
                              <hero-icon
                                name="information-circle"
                                type="outline"
                                class="w-5 h-5"
                              ></hero-icon>
                            </span>
                          </div>
                        </div>
                        <div class="flex items-center gap-2">
                          <div>
                            <button
                              type="button"
                              cbButton
                              (click)="saveProjectTasks(project)"
                              [disabled]="projectTasksHaveNotChanged(project)"
                            >
                              <div class="flex gap-2">
                                <div>
                                  <hero-icon
                                    name="save"
                                    type="outline"
                                    class="w-5 h-5"
                                  ></hero-icon>
                                </div>
                                <div>Save Tasks</div>
                              </div>
                            </button>
                          </div>
                          <div>
                            <button
                              type="button"
                              cbButton
                              colour="bg-brand-deep"
                              (click)="resetProjectTasks(project)"
                              [disabled]="projectTasksHaveNotChanged(project)"
                            >
                              <div class="flex gap-2">
                                <div>
                                  <hero-icon
                                    name="refresh"
                                    type="outline"
                                    class="w-5 h-5"
                                  ></hero-icon>
                                </div>
                                <div>Reset</div>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        class="grid grid-cols-2 gap-4 divide-x divide-mid-gray"
                      >
                        <div
                          class="py-2 px-4 cb-task-list"
                          cdkDropList
                          #pendingList="cdkDropList"
                          [cdkDropListData]="pendingTasks"
                          [cdkDropListConnectedTo]="[projectTaskList]"
                          (cdkDropListDropped)="itemDropped($event)"
                        >
                          <h3 class="text-xl text-center mb-4">
                            Unassigned Tasks ({{ pendingTasks.length }})
                          </h3>
                          <div class="drag-preview hidden">Unassign task</div>
                          <div
                            cdkDrag
                            *ngFor="let task of pendingTasks"
                            class="bg-white p-2 my-2 cursor-pointer border rounded-md hover:shadow-md"
                            [class]="getDynamicTaskStyles(task)"
                          >
                            <div class="py-4 flex gap-4">
                              <div>
                                <cb-risk-label
                                  [risk]="task.risk"
                                  [iconOnly]="true"
                                >
                                </cb-risk-label>
                              </div>
                              <div>
                                <a
                                  [routerLink]="['../../benchmark']"
                                  [queryParams]="{ task: task.taskId }"
                                  class="hover:underline"
                                >
                                  {{ task.title }}
                                </a>
                              </div>
                            </div>
                            <div class="flex justify-end mt-2">
                              <button
                                type="button"
                                cbButton
                                colour="bg-brand-deep"
                                (click)="assignTask(task, project)"
                              >
                                <div class="flex items-center gap-2">
                                  <div>Assign to project</div>
                                  <div>
                                    <hero-icon
                                      name="arrow-right"
                                      type="outline"
                                      class="w-5 h-5"
                                    ></hero-icon>
                                  </div>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>

                        <div
                          class="py-2 px-4 cb-task-list"
                          cdkDropList
                          #projectTaskList="cdkDropList"
                          [cdkDropListData]="project.tasks"
                          [cdkDropListConnectedTo]="[pendingList]"
                          (cdkDropListDropped)="itemDropped($event)"
                        >
                          <h3 class="text-xl text-center mb-4">
                            Project Assigned Tasks ({{ project.tasks.length }})
                          </h3>
                          <div class="drag-preview hidden">Assign task</div>
                          <div
                            cdkDrag
                            *ngFor="let task of project.tasks"
                            class="bg-white p-2 my-2 cursor-pointer border rounded-md hover:shadow-md"
                            [class]="getDynamicTaskStyles(task)"
                          >
                            <div class="py-4 flex gap-4">
                              <div>
                                <cb-risk-label
                                  [risk]="task.risk"
                                  [iconOnly]="true"
                                >
                                </cb-risk-label>
                              </div>
                              <div>
                                <a
                                  [routerLink]="['../../benchmark']"
                                  [queryParams]="{ task: task.taskId }"
                                  class="hover:underline"
                                >
                                  {{ task.title }}
                                </a>
                              </div>
                            </div>
                            <div class="flex justify-end mt-2">
                              <button
                                type="button"
                                cbButton
                                colour="bg-brand-danger"
                                (click)="unAssignTask(task, project)"
                              >
                                <div class="flex gap-2">
                                  <div>
                                    <hero-icon
                                      name="trash"
                                      type="outline"
                                      class="w-5 h-5"
                                    ></hero-icon>
                                  </div>
                                  <div>Unassign</div>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </mat-tab>
          </mat-tab-group>
        </ng-container>
        <ng-template #formOnly>
          <cb-project-form
            [project]="project"
            (close)="done()"
          ></cb-project-form>
        </ng-template>
      </div>
    </div>
    <p-dialog
      header="Project Tasks"
      [modal]="true"
      [(visible)]="projectModalVisible"
      [closeOnEscape]="true"
      [closable]="true"
      position="top"
      styleClass="w-full max-w-screen-xl"
    >
      <p class="my-2">Task quality and notes can be updated here.</p>
      <div class="grid grid-cols-1 sm:grid-cols-2 gap-2">
        <div
          class="px-2 py-4 border border-mid-gray rounded-md my-4"
          *ngFor="let task of project.tasks"
        >
          <div class="text-lg font-bold" [title]="task.notes">
            {{ task.title }}
          </div>
          <div class="py-2">
            <cb-quality-selector
              [(quality)]="task.quality"
            ></cb-quality-selector>
          </div>
          <div class="py-2" *ngIf="task.notes">
            <div class="text-xs uppercase font-bold flex items-center gap-2">
              <div>notes</div>
              <div class="opacity-60 hover:opacity-100 transition">
                <button
                  type="button"
                  title="Edit notes"
                  (click)="toggleNoteEditingFor(task.entryId)"
                >
                  <ng-container *ngIf="isEditing(task.entryId); else pencil">
                    <hero-icon
                      name="x"
                      type="outline"
                      class="w-4 h-4"
                    ></hero-icon>
                  </ng-container>
                  <ng-template #pencil>
                    <hero-icon
                      name="pencil-alt"
                      type="outline"
                      class="w-4 h-4"
                    ></hero-icon>
                  </ng-template>
                </button>
              </div>
            </div>
            <div *ngIf="isEditing(task.entryId); else noteDisplay">
              <textarea cbTextarea [(ngModel)]="task.notes"></textarea>
            </div>
            <ng-template #noteDisplay>
              <div class="truncate">
                {{ task.notes }}
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="py-5 px-2 flex justify-end items-center min-w-full">
        <div class="pr-1">
          <button
            type="button"
            cbButton
            (click)="continueStatusChange(project)"
          >
            Continue
          </button>
        </div>
        <div class="pl-1">
          <button
            type="button"
            (click)="statusChangeDone()"
            cbButton
            colour="bg-brand-deep"
          >
            Cancel
          </button>
        </div>
      </div>
    </p-dialog>
  </cb-body>
</cb-authorized>
