import { Component, OnInit, EventEmitter } from '@angular/core';
import { Company } from '@app/domain/model';
import { Observable, of } from 'rxjs';
import { CompaniesListService } from './companies-list.service';
import { UserContextService } from '@app/user-context.service';
import { Router } from '@angular/router';
import { HeaderButton, HeaderControl } from '@app/components/page-header/controls';
import { RoleName } from '@app/roles';
import { map } from 'rxjs/operators';

@Component({
  selector: 'cb-companies-list',
  templateUrl: './companies-list.component.html',
  styles: [
  ]
})
export class CompaniesListComponent implements OnInit {

  companies$!: Observable<Company[]>;
  selectedCompanies: Company[] = [];

  controls$!: Observable<HeaderControl[]>;
  addCompanyEmitter = new EventEmitter<void>();

  constructor(
    private service: CompaniesListService,
    private context: UserContextService,
    private router: Router
  ) {

  }

  ngOnInit(): void {

    this.controls$ = this.context.accountStatus$.pipe(
      map((status) => {
        return [
          new HeaderButton(
            "Add company",
            "plus-circle",
            () => this.router.navigate(['/companies', 'add']),
            "CompanyManager",
            undefined,
            of(status.canAddClients)
          )
        ]
      })
    )

    this.companies$ = this.service.companies$.pipe(
      map(companies => companies.map(company => {
        const tags = [
          company.type.toString()
        ];

        let owner = "";

        if (company.ownerPersonName || company.ownerCompanyName) {
          owner = "owner: ";
        }

        if (company.ownerPersonName) {
          owner += company.ownerPersonName;
        }

        if (company.ownerPersonName && company.ownerCompanyName) {
          owner += " at ";
        }

        if (company.ownerCompanyName) {
          owner += company.ownerCompanyName;
        }

        owner && tags.push(owner);

        tags.push(`created: ${new Date(company.createdOn).toDateString()}`);
        tags.push(`modified: ${new Date(company.modifiedOn).toDateString()}`)

        return {
          ...company,
          _listTags: tags
        }
      }))
    );

    this.addCompanyEmitter.subscribe(() => {
      this.router.navigate(['/companies', 'add']);
    });
  }

  viewCompany(company: Company): void {
    this.router.navigate(['/c', company.id, 'overview']);
  }

}
