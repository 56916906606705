import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CheckboxModule } from 'primeng/checkbox';
import { SkeletonModule } from 'primeng/skeleton';
import { SidebarModule } from 'primeng/sidebar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ComponentsModule } from '@app/components/components.module';
import { IconsModule } from '@app/app-icons.module';
import { StylesModule } from '@app/styles/styles.module';
import { TasksListComponent } from './tasks-list/tasks-list.component';
import { TaskDetailComponent } from './task-detail/task-detail.component';
import { AddCategoryComponent } from './add-category/add-category.component';
import { AddGroupComponent } from './add-group/add-group.component';

@NgModule({
  declarations: [
    TasksListComponent,
    TaskDetailComponent,
    AddCategoryComponent,
    AddGroupComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    IconsModule,
    StylesModule,
    RouterModule,
    SelectButtonModule,
    CheckboxModule,
    SkeletonModule,
    SidebarModule,
    AutoCompleteModule
  ]
})
export class TasksModule { }
