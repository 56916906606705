export type ProductId = "Free" | "5" | "10" | "25" | "50" | "100" | "250" | "500" | "1000";

export type ProductNode = Node<Product>;

export type Pricing = {
    amount: string;
    schedule: "month" | "year" | "forever";
}

class Product {
    constructor(
        public id: ProductId,
        public detail: {
            maxClients: number,
            maxDocuments: number,
            pricing: Pricing,
        }
    ) { }
}

class Node<T> {
    public next: Node<T> | null = null;
    public prev: Node<T> | null = null;
    constructor(public data: T) { }
}

export const free = new Product("Free", {
    maxClients: 1,
    maxDocuments: 2,
    pricing: {
        amount: "Free",
        schedule: "forever"
    }
});

const clear5 = new Product("5", {
    maxClients: 5,
    maxDocuments: 100,
    pricing: {
        amount: "£199.00",
        schedule: "month"
    }
});

const clear10 = new Product("10", {
    maxClients: 10,
    maxDocuments: 200,
    pricing: {
        amount: "£249.00",
        schedule: "month"
    }
});

const clear25 = new Product("25", {
    maxClients: 25,
    maxDocuments: 500,
    pricing: {
        amount: "£349.00",
        schedule: "month"
    }
});

const clear50 = new Product("50", {
    maxClients: 50,
    maxDocuments: 750,
    pricing: {
        amount: "£449.00",
        schedule: "month"
    }
});

const clear100 = new Product("100", {
    maxClients: 100,
    maxDocuments: 1000,
    pricing: {
        amount: "£599.00",
        schedule: "month"
    }
});

const clear250 = new Product("250", {
    maxClients: 250,
    maxDocuments: 2000,
    pricing: {
        amount: "£749.00",
        schedule: "month"
    }
});

const clear500 = new Product("500", {
    maxClients: 500,
    maxDocuments: 5000,
    pricing: {
        amount: "£999.00",
        schedule: "month"
    }
});

const clear1000 = new Product("1000", {
    maxClients: 1000,
    maxDocuments: 10000,
    pricing: {
        amount: "£1,299.00",
        schedule: "month"
    }
});

class LinkedProducts {
    private head: Node<Product> | null = null;

    constructor() {
        this.insertAtEnd(free);
        this.insertAtEnd(clear5);
        this.insertAtEnd(clear10);
        this.insertAtEnd(clear25);
        this.insertAtEnd(clear50);
        this.insertAtEnd(clear100);
        this.insertAtEnd(clear250);
        this.insertAtEnd(clear500);
        this.insertAtEnd(clear1000);
    }

    private insertAtEnd(product: Product): Node<Product> {
        const node = new Node(product);

        if (!this.head) {
            this.head = node;
            return node;
        }

        const getLast = (node: Node<Product>): Node<Product> => {
            return node.next ? getLast(node.next) : node;
        }

        const last = getLast(this.head);
        node.prev = last;
        last.next = node;

        return node;
    }

    public search(comparator: (data: Product) => boolean): Node<Product> | null {
        const checkNext = (node: Node<Product>): Node<Product> | null => {
            if (comparator(node.data)) {
                return node;
            }
            return node.next ? checkNext(node.next) : null;
        };

        return this.head ? checkNext(this.head) : null;
    }

    toArray(): Product[] {
        const products: Product[] = [];
        let current = this.head;

        while (current !== null) {
            products.push(current.data);
            current = current.next;
        }

        return products;
    }
}

export const products = new LinkedProducts();