import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[cbTextarea]'
})
export class TextareaDirective {

  styles = [
    "px-2", 
    "py-4", 
    "mt-2", 
    "min-w-full",
    "form-textarea", 
    "rounded-md", 
    "focus:ring-brand-primary", 
    "focus:border-brand-primary"
  ]

  @HostBinding("class")
  get elementStyles(): string {
    return this.styles.join(" ");
  }

  constructor() { }
}
