import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CompanySearchResultItem } from './company-search.model';
import { environment as env } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompaniesHouseService {

  constructor(private httpClient: HttpClient, private toast: ToastrService) { }

  searchCompanies(q: string): Observable<CompanySearchResultItem[]> {

    if(!q) {
      return of([]);
    }

    const apiUrl = `${env.api.baseUrl}/v${env.api.version}/companies/search?q=${encodeURIComponent(q)}`;

    return this.httpClient.get<CompanySearchResult>(apiUrl).pipe(
      catchError((err: HttpErrorResponse) => {
        this.toast.error(err.message, err.name);
        return throwError(err);
      }),
      map(x => x.result)
    )    
  }
}

interface CompanySearchResult {
  result: CompanySearchResultItem[];
  totalResults: number;
}