import { Component, OnInit, Inject, Renderer2, ViewChild, ElementRef, HostBinding, HostListener, Input } from '@angular/core';
import { UserContext, UserContextService } from '@app/user-context.service';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { AuthService } from '@auth0/auth0-angular';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'cb-sub-nav',
  templateUrl: './sub-nav.component.html',
  animations: [
    trigger("menuTrigger", [
      state("open", style({
        opacity: 1,
        visibility: "visible",
        transform: "translateY(1rem)"
      })),
      state("closed", style({
        opacity: 0,
        transform: "translateY(-1rem)",
        visibility: "hidden"
      })),
      transition("open <=> closed", [
        animate("100ms")
      ])
    ])
  ]
})
export class SubNavComponent implements OnInit {

  user$!: Observable<UserContext | null>;
  menuVisible = false;

  @ViewChild("menuToggle") menuToggle!: ElementRef;
  @ViewChild("menu") menu!: ElementRef;
  @Input() splitMode?: "top" | "bottom";

  constructor(
    private userContext: UserContextService,
    public auth: AuthService,
    @Inject(DOCUMENT) private doc: Document
  ) {
  }

  ngOnInit(): void {
    this.user$ = this.userContext.user$;
  }

  logout(): void {
    this.userContext.clear();
    this.auth.logout({ returnTo: this.doc.location.origin });
  }

  toggleMenuVisibility() {
    this.menuVisible = !this.menuVisible;
  }

  @HostListener("document:click", ["$event"])
  @HostListener("document:keydown.escape", ["$event"])
  offClick(event: Event) {
    if (!this.menuToggle.nativeElement.contains(event.target)) {
      this.menuVisible = false;
    }
  }
}
