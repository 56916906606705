import { IntercomInstance } from "./types";

export const init = async () => {
  var w = window;
  var ic = w.Intercom;
  if (w.intercomSettings) {
    w.intercomSettings.installation_type = "npm-package";
  }
  if (typeof ic === "function") {
    ic("reattach_activator");
    ic("update", w.intercomSettings);
  } else {
    var d = document;
    var i: any = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args: any) {
      i.q.push(args);
    };
    w.Intercom = i;
    var l = function () {
      var s = d.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src =
        "https://widget.intercom.io/widget/" + window.intercomSettings?.app_id;
      var x = d.getElementsByTagName("script")[0];
      x.parentNode?.insertBefore(s, x);
    };
    if (document.readyState === "complete") {
      l();
    } else if (w.attachEvent) {
      w.attachEvent("onload", l);
    } else {
      w.addEventListener("load", l, false);
    }
  }
};

export let ref: IntercomInstance | undefined = undefined;
export const getIntercomRef = () => {
  if (typeof window !== "undefined" && !ref) {
    ref = window.Intercom;
  }
  if (!ref || typeof window === "undefined") {
    console.warn("Intercom not booted or setup incomplete.");
    if (typeof window === "undefined") {
      console.warn("Are you sure you are running on client-side?");
    }
    return () => {};
  }
  return ref;
};
