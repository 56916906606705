import { Component, OnInit, Input } from '@angular/core';

type LogoSize = "xs" | "verysmall" | "small" | "medium" | "large";
type LogoStyle = "light" | "dark";

@Component({
  selector: 'cb-logo',
  templateUrl: './logo.component.html'
})
export class LogoComponent implements OnInit {

  @Input() size?: LogoSize;
  @Input() theme: LogoStyle = "light";

  private sizeMap = {
    "xs": "lg:max-w-screen-sm",
    "verysmall": "max-w-xs lg:max-w-full",
    "small": "lg:max-w-sm",
    "medium": "lg:max-w-md",
    "large": "lg:max-w-lg"
  }

  private sourceMap = {
    "light": "LogoWhite.png",
    "dark": "CBMaster.png"
  }

  cssClass!: string;
  logoSource!: string;

  constructor() { }

  ngOnInit(): void {
    this.cssClass = this.size ? this.sizeMap[this.size] : "";
    this.logoSource = `assets/${this.sourceMap[this.theme]}`;
  }

}
