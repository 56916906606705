import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[cbValidationMessage]'
})
export class ValidationMessageDirective {

  @HostBinding("class")
  get elementStyles(): string {
    return "mt-1 pl-2 py-2 text-sm font-bold text-brand-danger bg-white absolute";
  }

  constructor() { }

}
