import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { ToastrModule } from 'ngx-toastr';
import { IconsModule } from './app-icons.module';
import { DndModule } from 'ngx-drag-drop';

import { SidebarModule } from 'primeng/sidebar';
import { SelectButtonModule } from 'primeng/selectbutton';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TableModule } from 'primeng/table';
import { FileUploadModule } from 'primeng/fileupload';

import { environment as env } from '@env';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { AuthModule } from '@auth0/auth0-angular';
import { ErrorComponent } from './pages/error/error.component';
import { MenuModule } from 'primeng/menu';
import { DataGridComponent } from './components/data-grid/data-grid.component';
import { ModalWindowComponent } from './components/modal-window/modal-window.component';
import { QuestionPunctuationPipe } from './domain/question-punctuation.pipe';
import { LoaderComponent } from './components/loader/loader.component';

import { StylesModule } from '@app/styles/styles.module';
import { BenchmarkModule } from '@app/benchmark/benchmark.module';
import { UsersModule } from '@app/users/users.module';
import { NavigationModule } from '@app/navigation/navigation.module';
import { ComponentsModule } from '@app/components/components.module';
import { CompaniesModule } from '@app/companies/companies.module';
import { InvitationsModule } from '@app/invitations/invitations.module';
import { ReportModule } from '@app/report/report.module';
import { DocumentsModule } from '@app/documents/documents.module';
import { DashboardModule } from '@app/dashboard/dashboard.module';
import { TasksModule } from '@app/tasks/tasks.module';
import { SettingsModule } from '@app/settings/settings.module';
import { ProjectsModule } from '@app/projects/projects.module';
import { RoadmapModule } from '@app/roadmap/roadmap.module';
import { UserContextInterceptor } from '@app/user-context.interceptor';
import { JourneyModule } from '@app/journey/journey.module';
import { SignUpModule } from './sign-up/sign-up.module';
import { ClientsModule } from './clients/clients.module';
import { CustomersModule } from '@app/customers/customers.module';
import { setAppInjector } from './app-injector';

interface Analytics {
  identify(
    userId: string,
    context: {
      name: string;
      email: string;
      companyId: string;
    }
  ): void;

  page(name?: string, context?: any): void;
}

declare global {
  interface Window {
    analytics?: Analytics;
  }
}

@NgModule({
  declarations: [
    AppComponent,
    SideBarComponent,
    ErrorComponent,
    DataGridComponent,
    ModalWindowComponent,
    QuestionPunctuationPipe,
    LoaderComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    StylesModule,
    DndModule,
    HttpClientModule,
    MenuModule,
    ReactiveFormsModule,
    LoadingBarHttpClientModule,
    SelectButtonModule,
    DropdownModule,
    TooltipModule,
    DialogModule,
    InputTextareaModule,
    TableModule,
    SidebarModule,
    FileUploadModule,
    IconsModule,
    BenchmarkModule,
    UsersModule,
    AppRoutingModule,
    NavigationModule,
    ComponentsModule,
    CompaniesModule,
    InvitationsModule,
    ReportModule,
    DocumentsModule,
    DashboardModule,
    TasksModule,
    SettingsModule,
    ProjectsModule,
    RoadmapModule,
    JourneyModule,
    SignUpModule,
    ClientsModule,
    CustomersModule,
    AuthModule.forRoot({
      ...env.auth,
      redirectUri: window.location.origin,
      useRefreshTokens: true,
      scope: 'openid profile offline_access',
      httpInterceptor: {
        allowedList: [
          {
            uri: `${env.api.baseUrl}/v1/signup`,
            allowAnonymous: true,
          },
          {
            uri: `${env.api.baseUrl}/v1/*`,
            tokenOptions: {
              audience: 'https://app.clearbenchmark.com',
            },
          },
        ],
      },
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UserContextInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    setAppInjector(injector);
  }
}
