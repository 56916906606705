import { Injectable } from '@angular/core';
import { ReplaySubject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MetricsRefreshService {

  clientCount$ = new BehaviorSubject<number>(0);
  customerCount$ = new BehaviorSubject<number>(0);

  constructor() { }
}
