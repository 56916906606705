import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  HeaderControl,
  HeaderButton,
} from '@app/components/page-header/controls';
import { makeCompanyId } from '@app/interfaces';
import { UserContextService } from '@app/user-context.service';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  switchMap,
} from 'rxjs/operators';
import { DashboardService, MspActivity } from '../dashboard.service';
import { MspDashboardClient } from '../dashboard.service';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionService } from '@app/subscription.service';
import { CheckSubscriptionActivator } from '@app/subscription-activator';

@Component({
  selector: 'cb-msp-dashboard',
  templateUrl: './msp-dashboard.component.html',
  styleUrls: ['./msp-dashboard.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class MspDashboardComponent implements OnInit, OnDestroy {
  controls$!: Observable<HeaderControl[]>;
  clientList$: Observable<MspDashboardClient[]>;
  activityList$: Observable<MspActivity[]>;
  clientsPieData: any;
  budgetChartData: any;

  addClientActivated = false;
  addCustomerActivated = false;

  private subscriptions: Subscription[] = [];
  private addCustomerClick = new EventEmitter<void>();

  filter = new BehaviorSubject<string | null>(null);

  private clientClickSub: Subscription | null = null;

  constructor(
    public context: UserContextService,
    private service: DashboardService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private subscriptionsService: SubscriptionService,
    private activator: CheckSubscriptionActivator
  ) {
    const mspUser$ = this.context.accountStatus$.pipe(
      map((status) => [
        new HeaderButton(
          'Add Client',
          'plus-circle',
          () => this.addClientClick(),
          'CompanyManager',
          undefined,
          of(!status.accountChangePending || status.canAddClients)
        ),
      ])
    );

    const cbUser$ = of([
      new HeaderButton(
        'Add Customer',
        'plus-circle',
        () => (this.addCustomerActivated = true),
        'CustomerManager'
      ),
    ]);

    this.controls$ = this.context.user$.pipe(
      switchMap((user) =>
        user?.hasRole('CustomerManager') ? cbUser$ : mspUser$
      )
    );

    if (this.route.snapshot.queryParams.filter) {
      this.filter.next(this.route.snapshot.queryParams.filter);
    }

    this.clientList$ = this.route.queryParams.pipe(
      switchMap((params) =>
        this.service.getServiceProviderDashboard(params.filter ?? null)
      )
    );

    this.filter
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((filter) => {
        this.router.navigate([], {
          queryParams: { filter: filter || null },
          queryParamsHandling: 'merge',
        });
      });

    this.activityList$ = this.service.getActivityForMsp();
  }

  private addClientClick(): void {
    this.activator
      .canActivateFor('client', undefined, 'redirectOnReject')
      .pipe(
        filter((canActivate) => canActivate),
        first()
      )
      .subscribe(() => {
        this.addClientActivated = true;
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.clientClickSub?.unsubscribe();
  }

  ngOnInit(): void {
    if (this.context.currentCompanySnapshot === null) {
      this.subscriptions.push(
        this.context.myCompany$.subscribe((company) => {
          this.context.currentCompany$.next({
            name: company.name,
            id: makeCompanyId(company.id),
            type: company.type,
          });
        })
      );
    }
  }
}
