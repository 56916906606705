import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cb-header-column',
  templateUrl: './header-column.component.html',
  styles: [
  ]
})
export class HeaderColumnComponent implements OnInit {

  @Input() span?: number;

  @HostBinding("class") get cssClass(): string {
    if(this.span) {
      return `col-span-${this.span}`;
    }
    
    return "";
  }

  constructor() { }

  ngOnInit(): void {
  }

}
