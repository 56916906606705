<div class="px-2 py-4 h-full">
    <div class="w-full max-w-3xl mx-auto py-4">
        <h1 class="text-3xl text-brand-deep">Add Group</h1>
    </div>
    <div class="py-4 h-full">
        <form [formGroup]="form" novalidate (ngSubmit)="submit()" autocomplete="off">
            <div class="flex flex-col justify-between h-full">
                <div class="mt-5">
                    <label cbLabel>
                        Name
                    </label>
                    <input type="text" formControlName="groupName" cbInput />
                    <div *ngIf="isInvalid('groupName')" class="text-brand-danger">
                        Please enter a group name
                    </div>
                </div>
                <div class="mt-20">
                    <button type="submit" cbButton colour="bg-brand-primary" [disabled]="!form.valid">
                        <div class="flex justify-between items-center">
                            <hero-icon name="check" type="outline" class="w-5 h-5 mr-2"></hero-icon>
                            <p>Save</p>
                        </div>
                    </button>
                </div>
            </div>            
        </form>    
    </div>
    
</div>