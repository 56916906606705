import { Observable } from "rxjs";

export function isNotNull<T>(value: T | null | undefined): value is NonNullable<T> {
    return value !== null && value !== undefined;
}

export function hasAnyOf<T>(set: Set<T>, input: T[]): boolean {
    for (const item of input) {
        if (set.has(item)) {
            return true;
        }
    }
    return false;
}
