import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cb-metric-value',
  templateUrl: './metric-value.component.html',
  styles: [
  ]
})
export class MetricValueComponent implements OnInit {

  @Input() metric: number = 0;
  @Input() text: string = "";
  @Input() suffixText: string = "";
  @Input() pluralise = true;

  constructor() { }

  ngOnInit(): void {
  }

}
