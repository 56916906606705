<span *ngIf="textOnly;else full">
    {{levelText}}
</span>
<ng-template #full>
    <div class="p-2 text-center" *ngIf="mode === 'break'">
        <div *ngIf="!textOnly" class="text-3xl">
            {{level || "0"}}
        </div>
        <div class="text-brand-deep font-bold text-sm">
            {{levelText}}
        </div>    
    </div>
    <div *ngIf="mode === 'inline'">
        <div>{{levelText}}</div>
        <div class="text-xs">level {{level || 0}}</div>
    </div>
</ng-template>
