import { Component, OnInit, Input } from '@angular/core';
import { CompanyDetail } from '@app/domain/model';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RoadmapByDate, CompanyRoadmapService } from './company-roadmap.service';

@Component({
  selector: 'cb-company-roadmap',
  templateUrl: './company-roadmap.component.html',
  styles: [
  ]
})
export class CompanyRoadmapComponent implements OnInit {

  dataAvailable = true;
  loading = false;

  roadmapData!: RoadmapByDate;

  @Input() company!: CompanyDetail;

  constructor(
    private service: CompanyRoadmapService
  ) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.roadmapData = await this.service.getCompanyRoadmapOverview(this.company.id).toPromise();
    this.loading = false;

    this.dataAvailable = this.roadmapData.roadmap.length > 0;
  }

}
