import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Level } from '@app/components/level-label/level-label.component';
import { HttpErrorService } from '@app/services/http-error.service';
import { environment as env } from '@env';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Client } from '@app/clients/add-client/add-client.component';

export interface MspDashboardClient {
  id: string;
  name: string;
  level: Level | null;
  roadmapBudget: number;
  score: number;
  lastMeetingAt?: string;
  accountManager?: string;
  lastModified: string;
}

export interface MspActivity {
  clientName: string;
  createdBy: string;
  activityType: |
  "AccountCreated" |
  "BenchmarkUpdated" |
  "ReportSent" |
  "RoadmapSent" |
  "ProjectClosed" |
  "NewClientMeeting" |
  "LevelChange" |
  "RiskChange" |
  "AccountManagerChanged" |
  "ClientPeopleChanged" |
  "Anniversary" |
  "UserCreated" |
  "UserSignedUp";
  mood: |
  "Neutral" |
  "Positive" |
  "Negative" |
  "Celebration";
  title: string;
  detail: string | null;
  riskScore: number;
  createdDate: string;
}

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private httpClient: HttpClient,
    private errorHandler: HttpErrorService
  ) { }

  getServiceProviderDashboard(filter: string | null): Observable<MspDashboardClient[]> {
    let url = `${env.api.baseUrl}/v${env.api.version}/msp/dashboard`;
    const params: string[] = [];

    if (filter) {
      params.push(`filter=${encodeURIComponent(filter)}`);
    }

    if (params.length) {
      url += `?${params.join("&")}`;
    }

    return this.httpClient.get<MspDashboardClient[]>(url).pipe(
      this.errorHandler.handleHttpError
    );
  }

  getActivityForMsp(): Observable<MspActivity[]> {
    const { baseUrl } = env.api;
    const url = `${baseUrl}/v1/msp/activity`;
    return this.httpClient.get<MspActivity[]>(url).pipe(
      this.errorHandler.handleHttpError
    );
  }

  addClient(client: Client): Observable<string> {
    const { baseUrl } = env.api;
    const url = `${baseUrl}/v1/clients`;
    return this.httpClient.post<{ id: string }>(url, client).pipe(
      this.errorHandler.handleHttpError,
      map((res) => res.id)
    );
  }
}
