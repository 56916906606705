<ng-container > <!-- *ngIf="items$ | async as items" -->
    <!-- *ngIf="items.length;else noData" -->
    <div class="divide-y divide-bright-gray border border-gray-300" [ngClass]="styles">
        <ng-content></ng-content>
        <!-- <ng-container *ngFor="let item of items">
            <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item}"></ng-container>
        </ng-container> -->
    </div>    
</ng-container>
<ng-content select="[empty]">
</ng-content>
<ng-template #noData>
    <div class="px-4 py-6 flex justify-center items-center rounded-md bg-white shadow-md">
        <div class="flex flex-col gap-4 justify-center text-xl">
            <ng-container *ngTemplateOutlet="noDataTemplate">
            </ng-container>
        </div>
    </div>    
</ng-template>


