import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleGuard } from '@app/auth/roles/role.guard';
import { RoleName } from '@app/roles';
import { ProjectsListComponent } from './projects-list/projects-list.component';
import { ProjectDetailComponent } from './project-detail/project-detail.component';
import { NewProjectComponent } from './new-project/new-project.component';

export const ProjectRoutes: Routes = [
    RoleGuard.build("projects", ProjectsListComponent, [RoleName.ProjectViewer]),
    RoleGuard.build("projects/new", NewProjectComponent, [RoleName.ProjectManager]),
    RoleGuard.build("projects/:id", ProjectDetailComponent, [RoleName.ProjectViewer])   
];

@NgModule({
    imports: [RouterModule],
    exports: [RouterModule]
})
export class ProjectsRouterModule{}