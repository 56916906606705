import { Component, OnInit, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'cb-modal-window',
  templateUrl: './modal-window.component.html',
  styles: [
  ]
})
export class ModalWindowComponent implements OnInit {

  isVisible: boolean = false;
  
  @Input() title: string = "";
  @Input() content!: TemplateRef<any>;

  constructor() { }

  ngOnInit(): void {
  }

  show(): void {
    this.isVisible = true;
  }

  close(): void {
    this.isVisible = false;
  }

}
