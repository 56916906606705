import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleGuard } from '@app/auth/roles/role.guard';
import { RoleName } from '@app/roles';
import { ProjectBoardComponent } from './project-board/project-board.component';

export const RoadmapRoutes: Routes = [
    RoleGuard.build("roadmap", ProjectBoardComponent, [RoleName.RoadmapViewer])
];

@NgModule({
    imports: [RouterModule],
    exports: [RouterModule]
})
export class RoadmapRouterModule{}