import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '@app/components/components.module';
import { SignUpFormComponent } from './sign-up-form/sign-up-form.component';
import { StylesModule } from '@app/styles/styles.module';
import { HeroIconModule, externalLink } from 'ng-heroicon';

@NgModule({
  declarations: [
    SignUpFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    StylesModule,
    HeroIconModule.withIcons({
      externalLink
    })
  ]
})
export class SignUpModule { }
