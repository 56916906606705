import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { SidebarService } from './sidebar.service';

@Component({
  selector: 'cb-side-bar',
  templateUrl: './side-bar.component.html'
})
export class SideBarComponent implements OnInit {

  authenticated$!: Observable<boolean>;
  
  constructor(
    private auth: AuthService, 
    public sidebar: SidebarService
  ) {
  }

  ngOnInit(): void {
    this.authenticated$ = this.auth.isAuthenticated$;
  }

  toggleSideBar(): void {
    this.sidebar.toggle();
  }

}
