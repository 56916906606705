<form [formGroup]="form" (ngSubmit)="saveProfile()" class="flex flex-col h-full justify-between border-box">
    <main class="w-full">
        <h3 class="text-xl text-center mb-10">Profile</h3>
        <div class="mb-10">
            <label for="email" cbLabel>Email address</label>            
            <p>{{user?.email}}</p>
        </div>
        <div class="mb-10">
            <label for="name" cbLabel>Full name</label>
            <input id="name" placeholder="Full name" type="text" formControlName="name" cbInput>
        </div>
    </main>
    <footer class="px-6 w-full mt-10">
        <div class="flex justify-end items-center gap-6">
            <button type="button" (click)="changePassword($event)" cbSecondaryButton>Change password</button>
            <button type="submit" cbButton>Update profile</button>
        </div>
    </footer>
</form>
<p-confirmPopup></p-confirmPopup>