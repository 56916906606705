import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cb-body-card',
  templateUrl: './body-card.component.html',
  styles: [
  ]
})
export class BodyCardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
