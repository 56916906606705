import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleGuard } from '@app/auth/roles/role.guard';
import { RoleName } from '@app/roles';

import { TasksListComponent } from './tasks-list/tasks-list.component';
import { TaskDetailComponent } from './task-detail/task-detail.component';

const routes: Routes = [
    RoleGuard.build("settings/tasks", TasksListComponent, [RoleName.TasksManager]),
    RoleGuard.build("settings/tasks/add", TaskDetailComponent, [RoleName.TasksManager]),
    RoleGuard.build("settings/tasks/:id", TaskDetailComponent, [RoleName.TasksManager])
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TasksRoutingModule {}