import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { sectors } from '@data/static';
import { Observable } from 'rxjs';
import { SignUpService } from '../sign-up.service';

@Component({
  selector: 'cb-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styles: [
  ]
})
export class SignUpFormComponent implements OnInit {

  form!: FormGroup;
  sectorList = [...sectors];
  complete = false;
  working = false;

  constructor(private fb: FormBuilder, private service: SignUpService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: ["", [Validators.required, Validators.maxLength(100)]],
      email: ["", [Validators.required, Validators.maxLength(255), Validators.email]],
      mspName: ["", [Validators.required, Validators.maxLength(100)]],
      sector: [null, [Validators.required]]
    });
  }

  submit(): void {
    this.working = true;
    const { name, email, mspName, sector } = this.form.getRawValue();
    this.service.signup({
      name,
      email,
      mspName,
      sector
    }).subscribe(() => {
      this.working = false;
      this.complete = true;
    });
  }

  firstName(): string {
    const name: string | undefined = this.form.get("name")?.value;
    if (!name) {
      return "";
    }

    return name.split(" ")[0];
  }

}
