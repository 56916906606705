import { Component, OnInit } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { RoleGroup, User } from '@app/users/users.model';
import { UserListService } from './user-list.service';
import { UserContextService } from '@app/user-context.service';
import { map, switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

interface UserListItem extends User {
  isContact: boolean;
}

@Component({
  selector: 'cb-users-list',
  templateUrl: './users-list.component.html',
  styles: [
  ]
})
export class UsersListComponent implements OnInit {


  data$!: Observable<UserListItem[]>;
  selectedUsers: User[] = [];
  searchText: string | undefined;

  private reload = new BehaviorSubject<number>(1);

  constructor(
    private service: UserListService,
    private context: UserContextService,
    private toastr: ToastrService
  ) {

    this.data$ = this.reload.pipe(
      switchMap(_ => this.service.getCompanyUsers(this.context.currentCompanySnapshot!.id)),
      map((list: User[]): UserListItem[] => list.map(user => {
        return {
          ...user,
          isContact: user.roleGroup === "Contact"
        };
      }))
    );
  }

  ngOnInit(): void {

  }

  setSearchText(value: string | undefined): void {
    this.searchText = value;
  }

  deleteUserInvite(event: Event, user: User): void {
    event.stopPropagation();
    if (user.invitationId) {
      this.service.deleteInvitation(user.invitationId).subscribe(_ => {
        this.toastr.success("User invitation deleted!");
        this.reload.next(1);
      });
    }
  }

}
