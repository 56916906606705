import { HeroIconName } from 'ng-heroicon/lib/icons/icons-names';
import { EventEmitter } from '@angular/core';
import { RoleName, Role } from '@app/roles';
import { Observable, of, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  shareReplay,
} from 'rxjs/operators';
import { Feature } from '@app/user-context.service';

type ControlType =
  | 'Button'
  | 'Dropdown'
  | 'Date'
  | 'Search'
  | 'LinkButton'
  | 'MultiSelect';

export interface HeaderControl {
  type: ControlType;
}

export class HeaderButton implements HeaderControl {
  type: ControlType = 'Button';
  loading = false;
  private onloading = new EventEmitter<void>();
  private onloaded = new EventEmitter<void>();

  constructor(
    public text: string,
    public icon?: HeroIconName,
    public action?: () => void,
    public role?: Role,
    public feature?: Feature,
    public enabled?: Observable<boolean>
  ) {
    this.onloading.subscribe(() => (this.loading = true));
    this.onloaded.subscribe(() => (this.loading = false));
  }

  startLoading() {
    this.onloading.emit();
  }

  stopLoading() {
    this.onloaded.emit();
  }
}

export class HeaderLinkButton extends HeaderButton {
  type: ControlType = 'LinkButton';

  constructor(
    public text: string,
    public action: () => void,
    public role?: Role,
    public visible: boolean = true
  ) {
    super(text, undefined, action, role);
  }
}

export class HeaderSelectList implements HeaderControl {
  type: ControlType = 'Dropdown';

  constructor(
    public id: string,
    public options: Observable<HeaderSelectListOption[]>,
    public changeEvent?: EventEmitter<string>,
    public selectedOption?: string,
    public label?: string
  ) {}
}

export interface HeaderSelectListOption {
  text: string;
  value: string;
  isDefaultValue?: boolean;
}

export class HeaderMultiSelectList implements HeaderControl {
  type: ControlType = 'MultiSelect';

  constructor(
    public id: string,
    public options: Observable<HeaderSelectListOption[]>,
    public changeEvent?: EventEmitter<string[]>,
    public selectedOptions?: string[],
    public label?: string
  ) {}
}

export class HeaderDatePicker implements HeaderControl {
  type: ControlType = 'Date';

  constructor(
    public id: string,
    public label: string = 'Date',
    public dateChangedEvent?: EventEmitter<Date | null>,
    public selectedDate?: Date
  ) {}
}

interface HeaderSearchConfig {
  id: string;
  label?: string;
  placeholder?: string;
  changed?: EventEmitter<string>;
  value?: string;
}

export class HeaderSearch implements HeaderControl {
  type: ControlType = 'Search';
  debouncer = new Subject<string>();

  constructor(public config: HeaderSearchConfig) {
    this.debouncer
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((v) => this.config.changed?.emit(v));
  }

  onChange(value: string, debounce: boolean): void {
    if (debounce) {
      this.debouncer.next(value);
    } else {
      this.config.changed?.emit(value);
    }
  }
}

export function levelOptions(
  allowAll: boolean
): Observable<HeaderSelectListOption[]> {
  let levelOptions: HeaderSelectListOption[] = [];

  if (allowAll) {
    levelOptions.push({ text: 'All', value: '' });
  }

  for (let l = 1; l <= 5; l++) {
    levelOptions.push({
      text: `Level ${l}`,
      value: l.toString(),
    });
  }

  return of(levelOptions).pipe(shareReplay(1));
}
