import { Component, OnInit, Input, TemplateRef, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { Menu } from 'primeng/menu';

export interface Option {
  text: string;
  icon?: any;
  action?: (option: Option) => void;
  routerLink?: string[];
}

@Component({
  selector: 'cb-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.css']
})
export class DataGridComponent implements OnInit {

  @Input() title: string = "";
  @Input() header!: TemplateRef<any>;
  @Input() body!: TemplateRef<any>;
  @Input() options: MenuItem[] = [];
  @Input() actions: Option[] = [];

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  actionButtonClick(option: Option): void {
    if(option.action) {
      option.action(option);
    }

    if(option.routerLink) {
      this.router.navigate(option.routerLink);
    }
  }
}
