<form [formGroup]="form" (ngSubmit)="submit()" novalidate>
  <input type="hidden" formControlName="registeredName" />
  <h3 class="text-xl mb-5">
    {{ formTitle }}
  </h3>
  <div
    class="mt-5 flex items-center justify-end"
    *ngIf="form.get('logoUrl')?.value"
  >
    <img
      [src]="form.get('logoUrl')?.value"
      [alt]="form.get('displayName')?.value"
      width="200px"
    />
  </div>
  <div class="mt-5">
    <div class="md:flex gap-4">
      <div class="flex-1 mt-5 md:mt-0">
        <cb-form-label for="display_name"> Display name </cb-form-label>
        <input
          id="display_name"
          required
          maxlength="255"
          #companyNameInput
          name="displayName"
          formControlName="displayName"
          type="text"
          cbInput
        />
        <div
          cbValidationMessage
          *ngIf="
            form.get('displayName')?.errors?.required &&
            (form.get('displayName')?.dirty || form.get('displayName')?.touched)
          "
        >
          Display name is required
        </div>
      </div>
      <div class="flex-1 mt-5 md:mt-0">
        <cb-form-label for="account_manager"> Account Manager </cb-form-label>
        <select
          cbSelect
          required
          id="account_manager"
          name="accountManagerId"
          formControlName="accountManagerId"
        >
          <option *ngFor="let member of members$ | async" [value]="member.id">
            {{ member.name }}
          </option>
        </select>
        <div
          cbValidationMessage
          *ngIf="
            form.get('accountManagerId')?.errors?.required &&
            (form.get('accountManagerId')?.dirty ||
              form.get('accountManagerId')?.touched)
          "
        >
          Account Manager is required
        </div>
      </div>
    </div>
  </div>
  <div class="mt-5">
    <div class="md:flex md:gap-4">
      <div class="flex-1">
        <cb-form-label for="company_number" [optional]="true">
          Company number
        </cb-form-label>
        <input
          id="company_number"
          maxlength="50"
          name="companyNumber"
          formControlName="registeredNumber"
          type="text"
          cbInput
        />
      </div>
      <div class="flex-1 mt-5 md:mt-0">
        <label for="sector" cbLabel>Sector</label>
        <select
          required
          id="sector"
          name="sector"
          formControlName="sector"
          cbSelect
        >
          <option [value]="null">--</option>
          <option *ngFor="let sector of sectorList" [value]="sector">
            {{ sector }}
          </option>
        </select>
        <div
          cbValidationMessage
          *ngIf="
            form.get('sector')?.errors?.required &&
            (form.get('sector')?.dirty || form.get('sector')?.touched)
          "
        >
          Please choose a business sector
        </div>
      </div>
    </div>
  </div>

  <div class="md:flex gap-6">
    <div class="flex-1 mt-5 pt-5" formGroupName="address">
      <h3 class="text-xl mb-5">Company address</h3>
      <div class="mt-5">
        <cb-form-label
          for="addr_line_1"
          text="Line 1"
          [optional]="true"
        ></cb-form-label>
        <input
          type="text"
          maxlength="100"
          id="addr_line_1"
          name="address.line1"
          formControlName="line1"
          cbInput
        />
      </div>
      <div class="mt-5">
        <cb-form-label
          for="addr_line_2"
          text="Line 2"
          [optional]="true"
        ></cb-form-label>
        <input
          type="text"
          maxlength="100"
          id="addr_line_2"
          name="address.line2"
          formControlName="line2"
          cbInput
        />
      </div>
      <div class="mt-5">
        <cb-form-label
          for="addr_locality"
          text="Locality"
          [optional]="true"
        ></cb-form-label>
        <input
          type="text"
          maxlength="100"
          id="addr_locality"
          name="address.locality"
          formControlName="locality"
          cbInput
        />
      </div>
      <div class="mt-5">
        <cb-form-label
          for="addr_region"
          text="Region"
          [optional]="true"
        ></cb-form-label>
        <input
          type="text"
          maxlength="100"
          id="addr_region"
          name="address.region"
          formControlName="region"
          cbInput
        />
      </div>
      <div class="mt-5">
        <cb-form-label
          for="addr_postcode"
          text="Postcode"
          [optional]="true"
        ></cb-form-label>
        <input
          type="text"
          id="addr_postcode"
          maxlength="100"
          name="address.postcode"
          formControlName="postcode"
          cbInput
        />
      </div>
      <div class="mt-5">
        <cb-form-label
          for="addr_country"
          text="Country"
          [optional]="true"
        ></cb-form-label>
        <select
          id="addr_country"
          name="address.country"
          formControlName="country"
          cbSelect
        >
          <option value="">--</option>
          <option *ngFor="let country of countryList" [value]="country">
            {{ country }}
          </option>
        </select>
      </div>
    </div>

    <div class="flex-1 mt-5 pt-5">
      <h3 class="text-xl mb-5">Branding</h3>
      <div class="mt-5">
        <cb-form-label for="company_logo" [optional]="true">
          Logo URL
        </cb-form-label>
        <input
          id="company_logo"
          type="url"
          cbInput
          maxlength="255"
          formControlName="logoUrl"
        />
        <div
          cbValidationMessage
          *ngIf="
            form.get('logoUrl')?.errors?.pattern &&
            (form.get('logoUrl')?.dirty || form.get('logoUrl')?.touched)
          "
        >
          Must be a valid and secure image link URL.
        </div>
      </div>
      <div class="mt-5">
        <div class="flex justify-between">
          <div>
            <cb-form-label for="company_brand" [optional]="true">
              Brand colour (hex code)
            </cb-form-label>
          </div>
          <div
            class="w-4 h-4 border py-1 px-4"
            [ngStyle]="{
              backgroundColor: form.get('brandColor')?.value || '#fff'
            }"
          ></div>
        </div>

        <input
          id="company_brand"
          type="text"
          cbInput
          maxlength="7"
          formControlName="brandColor"
        />
        <div
          cbValidationMessage
          *ngIf="
            form.get('brandColor')?.errors?.pattern &&
            (form.get('brandColor')?.dirty || form.get('brandColor')?.touched)
          "
        >
          Must be a valid hex code starting with a '#' e.g. '#fff' or '#ffffff'
        </div>
      </div>
    </div>
  </div>

  <div class="mt-10">
    <cb-form-buttons [form]="form" (cancel)="formCancel()"></cb-form-buttons>
  </div>
</form>
