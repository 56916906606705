<cb-authorized>
    <cb-body *ngIf="context.myCompany$ | async as company">
        <div *ngIf="company.type === 'MSP'" class="flex justify-end">
            <cb-subscription-balance type="clients" countProp="clientCount"
                totalProp="maxClientsAllowed"></cb-subscription-balance>
        </div>
        <cb-page-header [actions]="actions$ | async" [showCurrentCompany]="false" [spaceBelow]="false"
            subHeading="Dashboard">
            {{company.name}}
        </cb-page-header>

        <div class="mb-10">
            <cb-customer-metrics *ngIf="company.type === 'SYS'"></cb-customer-metrics>
            <cb-msp-client-metrics *ngIf="company.type === 'MSP'"></cb-msp-client-metrics>
        </div>

        <div *ngIf="company.type === 'MSP'" class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-10">
            <cb-msp-subscription></cb-msp-subscription>

            <cb-projects-card></cb-projects-card>

            <div class="sm:col-span-2">
                <cb-budget-card></cb-budget-card>
            </div>

        </div>

        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            <cb-clients-by-level *ngIf="company.type === 'MSP'"></cb-clients-by-level>
            <cb-customers-by-plan *ngIf="company.type === 'SYS'"></cb-customers-by-plan>


            <div class="lg:col-span-2">
                <cb-activity></cb-activity>
            </div>
        </div>

    </cb-body>

</cb-authorized>

<p-sidebar [(visible)]="addClientActivated" position="right" appendTo="body" [closeOnEscape]="true"
    (onShow)="addClientComponent.focusCompanyName()" (onHide)="addClientComponent.clearForm()">
    <cb-add-client #addClientComponent (done)="clientAdded()"></cb-add-client>
</p-sidebar>

<p-sidebar [(visible)]="addCustomerActivated" position="right" appendTo="body"
    (onShow)="addCustomerComponent.focusName()" (onHide)="addCustomerComponent.clearForm()">
    <cb-add-customer #addCustomerComponent (done)="customerAdded()"></cb-add-customer>
</p-sidebar>