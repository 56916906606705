<cb-metric-card heading="Activity">
    <cb-list-view mode="embedded">
        <cb-list-header [clickable]="false">
            <cb-header-column>Activity</cb-header-column>
            <cb-header-column>Client</cb-header-column>
            <cb-header-column>Date</cb-header-column>
        </cb-list-header>
        <ng-container *ngIf="activityList$ | async as activity">
            <ng-container *ngIf="activity.length; else emptyActivity">
                <cb-list-row [clickable]="false" *ngFor="let activity of activity">
                    <cb-list-column>
                        <div>
                            <span *ngIf="activity.mood === 'Celebration'">
                                🎉
                            </span>
                            {{activity.title}}
                        </div>
                        <div *ngIf="activity.riskScore" class="text-sm">
                            Score: {{activity.riskScore}}
                        </div>
                        <div class="text-xs">
                            {{activity.detail}}
                        </div>
                    </cb-list-column>
                    <cb-list-column>{{activity.clientName}}</cb-list-column>
                    <cb-list-column>{{activity.createdDate | date:'dd MMM YYYY'}}
                    </cb-list-column>
                </cb-list-row>
            </ng-container>
        </ng-container>
        <ng-template #emptyActivity>
            <cb-empty-list>
                <div class="text-xl">
                    No activity recorded yet
                </div>
            </cb-empty-list>
        </ng-template>

    </cb-list-view>
</cb-metric-card>