import { Pipe, PipeTransform } from '@angular/core';
import { splitWords } from '@app/util/split-words';

@Pipe({
  name: 'splitWords',
})
export class SplitWordsPipe implements PipeTransform {
  transform(value?: string): string | undefined {
    return value ? splitWords(value) : undefined;
  }
}
