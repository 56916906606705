import { Component, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { ClientsListService } from './clients-list.service';
import { type MspClient } from './clients-list.service';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest, of } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { HeaderButton, HeaderControl, HeaderSearch, HeaderSelectList, levelOptions } from '@app/components/page-header/controls';
import { UserContextService } from '@app/user-context.service';
import { CheckSubscriptionActivator } from '@app/subscription-activator';

@Component({
  selector: 'cb-clients-list',
  templateUrl: './clients-list.component.html',

})
export class ClientsListComponent implements OnInit, OnDestroy {

  clients$: Observable<MspClient[]> = of([]);
  actions: HeaderControl[] = [];
  controls$: Observable<HeaderControl[]> = of([]);

  addClientActivated = false;

  private clientFilter = new EventEmitter<string>();
  private levelFilter = new EventEmitter<string>();
  private subscriptions: Subscription[] = [];
  private refresh = new BehaviorSubject<number>(0);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: ClientsListService,
    private context: UserContextService,
    private activator: CheckSubscriptionActivator,
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngOnInit(): void {

    const paramsSubject = new Subject<Params>();

    const addClientClick = () => {
      this.activator.canActivateFor("client", undefined, "redirectOnReject").pipe(
        filter((canActivate) => canActivate),
        first()
      ).subscribe(() => {
        this.addClientActivated = true;
      })
    };

    this.actions = [
      new HeaderButton(
        "Add Client",
        "plus-circle",
        addClientClick,
        "CompanyManager"
      )
    ]

    this.controls$ = this.route.queryParams.pipe(
      map(({ filter, level }) => {
        return [
          new HeaderSearch({
            id: "client-filter",
            changed: this.clientFilter,
            label: "Filter",
            placeholder: "find a client by name",
            value: filter ?? undefined
          }),
          new HeaderSelectList("client-level", levelOptions(true), this.levelFilter, level ?? "", "Level")
        ]
      })
    );

    this.clients$ = combineLatest([this.route.queryParams, this.refresh]).pipe(
      switchMap(([{ filter, level }]) => this.service.getClients(filter, level))
    );

    this.subscriptions.push.apply(this.subscriptions, [
      this.clientFilter.subscribe((filter) => paramsSubject.next({ filter: filter || null })),
      this.levelFilter.subscribe((level) => paramsSubject.next({ level: level || null })),
      paramsSubject.subscribe((params) => {
        this.router.navigate([], {
          queryParams: params,
          queryParamsHandling: "merge"
        });
      })
    ]
    );

  }

  clientAdded(): void {
    this.addClientActivated = false;
    this.refresh.next(1);
  }

}
