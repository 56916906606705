import { Component, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import {
  HeaderButton,
  HeaderControl,
  HeaderSearch,
  HeaderSelectList,
  HeaderSelectListOption,
  levelOptions,
} from '@app/components/page-header/controls';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  Observable,
  BehaviorSubject,
  combineLatest,
  Subject,
  Subscription,
  of,
} from 'rxjs';
import { TaskListItem } from '@app/tasks/model';
import {
  TasksService,
  UploadReport,
  TaskImportResult,
  TaskImportError,
} from './tasks.service';
import { shareReplay, switchMap, map, tap } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';
import { ToastrService } from 'ngx-toastr';
import { FrameworkService } from '@app/settings/frameworks/framework.service';

interface TaskParams {
  query?: string;
  level?: number;
  framework?: string;
}

@Component({
  selector: 'cb-tasks-list',
  templateUrl: './tasks-list.component.html',
  styles: [],
})
export class TasksListComponent implements OnInit, OnDestroy {
  controls$: Observable<HeaderControl[]> = of([]);
  actions: HeaderControl[] = [];

  newTaskEmitter = new EventEmitter<void>();
  importTasksEmitter = new EventEmitter<void>();
  exportTasksEmitter = new EventEmitter<void>();
  onUploadProgress = new EventEmitter<number>();
  onUploadDone = new EventEmitter<TaskImportResult>();
  onUploadError = new EventEmitter<TaskImportError>();
  onSearchChanged = new EventEmitter<string>();
  onLevelChanged = new EventEmitter<string>();
  onFrameworkChanged = new EventEmitter<string>();
  reload = new BehaviorSubject(0);
  params = new Subject<Params>();

  tasks$?: Observable<TaskListItem[]>;
  uploadProgress: number = 0;
  resultCount: number = 0;

  breadcrumb: MenuItem[] = [
    { label: 'Settings', routerLink: ['/settings'] },
    { label: 'Tasks', routerLink: ['/settings/tasks'] },
  ];

  constructor(
    private router: Router,
    private service: TasksService,
    private toast: ToastrService,
    private route: ActivatedRoute,
    private frameworkService: FrameworkService
  ) {}

  private subscriptions: Subscription[] = [];

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngOnInit(): void {
    this.actions = [
      new HeaderButton(
        'Add task',
        'document-add',
        () => this.router.navigate(['/settings/tasks/add']),
        'TasksManager'
      ),
      new HeaderButton(
        'Import',
        'upload',
        () => document?.querySelector<HTMLElement>('input[type=file]')?.click(),
        'TasksManager'
      ),
      new HeaderButton(
        'Export',
        'download',
        () => this.exportTasksClick(),
        'TasksManager'
      ),
    ];

    this.controls$ = this.route.queryParams.pipe(
      map((params: Params) => {
        return [
          new HeaderSelectList(
            'tasks-framework',
            this.frameworkService.selectListOptions$,
            this.onFrameworkChanged,
            params.framework ?? '',
            'Framework'
          ),
          new HeaderSelectList(
            'tasks-level',
            levelOptions(true),
            this.onLevelChanged,
            params.level?.toString() ?? '',
            'Level'
          ),
          new HeaderSearch({
            id: 'tasks-search',
            label: 'Filter',
            placeholder: 'by title',
            changed: this.onSearchChanged,
            value: params.q ?? undefined,
          }),
        ];
      })
    );

    this.subscriptions.push(
      this.onUploadProgress.subscribe((progress) => {
        this.uploadProgress = progress;
      })
    );

    this.subscriptions.push(
      this.onUploadDone.subscribe((result) => {
        const createdPlural = result.created === 1 ? '' : 's';
        const updatedPlural = result.updated === 1 ? '' : 's';
        this.toast.success(
          `${result.created} task${createdPlural} created, ${result.updated} task${updatedPlural} updated`,
          'Import successful'
        );
        this.reload.next(1);
        this.frameworkService.refreshOptions();
      })
    );

    this.subscriptions.push(
      this.onUploadError.subscribe((err) => {
        this.toast.error(err.message, `${err.status} Error`, {
          disableTimeOut: true,
        });
      })
    );

    this.subscriptions.push.apply(this.subscriptions, [
      this.onSearchChanged.subscribe((q) => {
        this.params.next({ q: q || null });
      }),
      this.onFrameworkChanged.subscribe((framework) => {
        this.params.next({ framework: framework || null });
      }),
      this.onLevelChanged.subscribe((level) =>
        this.params.next({ level: level || null })
      ),
      this.params.subscribe((queryParams: Params) => {
        this.router.navigate([], {
          queryParams,
          queryParamsHandling: 'merge',
        });
      }),
    ]);

    this.tasks$ = this.route.queryParams.pipe(
      switchMap((param) =>
        this.loadTasks({
          query: param.q || null,
          level: param.level || null,
          framework: param.framework || null,
        })
      )
    );
  }

  private exportTasksClick(): void {
    this.service.exportTasks().subscribe((res: any) => {
      let blob = new Blob([res], { type: 'text/csv' });
      let anchor = document.createElement('a');
      anchor.href = window.URL.createObjectURL(blob);
      anchor.download = 'TasksExport.csv';
      anchor.click();
    });
  }

  private loadTasks(params: TaskParams) {
    const { query, level, framework } = params;

    return this.reload.pipe(
      switchMap(() => this.service.getTasks({ query, level, framework })),
      tap((response) => (this.resultCount = response.total)),
      map((response) =>
        response.data.map((taskResponse) => {
          taskResponse._listTags = [
            `Level ${taskResponse.level}`,
            taskResponse.category,
            taskResponse.group,
          ].concat(taskResponse.tags.map((ta) => ta.label));
          return taskResponse;
        })
      ),
      shareReplay(1)
    );
  }

  sendFileUpload(el: HTMLInputElement) {
    var file = (el.files || [])[0];

    if (!file) {
      this.toast.warning('No file selected');
      return;
    }

    var report = new UploadReport(
      this.onUploadProgress,
      this.onUploadDone,
      this.onUploadError
    );

    this.service.importTasks(file, report);
  }
}
