import { NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ActionButtonDirective } from '@app/styles/action-button.directive';
import { ButtonDirective } from '@app/styles/button.directive';
import { ControlButtonDirective } from '@app/styles/control-button.directive';
import { DataGridActionButtonDirective } from '@app/styles/data-grid-action-button.directive';
import { GanttSpanDirective } from '@app/styles/gantt-span.directive';
import { SecondaryButtonDirective } from '@app/styles/secondary-button.directive';
import { TextareaDirective } from '@app/styles/textarea.directive';
import { ValidationMessageDirective } from '@app/styles/validation-message.directive';
import { LabelDirective } from '@app/styles/label.directive';
import { InputDirective } from '@app/styles/input.directive';
import { SelectDirective } from '@app/styles/select.directive';

const types: Type<any>[] = [
  ActionButtonDirective,
  TextareaDirective,
  ButtonDirective,
  ControlButtonDirective,
  DataGridActionButtonDirective,
  GanttSpanDirective,
  SecondaryButtonDirective,
  ValidationMessageDirective,
  LabelDirective,
  InputDirective,
  SelectDirective
]

@NgModule({
  declarations: types,
  exports: types,
  imports: [
    CommonModule
  ]
})
export class StylesModule { }
