<div class="w-full flex justify-between group items-center bg-white transition border-b border-gray-300">
    <div class="w-full px-4 pt-4 pb-2 bg-white transition">
        <div class="w-full grid gap-4" [class]="'grid-cols-' + columnCount">
            <ng-content></ng-content>
        </div>                                
    </div>
    <!-- the below markup is required to ensure the column titles line up -->
    <div class="flex-none hidden lg:block px-4 transition">
        <div *ngIf="clickable" class="w-6 h-6"></div>        
    </div>    
</div>
