import { Component, OnInit, Input } from '@angular/core';

let initialised = false;

@Component({
  selector: 'cb-video-player',
  template: '<youtube-player [videoId]="videoId"></youtube-player>'
})
export class VideoPlayerComponent implements OnInit {

  @Input() videoId!: string;

  constructor() { }

  ngOnInit(): void {
    if(initialised){
      return;
    }

    const tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    document.body.appendChild(tag);
    initialised = true;
  }

}
