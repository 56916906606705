import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  EventEmitter,
  LOCALE_ID,
} from '@angular/core';
import { Risk } from '@app/benchmark/benchmark.model';
import {
  HeaderButton,
  HeaderSelectList,
  levelOptions,
  HeaderControl,
  HeaderDatePicker,
} from '@app/components/page-header/controls';

import { UserContextService } from '@app/user-context.service';
import { Observable, combineLatest, BehaviorSubject, Subject, of } from 'rxjs';
import { map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  ReportService,
  Report,
  ReportTaskGroup,
  ReportParams,
} from './report.service';
import { Router, ActivatedRoute } from '@angular/router';
import { formatDate } from '@angular/common';
import { triggerBrowserDownload } from '@app/util';
import { LoggingService } from '@app/logging/logging.service';

type Rag = 'rag-green' | 'rag-amber' | 'rag-red' | 'brand-deep';

interface QueryParams {
  level?: number;
  date?: string;
}

@Component({
  selector: 'cb-report-view',
  templateUrl: './report-view.component.html',
})
export class ReportViewComponent implements OnInit, OnDestroy {
  controls$: Observable<HeaderControl[]> = of([]);
  actions: HeaderControl[] = [];
  report$?: Observable<Report>;

  downloadEmitter = new EventEmitter<void>();
  levelChangedEmitter = new EventEmitter<string>();
  dateChangedEmitter = new EventEmitter<Date | null>();

  dateValue: Date | null = null;
  allExpanded = false;
  downloadButton: HeaderButton | null = null;
  dispose = new Subject();
  levelSubject = new BehaviorSubject<number | undefined>(undefined);

  constructor(
    public context: UserContextService,
    private service: ReportService,
    private router: Router,
    private route: ActivatedRoute,
    private logger: LoggingService,
    @Inject(LOCALE_ID) private locale: string
  ) {}

  ngOnInit(): void {
    this.levelChangedEmitter.subscribe((level) => this.applyLevelFilter(level));
    this.dateChangedEmitter.subscribe((date) => this.applyDateFilter(date));

    this.downloadButton = new HeaderButton(
      'Download PDF',
      'download',
      () => this.downloadReport(),
      'ReportViewer',
      undefined,
      this.context.accountStatus$.pipe(
        map(({ accountChangePending }) => {
          return !accountChangePending;
        })
      )
    );
    this.actions = [this.downloadButton];

    this.controls$ = combineLatest([
      this.route.queryParams,
      this.levelSubject,
    ]).pipe(
      map(([params, level]) => {
        const date = this.dateFromQueryParam(params.date);
        return [
          new HeaderSelectList(
            'report-level',
            levelOptions(false),
            this.levelChangedEmitter,
            params.level ?? level?.toString() ?? '',
            'Target level'
          ),
          new HeaderDatePicker(
            'report-date',
            'Target date',
            this.dateChangedEmitter,
            date ?? undefined
          ),
        ];
      })
    );

    this.report$ = combineLatest([
      this.context.currentCompany$,
      this.route.queryParams,
    ]).pipe(
      map(([company, params]) => this.buildArgs(company!.id, params)),
      switchMap((params) => this.getReport(params)),
      tap((report) => this.levelSubject.next(report.targetLevel)),
      takeUntil(this.dispose),
      shareReplay(1)
    );
  }

  ngOnDestroy(): void {
    this.dispose.next();
  }

  toggleExpandAll(): void {
    this.allExpanded = !this.allExpanded;
  }

  private buildArgs(companyId: string, params: QueryParams): ReportParams {
    const date = this.dateFromQueryParam(params.date);
    const level = params.level;

    return { companyId, level, date };
  }

  private downloadReport() {
    this.downloadButton?.startLoading();
    const companyId = this.context.currentCompanySnapshot?.id;
    const queryParams = this.route.snapshot.queryParams;

    this.service
      .downloadReport(this.buildArgs(companyId!, queryParams))
      .subscribe(
        (res: any) => {
          const filename = `BenchmarkReport-${formatDate(
            Date.now(),
            'yyyy-MM-dd',
            this.locale
          )}.pdf`;
          triggerBrowserDownload(filename, res);
        },
        (error) => {
          this.logger.error('Error downloading benchmark report', error, {
            companyId,
            queryParams,
          });
        }
      )
      .add(() => {
        this.downloadButton?.stopLoading();
        this.context.reloadSubscription();
      });
  }

  private getReport(args: ReportParams): Observable<Report> {
    return this.service.getReport(args).pipe(
      map((report) => {
        report.targetLevel = args.level || report.targetLevel;
        return report;
      })
    );
  }

  private dateFromQueryParam(value: string | undefined): Date | null {
    if (!value) {
      return null;
    }
    return new Date(value);
  }

  private applyDateFilter(date: Date | null): void {
    if (!date) {
      this.router.navigate([], {
        queryParams: { date: null },
        queryParamsHandling: 'merge',
      });
    } else {
      this.applyFilters({ date: this.service.formatReportDate(date) });
    }
  }

  private applyLevelFilter(level: string): void {
    if (!level) {
      this.router.navigate([], {
        queryParams: { level: null },
        queryParamsHandling: 'merge',
      });
    } else {
      this.applyFilters({ level });
    }
  }

  private applyFilters(params: {}) {
    this.router.navigate([], {
      queryParams: params,
      queryParamsHandling: 'merge',
    });
  }

  mapRiskProfileStyles(risk: Risk | string): string {
    const rag = ReportViewComponent.mapRagValue(risk);

    return `text-${rag} font-bold`;
  }

  private static mapRagValue(riskScore: Risk | string): Rag {
    switch (riskScore) {
      case Risk.Medium:
        return 'rag-amber';
      case Risk.High:
      case Risk.VeryHigh:
      case Risk.Unrated:
        return 'rag-red';
    }

    return 'rag-green';
  }

  toggleDetail(group: ReportTaskGroup): void {
    const expanded = !!group._expanded;
    group._expanded = !expanded;

    if (!group._expanded) {
      this.allExpanded = false;
    }
  }
}
