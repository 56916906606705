import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[cbLabel]'
})
export class LabelDirective {

  styles = [
    "block",
    "text-sm",
    "font-medium",
    "text-brand-dark",
    "mb-1"
  ]

  constructor() { }

  @HostBinding("class")
  get elementStyle(): string {
    return this.styles.join(" ");
  }

}
