import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[cbInput]'
})
export class InputDirective {

  styles = [
    "py-2", 
    "focus:ring-brand-primary", 
    "focus:border-brand-primary", 
    "block", 
    "w-full", 
    "shadow-sm", 
    "sm:text-sm", 
    "border", 
    "border-brand-cool", 
    "rounded-md",
    "disabled:opacity-50",
    "disabled:cursor-not-allowed"
  ];

  @HostBinding("class")
  get elementStyle(): string {
    return this.styles.join(' ');
  }

  constructor() { }

}
