import { Component, OnInit, Input } from '@angular/core';
import { RiskHistoryService } from './risk-history.service';

@Component({
  selector: 'cb-risk-history',
  templateUrl: './risk-history.component.html',
  styles: [
  ]
})
export class RiskHistoryComponent implements OnInit {

  @Input() companyId!: string;
  dataAvailable = true;
  loading = false;  

  categoryRiskData: any;

  chartOptions = {
    scales: {
      y: {
        max: 1000,
        min: 0,
        ticks: {
          beginAtZero: true,          
          stepSize: 100
        }
      }
    }
  }

  constructor(
    private service: RiskHistoryService
  ) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;

    const data = await this.service.getRiskByCategory(this.companyId);

    this.loading = false;
    this.dataAvailable = data.available;

    const cols = data.data.slice(-12);

    this.categoryRiskData = {
      labels: cols.map(d => d.scoreDate),
      datasets: [
        {
          label: "Score",
          borderColor: "#0062AB",
          fill: false,
          data: cols.map(d => d.score),
          tension: .2
        }        
      ]
    };
  }

}
