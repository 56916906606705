import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpErrorService } from '@app/services/http-error.service';
import { environment as env } from '@env';

export interface UpdateProfileRequest {
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  constructor(private httpClient: HttpClient, private errorHandler: HttpErrorService) { }

  updateProfile(id: string, request: UpdateProfileRequest): Observable<void> {
    const url = `${env.api.baseUrl}/v${env.api.version}/users/${encodeURIComponent(id)}`;
    return this.httpClient.put<void>(url, request).pipe(
      this.errorHandler.handleHttpError
    );
  }

  changePassword(id: string, email: string): Observable<void> {
    const url = `${env.api.baseUrl}/v${env.api.version}/users/${encodeURIComponent(id)}`;
    return this.httpClient.patch<void>(url, { email }).pipe(
      this.errorHandler.handleHttpError
    );
  }
}
