export const environment = {
  production: false,
  APPLICATIONINSIGHTS_INSTRUMENTKEY: '6fd96d7b-fddb-4b83-82af-c8437d67a898',
  api: {
    baseUrl: 'https://cbm-uks-dev-api.azurewebsites.net',
    version: 1,
  },
  auth: {
    clientId: 'bQwVUAFJxZztaRiDDSQSWdM2f5oYipnl',
    domain: 'clearbenchmark-dev.eu.auth0.com',
    redirectUri: window.location.origin,
  },
  configCatSdkKey:
    'configcat-sdk-1/CmrcCGW7O02MCfuV_iWqfA/eqRwHlhpCUOuYDKDbAKwEQ',
};
