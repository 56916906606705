import { Component, OnInit, Input } from '@angular/core';
import { UserContextService } from '@app/user-context.service';
import { MenuItem } from 'primeng/api';
import { HeaderControl } from './controls';
import { FiltersActiveService } from './filters-active.service';

@Component({
  selector: 'cb-page-header',
  templateUrl: './page-header.component.html',
  styles: [
  ]
})
export class PageHeaderComponent implements OnInit {

  @Input() controls: HeaderControl[] | null = null;
  @Input() actions: HeaderControl[] | null = null;
  @Input() showCurrentCompany = true;
  @Input() breadcrumb?: MenuItem[];
  @Input() subHeading?: string | null;
  @Input() spaceBelow = true;

  breadcrumbHome: MenuItem = { icon: 'pi pi-home', routerLink: '/' };
  filtersOpen: boolean = false;

  constructor(public context: UserContextService, private filtersActive: FiltersActiveService) { }

  ngOnInit(): void {
  }

  toggleFilters() {
    this.filtersOpen = !this.filtersOpen;
  }

  hasActiveFilters(): boolean {
    return this.filtersActive.hasActiveFilters();
  }
}
