<cb-authorized>
    <cb-body>
        <cb-page-header [showCurrentCompany]="false" [spaceBelow]="false" [actions]="actions">
            Customers
        </cb-page-header>
        <cb-list-view mode="embedded" *ngIf="customers$ | async as customers">
            <cb-list-header>
                <cb-header-column>Name</cb-header-column>
                <cb-header-column>Clients</cb-header-column>
                <cb-header-column>Documents</cb-header-column>
                <cb-header-column>Plan</cb-header-column>
                <cb-header-column>Last Active</cb-header-column>
            </cb-list-header>
            <ng-container *ngIf="customers.length;else emptyList">
                <cb-list-row *ngFor="let customer of customers" [routerLink]="['/c', customer.id, 'overview']">
                    <cb-list-column>
                        {{customer.name}}
                    </cb-list-column>
                    <cb-list-column>
                        {{customer.clients}}
                    </cb-list-column>
                    <cb-list-column>
                        {{customer.documents}}
                    </cb-list-column>
                    <cb-list-column>
                        {{customer.plan}}
                    </cb-list-column>
                    <cb-list-column>
                        <ng-container *ngIf="customer.lastActivity">
                            <div>
                                {{customer.lastActivity.fullName}}
                            </div>
                            <div class="text-xs">{{customer.lastActivity.lastActive | date:'dd MMM YYYY'}}</div>
                        </ng-container>
                    </cb-list-column>
                </cb-list-row>
            </ng-container>
            <ng-template #emptyList>
                <cb-empty-list>
                    <div class="text-2xl">
                        No customers yet
                    </div>
                </cb-empty-list>
            </ng-template>
        </cb-list-view>
    </cb-body>

</cb-authorized>

<p-sidebar [(visible)]="addCustomerActive" position="right" appendTo="body" (onShow)="addCustomerComponent.focusName()"
    (onHide)="addCustomerComponent.clearForm()">
    <cb-add-customer #addCustomerComponent (done)="customerAdded()"></cb-add-customer>
</p-sidebar>