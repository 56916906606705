<a 
    [routerLink]="route"    
    #rla="routerLinkActive"
    [class]="rla.isActive ? 'text-brand-primary' : 'text-brand-secondary'"
    class="block py-2 mb-2 rounded-md group text-md lg:text-sm hover:bg-low-light hover:text-brand-deep transition duration-200"
    routerLinkActive="bg-low-light shadow-inner">

    <div class="pl-4 pr-2 flex justify-start items-center min-w-">
        <div class="px-2" *ngIf="icon">
            <hero-icon [name]="icon" type="outline" class="w-5 h-5"></hero-icon>
        </div>                
        <div class="px-2 w-20">
            <ng-content></ng-content>
        </div>
    </div>
</a>
