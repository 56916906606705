import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Quality, Risk } from '@app/benchmark/benchmark.model';
import { map, shareReplay } from 'rxjs/operators';
import { CollectionResponse, Importance } from '@app/domain/model';
import { environment as env } from '@env';
import { HttpErrorService } from '@app/services/http-error.service';

export type CalendarLength = 3 | 6 | 12;

export interface Project {
  id?: string;
  name: string;
}

export interface Task {
  taskId: string;
  entryId: string;
  title: string;
  summary: string;
  description?: string;
  level: number;
  category: string;
  group: string;
  importance: Importance;
  quality: Quality;
  risk: Risk;
  tags: string[];
  createdDate: Date;
  projectName: string | null;
  projectId: string | null;
}

@Injectable({
  providedIn: 'root'
})
export class RoadmapService {

  constructor(
    private httpClient: HttpClient,
    private error: HttpErrorService
  ) { }

  addTaskToProject(projectId: string, entryId: string): Observable<void> {
    const url = `${env.api.baseUrl}/v${env.api.version}/roadmap`;
    return this.httpClient.put<void>(url, { projectId, entryId }).pipe(
      this.error.handleHttpError
    )
  }

  downloadRoadmap(companyId: string, from: Date | null = null, to: Date | null = null): Observable<any> {
    let url = `${env.api.baseUrl}/v1/roadmap/pdf?companyId=${encodeURIComponent(companyId)}`;

    if (from && to) {
      url += `&from=${from.toJSON()}&to=${to.toJSON()}`;
    }

    return this.httpClient.get(url, { responseType: "blob" }).pipe(
      this.error.handleHttpError
    );
  }
}
