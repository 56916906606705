import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { iif, Subscription } from 'rxjs';
import { FrameworkService, Framework } from '../framework.service';

@Component({
  selector: 'cb-framework-form',
  templateUrl: './framework-form.component.html',
  providers: [ConfirmationService]
})
export class FrameworkFormComponent implements OnInit, OnDestroy {

  @Input() framework?: Framework;
  @Output() done = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
  form!: FormGroup;

  private subscriptions: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private service: FrameworkService,
    private toastr: ToastrService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      id: ['', this.framework?.id ? Validators.required : Validators.nullValidator],
      name: ['', [Validators.required, Validators.maxLength(255)]],
      shortName: ['', [Validators.required, Validators.maxLength(100)]],
      version: ['', [Validators.maxLength(50)]],
      link: ['', [Validators.required, Validators.maxLength(255)]],
      description: ['', [Validators.required, Validators.maxLength(10000)]],
      country: ['', [Validators.required, Validators.maxLength(100)]],
      organisation: ['', [Validators.maxLength(100)]]
    });

    if(this.framework){
      this.form.patchValue(this.framework);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  get addMode(): boolean {
    return !this.framework?.id;
  }

  submit(): void {
    const framework = this.form.getRawValue();
    const createObs = this.service.createFramework(framework);
    const updateObs = this.service.updateFramework(this.framework?.id!, framework);

    iif(() => this.addMode, createObs, updateObs).subscribe(() => {
      this.toastr.success("Framework saved!");
      this.done.emit();
    })
  }

  deleteFramework(): void {
    this.framework && this.confirmationService.confirm({
      message: "Any benchmark tasks tagged with this framework will have their tag removed",
      header: "Do you want to delete this framework?",
      accept: () => {
        this.subscriptions.push(
          this.service.deleteFramework(this.framework?.id!).subscribe(() => {
            this.toastr.success("Framework deleted");
            this.done.emit();
          })
        )
      }
    });
  }

  cancelClick(): void {
    this.cancel.emit();
  }

}
