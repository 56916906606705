import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment as env } from '@env';
import { HttpErrorService } from '@app/services/http-error.service';
import { Company } from '@app/domain/model';
import { switchMapTo, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MyCompanyService {

  private reload = new BehaviorSubject<unknown>(undefined);

  constructor(private httpClient: HttpClient, private errorHandler: HttpErrorService) { }

  myCompany$: Observable<Company> = this.reload.pipe(
    switchMapTo(this.sendRequest()),
    shareReplay(1)
  );

  private sendRequest(): Observable<Company> {
    const url = `${env.api.baseUrl}/v${env.api.version}/my/company`;
    return this.httpClient.get<Company>(url).pipe(
      this.errorHandler.handleHttpError
    );
  }
}
