<div *ngIf="heading" class="my-5 px-2">
    <h3 class="text-xl">{{heading}}</h3> 
    <p *ngIf="subHeading" class="text-xs leading-snug">{{subHeading}}</p>
</div>                
<div class="mb-10 rounded-md shadow-md border border-gray-300 bg-white p-4 relative">
    <div *ngIf="disabled" class="absolute inset-0 bg-white opacity-80 z-10">
        <div class="flex w-full h-full justify-center text-brand-danger pt-5 font-bold">
            {{disabledMessage}}
        </div>        
    </div>
    <ng-content></ng-content>
</div>
