<cb-authorized>
  <cb-body>
    <cb-subscription-balance
      type="documents"
      countProp="documentCount"
      totalProp="maxDocumentsAllowed"
    >
    </cb-subscription-balance>
    <cb-page-header
      [controls]="controls$ | async"
      [actions]="actions"
      [showCurrentCompany]="false"
      [spaceBelow]="false"
    >
      Benchmark Report
    </cb-page-header>

    <ng-container *ngIf="report$ | async as report">
      <header class="flex items-start justify-end py-2 px-6 mt-4">
        <div
          *ngIf="report.serviceProviderLogoUrl; else companyName"
          class="pb-4"
        >
          <img
            [src]="report.serviceProviderLogoUrl"
            class="w-full max-w-xs"
            [alt]="report.serviceProviderName"
          />
        </div>
        <ng-template #companyName>
          <h2 class="text-3xl">{{ report.serviceProviderName }}</h2>
        </ng-template>
      </header>
      <div class="mx-auto w-full mt-2">
        <ng-container>
          <section class="px-6 py-4 md:shadow-md bg-white mb-10 rounded-md">
            <div
              class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 text-center"
            >
              <div>
                <label class="font-thin block">Client</label>
                <span class="text-xl">{{ report.clientName }}</span>
              </div>
              <div *ngIf="report.consultant">
                <label class="font-thin block">Consultant</label>
                <span class="text-xl">{{ report.consultant }}</span>
              </div>
              <div>
                <label class="font-thin block">Current level</label>
                <span class="text-xl">
                  {{ report.level }}
                </span>
              </div>
              <div>
                <label class="font-thin block">Target level</label>
                <span class="text-xl">{{ report.targetLevel }}</span>
              </div>
              <div>
                <label class="font-thin block">Date</label>
                <span class="text-xl">{{
                  report.reportDate | date : "d LLL yyyy"
                }}</span>
              </div>
            </div>
          </section>
          <section class="px-6 py-4 md:shadow-md bg-white mb-10 rounded-md">
            <div class="flex justify-between">
              <h3 class="text-xl mb-2">Risk profile</h3>
              <button
                type="button"
                cbButton
                colour="bg-brand-deep"
                (click)="toggleExpandAll()"
              >
                <div class="flex space-between items-center gap-4">
                  <div>
                    <ng-container *ngIf="allExpanded; else arrowDown">
                      <hero-icon
                        name="arrow-up"
                        type="outline"
                        class="w-4 h-4"
                      ></hero-icon>
                    </ng-container>
                    <ng-template #arrowDown>
                      <hero-icon
                        name="arrow-down"
                        type="outline"
                        class="w-4 h-4"
                      ></hero-icon>
                    </ng-template>
                  </div>
                  <div>{{ allExpanded ? "Collapse" : "Expand" }} all</div>
                </div>
              </button>
            </div>

            <p class="mt-2 mb-5">
              Below is a summary of the areas that have been reported as either
              medium, high or very high risk. This report will form part of your
              road-map
            </p>

            <p-accordion [multiple]="true">
              <p-accordionTab
                *ngFor="let group of report.taskGroups"
                [selected]="allExpanded"
                [transitionOptions]="'200ms cubic-bezier(0.86, 0, 0.07, 1)'"
              >
                <ng-template pTemplate="header">
                  <div class="flex items-center justify-between w-full">
                    <div>
                      <h3 class="text-lg">
                        {{ group.title }}
                      </h3>
                      <small class="block text-sm"
                        >{{ group.percentageComplete }}% of
                        {{ group.totalTasksCount }} task{{
                          group.totalTasksCount | plural
                        }}
                        complete</small
                      >
                    </div>
                    <div>
                      <cb-risk-label [risk]="group.risk"></cb-risk-label>
                    </div>
                  </div>
                </ng-template>
                <ng-template pTemplate="content">
                  <div
                    class="py-4 px-2 border-b border-bright-gray"
                    *ngFor="let item of group.details"
                  >
                    <div class="mb-2 flex justify-between items-center">
                      <div>
                        <h3 class="text-xl">{{ item.title }}</h3>
                        <small class="text-sm">Level {{ item.level }}</small>
                      </div>
                      <div>
                        <cb-risk-label [risk]="item.risk"></cb-risk-label>
                      </div>
                    </div>
                    <p>{{ item.notes }}</p>
                  </div>
                </ng-template>
              </p-accordionTab>
            </p-accordion>
          </section>
        </ng-container>
        <ng-template #nodata>
          <div class="flex justify-center items-center">
            <div class="py-6 px-4 text-3xl flex space-x-5 items-center">
              <hero-icon
                name="light-bulb"
                type="outline"
                class="w-8 h-8"
              ></hero-icon>
              <h3>Nothing to report!</h3>
            </div>
          </div>
          <div class="mx-auto">
            <p>This can happen because:</p>
            <ul class="list-disc px-6">
              <li>
                No benchmark data has been recorded yet, in which case, head
                over to
                <a
                  [routerLink]="['../benchmark']"
                  class="hover:underline text-brand-deep"
                  >Benchmark</a
                >
                and start adding information there
              </li>
              <li>
                The date and level filters are applied and we don't have any
                data for the criteria, in which case, you can try changing your
                current filters
              </li>
              <li>
                You've scored better than
                <span class="text-rag-amber font-bold">medium</span> risk on
                your current level, in which case, yay!
              </li>
            </ul>
          </div>
        </ng-template>
      </div>
    </ng-container>
  </cb-body>
</cb-authorized>
