import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CategoryService } from '@app/tasks/add-category/category.service';
import { Category } from '@app/benchmark/benchmark.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CacheService } from '@app/cache.service';

@Component({
  selector: 'cb-add-category',
  templateUrl: './add-category.component.html',
  styles: [
  ]
})
export class AddCategoryComponent implements OnInit {

  form!: FormGroup;
  private dispose = new Subject<void>();

  @Output() saved = new EventEmitter<Category>();

  constructor(
    private formBuilder: FormBuilder,
    private service: CategoryService,
    private cacheService: CacheService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      categoryName: ['', [Validators.required, Validators.maxLength(100)]]
    });
  }

  submit(): void {
    const name = this.form.get("categoryName")?.value;
    this.service.addCategory(name)
      .pipe(takeUntil(this.dispose))
      .subscribe(cat => {
        this.form.reset();
        this.cacheService.destroy("categories");
        this.saved.emit(cat);
        this.dispose.next();        
      });
  }

  isInvalid(controlName: string): boolean {
    const control = this.form.get(controlName);
    return control?.errors?.required && (control?.dirty || control?.touched);
  }

}
