import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { RoleService } from '@app/auth/roles/role.service';
import { RoleName, Role } from '@app/roles';

@Directive({
    selector: '[cbRequiresRole]'
})
export class RequiresRoleDirective {

    private hasView = false;

    @Input() set cbRequiresRole(role: Role | undefined) {

        if (!role) {
            this.createView();
            return;
        }

        this.service.userHasRole(role).subscribe(hasRole => {
            if (hasRole && !this.hasView) {
                this.createView();
            }
            else if (!hasRole && this.hasView) {
                this.viewContainer.clear();
                this.hasView = false;
            }
        });
    }

    private createView() {
        this.viewContainer.createEmbeddedView(this.template);
        this.hasView = true;
    }

    constructor(
        private template: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private service: RoleService
    ) { }
}
