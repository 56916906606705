<cb-metric-card heading="Customers by plan">
    <ng-container *ngIf="customersByPlan$ | async as data; else loading">
        <p-chart type="pie" [data]="data" [options]="pieOptions"></p-chart>
    </ng-container>
    <ng-template #loading>
        <div class="mt-10">
            <div class="grid grid-cols-5 gap-4">
                <div *ngFor="let i of [1,2,3,4,5]">
                    <p-skeleton width="3rem"></p-skeleton>
                </div>
            </div>
            <div class="mt-5 flex justify-center">
                <p-skeleton shape="circle" size="15rem"></p-skeleton>
            </div>

        </div>
    </ng-template>

</cb-metric-card>