import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleGuard } from '@app/auth/roles/role.guard';
import { RoleName } from '@app/roles';
import { CompanyJourneyComponent } from './company-journey/company-journey.component';

export const JourneyRoutes: Routes = [
  RoleGuard.build("journey", CompanyJourneyComponent, [RoleName.CompanyViewer])
];

@NgModule({
  imports: [RouterModule],
  exports: [RouterModule]
})
export class JourneyRoutingModule { }
