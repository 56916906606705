import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GroupService } from '@app/tasks/add-group/group.service';
import { Group } from '@app/benchmark/benchmark.model';

@Component({
  selector: 'cb-add-group',
  templateUrl: './add-group.component.html',
  styles: [
  ]
})
export class AddGroupComponent implements OnInit {

  form!: FormGroup;

  @Output() saved = new EventEmitter<Group>();

  constructor(
    private formBuilder: FormBuilder,
    private service: GroupService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      groupName: ['', [Validators.required, Validators.maxLength(100)]]
    });
  }

  submit(): void {
    const name = this.form.get("groupName")?.value;
    this.service.addGroup(name)
      .subscribe(grp => {
        this.form.reset();
        this.saved.emit(grp);
      });
  }

  isInvalid(controlName: string): boolean {
    const control = this.form.get(controlName);
    return control?.errors?.required && (control?.dirty || control?.touched);
  }

}
