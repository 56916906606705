import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cb-my-company',
  templateUrl: './my-company.component.html',
  styles: [
  ]
})
export class MyCompanyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
