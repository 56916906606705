<cb-authorized>
    <cb-body>
        <div class="max-w-full px-2 py-2 lg:px-4 lg:mx-auto lg:max-w-4xl">
            <div class="mb-5">
                <a class="flex items-center gap-2 py-1 hover:underline" [routerLink]="['../']">
                    <div>
                        <hero-icon name="arrow-left" type="outline" class="w-5 h-5"></hero-icon>
                    </div>
                    <div>
                        Back to projects
                    </div>
                </a>
            </div>
            <h3 class="text-2xl mb-5">
                {{project.name}}
            </h3>
            <div class="lg:border lg:border-gray-200 rounded-md shadow-md bg-white lg:p-4">                
                <cb-project-form [project]="project" (close)="done()"></cb-project-form>
            </div>            
        </div>
    </cb-body>
</cb-authorized>
