<div class="flex px-2 py-4 items-center justify-start max-w-full w-full md:w-40">
    <div class="text-sm mt-1 font-bold" [class]="colour" [title]="((quality?.toString() || 'Unrated') | splitWords)">
        <hero-icon [name]="icon" type="outline" class="w-6 h-6"></hero-icon>
    </div>
    <div class="hidden md:block md:ml-1">
        <span class="text-sm text-brand-secondary">
            {{(quality?.toString() || 'Unrated') | splitWords}} <span *ngIf="includeSuffix && quality !== null">Quality</span>
        </span>
    </div>
</div>
