import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TaskListItem } from '@app/tasks/model';
import { environment as env } from '@env';
import { CollectionResponse } from '@app/domain/model';
import { HttpErrorService } from '@app/services/http-error.service';

export interface TaskImportResult {
  created: number;
  updated: number;
}

export interface TaskImportError {
  message: string;
  status: number;
}

export class UploadReport {
  constructor(
    public progress: EventEmitter<number>,
    public done: EventEmitter<TaskImportResult>,
    public error: EventEmitter<TaskImportError>
  ) { }
}

export interface TaskQueryParams {
  query?: string;
  level?: number;
  framework?: string;
}

@Injectable({
  providedIn: 'root'
})
export class TasksService {

  constructor(private httpClient: HttpClient, private errorHandler: HttpErrorService) { }

  getTasks(params: TaskQueryParams): Observable<{ data: TaskListItem[], total: number }> {
    let url = `${env.api.baseUrl}/v${env.api.version}/tasks`;
    const qs = [];
    const { query, level, framework } = params;

    if (query) {
      qs.push(`q=${query}`);
    }

    if (level) {
      qs.push(`level=${level}`);
    }

    if (framework) {
      qs.push(`framework=${framework}`);
    }

    if (qs.length) {
      url += `?${qs.join('&')}`;
    }

    return this.httpClient.get<CollectionResponse<TaskListItem>>(url).pipe(
      this.errorHandler.handleHttpError,
      map(res => ({
        data: res.result,
        total: res.totalResults
      }))
    );
  }

  importTasks(file: File, report: UploadReport): void {
    const url = `${env.api.baseUrl}/v${env.api.version}/tasks/csv`;
    let formData = new FormData();
    formData.append("file", file, file.name);

    const req = new HttpRequest("POST", url, formData, { reportProgress: true });

    this.httpClient.request(req).subscribe(
      (ev) => this.handleHttpResponse(ev, report),
      (err) => this.handleHttpError(err, report)
    );
  }

  private handleHttpResponse(httpEvent: HttpEvent<unknown>, report: UploadReport): void {

    if (httpEvent.type === HttpEventType.UploadProgress) {
      report.progress.emit(Math.round(100 * httpEvent.loaded / (httpEvent.total || 1)));
      return;
    }

    if (httpEvent.type === HttpEventType.Response) {
      report.progress.emit(100);
      var body: any = httpEvent.body;

      if (httpEvent.ok) {
        report.done.emit({
          created: body.created || 0,
          updated: body.updated || 0
        });
      }
    }
  }

  private handleHttpError(err: HttpErrorResponse, report: UploadReport): void {
    report.error.emit({
      status: err.status,
      message: err.error.message
    });
  }

  exportTasks(): Observable<any> {
    const url = `${env.api.baseUrl}/v${env.api.version}/tasks/csv`;
    return this.httpClient.get(url, { responseType: 'blob' });
  }
}
