import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { PrimeNGConfig } from 'primeng/api';
import { LoggingService } from './logging/logging.service';
import { UserContextService, COMPANY_KEY } from './user-context.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SubscriptionService } from './subscription.service';
import { combineLatest, Observable } from 'rxjs';
import { endWith, filter, map, switchMap, take } from 'rxjs/operators';
import { products, ProductNode } from './shared/products';
import Intercom from 'lib/messenger-js-sdk';
import { FeaturesService } from './features.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  title = 'Clearbenchmark App';
  isAnonymous = false;
  showUpgradeModal = false;
  currentPlan?: string;
  referrer?: string;
  upgradePending = false;

  constructor(
    public auth: AuthService,
    private prime: PrimeNGConfig,
    private context: UserContextService,
    private route: ActivatedRoute,
    private router: Router,
    private logger: LoggingService,
    private subscriptions: SubscriptionService,
    private features: FeaturesService
  ) {
    this.logger.configure();
  }

  ngOnInit(): void {
    this.prime.ripple = true;

    const companyJSON = localStorage.getItem(COMPANY_KEY);

    if (companyJSON) {
      this.auth.isAuthenticated$
        .pipe(
          filter((isAuthenticated) => isAuthenticated),
          map(() => JSON.parse(companyJSON))
        )
        .subscribe((company) => this.context.currentCompany$.next(company));
    }

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        window.analytics?.page();
      }
    });

    this.auth.isAuthenticated$.subscribe((authenticated) => {
      if (authenticated) {
        this.route.queryParams.subscribe((q) => {
          this.showUpgradeModal = !!q.upgrade && !q.pending;
          this.currentPlan = q.upgrade;
          this.upgradePending = !!q.pending;
          this.referrer = decodeURIComponent(q.referrer);

          if (q.upgrade && !this.upgradePending) {
            this.logger.trace('[Subscription]: Upgrade required', {
              companyId: this.context.currentCompanySnapshot?.id,
              currentPlan: this.currentPlan,
              referrer: this.referrer,
            });
          }
        });

        this.context.accountStatus$.subscribe(({ accountChangePending }) => {
          this.upgradePending = accountChangePending;
        });
      }
    });

    // userId on the user$ object is the Auth0 ID
    // for intercom, the Guid user ID is required,
    // which is supplied by the myCompany$ data
    combineLatest([
      this.context.user$,
      this.context.myCompany$,
      this.features.enabled('intercomEnabled'),
    ])
      .pipe(
        filter(([_, __, enabled]) => enabled),
        map(([{ name, email }, { userId, intercomHash }]) => ({
          app_id: 'g03w550i',
          user_id: userId,
          name,
          email,
          user_hash: intercomHash,
        })),
        take(1)
      )
      .subscribe((intercomData) => {
        Intercom(intercomData);
      });
  }

  goToDashboard(): void {
    this.logger.trace('[Subscription]: Upgrade rejected', {
      companyId: this.context.currentCompanySnapshot?.id,
      currentPlan: this.currentPlan,
      referrer: this.referrer,
    });

    this.router.navigate([], {
      queryParams: { upgrade: null },
      queryParamsHandling: 'merge',
    });
  }

  upgradePlan(): void {
    this.logger.trace('[Subscription]: Upgrade started', {
      companyId: this.context.currentCompanySnapshot?.id,
      currentPlan: this.currentPlan,
      referrer: this.referrer,
    });

    const plan = this.getUpgradePlan();

    if (!plan || !plan.next) {
      this.logger.trace('[Subscription]: No upgrade plan found', {
        companyId: this.context.currentCompanySnapshot?.id,
        currentPlan: this.currentPlan,
        referrer: this.referrer,
      });
      return;
    }

    this.subscriptions
      .startSubscriptionUpgrade(plan.next.data.id)
      .subscribe((res) => {
        this.logger.trace('[Subscription]: Upgrade finished', {
          companyId: this.context.currentCompanySnapshot?.id,
          currentPlan: this.currentPlan,
          referrer: this.referrer,
          res,
        });

        window.location.href = res.redirectTo ?? '/';
      });
  }

  getUpgradePlan(): ProductNode | null {
    return products.search((product) => product.id === this.currentPlan);
  }

  currentUserIsAdmin$: Observable<boolean> = this.auth.isAuthenticated$.pipe(
    filter((authenticated) => authenticated === true),
    switchMap(() =>
      this.context.user$.pipe(map((user) => user?.roleGroup === 'Admin'))
    )
  );
}
