<h3 class="text-lg text-brand-primary my-2">Customers</h3>
<cb-metric-card>
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:divide-x">
        <ng-container *ngIf="metrics$ | async as metrics;else loading">
            <ng-container *ngIf="customerCount$ | async as customers">
                <cb-metric-value [routerLink]="['/customers']" [metric]="customers" text="Customer"
                    suffixText="in total"></cb-metric-value>
            </ng-container>
            <cb-metric-value [metric]="metrics.totalClients" text="Client" suffixText="in total"></cb-metric-value>
            <cb-metric-value [metric]="metrics.customersThisMonth" text="Customer"
                suffixText="this month"></cb-metric-value>
            <cb-metric-value [metric]="metrics.activeBenchmarks" text="Active Benchmark"></cb-metric-value>
        </ng-container>
        <ng-template #loading>
            <cb-metric-skeleton></cb-metric-skeleton>
            <cb-metric-skeleton></cb-metric-skeleton>
            <cb-metric-skeleton></cb-metric-skeleton>
            <cb-metric-skeleton></cb-metric-skeleton>
        </ng-template>
    </div>
</cb-metric-card>
<div class="mt-10">
    <h3 class="text-lg text-brand-primary my-2">Documents</h3>
    <cb-metric-card>
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:divide-x">
            <ng-container *ngIf="metrics$ | async as metrics;else loading">
                <cb-metric-value [metric]="metrics.totalDocumentsExported" text="Exported" suffixText="in total"
                    [pluralise]="false">
                </cb-metric-value>
                <cb-metric-value [metric]="metrics.totalDocumentsStuck" text="Stuck" suffixText="in total"
                    [pluralise]="false">
                </cb-metric-value>
                <cb-metric-value [metric]="metrics.documentsFailedThisMonth" text="Failed" suffixText="this month"
                    [pluralise]="false">
                </cb-metric-value>
                <cb-metric-value [metric]="metrics.documentsExportedThisMonth" text="Exported" [pluralise]="false"
                    suffixText="this month">
                </cb-metric-value>
            </ng-container>
            <ng-template #loading>
                <cb-metric-skeleton></cb-metric-skeleton>
                <cb-metric-skeleton></cb-metric-skeleton>
                <cb-metric-skeleton></cb-metric-skeleton>
                <cb-metric-skeleton></cb-metric-skeleton>
            </ng-template>
        </div>
    </cb-metric-card>
</div>