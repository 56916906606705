import { Pipe, PipeTransform } from '@angular/core';

/** Used to filter simple strings */

interface Object {
  [key: string]: any;
}

@Pipe({
  name: 'filter'
})
export class FilterPipe<T extends Object> implements PipeTransform {

  transform(items: T[] | null, prop: string, searchText: string): T[] {
    if(!items){
      return [];
    }
    
    if(!searchText){
      return items;
    }

    return items.filter(item => {
      if(!(prop in item)){
        return false;
      }

      return item[prop].toString().toLowerCase().includes(searchText.toLowerCase());
    });
  }

}
