import { Component, Input, OnInit } from '@angular/core';
import { User } from '../users.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserProfileService, UpdateProfileRequest } from './user-profile.service';
import { ToastrService } from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'cb-user-profile',
  templateUrl: './user-profile.component.html',
  providers: [ConfirmationService]
})
export class UserProfileComponent implements OnInit {

  @Input() user!: User;

  form!: FormGroup;

  constructor(
    private fb: FormBuilder, 
    private service: UserProfileService,
    private toastr: ToastrService,
    private confirmService: ConfirmationService
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [this.user?.name, [Validators.required, Validators.maxLength(200)]]
    });
  }

  saveProfile(): void {    
    const req: UpdateProfileRequest = this.form.getRawValue();
    const previousName = this.user.name;
    this.user.name = req.name;
    this.service.updateProfile(this.user.id!, req).subscribe(_ => {
      this.toastr.success("Profile updated!");
    }, _ => this.user.name = previousName);
  }

  changePassword(ev: Event): void {
    this.confirmService.confirm({
      target: ev.target!,
      message: `This will send an email to ${this.user?.email}, continue?`,
      accept: () => {
        this.service.changePassword(this.user?.id!, this.user?.email!).subscribe(_ => {
          this.toastr.info(`An email has been sent to ${this.user?.email} to allow the account password to be changed`, "Change password");
        })        
      }
    })
  }

}
