import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[cbDataGridActionButton]'
})
export class DataGridActionButtonDirective {

  styles = [
    "bg-brand-primary",
    "p-2", 
    "transition", 
    "hover:opacity-90", 
    "text-white", 
    "border", 
    "border-brand-primary", 
    "rounded-full", 
    "focus:outline-black", 
    "focus:ring-brand-primary", 
    "hover:shadow-md"
  ];

  @HostBinding("class")
  get elementStyles(): string {
    return this.styles.join(' ');
  }

  constructor() { }

}
