import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleGuard } from '@app/auth/roles/role.guard';
import { SettingsViewComponent } from './settings-view/settings-view.component';
import { FrameworksComponent } from './frameworks/frameworks.component';
import { NewFrameworkComponent } from './frameworks/new-framework/new-framework.component';
import { FrameworkDetailComponent } from './frameworks/framework-detail/framework-detail.component';
import { PsaConfigurationComponent } from './psa-configuration/psa-configuration.component';

const routes: Routes = [
  { path: 'settings', component: SettingsViewComponent },
  RoleGuard.build('settings/frameworks', FrameworksComponent, [
    'FrameworksManager',
  ]),
  RoleGuard.build('settings/frameworks/new', NewFrameworkComponent, [
    'FrameworksManager',
  ]),
  RoleGuard.build('settings/frameworks/:id', FrameworkDetailComponent, [
    'FrameworksManager',
  ]),
  RoleGuard.build('settings/psa-inbox', PsaConfigurationComponent, [
    'Administrator',
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SettingsRoutingModule {}
