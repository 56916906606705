import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HeroIconName } from 'ng-heroicon/lib/icons/icons-names';

@Component({
  selector: 'cb-settings-card',
  templateUrl: './settings-card.component.html',
  styles: [],
})
export class SettingsCardComponent implements OnInit {
  @Input() icon!: HeroIconName;
  @Input() external = false;
  @Input() beta = false;

  constructor() {}

  ngOnInit(): void {}
}
