import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Invitation } from '@app/invitations/invitations.model';
import { environment as env } from '@env';
import { HttpErrorService } from '@app/services/http-error.service';
import { map, tap } from 'rxjs/operators';

export interface InvitationRequest {
  firstName: string;
  lastName: string;
  password: string;
}

@Injectable({
  providedIn: 'root'
})
export class AcceptInviteService {

  private httpClient: HttpClient;

  constructor(
    private handler: HttpBackend,
    private errorHandler: HttpErrorService
  ) {
    this.httpClient = new HttpClient(this.handler);
  }

  getInvitation(id: string): Observable<Invitation> {
    const url = `${env.api.baseUrl}/v${env.api.version}/invitations/${encodeURIComponent(id)}`;
    return this.httpClient.get<Invitation>(url);
  }

  acceptInvitation(id: string, invitation: InvitationRequest): Observable<{ outcome: "Accepted" }> {
    const url = `${env.api.baseUrl}/v${env.api.version}/invitations/${encodeURIComponent(id)}`;
    return this.httpClient.put<{ outcome: "Accepted" }>(url, invitation).pipe(
      this.errorHandler.handleHttpError,
      tap((res) => console.log("RES: ", res)),
      map(() => ({ outcome: "Accepted" })),
    );
  }
}
