import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { type Project } from '@app/projects/projects.service';

@Component({
  selector: 'cb-new-project',
  templateUrl: './new-project.component.html',
  styles: [
  ]
})
export class NewProjectComponent implements OnInit {

  project: Project;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.project = {
      name: "New project",
      startDate: new Date(),
      tasks: [],
      duration: 1,
      status: "Proposed",
      budget: 0,
      completeTasksPercentage: 0,
      description: null
    }
  }

  ngOnInit(): void {

  }

  done() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }
}
