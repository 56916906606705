import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Project } from '@app/projects/projects.service';
import { ProjectsService } from '@app/projects/projects.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { iif } from 'rxjs';
import { projectTypeOptions } from '../project-options';

@Component({
  selector: 'cb-project-form',
  templateUrl: './project-form.component.html',
  providers: [ConfirmationService],
})
export class ProjectFormComponent implements OnInit {
  @Input() project: Project | null = null;
  @Input() saveLabel?: string;
  @Output() projectChange = new EventEmitter<Project | null>();
  @Output() close = new EventEmitter<void>();
  @Output() save = new EventEmitter<Project>();

  form!: FormGroup;
  formTitle!: string;

  projectTypeOptions = projectTypeOptions;

  get addMode(): boolean {
    return !this.project?.id;
  }

  constructor(
    private fb: FormBuilder,
    private service: ProjectsService,
    private toastr: ToastrService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.formTitle = this.project?.name || 'New project';

    this.form = this.fb.group({
      id: [
        '',
        [this.project?.id ? Validators.required : Validators.nullValidator],
      ],
      name: ['', [Validators.required, Validators.maxLength(100)]],
      startDate: ['', [Validators.required]],
      duration: ['', [Validators.required, Validators.min(1)]],
      budget: ['', [Validators.required, Validators.min(0)]],
      recurringRevenue: [''],
      projectType: ['', [Validators.maxLength(100)]],
      description: [''],
      ticketNumber: ['', [Validators.maxLength(100)]],
    });

    this.patchForm();
  }

  reset(): void {
    this.form.reset();
    this.patchForm();
  }

  submit(): void {
    const project = this.form.getRawValue();

    iif(
      () => this.addMode,
      this.service.createProject(project),
      this.service.updateProject(project)
    ).subscribe(() => {
      this.toastr.success('Project saved!');
      this.save.emit(project);
      this.close.emit();
    });
  }

  deleteProject(id: string): void {
    this.confirmationService.confirm({
      message:
        'Any benchmark tasks linked to this project will no longer be linked to any project',
      header: 'Do you want to delete this project?',
      accept: () => {
        this.service.deleteProject(id).subscribe(() => {
          this.toastr.success('Project deleted!');
          this.close.emit();
        });
      },
    });
  }

  cancel(): void {
    this.reset();
    this.close.emit();
  }

  private patchForm() {
    if (this.project) {
      this.form.patchValue(this.project);
    }
  }
}
