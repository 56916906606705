import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hero-check-circle',
  template: `
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
  `
})
export class CheckCircleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
