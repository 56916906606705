import { Component, OnDestroy, OnInit } from '@angular/core';
import { StoredCompany, UserContextService } from '@app/user-context.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserModel, UsersService } from '@app/users/users.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';

@Component({
  selector: 'cb-create-user',
  templateUrl: './create-user.component.html',
  styles: [
  ]
})
export class CreateUserComponent implements OnInit, OnDestroy {

  constructor(
    public context: UserContextService,
    private router: Router,
    private route: ActivatedRoute,
    private service: UsersService,
    private toastr: ToastrService
  ) { }

  private company: StoredCompany | null = null;

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private subscriptions: Subscription[] = [];

  ngOnInit(): void {
    this.company = this.context.currentCompanySnapshot;
  }

  createContact(user: UserModel): void {
    this.subscriptions.push(
      this.service.createContactForCompany(this.company!.id, user).subscribe(() => {
        this.done("User created successfully");
      })
    );
  }

  inviteUser(user: UserModel): void {
    this.subscriptions.push(
      this.service.inviteUser({companyId: this.company!.id, ...user}).subscribe(() => {
        this.done(`An invitation email has been sent to ${user.email}`);
      })
    );    
  }

  private done(message: string): void {
    this.toastr.success(message);
    this.router.navigate(['../'], {relativeTo: this.route});
  }
}
