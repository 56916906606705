<div
  class="mt-5"
  *ngFor="let control of controls"
  [ngClass]="{ 'sm:mt-0': !space }"
>
  <ng-container *ngIf="isButton(control)">
    <ng-container *ngIf="featureEnabled(control.feature)">
      <button
        type="button"
        cbButton
        class="w-full md:w-auto"
        (click)="controlClick(control)"
        [disabled]="control.loading || (control.enabled | async) === false"
        *cbRequiresRole="control.role"
      >
        <div class="flex justify-center md:justify-between items-center">
          <ng-container *ngIf="!control.loading; else buttonLoading">
            <hero-icon
              [name]="control.icon"
              type="outline"
              class="w-6 h-6 mr-2"
            ></hero-icon>
            <p>{{ control.text }}</p>
          </ng-container>
          <ng-template #buttonLoading>
            <hero-spinner></hero-spinner>
            <p>Loading</p>
          </ng-template>
        </div>
      </button>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="isLinkButton(control)">
    <ng-container *ngIf="control.visible">
      <button
        type="button"
        (click)="controlClick(control)"
        *cbRequiresRole="control.role"
        class="text-brand-primary underline hover:opacity-80 hover:no-underline cursor-pointer"
      >
        {{ control.text }}
      </button>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="isSelectList(control)">
    <label *ngIf="control.label" cbLabel>{{ control.label }}</label>
    <select
      cbSelect
      class="flex-auto control-list-dropdown"
      [(ngModel)]="control.selectedOption"
      title="Pick an option"
      (change)="controlChange(control)"
    >
      <option
        *ngFor="let option of getSelectOptions(control) | async"
        [ngValue]="option.value"
      >
        {{ option.text }}
      </option>
    </select>
  </ng-container>

  <ng-container *ngIf="isMultiSelect(control)">
    <label cbLabel *ngIf="control.label">{{ control.label }}</label>
    <div>
      <p-multiSelect
        *ngIf="control.options | async as options"
        [options]="options"
        [(ngModel)]="control.selectedOptions"
        (onChange)="controlChange(control)"
        optionLabel="text"
        optionValue="value"
        [filter]="false"
        [inputId]="control.id"
        [showToggleAll]="false"
        [showHeader]="false"
        styleClass="sm:text-sm"
      >
      </p-multiSelect>
    </div>
  </ng-container>

  <ng-container *ngIf="isDatePicker(control)">
    <label *ngIf="control.label" cbLabel>{{ control.label }}</label>
    <cb-date-picker
      [value]="control.selectedDate"
      (valueChange)="dateSelected($event, control)"
    ></cb-date-picker>
  </ng-container>

  <ng-container *ngIf="isSearchControl(control)">
    <label *ngIf="control.config.label" cbLabel>{{
      control.config.label
    }}</label>
    <input
      type="search"
      [placeholder]="control.config.placeholder"
      [(ngModel)]="control.config.value"
      #s
      (keyup)="textControlChange(control, s.value, true)"
      (search)="textControlChange(control, s.value, false)"
      cbInput
    />
  </ng-container>
</div>
