import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CreateCompanyRequest,
  CompanyType,
  CompanySize,
} from '@app/domain/model';
import { UsersService } from '@app/users/users.service';
import { countries, sectors } from '@data/static';
import { CompanyDetailService } from '../company-detail/company-detail.service';
import { Observable, of } from 'rxjs';
import { User } from '@app/users/users.model';
import { UserContextService } from '@app/user-context.service';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { UserListService } from '@app/users/users-list/user-list.service';
import { isNotNull } from '@app/shared';

interface TypeSelect {
  key: CompanyType;
  value: string;
}

const urlRegex = /^(?:https:\/\/)+/gi;
const hexRegex = /^#(?=[a-f0-9]*$)(?:.{3}|.{6})$/gi;

@Component({
  selector: 'cb-company-form',
  templateUrl: './company-form.component.html',
  styles: [],
})
export class CompanyFormComponent implements OnInit, AfterViewInit {
  @Input() formTitle?: string;
  @Input() company?: CreateCompanyRequest;
  @Output() save = new EventEmitter<CreateCompanyRequest>();
  @Output() cancel = new EventEmitter<void>();

  private companyNameElement!: ElementRef<HTMLInputElement>;
  @ViewChild('companyNameInput', { static: false })
  set content(content: ElementRef<HTMLInputElement>) {
    if (content) {
      this.companyNameElement = content;
    }
  }

  form!: FormGroup;

  countryList = countries;
  sectorList = sectors;

  sizes: CompanySize[] = ['Small', 'Medium', 'Enterprise'];

  members$: Observable<User[]>;

  constructor(
    private fb: FormBuilder,
    private usersService: UserListService,
    private context: UserContextService
  ) {
    this.members$ = this.context.myCompany$.pipe(
      switchMap(({ id }) => this.usersService.getCompanyUsers(id, true))
    );
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      displayName: ['', [Validators.required, Validators.maxLength(255)]],
      accountManagerId: [null, [Validators.required]],
      registeredName: [null, [Validators.maxLength(255)]],
      registeredNumber: [null, [Validators.maxLength(50)]],
      sector: [null, [Validators.required]],
      logoUrl: [
        null,
        [Validators.pattern(urlRegex), Validators.maxLength(255)],
      ],
      brandColor: [
        null,
        [Validators.pattern(hexRegex), Validators.maxLength(7)],
      ],
      address: this.fb.group({
        line1: ['', [Validators.maxLength(50)]],
        line2: ['', [Validators.maxLength(50)]],
        country: ['United Kingdom', [Validators.maxLength(50)]],
        postcode: ['', [Validators.maxLength(50)]],
        locality: ['', Validators.maxLength(50)],
        region: ['', [Validators.maxLength(50)]],
      }),
    });

    if (this.company) {
      this.form.patchValue(this.company);
    }

    if (
      !['Admin', 'Manager'].includes(this.context.userSnapshot?.roleGroup ?? '')
    ) {
      this.form.controls['accountManagerId'].disable();
    }
  }

  ngAfterViewInit(): void {
    this.focusCompanyName();
  }

  submit(): void {
    const company: CreateCompanyRequest = this.form.getRawValue();
    const address = company.address?.line1 ? company.address : undefined;
    this.save.emit({
      ...company,
      address,
    });
  }

  formCancel(): void {
    this.form.reset();
    this.cancel?.emit();
  }

  focusCompanyName() {
    setTimeout(() => this.companyNameElement.nativeElement.focus());
  }
}
