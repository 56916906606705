<div [title]="(risk | splitWords) + ' risk'">
    <ng-container *ngIf="iconOnly; else withText">
        <div [class]="colour">
            <hero-icon [name]="icon" type="outline" class="w-6 h-6"></hero-icon>
        </div>
    </ng-container>
    <ng-template #withText>
        <div class="flex px-2 items-center justify-start max-w-full w-full md:w-36">
            <div class="text-sm mt-1 font-bold" [class]="colour">
                <hero-icon [name]="icon" type="outline" class="w-6 h-6"></hero-icon>
            </div>
            <div class="hidden md:block md:ml-1">
                <span class="text-sm text-brand-secondary">
                    {{risk | splitWords}} <span *ngIf="includeSuffix">Risk</span>
                </span>
            </div>
        </div>
    </ng-template>
</div>