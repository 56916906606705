<div [hidden]="!isVisible" class="fixed z-30 inset-0">
    <section class="flex items-start min-w-full justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0 sm:max-w-lg">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true" >
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>   

        <section class="inline-block sm:align-bottom w-full bg-white rounded-lg text-left overflow-y-auto sm:overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
            <div class="fixed top-1 right-1">
                <button type="button" (click)="close()" class="p-4 opacity-40 hover:opacity-100">
                    <hero-icon name="x" type="outline" class="w-6 h-6"></hero-icon>
                    <span class="sr-only">Close modal window</span>
                </button>
                
            </div>
            <div class="px-6 py-8">
                <h2 class="text-xl mb-10">{{title}}</h2>
                <main class="mt-10">
                    <ng-container *ngTemplateOutlet="content"></ng-container>
                </main>
            </div>
        </section>
    </section>

</div>
