import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderButton, HeaderControl } from '@app/components/page-header/controls';
import { MenuItem } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { Framework, FrameworkService } from './framework.service';

@Component({
  selector: 'cb-frameworks',
  templateUrl: './frameworks.component.html',
  styles: [
  ]
})
export class FrameworksComponent implements OnInit {

  subscriptions: Subscription[] = [];
  controls: HeaderControl[] = [];
  breadcrumb: MenuItem[] = [
    { label: "Settings", routerLink: ["/settings"] },
    { label: "Frameworks", routerLink: ["/settings", "frameworks"] }
  ];

  frameworks$?: Observable<Framework[]>;

  constructor(
    private service: FrameworkService,
    private router: Router
  ) {
    this.controls = [
      new HeaderButton("Add Framework", "plus-circle", () => this.router.navigate(['/settings/frameworks/new']))
    ];
  }

  ngOnInit(): void {
    this.frameworks$ = this.service.getFrameworks();
  }

}
