import { Component, OnInit, OnDestroy } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { makeCompanyId } from '@app/interfaces';
import { UserContextService } from '@app/user-context.service';
import { AuthService } from '@auth0/auth0-angular';
import { combineLatest, EMPTY, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'cb-index',
  template: '',
  styles: [
  ]
})
export class IndexComponent implements OnInit, OnDestroy {

  private sub?: Subscription;

  constructor(
    private router: Router, 
    private context: UserContextService,
    private auth: AuthService
  ) {}

  ngOnInit(): void {

    const stream = [
      this.auth.isLoading$,
      this.auth.isAuthenticated$
    ];

    this.sub = combineLatest(stream).pipe(
      switchMap(([loading, authenticated]) => {
        if(!loading && authenticated){
          return this.context.myCompany$;        
        }
        else if(!loading && !authenticated){
          this.auth.loginWithRedirect();
        }
        
        return EMPTY;
      })      
    )
    .subscribe(company => {
      if(company){
        const route = company.type === 'SME' ? ['/c', company.id, 'overview'] : ['/dashboard'];
        this.context.currentCompany$.next({
          id: makeCompanyId(company.id),
          name: company.name,
          type: company.type
        });
        this.router.navigate(route);
      }
    });    
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

}
