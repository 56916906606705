import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '@env';
import { HttpErrorService } from '@app/services/http-error.service';
import { Observable } from 'rxjs';
import { CollectionResponse } from '@app/domain/model';
import { Quality, TaskRisk } from '@app/benchmark/benchmark.model';
import { map, switchMap } from 'rxjs/operators';
import { TaskQuality } from '@app/benchmark/tasks-list/tasks-list.component';
import { CompanyId } from '@app/interfaces';
import { UserContextService } from '@app/user-context.service';
import { FeaturesService } from '@app/features.service';
import { splitWords } from '@app/util/split-words';

export type ProjectStatus =
  | 'Proposed'
  | 'InProgress'
  | 'Quoting'
  | 'Completed'
  | 'Rejected';

export interface Project {
  id?: string;
  name: string;
  startDate: Date | undefined;
  duration: number;
  budget: number | undefined;
  ownerCompanyId?: string;
  description: string | null;
  tasks: ProjectTask[];
  completeTasksPercentage: number;
  status: ProjectStatus;
  ticketNumber?: string | null;
  dateSentToPsa?: Date;
  recurringRevenue?: number;
  projectType?: string;
}

type ProjectsRequest = {
  companyId: CompanyId;
  term?: string | null;
  type?: string | null;
  status?: ProjectStatus[];
  offset?: number | null;
  fetch?: number | null;
};

type ProjectResponse = Omit<Project, 'startDate'> & {
  startDate: string;
};

type ProjectRequest = Omit<Project, 'startDate'> & {
  startDate: string | undefined;
};

export type ProjectTask = {
  taskId: string;
  entryId: string;
  title: string;
  risk: TaskRisk;
  quality: TaskQuality;
  notes: string;
  internalNotes: string | null;
};

function createProjectFromResponse(projectResponse: ProjectResponse): Project {
  return {
    ...projectResponse,
    startDate: projectResponse.startDate
      ? new Date(projectResponse.startDate)
      : undefined,
  };
}

function createProjectRequestFromProject(project: Project): ProjectRequest {
  return {
    ...project,
    startDate: getUtcStartDateString(project.startDate),
  };
}

function getUtcStartDateString(date: Date | undefined): string | undefined {
  if (!date) {
    return undefined;
  }

  const d = new Date(date);

  return new Date(Date.UTC(d.getFullYear(), d.getMonth())).toISOString();
}

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  constructor(
    private httpClient: HttpClient,
    private errorHandler: HttpErrorService,
    private context: UserContextService,
    private features: FeaturesService
  ) {
    this.projectStatusOptions$ = this.context.myCompany$.pipe(
      switchMap(({ id }) => this.features.enabledForCompany('pushToPsa', id)),
      map((featureEnabled) =>
        [
          'Proposed' as const,
          ...(featureEnabled ? ['Quoting' as const] : []),
          'InProgress' as const,
          'Completed' as const,
          'Rejected' as const,
        ].map((value) => ({
          text: splitWords(value),
          value,
        }))
      )
    );
  }

  projectStatusOptions$: Observable<{ text: string; value: ProjectStatus }[]>;

  getProjects(req: ProjectsRequest): Observable<Project[]> {
    const { companyId, term, type, status, offset, fetch } = req;
    const {
      api: { baseUrl },
    } = env;
    const params: string[] = [];
    let url = `${baseUrl}/v1/companies/${encodeURIComponent(
      companyId
    )}/projects`;

    if (term) {
      params.push(`term=${encodeURIComponent(term)}`);
    }

    if (status) {
      for (const st of status) {
        params.push(`status=${encodeURIComponent(st)}`);
      }
    }

    if (type) {
      params.push(`type=${encodeURIComponent(type)}`);
    }

    if (offset && fetch) {
      params.push.apply(params, [`offset=${offset}`, `fetch=${fetch}`]);
    }

    if (params.length) {
      url += `?${params.join('&')}`;
    }

    return this.httpClient.get<CollectionResponse<ProjectResponse>>(url).pipe(
      this.errorHandler.handleHttpError,
      map((response) => response.result),
      map((projects) =>
        projects.map((project) => createProjectFromResponse(project))
      )
    );
  }

  getPendingTasks(
    companyId: string,
    filter?: string | null
  ): Observable<ProjectTask[]> {
    const { baseUrl } = env.api;
    let url = `${baseUrl}/v1/companies/${encodeURIComponent(
      companyId
    )}/tasks/pending`;

    if (filter) {
      url += `?q=${encodeURIComponent(filter.trim())}`;
    }

    return this.httpClient.get<CollectionResponse<ProjectTask>>(url).pipe(
      this.errorHandler.handleHttpError,
      map((res) => res.result)
    );
  }

  removeTaskFromProject(entryId: string): Observable<void> {
    const url = `${env.api.baseUrl}/v${
      env.api.version
    }/roadmap/tasks/${encodeURIComponent(entryId)}/project`;
    return this.httpClient
      .delete<void>(url)
      .pipe(this.errorHandler.handleHttpError);
  }

  getProjectById(id: string): Observable<Project> {
    const url = `${env.api.baseUrl}/v${
      env.api.version
    }/projects/${encodeURIComponent(id)}`;
    return this.httpClient.get<ProjectResponse>(url).pipe(
      this.errorHandler.handleHttpError,
      map((project) => createProjectFromResponse(project))
    );
  }

  createProject(project: Project): Observable<Project> {
    const request = createProjectRequestFromProject(project);
    const { baseUrl } = env.api;
    const url = `${baseUrl}/v1/projects`;
    return this.httpClient.post<ProjectResponse>(url, request).pipe(
      this.errorHandler.handleHttpError,
      map((response) => createProjectFromResponse(response))
    );
  }

  updateProject(project: Project): Observable<Project> {
    const request = createProjectRequestFromProject(project);
    const { baseUrl } = env.api;
    const url = `${baseUrl}/v1/projects/${encodeURIComponent(project.id!)}`;
    return this.httpClient.put<ProjectResponse>(url, request).pipe(
      this.errorHandler.handleHttpError,
      map((response) => createProjectFromResponse(response))
    );
  }

  updateProjectStatus(
    id: string,
    status: Project['status'],
    tasks?: ProjectTask[]
  ): Observable<Project> {
    const url = `${env.api.baseUrl}/v1/projects/${encodeURIComponent(
      id
    )}/status`;
    return this.httpClient
      .put<Project>(url, { status, tasks })
      .pipe(this.errorHandler.handleHttpError);
  }

  sendToPsa(projectId: string): Observable<void> {
    const url = `${env.api.baseUrl}/v1/projects/${encodeURIComponent(
      projectId
    )}/psa`;
    return this.httpClient
      .put<void>(url, {})
      .pipe(this.errorHandler.handleHttpError);
  }

  deleteProject(id: string): Observable<void> {
    const url = `${env.api.baseUrl}/v${
      env.api.version
    }/projects/${encodeURIComponent(id)}`;
    return this.httpClient
      .delete<void>(url)
      .pipe(this.errorHandler.handleHttpError);
  }

  updateProjectTasks(
    projectId: string,
    entryIds: string[]
  ): Observable<{ outcome: 'Accepted' }> {
    const { baseUrl } = env.api;
    const url = `${baseUrl}/v1/projects/${encodeURIComponent(projectId)}/tasks`;
    return this.httpClient
      .put<{ outcome: 'Accepted' }>(url, { entryIds })
      .pipe(this.errorHandler.handleHttpError);
  }
}
