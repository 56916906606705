import { Injectable, Type } from '@angular/core';
import { CanActivate, Router, Route, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { claim_types } from '../claim-types';
import { AuthGuard } from '@auth0/auth0-angular';
import { RouteSubscriptionKind } from '@app/interfaces';
import { Role } from '@app/roles';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(public auth: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.auth.user$.pipe(map(user => {
      if (!user) {
        return true;
      }

      const roles = new Set<string>(user[claim_types.ROLE]);
      const requiredSet = new Set<string>(route.data.roles);
      const intersect = [...requiredSet].filter(x => roles.has(x));

      if (intersect.length === 0) {
        this.router.navigate(['error', '403']);
        return false;
      }

      return true;
    }))
  }

  public static build<TData extends { kind: RouteSubscriptionKind }>(
    path: string,
    component: Type<any>,
    roles: Role[] = [],
    data?: TData,
    activators: Type<CanActivate>[] = []): Route {
    return {
      path,
      component,
      canActivate: [
        ...activators,
        RoleGuard
      ],
      data: {
        ...data,
        roles: [...roles]
      }
    };
  }
}
