import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Framework } from '../framework.service';

@Component({
  selector: 'cb-new-framework',
  templateUrl: './new-framework.component.html',
  styles: [
  ]
})
export class NewFrameworkComponent implements OnInit {

  framework: Framework;

  constructor(
    private router: Router
  ) {
    this.framework = {};
  }

  ngOnInit(): void {
  }

  formClosed(): void {
    this.router.navigate(['/settings', 'frameworks']);
  }

}
