import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, switchMapTo } from 'rxjs/operators';
import { Company, CollectionResponse } from '@app/domain/model';
import { environment as env } from '@env';
import { HttpErrorService } from '@app/services/http-error.service';

@Injectable({
  providedIn: 'root'
})
export class CompaniesListService {

  private _refresh = new BehaviorSubject<void>(undefined);

  constructor(
    private httpClient: HttpClient,
    private errorHandler: HttpErrorService
  ) {

  }

  private sendRequest(ownedBy?: string): Observable<Company[]> {
    let url = `${env.api.baseUrl}/v${env.api.version}/companies`;
    const params = [];

    if (ownedBy) {
      params.push(`ownedBy=${encodeURIComponent(ownedBy)}`);
    }

    if (params.length) {
      url += `?${params.join('&')}`;
    }

    return this.httpClient.get<CollectionResponse<Company>>(url).pipe(
      this.errorHandler.handleHttpError,
      map(x => x.result)
    );
  }

  companies$: Observable<Company[]> = this._refresh.pipe(
    switchMapTo(this.sendRequest()),
    shareReplay(1),
    this.errorHandler.handleHttpError
  );

  getCompaniesOwnedBy(mspId: string): Observable<Company[]> {
    return this.sendRequest(mspId);
  }

  refresh(): void {
    this._refresh.next();
  }
}
