import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Level } from '@app/components/level-label/level-label.component';
import { HttpErrorService } from '@app/services/http-error.service';
import { Observable } from 'rxjs';
import { environment as env } from "@env";
import { map } from 'rxjs/operators';

type Response = {
  currentLevel: number | null;
}

@Injectable({
  providedIn: 'root'
})
export class CompanyLevelService {

  constructor(
    private httpClient: HttpClient,
    private errorService: HttpErrorService
  ) { }

  getCompanyLevel(companyId: string): Observable<Level> {
    const { baseUrl } = env.api;
    const url = `${baseUrl}/v1/${encodeURIComponent(companyId)}/level`;
    return this.httpClient.get<Response>(url).pipe(
      map(res => (res.currentLevel ?? 0) as Level),
      this.errorService.handleHttpError
    );
  }
}
