import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreateCompanyRequest, CompanyDetail } from '@app/domain/model';
import { environment as env } from '@env';
import { HttpErrorService } from '@app/services/http-error.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyEditService {
  constructor(
    private httpClient: HttpClient,
    private errorHandler: HttpErrorService
  ) {}

  updateCompany(
    id: string,
    company: CreateCompanyRequest
  ): Observable<CompanyDetail> {
    const url = `${env.api.baseUrl}/v1/companies/${encodeURIComponent(id)}`;
    return this.httpClient
      .put<CompanyDetail>(url, company)
      .pipe(this.errorHandler.handleHttpError);
  }
}
