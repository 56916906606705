import { Injectable } from "@angular/core";
import { ApplicationInsights, IExceptionTelemetry } from "@microsoft/applicationinsights-web";
import { environment } from "@env";

@Injectable({ providedIn: "root" })
export class LoggingService {

    appInsights?: ApplicationInsights;

    configure() {
        if (environment.APPLICATIONINSIGHTS_INSTRUMENTKEY) {
            this.appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: environment.APPLICATIONINSIGHTS_INSTRUMENTKEY,
                    enableAutoRouteTracking: true
                }
            });

            this.appInsights.loadAppInsights();
        }
    }

    trace(message: string, properties?: { [key: string]: any }) {
        if (!this.appInsights) {
            console.info(message, properties);
        }

        this.appInsights?.trackTrace({ message }, properties);
    }

    error(message: string, error: Error, properties?: { [key: string]: unknown }) {
        if (!this.appInsights) {
            return console.error(message, {
                ...properties,
                error
            });
        }

        const exception: IExceptionTelemetry = {
            exception: error,
            properties
        }

        this.appInsights.trackException(exception);
    }
}