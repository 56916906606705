import { Component, OnInit } from '@angular/core';
import { UserContextService } from '@app/user-context.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { products, free } from '@app/shared/products';

type MspSubscription = {
  plan: string;
  clients: number;
  documents: number;
  pricing: {
    amount: string;
    schedule: "month" | "year" | "forever";
  } | undefined;
}

@Component({
  selector: 'cb-msp-subscription',
  templateUrl: './msp-subscription.component.html',
  styles: [
  ]
})
export class MspSubscriptionComponent implements OnInit {

  subscription$: Observable<MspSubscription | undefined> = of(undefined);

  constructor(
    private context: UserContextService
  ) { }

  ngOnInit(): void {
    this.subscription$ = this.context.accountStatus$.pipe(
      map((status) => {
        const product = products.search((p) => p.id === status.currentPlanId)?.data ?? free;
        const { pricing } = product.detail;

        return {
          plan: `Clear ${status.currentPlanId}`,
          clients: status.maxClientsAllowed ?? 0,
          documents: status.maxDocumentsAllowed ?? 0,
          pricing
        }
      })
    )
  }

}
