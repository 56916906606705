<ng-container *ngIf="task">
  <ul
    class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200"
  >
    <li class="mr-2">
      <button
        type="button"
        (click)="toggleTab('notes')"
        aria-current="page"
        class="inline-block px-4 py-1 rounded-t-lg"
        [ngClass]="{
          'cb-tab-active': activeTab === 'notes',
          'cb-tab-inactive': activeTab !== 'notes'
        }"
      >
        Notes
      </button>
    </li>
    <li class="mr-2">
      <button
        type="button"
        (click)="toggleTab('internal')"
        class="inline-block px-4 py-1 rounded-t-lg"
        [ngClass]="{
          'cb-tab-active': activeTab === 'internal',
          'cb-tab-inactive': activeTab !== 'internal'
        }"
      >
        Internal Notes
      </button>
    </li>
  </ul>

  <div
    class="py-1 px-2 border border-gray-400 rounded-tr-lg rounded-b-lg focus-within:shadow-md"
  >
    <ng-container *ngIf="activeTab === 'notes'">
      <textarea
        [(ngModel)]="task.notes"
        cbTextarea
        class="border-none focus:ring-0 focus:border-none"
        rows="4"
        cols="10"
        placeholder="Add notes for the customer here"
      ></textarea>
    </ng-container>
    <ng-container *ngIf="activeTab === 'internal'">
      <textarea
        [(ngModel)]="task.internalNotes"
        cbTextarea
        class="border-none focus:ring-0 focus:border-none"
        rows="4"
        cols="10"
        placeholder="Add internal notes here"
      ></textarea>
    </ng-container>
  </div>
</ng-container>
