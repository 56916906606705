import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BenchmarkTask } from '@app/benchmark/benchmark.service';
import { TaskQuality } from '../tasks-list.component';
import { ConfirmationService } from 'primeng/api';

type QualityRating = {
  rating: number;
  quality: TaskQuality;
};

const ratings: QualityRating[] = [
  {
    rating: 0,
    quality: 'NotApplicable',
  },
  {
    rating: 1,
    quality: 'NotImplemented',
  },
  {
    rating: 2,
    quality: 'Low',
  },
  {
    rating: 3,
    quality: 'Medium',
  },
  {
    rating: 4,
    quality: 'High',
  },
  {
    rating: 5,
    quality: 'Excellent',
  },
];

@Component({
  selector: 'cb-quality-options',
  templateUrl: './quality-options.component.html',
  styleUrls: ['./quality-options.component.css'],
  providers: [ConfirmationService],
})
export class QualityOptionsComponent implements OnInit {
  @Input()
  task: BenchmarkTask | undefined;

  @Output()
  qualitySelected: EventEmitter<BenchmarkTask> =
    new EventEmitter<BenchmarkTask>();

  qualityRatings: {
    rating: number;
    label: 'N/A' | 'very poor' | 'poor' | 'OK' | 'good' | 'excellent';
  }[] = [
    { rating: 0, label: 'N/A' },
    { rating: 1, label: 'very poor' },
    { rating: 2, label: 'poor' },
    { rating: 3, label: 'OK' },
    { rating: 4, label: 'good' },
    { rating: 5, label: 'excellent' },
  ];

  set notes(value: string | undefined) {
    value && this.task && (this.task.notes = value);
    this.qualitySelected.emit(this.task);
  }

  getButtonStyle(qualityRating: number): string[] {
    const quality = ratings.find(
      ({ rating }) => rating === qualityRating
    )?.quality;

    if (!quality) {
      return [];
    }

    let qualityStyles = [];

    switch (quality) {
      case 'NotImplemented':
      case 'Low':
        qualityStyles = ['bg-rag-red', 'border-rag-red'];
        break;
      case 'Medium':
        qualityStyles = ['bg-rag-amber', 'border-rag-amber'];
        break;
      case 'NotApplicable':
        qualityStyles = ['bg-gray-500', 'border-gray-600'];
        break;
      default:
        qualityStyles = ['bg-rag-green', 'border-rag-green'];
        break;
    }

    return qualityStyles;
  }

  get quality(): number {
    if (!this.task || !this.task.quality) {
      return -1;
    }

    return (
      ratings.find(({ quality }) => quality === this.task?.quality)?.rating ??
      -1
    );
  }

  set quality(value: number) {
    if (value < 0 || value > 5) {
      return;
    }

    if (!this.task) {
      return;
    }

    const rating = ratings.find(({ rating }) => rating === value);

    if (!rating) {
      return;
    }

    this.task.quality = rating.quality;

    this.modelChange();
  }

  constructor(private confirmService: ConfirmationService) {}

  ngOnInit(): void {}

  private modelChange(): void {
    let notes: string | undefined = undefined;
    const examples = this.task?.examples;

    if (!examples) {
      return;
    }

    if (!this.task?.quality) {
      notes = undefined;
      return;
    }

    switch (this.task.quality) {
      case 'NotImplemented':
      case 'Low':
        notes = examples.lowQuality;
        break;
      case 'Medium':
        notes = examples.mediumQuality;
        break;
      case 'High':
        notes = examples.highQuality;
        break;
      case 'Excellent':
        notes = examples.excellentQuality;
        break;
      case 'NotApplicable':
        notes = 'N/A';
        break;
    }

    if (this.task?.notes?.length && notes && this.task?.notes !== notes) {
      this.confirmService.confirm({
        message: `Replace public notes with "${notes}"?`,
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.notes = notes;
        },
      });
      return;
    }

    this.notes = notes;
  }
}
