import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RoleName } from '@app/roles';

@Component({
  selector: 'cb-company-detail-card',
  templateUrl: './company-detail-card.component.html',
  styles: [
  ]
})
export class CompanyDetailCardComponent implements OnInit {

  @Input() heading!: string;
  @Input() buttonText?: string;
  @Output() buttonClick = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  handleButtonClick(arg: any): void {
    this.buttonClick.emit(arg);
  }

}
