<cb-authorized>
    <cb-body>
        <cb-page-header>
            Company Journey
        </cb-page-header>

        <cb-body-card *ngIf="activity$ | async as activity">
            <div class="max-w-3xl mx-auto">
                <p-timeline [value]="activity" [align]="'alternate'">
                    <ng-template pTemplate="content" let-activity>
                        <div class="px-4 py-6 border border-mid-gray rounded-md shadow-md">
                            <h2 class="text-xl">{{activity.title}}</h2>
                            <div class="text-sm">{{activity.timestamp | date:'dd MMM yyyy'}}</div>
                            <div class="text-sm" *ngIf="activity.riskScore">
                                <div class="pt-1">
                                    <span>Risk score: {{activity.riskScore}}</span>
                                    <span class="inline-block" *ngIf="getRiskScoreTrend(activity) as trend" [title]="trend.description">
                                        <hero-icon [name]="trend.icon" type="outline" class="w-5 h-5 pt-2" [ngClass]="{'text-rag-red': trend.up, 'text-rag-green': trend.down}"></hero-icon>
                                    </span>
                                </div>
                            </div>                        
                            <p *ngIf="activity.description" class="text-sm mt-4">{{activity.description}}</p>
                        </div>
                        
                    </ng-template>
                </p-timeline>
            </div>            
        </cb-body-card>
    </cb-body>
</cb-authorized>
