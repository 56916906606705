import { Component, OnInit } from '@angular/core';
import { FeaturesService } from '@app/features.service';
import { UserContextService } from '@app/user-context.service';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'cb-settings-view',
  templateUrl: './settings-view.component.html',
  styles: [],
})
export class SettingsViewComponent implements OnInit {
  pushToPsaEnabled$: Observable<boolean>;

  constructor(
    public context: UserContextService,
    private features: FeaturesService
  ) {
    this.pushToPsaEnabled$ = context.myCompany$.pipe(
      switchMap(({ id }) => features.enabledForCompany('pushToPsa', id))
    );
  }

  ngOnInit(): void {}
}
