<cb-metric-card heading="Subscription">
    <ng-container *ngIf="subscription$ | async as subscription; else loading">
        <div class="p-1 flex justify-between items-start">
            <div>
                <div class="text-2xl">{{subscription.plan}}</div>
                <div class="text-xs text-slate-400">
                    {{subscription.clients | number}} client{{subscription.clients | plural}}
                </div>
                <div class="text-xs text-slate-400">
                    {{subscription.documents | number}} document{{subscription.documents | plural}}
                </div>
            </div>
            <div>
                <div class="text-sm pt-1" *ngIf="subscription.pricing as pricing">
                    <span class="font-bold">{{pricing.amount}}</span>
                    <span class="text-slate-400">/{{pricing.schedule}}</span>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-template #loading>
        <div class="mb-2">
            <p-skeleton width="8rem" height="2rem"></p-skeleton>
        </div>
        <div>
            <p-skeleton width="5rem"></p-skeleton>
        </div>

    </ng-template>

</cb-metric-card>