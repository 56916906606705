import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpErrorService } from '@app/services/http-error.service';
import { environment as env } from '@env';

export interface RiskByMonth {
  available: boolean;
  data: RiskByMonthItem[];
}

interface RiskByMonthItem {
  scoreDate: string;
  score: number;
}

@Injectable({
  providedIn: 'root'
})
export class RiskHistoryService {

  constructor(
    private httpClient: HttpClient,
    private errorHandler: HttpErrorService
  ) { }

  getRiskByCategory(companyId: string): Promise<RiskByMonth> {
    const url = `${env.api.baseUrl}/v${env.api.version}/overall-risk-by-month?companyId=${encodeURIComponent(companyId)}`;

    const headers = { ignoreLoadingBar: '' };

    return this.httpClient.get<RiskByMonth>(url, { headers }).pipe(
      this.errorHandler.handleHttpError
    ).toPromise();

  }
}
