import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClientsListComponent } from './clients-list/clients-list.component';
import { ComponentsModule } from '@app/components/components.module';
import { RouterModule } from '@angular/router';
import { IconsModule } from '@app/app-icons.module';
import { StylesModule } from '@app/styles/styles.module';
import { SidebarModule } from 'primeng/sidebar';
import { AddClientComponent } from './add-client/add-client.component';

@NgModule({
  declarations: [
    ClientsListComponent,
    AddClientComponent
  ],
  exports: [
    AddClientComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    ComponentsModule,
    IconsModule,
    StylesModule,
    SidebarModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class ClientsModule { }
