<h3 class="text-center mb-4">{{year}}</h3>
<section id="roadmap" class="relative grid overflow-auto lg:overflow-hidden">
    <div class="grid grid-cols-gantt gap-2 lg:gap-0 border-b border-gray-200">
        <div></div>
        <div class="p-2 text-center text-brand-secondary" *ngFor="let month of months">
            {{month}}
        </div>
    </div>    
    <div class="grid grid-cols-gantt gap-0 absolute h-full w-full">
        <div class="border-l border-gray-200"></div>
        <div *ngFor="let month of months" class="border-l border-gray-200"></div>
    </div>
    <div class="grid grid-cols-gantt-row border-b border-gray-200 cursor-pointer" *ngFor="let project of projects">
        <div class="px-2 py-2 font-normal text-sm lg:text-base lg:font-bold flex items-center justify-start lg:justify-end z-10">
            <a [routerLink]="['../projects', project.id]" class="cursor-pointer hover:underline">{{project.name}}</a>
        </div>
        <ul class="grid grid-cols-12 py-4">
            <li *ngFor="let task of project.tasks" [routerLink]="['../tasks', task.id]" [cbGanttSpan]="task.metadata" class="bg-brand-deep my-1 font-light lg:font-bold text-xs lg:text-sm relative overflow-hidden rounded-full px-2 lg:px-4 py-1 text-white opacity-80 hover:opacity-100">
                {{task.name}}
            </li>
        </ul>
    </div>
    
</section>