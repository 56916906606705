export interface Invitation {
    firstName: string;
    lastName: string;
    companyName: string;
    invitedBy: string;
    status: InvitationStatus;
}

export enum InvitationStatus {
    Pending = "Pending",
    Accepted = "Accepted",
    Expired = "Expired",
    Deleted = "Deleted"
}