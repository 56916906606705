import { HttpClient, HttpContext, HttpContextToken } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpErrorService } from '@app/services/http-error.service';
import { environment as env } from '@env';

export interface TasksByStatusResult {
  available: boolean;
  unplanned: number;
  planned: number;
  completed: number;
}

@Injectable({
  providedIn: 'root'
})
export class TastStatusService {

  constructor(
    private httpClient: HttpClient,
    private errorHandler: HttpErrorService
  ) { }

  getTasksByStatus(companyId: string): Promise<TasksByStatusResult> {
    const url = `${env.api.baseUrl}/v${env.api.version}/data/tasks-by-status?companyId=${encodeURIComponent(companyId)}`;

    const headers = { ignoreLoadingBar: '' };

    var promise = this.httpClient.get<TasksByStatusResult>(url, { headers }).pipe(
      this.errorHandler.handleHttpError
    ).toPromise();

    return promise;
  }
}
