<ng-container *ngIf="loading; else loaded">
    <div class="flex justify-center">
        <p-progressSpinner></p-progressSpinner>
    </div>
</ng-container>
<ng-template #loaded>
    <ng-container *ngIf="score;else norisk">
        <div class="flex">
            <div *ngIf="scorePosition.left" [style]="{'width': (scorePosition.left - 5) + '%'}"></div>
            <div class="text-2xl" [style]="{'width': (scorePosition.right + 5) + '%'}">                
                {{score}}
            </div>
        </div>
        <div class="bg-gradient-to-r from-rag-red via-rag-amber to-rag-green">    
            <div class="flex">
                <div *ngIf="scorePosition.left" class="h-full p-1" [style]="{'width': scorePosition.left + '%'}">
                </div>
                <div class="h-full px-1 py-2 border-l-2 border-brand-deep" [style]="{'width': scorePosition.right + '%'}">                
                </div>
            </div>        
        </div>
        <div class="flex justify-between items-center text-sm">
            <div>1000</div>
            <div>0</div>
        </div>
        <div class="mt-20">
            Your overall risk score has been calculated as {{score}}. The closer to zero your score gets, the less overall risk your IT strategy carries.
        </div>
    </ng-container>
    <ng-template #norisk>
        <div class="flex flex-col items-center h-full text-center text-2xl">
            <div class="text-brand-primary ">Smashing it!</div>
            <div>Your risk score is zero!</div>
        </div>
    </ng-template>    
</ng-template>
