<cb-authorized>
    <cb-body [hasBreadcrumb]="true">
        <cb-page-header [controls]="controls$ | async" [actions]="actions"
            [subHeading]="resultCount + ' task' + (resultCount | plural)" [showCurrentCompany]="false"
            [breadcrumb]="breadcrumb">
            Tasks
        </cb-page-header>

        <cb-list-view>
            <cb-list-header>
                <cb-header-column [span]="2">Title</cb-header-column>
                <cb-header-column>Category</cb-header-column>
                <cb-header-column>Group</cb-header-column>
            </cb-list-header>
            <ng-container *ngIf="tasks$ | async as tasks">
                <cb-list-row *ngFor="let task of tasks" [tags]="['Level ' + task.level]" [routerLink]="[task.id]">
                    <cb-list-column [span]="2">
                        {{task.title}}
                    </cb-list-column>
                    <cb-list-column>
                        {{task.category}}
                    </cb-list-column>
                    <cb-list-column>
                        {{task.group}}
                    </cb-list-column>
                </cb-list-row>
            </ng-container>
        </cb-list-view>

    </cb-body>
    <input type="file" #file class="hidden" (change)="sendFileUpload(file)">
</cb-authorized>