<div class="w-full">

    <form [formGroup]="form" (submit)="submit()" novalidate>
        <input type="hidden" formControlName="id">

        <div class="mt-5 flex gap-4">
            <div class="flex-1">
                <label for="framework_name" cbLabel>Framework name</label>
                <input id="framework_name" #frameworkNameField autofocus required maxlength="255" formControlName="name" type="text" cbInput />
                <div cbValidationMessage *ngIf="form.get('name')?.errors?.required && (form.get('name')?.dirty || form.get('name')?.touched)">
                    Framework name is required
                </div>
            </div>
            <div class="flex-1">
                <label for="framework_short_name" cbLabel>Short name</label>
                <input id="framework_short_name" required maxlength="100" formControlName="shortName" type="text" cbInput />
                <div cbValidationMessage *ngIf="form.get('shortName')?.errors?.required && (form.get('shortName')?.dirty || form.get('shortName')?.touched)">
                    Short name is required
                </div>
            </div>
                       
        </div>
        <div class="mt-5 flex gap-4">
            <div class="flex-1">
                <label for="framework_link" cbLabel>Link</label>
                <input id="framework_link" required maxlength="255" formControlName="link" type="text" cbInput />
                <div cbValidationMessage *ngIf="form.get('link')?.errors?.required && (form.get('link')?.dirty || form.get('link')?.touched)">
                    A link to the framework is required
                </div>
            </div> 
            <div class="flex-1">
                <label for="framework_version" cbLabel>Version</label>
                <input id="framework_version" required maxlength="50" formControlName="version" type="text" cbInput />
                <div cbValidationMessage *ngIf="form.get('version')?.errors?.required && (form.get('version')?.dirty || form.get('version')?.touched)">
                    Framework version is required
                </div>
            </div>
        </div>
        <div class="mt-5 flex gap-4">            
            <div class="flex-1">
                <label for="framework_country" cbLabel>Country</label>
                <input id="framework_country" required maxlength="100" formControlName="country" type="text" cbInput />
                <div cbValidationMessage *ngIf="form.get('country')?.errors?.required && (form.get('country')?.dirty || form.get('country')?.touched)">
                    Country is required
                </div>
            </div>
            <div class="flex-1">
                <label for="framework_organisation" cbLabel>Organisation</label>
                <input id="framework_organisation" maxlength="100" formControlName="organisation" type="text" cbInput />
            </div>
        </div>
        <div class="mt-5 w-full">
            <div>
                <label for="framework_description" cbLabel>Description</label>
                <textarea id="framework_description" required maxlength="1000" formControlName="description" cbTextarea></textarea>
                <div cbValidationMessage *ngIf="form.get('description')?.errors?.required && (form.get('description')?.dirty || form.get('description')?.touched)">
                    Description is required
                </div>
            </div>
        </div>
        <div class="mt-10 flex justify-between items-center">
            <div class="p-2">
                <button *ngIf="!addMode" (click)="deleteFramework()" type="button" cbButton colour="bg-brand-danger">
                    <div class="flex justify-between items-center gap-2 py-1">
                        <div>
                            <hero-icon name="trash" type="outline" class="h-6 w-6"></hero-icon>
                        </div>
                        <div>
                            Delete framework
                        </div>
                    </div>                    
                </button>
            </div>
            <div class="p-2 flex gap-4">
                <button type="submit" cbButton [disabled]="!form.valid">
                    <div class="flex justify-between items-center gap-2 py-1">
                        <div>
                            <hero-icon name="check" type="outline" class="h-6 w-6"></hero-icon>
                        </div>
                        <div>
                            Save
                        </div>
                    </div>                    
                </button>
                <button (click)="cancelClick()" type="button" cbButton colour="bg-brand-deep">
                    <div class="flex justify-between items-center gap-2 py-1">
                        <div>
                            <hero-icon name="x" type="outline" class="h-6 w-6"></hero-icon>
                        </div>
                        <div>
                            Cancel
                        </div>
                    </div>                    
                </button>
            </div>
        </div>
    </form>

</div>

<p-confirmDialog icon="pi pi-exclamation-triangle" [style]="{width: '50vw'}" rejectButtonStyleClass="p-button-outlined"></p-confirmDialog>