<form [formGroup]="form" (submit)="formSubmit()" novalidate>
    <h3 class="text-xl mb-5 text-center">
        New Client
    </h3>
    <div class="mt-5">
        <cb-form-label for="name" [form]="form">
            Client name
        </cb-form-label>
        <input id="name" maxlength="105" #nameInput name="name" formControlName="name" type="text" cbInput />
    </div>
    <div class="mt-5">
        <cb-form-label for="domain" [form]="form" example="clearbenchmark.com">
            Domain
        </cb-form-label>
        <input id="domain" maxlength="255" name="domain" formControlName="domain" type="text" cbInput />
    </div>
    <div class="mt-5">
        <cb-form-label for="companyNumber" [optional]="true" [form]="form">
            Company number
        </cb-form-label>
        <input id="companyNumber" maxlength="55" name="companyNumber" formControlName="companyNumber" type="text"
            cbInput />
    </div>
    <div class="mt-5">
        <cb-form-label for="sector" [form]="form">
            Sector
        </cb-form-label>
        <select requried id="sector" name="sector" formControlName="sector" cbSelect>
            <option [value]="null">--</option>
            <option *ngFor="let sector of sectorList" [value]="sector">{{sector}}</option>
        </select>
    </div>
    <div class="mt-5">
        <cb-form-label for="country" [form]="form">
            Country
        </cb-form-label>
        <select requried id="country" name="country" formControlName="country" cbSelect>
            <option [value]="null">--</option>
            <option *ngFor="let country of countryList" [value]="country">{{country}}</option>
        </select>
    </div>

    <div class="mt-10 flex justify-end">
        <div class="py-5">
            <button type="submit" cbButton [disabled]="!form.valid">
                Save
            </button>
        </div>
    </div>
</form>