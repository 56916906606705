import { Directive, Input, HostBinding } from '@angular/core';

export interface GanttSpacing {
  start: number;
  end: number;
}

@Directive({
  selector: '[cbGanttSpan]'
})
export class GanttSpanDirective {

  @Input() cbGanttSpan?: GanttSpacing | null;

  @HostBinding("class") get cssStyles(): string {

    if(!this.cbGanttSpan){
      return "";
    }

    const {start, end} = this.cbGanttSpan;

    const length = end - start;

    const styles = [
      `col-span-${length}`,
      `col-start-${start + 1}`,
      `col-end-${end + 1}`,
      "hover:opacity-80"
    ];

    return styles.join(' ');
  }
  
  constructor() { }

}
