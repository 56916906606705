import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plural'
})
export class PluralPipe implements PipeTransform {

  transform(value: number, form: string = "s"): string {
    return value == 1 ? "" : form;
  }

}
