import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckComponent } from './check/check.component';
import { PencilSquareComponent } from './pencil-square/pencil-square.component';
import { ChevronLeftComponent } from './chevron-left/chevron-left.component';
import { ChevronRightComponent } from './chevron-right/chevron-right.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { CheckCircleComponent } from './check-circle/check-circle.component';
import { WindowComponent } from './window/window.component';
import { ArrowLeftComponent } from './arrow-left/arrow-left.component';
import { XMarkComponent } from './x-mark/x-mark.component';
import { ExclamationTriangleComponent } from './exclamation-triangle/exclamation-triangle.component';
import { DynamicIconDirective } from './dynamic.directive';
import { CheckBadgeIconComponent } from './check-badge/check-badge.component';
import { EnvelopeComponent } from './envelope/envelope.component';
import { CloudArrowUpComponent } from './cloud-arrow-up/cloud-arrow-up.component';

const components = [
  CheckComponent,
  PencilSquareComponent,
  ChevronLeftComponent,
  ChevronRightComponent,
  SpinnerComponent,
  CheckCircleComponent,
  WindowComponent,
  ArrowLeftComponent,
  XMarkComponent,
  ExclamationTriangleComponent,
  DynamicIconDirective,
  CheckBadgeIconComponent,
  EnvelopeComponent,
  CloudArrowUpComponent,
];

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [CommonModule],
})
export class IconsModule {}
