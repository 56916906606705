import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'cb-authorized',
  templateUrl: './authorized.component.html',
  styles: [
  ]
})
export class AuthorizedComponent {

  isAuthenticated$: Observable<boolean>;

  constructor(public auth: AuthService) {

    this.isAuthenticated$ = combineLatest([this.auth.isLoading$, this.auth.isAuthenticated$]).pipe(
      map(([loading, authenticated]) => {
        if(!loading && !authenticated) {
          this.auth.loginWithRedirect();
        }
        return !loading && authenticated;
      })
    );    
  }
}
