export const ROLE = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
export const COMPANY = "https://clearbenchmark.com/claims/company_id";
export const USER_ID = "sub";
export const COMPANIES = "https://clearbenchmark.com/claims/companies";
export const ROLE_GROUP = "https://clearbenchmark.com/claims/role_group";
export const FEATURES = "https://clearbenchmark.com/claims/features";

export const claim_types = {
    ROLE,
    COMPANY,
    USER_ID,
    COMPANIES,
    ROLE_GROUP,
    FEATURES
}