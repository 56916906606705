import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleGuard } from '@app/auth/roles/role.guard';
import { CustomersListComponent } from './customers-list/customers-list.component';

const routes: Routes = [
    RoleGuard.build("customers", CustomersListComponent, ["CustomerManager"])
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CustomersRoutingModule { }

