import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpErrorService } from '@app/services/http-error.service';
import { Observable, of } from 'rxjs';
import { environment as env } from '@env';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { MetricsRefreshService } from '../metrics-refresh.service';

type CustomerMetrics = {
  customers: number;
  totalClients: number;
  documentsExportedThisMonth: number;
  documentsFailedThisMonth: number;
  pendingDocumentsThisMonth: number;
  customersThisMonth: number;
  totalDocumentsExported: number;
  totalDocumentsFailed: number;
  totalDocumentsStuck: number;
  activeBenchmarks: number;
}

@Component({
  selector: 'cb-customer-metrics',
  templateUrl: './customer-metrics.component.html',
  styles: [
  ]
})
export class CustomerMetricsComponent implements OnInit, OnDestroy {

  metrics$: Observable<CustomerMetrics | undefined> = of(undefined);
  customerCount$: Observable<number> = of(0);

  constructor(
    private httpClient: HttpClient,
    private errorHandler: HttpErrorService,
    private metricsRefresh: MetricsRefreshService
  ) { }

  ngOnDestroy(): void {
    this.metricsRefresh.customerCount$.next(0);
  }

  ngOnInit(): void {
    const { baseUrl } = env.api;
    const metrics = this.httpClient.get<CustomerMetrics>(`${baseUrl}/v1/metrics/customers`).pipe(
      this.errorHandler.handleHttpError,
      shareReplay(1)
    );

    this.customerCount$ = this.metricsRefresh.customerCount$.pipe(
      switchMap((value) => metrics.pipe(map(({ customers }) => customers + value)))
    )

    this.metrics$ = metrics;
  }

}
