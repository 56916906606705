import { NgModule, Type } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '@app/components/components.module';
import { MenuComponent } from '@app/navigation/menu/menu.component';
import { LinkComponent } from '@app/navigation/link/link.component';
import {
  HeroIconModule,
  chartPie,
  briefcase,
  users,
  cog,
  officeBuilding,
  lightBulb,
  documentReport,
  map,
  adjustments,
  documentText,
  calendar,
  userGroup
} from 'ng-heroicon';

const types: Type<any>[] = [
  MenuComponent,
  LinkComponent
];

@NgModule({
  declarations: types,
  exports: types,
  imports: [
    RouterModule,
    CommonModule,
    ComponentsModule,
    HeroIconModule.withIcons({
      chartPie,
      briefcase,
      users,
      cog,
      officeBuilding,
      lightBulb,
      documentReport,
      map,
      adjustments,
      documentText,
      calendar,
      userGroup
    })
  ]
})
export class NavigationModule { }
