import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  Company,
  CollectionResponse,
  CreateCompanyRequest,
  CreateCompanyResponse,
  CompanyDetail
}
  from '@app/domain/model';

import { environment as env } from '@env';
import { HttpErrorService } from '@app/services/http-error.service';

@Injectable({
  providedIn: 'root'
})
export class NewCompanyService {

  constructor(
    private httpClient: HttpClient,
    private errorHandler: HttpErrorService
  ) { }

  createCompany(company: CreateCompanyRequest): Observable<CreateCompanyResponse> {
    const url = `${env.api.baseUrl}/v${env.api.version}/companies`;
    return this.httpClient.post<CreateCompanyResponse>(url, company).pipe(
      this.errorHandler.handleHttpError
    );
  }
}
