import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyDetail, CreateCompanyRequest } from '@app/domain/model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanyEditService } from './company-edit.service';
import { CompanyDetailService } from '@app/companies/company-detail/company-detail.service';
import { ToastrService } from 'ngx-toastr';
import { CompaniesListService } from '@app/companies/companies-list/companies-list.service';

@Component({
  selector: 'cb-company-edit',
  templateUrl: './company-edit.component.html',
  styles: [],
})
export class CompanyEditComponent implements OnInit {
  id!: string;
  company$!: Observable<CreateCompanyRequest>;
  private redirectRoute: string[] = [];

  constructor(
    private service: CompanyEditService,
    private detailService: CompanyDetailService,
    private route: ActivatedRoute,
    private router: Router,
    private toast: ToastrService,
    private listService: CompaniesListService
  ) {}

  ngOnInit(): void {
    const paramId = this.route.snapshot.paramMap.get('id')!;
    this.redirectRoute = ['/c', paramId, 'overview'];

    this.id = paramId;

    this.company$ = this.detailService
      .getCompanyById(this.id)
      .pipe(map((c) => this.mapCompany(c)));
  }

  private mapCompany(company: CompanyDetail): CreateCompanyRequest {
    return {
      displayName: company.name,
      address: company.address,
      registeredNumber: company.companyNumber,
      sector: company.sector,
      size: company.businessSize,
      type: company.type,
      logoUrl: company.logoUrl,
      brandColor: company.brandColor,
      accountManagerId: company.accountManagerId,
    };
  }

  saveCompany(company: CreateCompanyRequest): void {
    this.service.updateCompany(this.id, company).subscribe((_) => {
      this.toast.success(`${company.displayName} updated!`);
      this.router.navigate(this.redirectRoute);
      this.listService.refresh();
    });
  }

  cancel(): void {
    this.toast.info('No changes were made', 'Edit Cancelled');
    this.router.navigate(['/c', this.id, 'overview']);
  }
}
