import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[cbActionButton]'
})
export class ActionButtonDirective {

  private styles = [
    "px-6", 
    "py-2", 
    "transition", 
    "hover:opacity-90", 
    "bg-brand-primary", 
    "text-white", 
    "rounded-full", 
    "focus:outline-black",
    "focus:ring-brand-primary",
    "hover:shadow-md",
    "disabled:opacity-30"
  ];

  @HostBinding("class")
  get elementStyle(): string {
    return this.styles.join(" ");
  }

  constructor() { }
}
