<cb-authorized>
    <cb-body>
        <cb-subscription-balance type="documents" countProp="documentCount"
            totalProp="maxDocumentsAllowed"></cb-subscription-balance>
        <cb-page-header [actions]="actions$ | async" [spaceBelow]="false">
            Project Roadmap
        </cb-page-header>
        <div class="flex flex-wrap gap-4 roadmap-boundary">
            <div class="flex-1 bg-white border border-mid-gray rounded-md">
                <div>
                    <div class="flex items-center py-4 px-2">
                        <div class="flex-none">
                            <button type="button" (click)="previous()" [class]="calendarButtonStyles"
                                title="Show previous month">
                                <hero-icon name="arrow-left" class="w-6 h-6" type="outline"></hero-icon>
                            </button>
                        </div>
                        <div class="flex-1 flex justify-center">
                            <button type="button" *ngIf="hasMoved()" [class]="calendarButtonStyles"
                                title="Reset to today" (click)="today()">
                                Reset
                            </button>
                        </div>
                        <div class="flex-none">
                            <button type="button" (click)="next()" [class]="calendarButtonStyles"
                                title="Show next month">
                                <hero-icon name="arrow-right" class="w-6 h-6" type="outline"></hero-icon>
                            </button>
                        </div>
                    </div>
                    <div class="grid gap-2 lg:gap-0 border-b border-mid-gray divide-x divide-mid-gray z-2"
                        [class]="gridCols">
                        <div class="py-4 text-center text-brand-secondary" *ngFor="let period of calendar"
                            [class]="getColumnStyles(period)">
                            {{period.month}}
                            <small class="block text-xs">{{period.year}}</small>
                        </div>
                    </div>
                </div>
                <div class="relative w-full overflow-hidden" *ngIf="projects$ | async as projects">
                    <ng-container *ngIf="projects.length; else noProjects">
                        <div class="grid gap-2 lg:gap-0 divide-x divide-mid-gray" [class]="gridCols">
                            <div class="py-4" *ngFor="let period of calendar" [class]="getColumnStyles(period)">
                                <div class="w-full min-h-screen"></div>
                            </div>
                        </div>
                        <div class="absolute inset-0 z-3 overflow-hidden overflow-y-auto">
                            <div class="grid py-4 relative overflow-auto" [class]="gridCols">
                                <ng-container *ngFor="let project of projects">
                                    <ng-container *ngIf="getProjectSpacing(project) as spacing">
                                        <div [cbGanttSpan]="spacing" (click)="toggleProjectDetail(project)"
                                            class="p-2 mb-1 mr-1 rounded-md text-white cursor-pointer"
                                            [ngClass]="getProjectColour(project)">
                                            <div class="truncate max-w-max" [title]="project.name">
                                                {{project.name}}
                                            </div>
                                            <div class="text-xs">
                                                <div class="py-1">
                                                    <span *ngIf="project.status === 'InProgress'; else statusView">
                                                        In Progress
                                                    </span>
                                                    <ng-template #statusView>
                                                        {{project.status}}
                                                    </ng-template>
                                                </div>
                                                <div class="py-1 flex gap-2" *ngIf="project.tasks.length">
                                                    <div>
                                                        {{project.tasks.length}} task{{project.tasks.length | plural}}
                                                    </div>
                                                    <div>
                                                        {{project.completeTasksPercentage || 0}}% complete
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>

                            </div>
                        </div>
                    </ng-container>
                    <ng-template #noProjects>
                        <div class="p-6 flex justify-center items-center">
                            <div class="text-center">
                                <div class="text-2xl">
                                    There aren't any projects yet
                                </div>
                                <div class="mt-4">
                                    <button type="button" cbButton [routerLink]="['../projects', 'new']">
                                        Add a project
                                    </button>
                                </div>
                            </div>

                        </div>
                    </ng-template>

                </div>

            </div>

        </div>

    </cb-body>
</cb-authorized>

<p-dialog [(visible)]="projectDetailsVisible" [modal]="true" position="top" [closeOnEscape]="true"
    [dismissableMask]="true" [header]="activeProject?.name ?? 'Project Detail'" styleClass="w-full max-w-4xl">

    <ng-container *ngIf="activeProject">
        <div class="mb-2">
            <a [routerLink]="['../projects', activeProject.id]"
                class="text-brand-deep underline opacity-80 hover:opacity-100 transition">
                Go to project
            </a>
        </div>

        <div class="divide-y divide-mid-gray">
            <div class="flex justify-between">
                <div class="p-2">
                    <label cbLabel>Start Date</label>
                    <div class="text-lg">{{activeProject.startDate | date:"MMM yyyy"}}</div>
                </div>
                <div class="p-2">
                    <label cbLabel>Duration</label>
                    <div class="text-lg">{{activeProject.duration}} month{{activeProject.duration | plural}}</div>
                </div>
                <div class="p-2">
                    <label cbLabel>Budget</label>
                    <div class="text-lg">
                        &pound;{{activeProject.budget | number:'1.2-2'}}
                    </div>
                </div>

            </div>
            <div class="p-2">
                <label cbLabel>Detail</label>
                <p class="text-justify">
                    {{activeProject.description}}
                </p>
            </div>

            <div class="p-2">
                <div class="flex justify-between items-center">
                    <h2 class="text-xl">Tasks ({{activeProject.tasks.length}})</h2>
                    <div *ngIf="activeProject.tasks.length">
                        <button type="button" cbButton [routerLink]="['../projects', activeProject.id]"
                            [queryParams]="{'tab': 'tasks'}">
                            <div class="flex gap-2 items-center">
                                <div>
                                    <hero-icon name="pencil" type="outline" class="w-5 h-5"></hero-icon>
                                </div>
                                <div>
                                    Edit tasks
                                </div>
                            </div>
                        </button>
                    </div>
                </div>

                <ul *ngIf="activeProject.tasks.length; else planning">
                    <li *ngFor="let task of activeProject.tasks" class="p-2 flex gap-2">
                        <div>
                            <cb-risk-label [risk]="task.risk" [iconOnly]="true"></cb-risk-label>
                        </div>
                        <div>
                            <a [routerLink]="['../benchmark']" [queryParams]="{'task': task.taskId}"
                                class="hover:underline">
                                {{task.title}}
                            </a>
                        </div>
                    </li>
                </ul>
                <ng-template #planning>
                    <div class="p-6 flex justify-center items-center flex-col">
                        <div class="text-center mb-4 text-xl">
                            No tasks have been assigned yet
                        </div>
                        <div>
                            <button type="button" cbButton [routerLink]="['../projects', activeProject.id]"
                                [queryParams]="{'tab': 'tasks', 'fn': 'planning'}">
                                Start planning
                            </button>
                        </div>
                    </div>
                </ng-template>
            </div>

        </div>

    </ng-container>
</p-dialog>