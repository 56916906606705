import { ISearchable } from "@app/components/search-filter/search-filter.pipe";
import { InvitationStatus } from "@app/invitations/invitations.model";

export interface User extends ISearchable {
    id?: string;
    email: string;
    name: string;
    companyId: string;
    status: string;
    roles?: Role[];
    companies?: UserCompany[];
    invitationId?: string;
    roleGroup: RoleGroup;
    state?: UserState;
    invitationStatus: InvitationStatus;
    invitationExpired: boolean;
    position?: string;

    searchQuery(): string[];
}

export interface Role {
    id: string;
    name: string;
}

export interface UserCompany {
    id: string;
    name: string;
}

export interface UserIdentity {
    id?: string;
    email: string;
    firstName: string;
    lastName: string;
    companyId: string;
    roleGroup: RoleGroup;
    name?: string;
    companies?: UserCompany[];
    state?: UserState;
    userId?: string;
    status?: InvitationStatus;
    invitationExpired?: boolean;
    invitedToRoleGroup?: RoleGroup;
    invitationId?: string;
    position?: string;
}

export type RoleGroup = "User" | "Manager" | "Admin" | "Contact";

export enum UserState {
    Active = "Active",
    Disabled = "Disabled",
    Archived = "Archived"
}