<div *ngIf="breadcrumb" class="text-sm mb-5">
    <p-breadcrumb [model]="breadcrumb" [home]="breadcrumbHome"></p-breadcrumb>
</div>
<div class="flex justify-between items-start pt-2 md:pt-0" [ngClass]="{'mb-5': spaceBelow}">
    <div>
        <h1 class="text-3xl pl-2 mb-2">
            <ng-content></ng-content>


        </h1>
        <small *ngIf="subHeading" class="text-sm pl-2 text-gray-500">{{subHeading}}</small>
        <ng-container *ngIf="showCurrentCompany">
            <h3 class="text-lg pl-2 text-gray-500" *ngIf="context.currentCompany$ | async as company">
                {{company.name}}
            </h3>
        </ng-container>
    </div>
    <div (click)="toggleFilters()" [class]="filtersOpen ? 'block': 'hidden'"
        class="fixed z-20 inset-0 bg-black opacity-50 transition-opacity sm:hidden"></div>
    <div class="flex flex-grow justify-end gap-4">


        <button *ngIf="controls?.length" type="button" (click)="toggleFilters()" title="Show Filters"
            class="text-gray-500 text-lg p-2 focus:outline-none sm:hidden">
            <div class="w-6 h-6 transform transition hover:scale-110">
                <svg fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75">
                    </path>
                </svg>
            </div>
            <span class="sr-only">Show filters</span>
        </button>

        <cb-control-list class="hidden md:flex justify-items-stretch items-end gap-4"
            [controls]="actions"></cb-control-list>

        <main
            class="sm:hidden px-2 py-4 -translate-x-0 ease-out border-l-2 border-mid-gray opacity-100 w-64 fixed z-30 inset-y-0 right-0 transition duration-200 h-full transform bg-bright-gray md:translate-x-0 md:static md:inset-0"
            [class]="filtersOpen ? '-translate-x-0 ease-out' : 'translate-x-full ease-in'">

            <button type="button" class="absolute top-1 right-1" (click)="toggleFilters()" title="Close filters">
                <hero-icon name="x" type="outline" class="w-6 h-6"></hero-icon>
                <span class="sr-only">Close</span>
            </button>

            <h2 class="text-lg text-center">
                Filters
            </h2>
            <cb-control-list [controls]="controls" [space]="true"></cb-control-list>
            <cb-control-list class="sm:hidden" [controls]="actions" [space]="true"></cb-control-list>
        </main>

    </div>

</div>
<div class="my-2 hidden sm:block px-2">
    <cb-control-list [controls]="controls" class="flex justify-start items-end gap-4"></cb-control-list>
</div>