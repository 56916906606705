import { Component, Input, OnInit } from '@angular/core';
import { BenchmarkTask } from '@app/benchmark/benchmark.service';

type Tab = 'notes' | 'internal';

@Component({
  selector: 'cb-notes-view',
  templateUrl: './notes-view.component.html',
  styleUrls: ['./notes-view.component.css'],
})
export class NotesViewComponent implements OnInit {
  @Input() task: BenchmarkTask | undefined;
  @Input() activeTab: Tab = 'notes';

  constructor() {}

  ngOnInit(): void {}

  toggleTab(tab: Tab): void {
    this.activeTab = tab;
  }
}
