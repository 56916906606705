import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { UserCompany, UserIdentity, UserState } from '@app/users/users.model';
import { StoredCompany, UserContextService } from '@app/user-context.service';
import {
  UserModel,
  UsersService,
  UpdateProfileRequest,
} from '@app/users/users.service';
import {
  HeaderControl,
  HeaderButton,
} from '@app/components/page-header/controls';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'cb-user-detail',
  templateUrl: './user-detail.component.html',
  styles: [],
})
export class UserDetailComponent implements OnInit, OnDestroy {
  user$!: Observable<UserIdentity>;
  userId!: string;

  companies: UserCompany[] = [];
  companyAccessModalOpen = false;
  companiesToAdd = new Set<string>();
  companiesToRemove = new Set<string>();
  controls: HeaderControl[] = [];

  private company: StoredCompany | null = null;
  private subscriptions: Subscription[] = [];

  userSubject = new Subject<string>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: UsersService,
    public context: UserContextService,
    private toastr: ToastrService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngOnInit(): void {
    this.company = this.context.currentCompanySnapshot;
    this.userId = this.route.snapshot.paramMap.get('id')!;

    this.user$ = this.userSubject.pipe(
      startWith(this.userId),
      switchMap((userId) => this.service.getUserById(userId)),
      tap((user) => this.buildControls(user))
    );
  }

  private reloadUser(): void {
    this.userSubject.next(this.userId);
  }

  companyAccessAdded(companyId: string): void {
    this.companiesToAdd.add(companyId);
    this.companiesToRemove.delete(companyId);
  }

  companyAccessRemoved(companyId: string): void {
    this.companiesToRemove.add(companyId);
    this.companiesToAdd.delete(companyId);
  }

  saveUser(user: UserModel): void {
    this.subscriptions.push(
      this.updateUserAccount(user).subscribe(() => {
        this.done('Saved!');
      })
    );
  }

  inviteUser(user: UserModel): void {
    this.subscriptions.push(
      this.service
        .inviteUser({ companyId: this.company!.id, ...user })
        .subscribe(() => {
          this.done(`An invitation email has been sent to ${user.email}`);
        })
    );
  }

  deleteUser(userId: string): void {
    this.subscriptions.push(
      this.service.deleteUser(userId).subscribe(() => {
        this.done('User deleted');
      })
    );
  }

  private done(message: string): void {
    this.toastr.success(message);
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  private updateUserAccount(user: UserModel): Observable<void> {
    const model: UpdateProfileRequest = {
      name: user.name!,
      roleGroup: user.roleGroup,
      accessToAdd: [...this.companiesToAdd],
      accessToRemove: [...this.companiesToRemove],
      position: user.position,
    };

    const observable =
      model.roleGroup === 'Contact'
        ? this.service.updateContact(this.company!.id, user)
        : this.service.updateProfile(user.id!, model);

    return observable;
  }

  private buildControls(user: UserIdentity) {
    if (user.roleGroup === 'Contact') {
      this.controls = [];
      return;
    }

    const resetPassword = () => {
      this.service
        .resetPassword(this.userId)
        .pipe(
          map(() => {
            this.toastr.success(
              'Password reset request was sent',
              'Reset password'
            );
            this.reloadUser();
          })
        )
        .subscribe();
    };

    const disableAccess = () => {
      this.service
        .blockUser(this.userId)
        .pipe(
          map(() => {
            this.toastr.success('User has been blocked', 'Block user');
            this.reloadUser();
          })
        )
        .subscribe();
    };

    const reactivate = () => {
      this.service
        .reActivateAccount(this.userId)
        .pipe(
          map(() => {
            this.toastr.success(
              'User account has been re-activated',
              'Re-activate user'
            );
            this.reloadUser();
          })
        )
        .subscribe();
    };

    this.controls = [
      new HeaderButton(
        'Reset password',
        'refresh',
        resetPassword,
        'UserManager'
      ),
    ];

    if (user.state === UserState.Active) {
      this.controls.push(
        new HeaderButton('Disable access', 'ban', disableAccess, 'UserManager')
      );
    }

    if (user.state === UserState.Disabled) {
      this.controls.push(
        new HeaderButton(
          'Re-activate account',
          'check',
          reactivate,
          'UserManager'
        )
      );
    }
  }
}
