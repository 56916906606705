import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'punctuation'
})
export class QuestionPunctuationPipe implements PipeTransform {

  transform(value: string): string {
    if(value.endsWith('?')){
      return value;
    }
    
    return `${value}?`;
  }
}
