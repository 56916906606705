<cb-authorized>
    <cb-body [hasBreadcrumb]="true">
        <cb-page-header [breadcrumb]="breadcrumb" [showCurrentCompany]="false">
            {{pageTitle}}
        </cb-page-header>

        <div class="w-full px-6 py-4 bg-white border border-gray-300 shadow-md">
            <form [formGroup]="form" novalidate (ngSubmit)="saveTask()">

                <div class="mt-5 px-2 grid grid-cols-1 md:grid-cols-3 gap-6">
                    <div class="col-span-2">
                        <label cbLabel>
                            Title
                        </label>
                        <input type="text" formControlName="title" autofocus cbInput />
                        <div *ngIf="form.get('title')?.dirty && form.get('title')?.errors?.required"
                            class="text-brand-danger">
                            Please enter a title for the task
                        </div>
                    </div>
                    <div>
                        <div>
                            <label cbLabel>
                                Importance
                            </label>
                            <p-selectButton styleClass="mt-1" [options]="importanceOptions"
                                formControlName="importance"></p-selectButton>
                            <div *ngIf="form.get('importance')?.dirty && form.get('importance')?.errors?.required"
                                class="text-brand-danger">
                                Please select an importance level
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-5 px-2 grid grid-cols-1 md:grid-cols-3 gap-6">
                    <div>
                        <div class="mt-5">
                            <label cbLabel>
                                Level
                            </label>
                            <select cbSelect formControlName="level">
                                <option value="">--</option>
                                <option *ngFor="let level of [1,2,3,4,5]" [value]="level">{{level}}</option>
                            </select>
                            <div *ngIf="form.get('level')?.dirty && form.get('level')?.errors?.required"
                                class="text-brand-danger">
                                Please select a level
                            </div>
                        </div>
                    </div>
                    <div>
                        <div *ngIf="categories$ | async as categories; else categoriesLoading" class="mt-5">
                            <label cbLabel>
                                Category
                            </label>
                            <select formControlName="categoryId" cbSelect>
                                <option value="">--</option>
                                <option *ngFor="let cat of categories" [value]="cat.id">{{cat.name}}</option>
                            </select>
                            <div *ngIf="form.get('categoryId')?.dirty && form.get('categoryId')?.errors?.required"
                                class="text-brand-danger">
                                Please select a category
                            </div>
                            <div class="mt-1 flex justify-end">
                                <button type="button" (click)="showAddCategory()"
                                    class="text-sm text-brand-deep hover:underline">add new category</button>
                            </div>
                        </div>
                        <ng-template #categoriesLoading>
                            <div class="mt-5 px-2 pt-2">
                                <p-skeleton styleClass="mb-2" width="10rem"></p-skeleton>
                                <p-skeleton styleClass="mb-2"></p-skeleton>
                            </div>
                        </ng-template>
                    </div>
                    <div>
                        <div *ngIf="groups$ | async as groups; else groupsLoading" class="mt-5">
                            <label cbLabel>
                                Group
                            </label>
                            <select formControlName="groupId" cbSelect>
                                <option value="">--</option>
                                <option *ngFor="let grp of groups" [value]="grp.id">{{grp.name}}</option>
                            </select>
                            <div *ngIf="form.get('groupId')?.dirty && form.get('groupId')?.errors?.required"
                                class="text-brand-danger">
                                Please select a group
                            </div>
                            <div class="mt-1 flex justify-end">
                                <button type="button" (click)="showAddGroup()"
                                    class="text-sm text-brand-deep hover:underline">add new group</button>
                            </div>
                        </div>
                        <ng-template #groupsLoading>
                            <div class="mt-5 px-2 pt-2">
                                <p-skeleton styleClass="mb-2" width="10rem"></p-skeleton>
                                <p-skeleton styleClass="mb-2"></p-skeleton>
                            </div>
                        </ng-template>
                    </div>
                </div>

                <div class="mt-5 w-full">
                    <h3 class="text-xl text-brand-deep mb-2">Frameworks</h3>
                    <span class="p-fluid">
                        <p-autoComplete formControlName="frameworks" [suggestions]="frameworks"
                            (completeMethod)="searchFrameworks($event)" [forceSelection]="true" field="name"
                            inputStyleClass="focus:outline-none focus:ring-0" [multiple]="true">
                        </p-autoComplete>
                    </span>
                </div>

                <div class="mt-5" formGroupName="examples">
                    <h3 class="text-xl text-brand-deep mb-5">Quality examples</h3>
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div>
                            <label cbLabel>
                                Low
                                <span class="text-brand-deep text-xs">(optional)</span>
                            </label>
                            <textarea placeholder="What is an example of low quality?" cbTextarea pInputTextarea
                                formControlName="lowQualityExample" rows="4"></textarea>
                            <div *ngIf="form.get('examples.lowQualityExample')?.dirty && form.get('examples.lowQualityExample')?.errors?.maxlength"
                                class="text-brand-danger">
                                Example cannot exceed 255 characters
                            </div>
                        </div>
                        <div>
                            <label cbLabel>
                                Medium
                                <span class="text-brand-deep text-xs">(optional)</span>
                            </label>
                            <textarea placeholder="What is an example of medium quality?" cbTextarea pInputTextarea
                                formControlName="mediumQualityExample" rows="4"></textarea>
                            <div *ngIf="form.get('examples.mediumQualityExample')?.dirty && form.get('examples.mediumQualityExample')?.errors?.maxlength"
                                class="text-brand-danger">
                                Example cannot exceed 255 characters
                            </div>
                        </div>
                        <div>
                            <label cbLabel>
                                High
                                <span class="text-brand-deep text-xs">(optional)</span>
                            </label>
                            <textarea placeholder="What is an example of high quality?" cbTextarea pInputTextarea
                                formControlName="highQualityExample" rows="4"></textarea>
                            <div *ngIf="form.get('examples.highQualityExample')?.dirty && form.get('examples.highQualityExample')?.errors?.maxlength"
                                class="text-brand-danger">
                                Example cannot exceed 255 characters
                            </div>
                        </div>
                        <div>
                            <label cbLabel>
                                Excellent
                                <span class="text-brand-deep text-xs">(optional)</span>
                            </label>
                            <textarea placeholder="What is an example of excellent quality?" cbTextarea pInputTextarea
                                formControlName="excellentQualityExample" rows="4"></textarea>
                            <div *ngIf="form.get('examples.excellentQualityExample')?.dirty && form.get('examples.excellentQualityExample')?.errors?.maxlength"
                                class="text-brand-danger">
                                Example cannot exceed 255 characters
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mt-5" formGroupName="metadata">
                    <div class="flex justify-start">
                        <div class="px-2 mt-5">
                            <label cbLabel>
                                Video ID
                                <span class="text-brand-deep text-xs">(optional)</span>
                            </label>
                            <input type="text" cbInput formControlName="videoId">
                        </div>
                    </div>

                    <div class="px-2 mt-5">
                        <label cbLabel>
                            Summary
                        </label>
                        <textarea cbTextarea pInputTextarea formControlName="summary" rows="4"></textarea>
                        <div *ngIf="form.get('metadata.summary')?.dirty && form.get('metadata.summary')?.errors?.required"
                            class="text-brand-danger">
                            Please add a brief summary of the task
                        </div>
                        <div *ngIf="form.get('metadata.summary')?.dirty && form.get('metadata.summary')?.errors?.maxlength"
                            class="text-brand-danger">
                            Summary can be a maximum of 255 characters
                        </div>
                    </div>

                    <div class="px-2 mt-5">
                        <label cbLabel>Description</label>
                        <textarea rows="10" cbTextarea pInputTextarea formControlName="description"></textarea>
                        <div *ngIf="form.get('metadata.description')?.dirty && form.get('metadata.description')?.errors?.required"
                            class="text-brand-danger">
                            Please add some detail about what the task involves
                        </div>
                        <div *ngIf="form.get('metadata.description')?.dirty && form.get('metadata.description')?.errors?.maxlength"
                            class="text-brand-danger">
                            Description can be a maximum of 1,000 characters
                        </div>
                    </div>

                </div>

                <div class="mt-10 mb-5 flex justify-end items-center gap-6">
                    <button type="submit" cbButton colour="bg-brand-primary" [disabled]="!form.valid">
                        <div class="flex justify-between items-center">
                            <hero-icon name="check" type="outline" class="w-5 h-5 mr-2"></hero-icon>
                            <p>Save</p>
                        </div>
                    </button>
                    <button type="button" [routerLink]="['/settings/tasks']" cbButton colour="bg-brand-deep">
                        <div class="flex justify-between items-center">
                            <hero-icon name="x" type="outline" class="w-5 h-5 mr-2"></hero-icon>
                            <p>Cancel</p>
                        </div>
                    </button>
                </div>

            </form>

        </div>
    </cb-body>
</cb-authorized>

<p-sidebar [(visible)]="showAddGroupForm" position="right">
    <cb-add-group (saved)="groupAdded($event)"></cb-add-group>
</p-sidebar>

<p-sidebar [(visible)]="showAddCategoryForm" position="right">
    <cb-add-category (saved)="categoryAdded($event)"></cb-add-category>
</p-sidebar>