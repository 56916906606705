<div *ngIf="companies$ | async as companies" class="w-full">
    <div *ngIf="contextSwitchAvailable">
        <p-dropdown 
            [options]="companies" 
            (onChange)="switchContext()" 
            optionLabel="name" 
            optionValue="id" 
            [(ngModel)]="selectedCompany"
            styleClass="w-full">
        </p-dropdown>
    </div>
</div>

