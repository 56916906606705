import { Component, OnInit, Input } from '@angular/core';
import { CompanyDetail } from '@app/domain/model';
import { Level } from '@app/components/level-label/level-label.component';
import { Observable } from 'rxjs';
import { CompanyLevelService } from './company-level.service';
import { map } from 'rxjs/operators';

type CompanyLevel = {
  value: Level
}

@Component({
  selector: 'cb-company-level',
  templateUrl: './company-level.component.html',
  styles: [
  ]
})
export class CompanyLevelComponent implements OnInit {

  @Input() companyId!: string;

  level$!: Observable<CompanyLevel>;

  constructor(
    private service: CompanyLevelService
  ) { }

  ngOnInit(): void {
    this.level$ = this.service.getCompanyLevel(this.companyId).pipe(
      map(value => ({value}))
    );
  };

}
