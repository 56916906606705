import { Quality, type TaskRisk } from '@app/benchmark/benchmark.model';
import { type TaskQuality } from '@app/benchmark/tasks-list/tasks-list.component';
import { type Importance } from '@app/domain/model';

const importanceScoreMap: Record<Importance, number> = {
  Low: 1,
  Medium: 2,
  High: 5,
};

const qualityScoreMap: Record<Quality, number> = {
  NotApplicable: -1,
  Excellent: 0,
  High: 2,
  Medium: 5,
  Low: 15,
  NotImplemented: 30,
};

export function calculateRisk(
  importance: Importance,
  quality: TaskQuality | undefined
): TaskRisk {
  if (quality === 'NotApplicable') {
    return 'VeryLow';
  }

  const importanceScore = importanceScoreMap[importance];
  const qualityScore = !quality ? -1 : qualityScoreMap[quality];
  const score = importanceScore * qualityScore;

  if (score < 5) {
    return 'VeryLow';
  }

  if (score <= 20) {
    return 'Low';
  }

  if (score <= 50) {
    return 'Medium';
  }

  if (score <= 100) {
    return 'High';
  }

  if (score > 100 && score < 1000) {
    return 'VeryHigh';
  }

  return 'Unrated';
}
