import { BenchmarkTask, GroupedTask } from '../benchmark.service';

export type ActiveTask = {
  task: BenchmarkTask;
  position: number;
  index: number;
  group: {
    name: string;
    index: number;
  };
};

export type LinkedTasks = {
  previous: ActiveTask | null;
  current: ActiveTask;
  next: ActiveTask | null;
};

export function buildLinkedTasks(
  groups: GroupedTask[]
): Map<string, LinkedTasks> {
  const taskMap = new Map<string, LinkedTasks>();
  let position = 0;

  for (let groupIndex = 0; groupIndex < groups.length; groupIndex += 1) {
    const { data, groupName } = groups[groupIndex];
    for (let taskIndex = 0; taskIndex < data.length; taskIndex += 1) {
      position += 1;
      const current = {
        task: data[taskIndex],
        index: taskIndex,
        position,
        group: {
          name: groupName,
          index: groupIndex,
        },
      };
      const previous = getPrevious(groups, groupIndex, taskIndex, position - 1);
      const next = getNext(groups, groupIndex, taskIndex, position + 1);

      taskMap.set(current.task.taskId, {
        current,
        previous,
        next,
      });
    }
  }
  return taskMap;
}

function getPrevious(
  groups: GroupedTask[],
  currentGroupIndex: number,
  taskIndex: number,
  position: number
): ActiveTask | null {
  let currentGroup = groups[currentGroupIndex];
  if (!currentGroup) {
    return null;
  }

  let activeGroupIndex = currentGroupIndex;
  let previousIndex = taskIndex - 1;
  let previousTask = currentGroup.data[previousIndex];

  const previousGroupIndex = currentGroupIndex - 1;
  const previousGroup = groups[previousGroupIndex];

  if (!previousTask && previousGroup) {
    previousIndex = previousGroup.data.length - 1;
    previousTask = previousGroup.data[previousIndex];
    currentGroup = previousGroup;
    activeGroupIndex = previousGroupIndex;
  }

  return {
    task: previousTask,
    index: previousIndex,
    position,
    group: {
      name: currentGroup.groupName,
      index: activeGroupIndex,
    },
  };
}

function getNext(
  groups: GroupedTask[],
  currentGroupIndex: number,
  taskIndex: number,
  position: number
): ActiveTask | null {
  let currentGroup = groups[currentGroupIndex];
  if (!currentGroup) {
    return null;
  }

  let activeGroupIndex = currentGroupIndex;
  let nextIndex = taskIndex + 1;
  let nextTask = currentGroup.data[nextIndex];

  const nextGroupIndex = currentGroupIndex + 1;
  const nextGroup = groups[nextGroupIndex];

  if (!nextTask && nextGroup) {
    nextIndex = 0;
    nextTask = nextGroup.data[nextIndex];
    currentGroup = nextGroup;
    activeGroupIndex = nextGroupIndex;
  }

  return {
    task: nextTask,
    index: nextIndex,
    position,
    group: {
      name: currentGroup.groupName,
      index: activeGroupIndex,
    },
  };
}
