import { Component, OnInit } from '@angular/core';
import { Company } from '@app/domain/model';
import { CompanyId } from '@app/interfaces';
import { Role } from '@app/roles';
import { isNotNull } from '@app/shared/utility-functions';
import { StoredCompany, UserContextService } from '@app/user-context.service';
import { concat, Observable, of } from 'rxjs';
import { defaultIfEmpty, filter, find, map, shareReplay, tap } from 'rxjs/operators';

interface SimpleCompany {
  id: string;
  name: string;
}

type LinkType = {
  label: "Clients" | "Customers";
  role: Role;
  route: ["/clients" | "/customers"];
};

@Component({
  selector: 'cb-menu',
  templateUrl: './menu.component.html',
  styles: [
  ]
})
export class MenuComponent implements OnInit {

  myCompany$!: Observable<Company>;
  company$!: Observable<SimpleCompany | undefined>;
  clientsLink$: Observable<LinkType | undefined> = of(undefined);

  constructor(public context: UserContextService) {
  }

  ngOnInit(): void {
    this.myCompany$ = this.context.myCompany$.pipe(
      shareReplay(1)
    );

    this.clientsLink$ = this.myCompany$.pipe(
      map(({ type }): LinkType => {
        if (type === "MSP") {
          return {
            label: "Clients",
            role: "CompanyViewer",
            route: ['/clients']
          };
        }

        return {
          label: "Customers",
          role: "CustomerManager",
          route: ['/customers']
        };
      })
    )

    this.company$ = this.context.currentCompany$.pipe(
      filter(isNotNull),
      shareReplay(1)
    );
  }

}
