import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleGuard } from '@app/auth/roles/role.guard';
import { RoleName } from '@app/roles';

import { ReportViewComponent } from './report-view/report-view.component';

export const ReportRoutes: Routes = [
    RoleGuard.build("report", ReportViewComponent, [RoleName.ReportViewer])
]

@NgModule({
    imports: [RouterModule],
    exports: [RouterModule]
})
export class ReportRoutingModule {}