<nav class="pt-2 h-full px-6 overflow-y-auto z-30">
  <div
    *ngIf="myCompany$ | async as company"
    class="h-full flex flex-col justify-between"
  >
    <div class="block mb-2 md:mb-0 md:hidden">
      <cb-sub-nav splitMode="top"></cb-sub-nav>
    </div>
    <div class="divide-y divide-mid-gray">
      <div *ngIf="company.type !== 'SME'" class="py-4">
        <cb-link [route]="['/dashboard']" icon="chart-pie"> Dashboard </cb-link>

        <ng-container *ngIf="clientsLink$ | async as link">
          <cb-link
            [route]="link.route"
            icon="user-group"
            *cbRequiresRole="link.role"
          >
            {{ link.label }}
          </cb-link>
        </ng-container>

        <cb-link
          [route]="['/c', company.id, 'overview']"
          icon="office-building"
        >
          <ng-container *ngIf="company.type === 'MSP'; else cle">
            My MSP
          </ng-container>
          <ng-template #cle> Overview </ng-template>
        </cb-link>
      </div>
      <div class="py-4">
        <ng-container *ngIf="company$ | async as selectedCompany">
          <h3
            class="text-sm uppercase mb-4 text-gray-400 text-center max-w-prose truncate"
          >
            <span [title]="selectedCompany.name">
              {{ selectedCompany.name }}
            </span>
          </h3>
          <ng-container *ngIf="company.id !== selectedCompany.id">
            <cb-link
              [route]="['/c', selectedCompany.id, 'overview']"
              icon="office-building"
              *cbRequiresRole="'CompanyViewer'"
            >
              Overview
            </cb-link>
          </ng-container>

          <ng-container *ngIf="context.userSnapshot?.hasFeature('Journey')">
            <cb-link
              [route]="['/c', selectedCompany.id, 'journey']"
              icon="map"
              *cbRequiresRole="'CompanyViewer'"
            >
              Journey
            </cb-link>
          </ng-container>

          <cb-link
            [route]="['/c', selectedCompany.id, 'benchmark']"
            icon="light-bulb"
            *cbRequiresRole="'BenchmarkViewer'"
          >
            Benchmark
          </cb-link>

          <cb-link
            [route]="['/c', selectedCompany.id, 'report']"
            icon="document-report"
            *cbRequiresRole="'ReportViewer'"
          >
            Report
          </cb-link>

          <cb-link
            [route]="['/c', selectedCompany.id, 'projects']"
            icon="map"
            *cbRequiresRole="'ProjectViewer'"
          >
            Projects
          </cb-link>

          <cb-link
            [route]="['/c', selectedCompany.id, 'roadmap']"
            icon="calendar"
            *cbRequiresRole="'RoadmapViewer'"
          >
            Roadmap
          </cb-link>

          <cb-link
            [route]="['/c', selectedCompany.id, 'documents']"
            icon="document-text"
            *cbRequiresRole="'DocumentViewer'"
          >
            Documents
          </cb-link>

          <cb-link
            [route]="['/c', selectedCompany.id, 'users']"
            icon="users"
            *cbRequiresRole="'UserViewer'"
          >
            People
          </cb-link>
        </ng-container>
      </div>
    </div>
    <div class="block md:hidden">
      <cb-sub-nav splitMode="bottom"></cb-sub-nav>
    </div>
  </div>
</nav>
