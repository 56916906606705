<div class="flex justify-center">
    <div class="p-2">
        <div class="flex justify-center">
            <cb-logo theme="dark" size="medium"></cb-logo>
        </div>
        <ng-container *ngIf="complete; else incomplete">
            <div class="max-w-prose mx-auto">
                <h2 class="text-center text-2xl font-bold text-brand-primary">Welcome onboard,
                    {{firstName()}}!</h2>
                <div class="mt-10 text-lg">
                    Thanks for signing up to Clearbenchmark! You're one step away from getting deep insight into your
                    customers' IT landscape.
                </div>
                <div class="mt-10">
                    We've sent a verification email to <span class="font-bold">{{form.get('email')?.value}}</span>.
                    Check
                    your inbox and follow the
                    steps from there.
                </div>
            </div>

        </ng-container>
        <ng-template #incomplete>
            <div class="my-5 text-lg">
                Start benchmarking your clients in seconds with some basic information
            </div>
            <div class="mt-10">
                <form novalidate [formGroup]="form" (submit)="submit()">
                    <div class="md:flex gap-4 mb-6">
                        <div class="mb-4 md:mb-0 md:flex-1 relative">
                            <cb-form-label for="full_name">
                                Your full name
                            </cb-form-label>
                            <input type="text" cbInput id="full_name" required formControlName="name" />
                            <cb-validation-error
                                *ngIf="form.get('name')?.errors?.required && (form.get('name')?.dirty || form.get('name')?.touched)">
                                Please enter your full name
                            </cb-validation-error>
                        </div>
                        <div class="md:flex-1 relative mb-4 md:mb-0">
                            <cb-form-label for="email">
                                Your email address
                            </cb-form-label>
                            <input type="email" cbInput id="email" required formControlName="email" />
                            <cb-validation-error
                                *ngIf="form.get('email')?.errors?.required && (form.get('email')?.dirty || form.get('email')?.touched)">
                                Please enter your email address
                            </cb-validation-error>
                            <cb-validation-error
                                *ngIf="form.get('email')?.errors?.email && (form.get('email')?.dirty || form.get('email')?.touched)">
                                Please enter a valid email address
                            </cb-validation-error>
                        </div>
                    </div>
                    <div class="md:flex gap-4 my-2">
                        <div class="md:flex-1 relative mb-4 md:mb-0">
                            <cb-form-label for="msp_name">
                                Company name
                            </cb-form-label>
                            <input type="text" cbInput id="msp_name" required formControlName="mspName" />
                            <cb-validation-error
                                *ngIf="form.get('mspName')?.errors?.required && (form.get('mspName')?.dirty || form.get('mspName')?.touched)">
                                Please enter a company name
                            </cb-validation-error>
                        </div>
                        <div class="md:flex-1 relative mb-4 md:mb-0">
                            <cb-form-label for="sector">
                                Company sector
                            </cb-form-label>
                            <select requried id="sector" name="sector" formControlName="sector" cbSelect>
                                <option [value]="null">--</option>
                                <option *ngFor="let sector of sectorList" [value]="sector">{{sector}}
                                </option>
                            </select>
                            <cb-validation-error
                                *ngIf="form.get('sector')?.errors?.required && (form.get('sector')?.dirty || form.get('sector')?.touched)">
                                Please choose a business sector
                            </cb-validation-error>
                        </div>

                    </div>
                    <div class="mt-10 md:mt-20 flex justify-center">
                        <button type="submit" [disabled]="!form.valid || working" cbButton
                            class="text-lg flex justify-between items-center">
                            <ng-container *ngIf="!working; else loading">
                                Get started for free
                            </ng-container>
                            <ng-template #loading>
                                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                        stroke-width="4">
                                    </circle>
                                    <path class="opacity-75" fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                                    </path>
                                </svg>
                                <p>Loading</p>
                            </ng-template>

                        </button>
                    </div>
                    <div class="mt-2 flex justify-center text-xs text-gray-400">
                        <div>
                            No payment information required
                        </div>
                    </div>
                    <div class="mt-24 flex justify-center text-xs text-gray-400">
                        <div>
                            Benchmark 1 client for free with the Clear Free plan
                        </div>
                    </div>
                    <div class="mt-2 flex justify-center text-xs text-brand-deep">
                        <div class="flex gap-4">
                            <div>
                                <a href="https://www.clearbenchmark.com/pricing" class="hover:underline"
                                    target="_blank">
                                    pricing
                                </a>
                            </div>
                            <div>
                                <a href="https://www.clearbenchmark.com/terms" class="hover:underline" target="_blank">
                                    terms & conditions
                                </a>
                            </div>
                            <div>
                                <a href="https://www.clearbenchmark.com/privacy" class="hover:underline"
                                    target="_blank">
                                    privacy statement
                                </a>
                            </div>

                        </div>
                    </div>

                </form>
            </div>
        </ng-template>

    </div>
</div>