<div class="w-full">
  <form [formGroup]="form" (submit)="submit()" novalidate>
    <input type="hidden" formControlName="id" />

    <div class="mt-5 flex gap-4">
      <div class="flex-1">
        <label for="project_name" cbLabel>Project name</label>
        <input
          id="project_name"
          required
          maxlength="100"
          formControlName="name"
          type="text"
          cbInput
        />
        <div
          cbValidationMessage
          *ngIf="
            form.get('name')?.errors?.required &&
            (form.get('name')?.dirty || form.get('name')?.touched)
          "
        >
          Project name is required
        </div>
      </div>
    </div>
    <div class="mt-5 flex gap-4">
      <div class="flex-1">
        <label for="budget" cbLabel>Project budget (&pound;)</label>
        <input
          id="budget"
          required
          min="0"
          formControlName="budget"
          type="text"
          inputmode="decimal"
          cbInput
        />
        <div
          cbValidationMessage
          *ngIf="
            form.get('budget')?.errors?.required &&
            (form.get('budget')?.dirty || form.get('budget')?.touched)
          "
        >
          Project budget is required
        </div>
        <div
          cbValidationMessage
          *ngIf="
            form.get('budget')?.errors?.min &&
            (form.get('budget')?.dirty || form.get('budget')?.touched)
          "
        >
          Project budget should be greater than or equal to zero
        </div>
      </div>
      <div class="flex-1">
        <cb-form-label for="ticket_number" [optional]="true">
          Ticket number
        </cb-form-label>
        <input
          type="text"
          id="ticket_number"
          cbInput
          formControlName="ticketNumber"
        />
        <div
          cbValidationMessage
          *ngIf="
            form.get('ticketNumber')?.errors?.maxlength &&
            (form.get('ticketNumber')?.dirty ||
              form.get('ticketNumber')?.touched)
          "
        >
          Cannot be longer than 100 characters
        </div>
      </div>
    </div>
    <div class="mt-5 flex gap-4">
      <div class="flex-1">
        <cb-form-label for="recurringRevenue" [optional]="true">
          Ongoing budget (&pound;)
        </cb-form-label>
        <input
          id="recurringRevenue"
          formControlName="recurringRevenue"
          type="text"
          inputmode="decimal"
          cbInput
        />
      </div>
      <div class="flex-1">
        <cb-form-label for="projectType" [optional]="true" [form]="form">
          Project type
        </cb-form-label>
        <select cbSelect id="projectType" formControlName="projectType">
          <option value="">&ndash;</option>
          <option *ngFor="let option of projectTypeOptions" [ngValue]="option">
            {{ option }}
          </option>
        </select>
      </div>
    </div>
    <div class="mt-5 flex gap-4">
      <div class="flex-1">
        <label for="startDate" cbLabel>Start date</label>
        <p-calendar
          formControlName="startDate"
          appendTo="body"
          view="month"
          dateFormat="M yy"
          [readonlyInput]="true"
          dataType="string"
          [style]="{ width: '100%' }"
        ></p-calendar>
        <div
          cbValidationMessage
          *ngIf="
            form.get('startDate')?.errors?.required &&
            (form.get('startDate')?.dirty || form.get('startDate')?.touched)
          "
        >
          Start date is required
        </div>
        <div
          cbValidationMessage
          *ngIf="
            form.get('startDate')?.errors?.startDate &&
            (form.get('startDate')?.dirty || form.get('startDate')?.touched)
          "
        >
          Start date should be greater than or equal to today
        </div>
      </div>
      <div class="flex-1">
        <label for="duration" cbLabel>Duration (in months)</label>
        <p-inputNumber
          formControlName="duration"
          [showButtons]="true"
          buttonLayout="horizontal"
        >
        </p-inputNumber>
        <div
          cbValidationMessage
          *ngIf="
            form.get('duration')?.errors?.deadline &&
            (form.get('duration')?.dirty || form.get('duration')?.touched)
          "
        >
          Deadline should be greater than start date
        </div>
      </div>
    </div>
    <div class="mt-5">
      <label for="description" cbLabel>Description</label>
      <textarea
        formControlName="description"
        rows="5"
        cbTextarea
        class="w-full"
      ></textarea>
    </div>
    <div class="mt-10 flex justify-between items-center">
      <div class="p-2">
        <button
          *ngIf="!addMode"
          (click)="deleteProject(project?.id!)"
          type="button"
          cbButton
          colour="bg-brand-danger"
        >
          <div class="flex justify-between items-center gap-2 py-1">
            <div>
              <hero-icon
                name="trash"
                type="outline"
                class="h-6 w-6"
              ></hero-icon>
            </div>
            <div>Delete project</div>
          </div>
        </button>
      </div>
      <div class="p-2 flex gap-4">
        <button type="submit" cbButton [disabled]="!form.valid">
          <div class="flex justify-between items-center gap-2 py-1">
            <div>
              <hero-icon
                name="check"
                type="outline"
                class="h-6 w-6"
              ></hero-icon>
            </div>
            <div>
              {{ saveLabel || "Save" }}
            </div>
          </div>
        </button>
      </div>
    </div>
  </form>
</div>

<p-confirmDialog
  icon="pi pi-exclamation-triangle"
  [style]="{ width: '50vw' }"
  rejectButtonStyleClass="p-button-outlined"
>
</p-confirmDialog>
