import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleGuard } from '@app/auth/roles/role.guard';
import { RoleName } from '@app/roles';

import { DocumentsViewComponent } from './documents-view/documents-view.component';

export const DocumentRoutes: Routes = [
    RoleGuard.build("documents", DocumentsViewComponent, [RoleName.DocumentViewer])
];

@NgModule({
    imports: [RouterModule],
    exports: [RouterModule]
})
export class DocumentsRoutingModule{}