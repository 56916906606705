<ng-container *ngIf="task">
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-6">
        <div>
            <h3 class="text-xl">Public Notes</h3>
            <div class="mt-4">
                <textarea [(ngModel)]="task.notes" cbTextarea rows="6" cols="10"></textarea>
            </div>
        </div>
        <div>
            <h3 class="text-xl">Private Notes</h3>
            <div class="mt-4">
                <textarea [(ngModel)]="task.internalNotes" cbTextarea rows="6" cols="10"></textarea>
            </div>
        </div>
    </div>
</ng-container>