export const Administrator = "Administrator";

export const CompanyViewer = "CompanyViewer";
export const CompanyManager = "CompanyManager";

export const PartnerManager = "PartnerManager";

export const BenchmarkViewer = "BenchmarkViewer";
export const BenchmarkManager = "BenchmarkManager";

export const UserViewer = "UserViewer";
export const UserManager = "UserManager";

export const ReportViewer = "ReportViewer";
export const ReportManager = "ReportManager";

export const PlanningViewer = "PlanningViewer";
export const PlanningManager = "PlanningManager";

export const DocumentViewer = "DocumentViewer";
export const DocumentManager = "DocumentManager";

export type Role =
    | "Administrator"
    | "CompanyViewer"
    | "CompanyManager"
    | "BenchmarkViewer"
    | "BenchmarkManager"
    | "UserViewer"
    | "UserManager"
    | "ReportViewer"
    | "ReportDownloader"
    | "ReportHistoryViewer"
    | "PlanningViewer"
    | "PlanningManager"
    | "DocumentViewer"
    | "DocumentManager"
    | "TasksManager"
    | "SettingsManager"
    | "TeamViewer"
    | "TeamManager"
    | "ProjectViewer"
    | "ProjectManager"
    | "RoadmapViewer"
    | "RoadmapManager"
    | "FrameworksManager"
    | "CustomerManager";

/**
 * @deprecated Use Role instead
 */
export enum RoleName {
    Administrator = "Administrator",
    CompanyViewer = "CompanyViewer",
    CompanyManager = "CompanyManager",
    BenchmarkViewer = "BenchmarkViewer",
    BenchmarkManager = "BenchmarkManager",
    UserViewer = "UserViewer",
    UserManager = "UserManager",
    ReportViewer = "ReportViewer",
    ReportDownloader = "ReportDownloader",
    ReportHistoryViewer = "ReportHistoryViewer",
    PlanningViewer = "PlanningViewer",
    PlanningManager = "PlanningManager",
    DocumentViewer = "DocumentViewer",
    DocumentManager = "DocumentManager",
    TasksManager = "TasksManager",
    SettingsManager = "SettingsManager",
    TeamViewer = "TeamViewer",
    TeamManager = "TeamManager",
    ProjectViewer = "ProjectViewer",
    ProjectManager = "ProjectManager",
    RoadmapViewer = "RoadmapViewer",
    RoadmapManager = "RoadmapManager",
    FrameworksManager = "FrameworksManager"
}