import { Component, OnInit } from '@angular/core';
import { GanttSpacing } from '@app/styles/gantt-span.directive';
import { DndDropEvent } from 'ngx-drag-drop';

interface Project {
  id: string;
  name: string;
  tasks: TaskItem[];
}

interface TaskItem {
  id: string;
  name: string;
  start: Month;
  end: Month;
  metadata?: GanttSpacing
}

type Month = 'Jan' | 'Feb' | 'Mar' | 'Apr' | 'May' | 'Jun' | 'Jul' | 'Aug' | 'Sep' | 'Oct' | 'Nov' | 'Dec';

@Component({
  selector: 'cb-roadmap',
  templateUrl: './roadmap.component.html',
  styles: [
  ]
})
export class RoadmapComponent implements OnInit {

  projects: Project[] = [
    {
      id: "1",
      name: "Policies",
      tasks: [
        {
          id: "1",
          name: "Upload documents",
          start: "Jan",
          end: "Mar"
        },
        {
          id: "2",
          name: "Implement IT policy",
          start: "Apr",
          end: "Jul"
        },
        {
          id: "3",
          name: "Another task",
          start: "Sep",
          end: "Dec"
        }
      ]
    },
    {
      id: "2",
      name: "Another project",
      tasks: [{
        id: "4",
        name: "Some task",
        start: "May",
        end: "Sep"
      }]
    },
    {
      id: "3",
      name: "Third project",
      tasks: [
        {
          id: "5",
          name: "Task A",
          start: "May",
          end: "Sep"
        },
        {
          id: "6",
          name: "Task B",
          start: "Jun",
          end: "Dec"
        }
      ]
    }
  ];
  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  year = new Date().getFullYear();

  constructor() { }

  ngOnInit(): void {

    this.projects = this.projects.map(p => {
      p.tasks = p.tasks.map(t => {
        t.metadata = this.getSpacing(t);
        return t;
      });
      return p;
    });
    
  }

  isDataActive(data: string[], month: string): boolean {
    return data.includes(month);
  }

  getSpacing(task: TaskItem): GanttSpacing {
    const start = this.months.indexOf(task.start) + 1;
    const end = this.months.indexOf(task.end) + 2;

    return {
      start,
      end
    };
  }
}
