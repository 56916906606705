import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Framework, FrameworkService } from '../framework.service';
import { MenuItem } from 'primeng/api';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'cb-framework-detail',
  templateUrl: './framework-detail.component.html',
  styles: [
  ]
})
export class FrameworkDetailComponent implements OnInit {

  framework$: Observable<Framework> = of();
  breadcrumb$: Observable<MenuItem[]> = of([]);
  subHeading$: Observable<string> = of("");

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: FrameworkService
  ) { }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id')!;
    this.framework$ = this.service.getFrameworkById(id).pipe(
      shareReplay(1)
    );

    this.breadcrumb$ = this.framework$.pipe(
      map((framework) => ([
        { label: "Settings", routerLink: ["/settings"] },
        { label: "Frameworks", routerLink: ["/settings/frameworks"] },
        { label: framework.name }
      ]))
    );

    this.subHeading$ = this.framework$.pipe(
      map((framework) => {
        const count = framework?.taskCount ?? 0;
        const plural = count === 1 ? '' : 's';
        return `${count} task${plural} assigned`;
      })
    );
  }

  formClosed(): void {
    this.router.navigate(['/settings', 'frameworks']);
  }
}
