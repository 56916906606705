import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleGuard } from '@app/auth/roles/role.guard';
import { UserViewer } from '@app/roles';

import { UsersViewComponent } from './users-view/users-view.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { CreateUserComponent } from './create-user/create-user.component';

export const UserRoutes: Routes = [
    RoleGuard.build("users", UsersViewComponent, [UserViewer]),
    RoleGuard.build("users/add", CreateUserComponent, [UserViewer]),
    RoleGuard.build("users/:id", UserDetailComponent, [UserViewer])    
]

@NgModule({
    imports: [RouterModule],
    exports: [RouterModule]
})
export class UsersRoutingModule {}