import { Component, Input, OnInit, Type, ViewChild } from '@angular/core';
import { TaskRisk } from '@app/benchmark/benchmark.model';
import { CheckBadgeIconComponent } from '@app/icons/check-badge/check-badge.component';
import { DynamicIconDirective } from '@app/icons/dynamic.directive';
import { ExclamationCircleComponent } from '@app/icons/exclamation-circle/exclamation-circle.component';
import { ExclamationTriangleComponent } from '@app/icons/exclamation-triangle/exclamation-triangle.component';

export type RiskIconType =
  | ExclamationCircleComponent
  | ExclamationTriangleComponent
  | CheckBadgeIconComponent;

export type RiskIcon = {
  risk: TaskRisk[];
  icon: Type<RiskIconType>;
  colour: 'text-rag-green' | 'text-rag-amber' | 'text-rag-red';
};

@Component({
  selector: 'cb-modal-risk-icon',
  template: `
    <div
      class="flex items-center justify-evenly gap-2"
      [class]="iconColour"
      [title]="(risk | splitWords) + ' Risk'"
    >
      <div>
        <ng-template [cbDynamicIcon]></ng-template>
      </div>
      <div class="text-lg">
        {{ state }}
      </div>
    </div>
  `,
})
export class ModalRiskIconComponent implements OnInit {
  @ViewChild(DynamicIconDirective, { static: true })
  private dynamicHost!: DynamicIconDirective;

  private static icons: RiskIcon[] = [
    {
      risk: ['High', 'VeryHigh'],
      icon: ExclamationCircleComponent,
      colour: 'text-rag-red',
    },
    {
      risk: ['Unrated'],
      icon: ExclamationTriangleComponent,
      colour: 'text-rag-red',
    },
    {
      risk: ['Medium'],
      icon: ExclamationCircleComponent,
      colour: 'text-rag-amber',
    },
    {
      risk: ['Low', 'VeryLow'],
      icon: CheckBadgeIconComponent,
      colour: 'text-rag-green',
    },
  ];

  iconColour: RiskIcon['colour'] = 'text-rag-red';
  state: 'Unrated' | 'Rated' | undefined;

  private _risk: TaskRisk | undefined;

  get risk(): TaskRisk | undefined {
    return this._risk;
  }

  @Input() set risk(value: TaskRisk | undefined) {
    this._risk = value;

    if (!value) {
      return;
    }

    const viewContainerRef = this.dynamicHost.viewContainerRef;
    viewContainerRef.clear();

    const item = ModalRiskIconComponent.icons.find(({ risk }) =>
      risk.includes(value)
    );

    this.state = value === 'Unrated' ? 'Unrated' : 'Rated';

    if (item) {
      viewContainerRef.createComponent(item.icon);
      this.iconColour = item.colour;
    }
  }

  constructor() {}

  ngOnInit(): void {}
}
