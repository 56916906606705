<ng-container *ngIf="loading; else graph">
    <div class="flex justify-center">
        <p-progressSpinner></p-progressSpinner>
    </div>
</ng-container>
<ng-template #graph>
    <ng-container *ngIf="dataAvailable; else nodata">
        <div *ngIf="header" class="text-center">
            {{header}}
        </div>
        <p-chart type="bar" [data]="data" [options]="dataOptions"></p-chart>
    </ng-container>
    <ng-template #nodata>
        <cb-no-data></cb-no-data>
    </ng-template>
</ng-template>


