import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleGuard } from '@app/auth/roles/role.guard';
import { ClientsListComponent } from './clients-list/clients-list.component';

const routes: Routes = [
    RoleGuard.build("clients", ClientsListComponent, ["CompanyViewer"])
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ClientsRoutingModule { }

