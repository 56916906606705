import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'cb-body',
  templateUrl: './body.component.html',
  styles: [
  ]
})
export class BodyComponent implements OnInit {

  constructor() { }

  @Input() hasBreadcrumb: boolean = false;

  private styles: string[] = ["px-4 mx-auto max-w-screen-2xl"];

  cssClass!: string;

  ngOnInit(): void {
    const padding = this.hasBreadcrumb ? "pb-6" : "py-6"
    this.styles.push(padding);
    this.cssClass = this.styles.join(" ");
  }
}
