import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyJourneyComponent } from './company-journey/company-journey.component';
import { JourneyRoutingModule } from './routing.module';
import { ComponentsModule } from '@app/components/components.module';
import { TimelineModule } from 'primeng/timeline';
import { HeroIconModule, arrowUp, arrowDown } from 'ng-heroicon';

export type Activity = {
  title: string;
  description?: string | null;
  riskScore: number;
  timestamp: Date;
  mood?: "Neutral" | "Positive" | "Negative" | "Celebration";
  riskTrend?: "up" | "down";
}

@NgModule({
  declarations: [
    CompanyJourneyComponent
  ],
  imports: [
    CommonModule,
    JourneyRoutingModule,
    ComponentsModule,
    TimelineModule,
    HeroIconModule.withIcons({
      arrowUp,
      arrowDown
    })
  ]
})
export class JourneyModule { }
