<cb-authorized>
    <cb-body>
        <cb-page-header [showCurrentCompany]="false" [actions]="controls" [breadcrumb]="breadcrumb">
            Frameworks
        </cb-page-header>

        <cb-list-view [items$]="frameworks$">
            <cb-list-header>
                <cb-header-column>Name</cb-header-column>
                <cb-header-column>Short Name</cb-header-column>
                <cb-header-column>Linked Task Count</cb-header-column>
                <cb-header-column>Description</cb-header-column>
            </cb-list-header>
            <ng-container *ngIf="frameworks$ | async as frameworks">
                <ng-container *ngIf="frameworks.length">
                    <cb-list-row *ngFor="let framework of frameworks" [routerLink]="[framework.id]">
                        <cb-list-column>
                            {{framework.name}}
                        </cb-list-column>
                        <cb-list-column>
                            {{framework.shortName}}
                        </cb-list-column>
                        <cb-list-column>
                            {{framework.taskCount}}
                        </cb-list-column>
                        <cb-list-column>
                            <div class="truncate" [title]="framework.description">
                                {{framework.description}}
                            </div>
                        </cb-list-column>
                    </cb-list-row>
                </ng-container>
                <ng-template #empty>
                    <cb-empty-list>
                        Nothing yet
                    </cb-empty-list>
                </ng-template>
            </ng-container>
        </cb-list-view>

    </cb-body>
</cb-authorized>