import { Component, OnInit, ViewChild, Output, ElementRef, EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, mergeMap, distinctUntilChanged } from 'rxjs/operators';
import { CompaniesHouseService } from './companies-house.service';
import { CompanySearchResultItem } from './company-search.model';

@Component({
  selector: 'cb-company-search',
  templateUrl: './company-search.component.html'
})
export class CompanySearchComponent implements OnInit {

  @ViewChild("companySearch") companyNameEl!: ElementRef<HTMLInputElement>;
  @Output() selected = new EventEmitter<CompanySearchResultItem>();
  @Output() add = new EventEmitter<string>();

  results$?: Observable<CompanySearchResultItem[]> | undefined;
  searchSubject: Subject<string> = new Subject<string>();

  constructor(private companiesHouse: CompaniesHouseService) {    
  }

  ngOnInit(): void {
    this.initSearch();
  }

  private initSearch(){
    this.results$ = this.searchSubject.pipe(
      debounceTime(750),
      distinctUntilChanged(),
      mergeMap(q => this.companiesHouse.searchCompanies(q))
    )    
  }

  private searchRequiresInit(): boolean {
    return this.results$ === null;
  }

  private clearSearch(el: HTMLInputElement): void {
    this.searchSubject.next("");
    el.value = "";
    this.results$ = undefined;
  }

  focus(): void {
    this.companyNameEl.nativeElement.focus();
  }

  search(evt: KeyboardEvent): void {
    
    var el = (evt.target as HTMLInputElement);

    if(evt.key === "Escape") {      
      this.clearSearch(el);
      return;
    }

    if(this.searchRequiresInit()) {
      this.initSearch();
    }
    
    const query = el.value;
    this.searchSubject.next(query);
  }

  addCompany(): void {
    this.add.emit(this.companyNameEl.nativeElement.value);
  }

  selectCompany(company: CompanySearchResultItem): void {
    this.selected.emit(company);
  }
}
