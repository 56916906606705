<form [formGroup]="form" novalidate (ngSubmit)="addNewContact()">
    <div class="mt-2 flex gap-2 items-center">
        <div>
            <label cbLabel for="newcontact_name">Name</label>                    
            <input type="text" cbInput formControlName="name">
        </div>
        <div>
            <label cbLabel for="newcontact_email">Email</label>                    
            <input type="text" inputmode="email" cbInput formControlName="email">
        </div>                
    </div>  
    <div class="my-2 flex gap-2 items-center">
        <div class="py-2">
            <p-checkbox [binary]="true" label="Save to contacts" formControlName="save"></p-checkbox>
        </div>                
    </div>
    <div class="mt-2">
        <button type="submit" [disabled]="!form.valid" cbButton>Add</button>
    </div>            
</form>
