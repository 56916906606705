import { 
  Component, 
  OnInit, 
  Input, 
  ContentChild, 
  TemplateRef, 
  ViewChild, 
  ViewContainerRef,
  AfterViewInit,
  
} from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'cb-list-view',
  templateUrl: './list-view.component.html',
  styles: [
  ]
})
export class ListViewComponent implements OnInit, AfterViewInit {

  @Input() items$?: Observable<any[]>;
  @ContentChild(TemplateRef) itemTemplate!: TemplateRef<any>;
  @Input() noDataTemplate: TemplateRef<any> | null = null;
  @Input() mode: "embedded" | "standard" = "standard";

  @ViewChild("emptyTemplate", {read: TemplateRef})
  emptyTemplate: TemplateRef<any> | null = null;

  get styles(): {
    "shadow-md": boolean,
    "border": boolean,
    "border-gray-300": boolean
  } {
    const enabled = this.mode === "standard";
    return {
      "shadow-md": enabled,
      "border": enabled,
      "border-gray-300": enabled
    }
  }

  constructor(
    private vref: ViewContainerRef
  ) { }

  ngAfterViewInit(): void {
    if(this.emptyTemplate){
      this.vref.createEmbeddedView(this.emptyTemplate);
    }
  }

  ngOnInit(): void {
  }

}
