<cb-authorized>
  <cb-body *ngIf="company$ | async as company">
    <cb-page-header
      [showCurrentCompany]="false"
      [spaceBelow]="false"
      [actions]="controls"
    >
      <ng-container *ngIf="company.logoUrl; else companyName">
        <img
          [src]="company.logoUrl"
          [alt]="company.name"
          width="200px"
          height="100px"
        />
      </ng-container>
      <ng-template #companyName>
        {{ company.name }}
      </ng-template>
      <small *ngIf="company.domain" class="mt-2 text-xs block text-gray-500">{{
        company.domain
      }}</small>
      <small
        *cbRequiresRole="'Administrator'"
        class="mt-2 text-xs block text-gray-500"
        >{{ company.id }}</small
      >
    </cb-page-header>

    <div class="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-6">
      <div>
        <cb-company-detail-card heading="Current Level">
          <cb-company-level [companyId]="company.id"></cb-company-level>
        </cb-company-detail-card>
      </div>

      <div>
        <cb-company-detail-card heading="Overall Progress">
          <cb-company-progress [companyId]="company.id"></cb-company-progress>
        </cb-company-detail-card>
      </div>

      <cb-company-detail-card heading="Risk Score">
        <cb-risk-score [companyId]="company.id"></cb-risk-score>
      </cb-company-detail-card>

      <cb-company-detail-card heading="12 Month Roadmap" class="row-span-2">
        <cb-company-roadmap [company]="company"></cb-company-roadmap>
      </cb-company-detail-card>

      <cb-company-detail-card
        heading="Risk Score History"
        class="md:col-span-2"
      >
        <cb-risk-history [companyId]="company.id"></cb-risk-history>
      </cb-company-detail-card>
    </div>

    <p-dialog [(visible)]="userAccessOpen" [modal]="true">
      <ng-template pTemplate="header">
        <h2 class="text-xl px-8 mb-5 flex">
          <div class="pr-2">
            <hero-icon name="users" type="outline" class="h-6 w-6"></hero-icon>
          </div>
          <div class="pl-2">Team access</div>
        </h2>
      </ng-template>
      <div class="w-full px-8">
        <p class="my-2">
          Here you can manage the access
          <strong class="text-bold">members of your team</strong> have to
          {{ company.name }}
        </p>
        <p class="my-2">
          To manage members of {{ company.name }}, head to the
          <a
            [routerLink]="['../users']"
            class="text-brand-deep underline opacity-80 hover:opacity-100"
            >Users section</a
          >.
        </p>
      </div>
      <div class="w-full pb-4 mt-5 px-8 max-w-3xl">
        <p-autoComplete
          appendTo="body"
          [(ngModel)]="selectedUsers"
          [multiple]="true"
          field="name"
          [suggestions]="userResults"
          [style]="{ display: 'block' }"
          inputStyleClass="focus:outline-none focus:ring-0"
          placeholder="Enter a name"
          (completeMethod)="searchUsers($event)"
        >
        </p-autoComplete>
      </div>
      <div class="mt-10 flex justify-end">
        <button type="button" cbButton (click)="saveUserAccess()">Save</button>
      </div>
    </p-dialog>
  </cb-body>
</cb-authorized>

<ng-template #noCompany> Loading... </ng-template>
