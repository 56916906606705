export function triggerBrowserDownload(filename: string, stream: any, type: string = "application/pdf"): void {
    const blob = new Blob([stream], { type });
    const anchor = document.createElement("a");
    anchor.href = window.URL.createObjectURL(blob);
    anchor.download = filename;
    anchor.click();
}

export function triggerBrowserDownloadFromUrl(filename: string, fileUrl: string): void {
    const anchor = document.createElement("a");
    anchor.href = fileUrl;
    anchor.download = filename;
    anchor.click();
}