import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserContextService } from './user-context.service';

@Injectable()
export class UserContextInterceptor implements HttpInterceptor {

  constructor(private context: UserContextService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const companyId = this.context.currentCompanySnapshot?.id;

    if(!companyId){
      return next.handle(request);
    }
    
    const modified = request.clone({
      headers: request.headers.set("x-cb-company", companyId)
    });

    return next.handle(modified);
  }
}
