<label [for]="for" cbLabel
    [ngClass]="{'text-brand-danger': for && !!form?.get(for)?.errors && (form?.get(for)?.dirty && form?.get(for)?.touched)}">
    <div class="flex justify-between">
        <div>
            <div class="flex gap-2 items-center">
                <div *ngIf="text; else content">{{text}}</div>
                <ng-template #content>
                    <ng-content></ng-content>
                </ng-template>
                <div *ngIf="optional" class="text-xs text-gray-400">(optional)</div>
                <div *ngIf="example">
                    <div [pTooltip]="'Example value: ' + example">
                        <hero-icon name="question-mark-circle" type="outline" class="w-4 h-4 text-gray-400"></hero-icon>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!optional && for && form?.get(for)?.errors?.required && (form?.get(for)?.dirty && form?.get(for)?.touched)"
            class="inline-block text-right text-xs font-bold text-brand-danger">required!</div>

        <div *ngIf="for && form?.get(for)?.errors?.maxlength && (form?.get(for)?.dirty && form?.get(for)?.touched)"
            class="inline-block text-right text-xs font-bold text-brand-danger">too long!</div>

        <div *ngIf="for && form?.get(for)?.errors?.email && (form?.get(for)?.dirty && form?.get(for)?.touched)"
            class="inline-block text-right text-xs font-bold text-brand-danger">
            invalid email!
        </div>

        <div *ngIf="for && form?.get(for)?.errors?.pattern && (form?.get(for)?.dirty && form?.get(for)?.touched)"
            class="inline-block text-right text-xs font-bold text-brand-danger">
            invalid format!
        </div>
    </div>


</label>