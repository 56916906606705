import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'cb-date-picker',
  templateUrl: './date-picker.component.html',
  styles: [
  ]
})
export class DatePickerComponent implements OnInit {

  @Input() time: boolean = false;
  @Input() value?: Date;
  @Output() valueChange = new EventEmitter<Date | null>();

  constructor() { }

  ngOnInit(): void {    
  }

  dateSelected(date: Date): void {        
    this.valueChange.emit(date);
  }

  dateCleared(): void {
    this.valueChange.emit(null);
  }

}
