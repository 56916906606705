import { Project, ProjectStatus } from './projects.service';

export type StatusLabel = Omit<Project['status'], 'InProgress'> | 'In Progress';

export function toStatusLabel(status: Project['status']): string {
  if (status === 'InProgress') {
    return 'In Progress';
  }
  return status;
}

type BgState = {
  'bg-brand-deep': boolean;
  'bg-[#63AB00]': boolean;
  'bg-brand-primary': boolean;
  'bg-rag-amber': boolean;
  'bg-rag-red': boolean;
};

type TextState = {
  'text-brand-deep': boolean;
  'text-[#63AB00]': boolean;
  'text-brand-primary': boolean;
  'text-rag-amber': boolean;
  'text-rag-red': boolean;
};

export function getProjectState(
  status: ProjectStatus | undefined,
  format: 'bg' | 'text' = 'bg'
): BgState | TextState | {} {
  if (!status) {
    return {};
  }

  if (format === 'bg') {
    return {
      'bg-brand-deep': status === 'Proposed',
      'bg-[#63AB00]': status === 'Quoting',
      'bg-brand-primary': status === 'Completed',
      'bg-rag-amber': status === 'InProgress',
      'bg-rag-red': status === 'Rejected',
    };
  }

  return {
    'text-brand-deep': status === 'Proposed',
    'text-[#63AB00]': status === 'Quoting',
    'text-brand-primary': status === 'Completed',
    'text-rag-amber': status === 'InProgress',
    'text-rag-red': status === 'Rejected',
  };
}
