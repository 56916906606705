import { HttpClient, HttpContext, HttpContextToken } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpErrorService } from '@app/services/http-error.service';
import { environment as env } from '@env';
import { Observable } from 'rxjs';

export interface RoadmapByDate {
  roadmap: CompanyRoadmapResult[];
  totalBudget: number;
}

interface CompanyRoadmapResult {
  dateKey: string;
  projects: CompanyRoadmapProject[];
  totalBudget: number;
}

interface CompanyRoadmapProject {
  projectId: string;
  projectName: string;
  taskCount: number;
}

@Injectable({
  providedIn: 'root'
})
export class CompanyRoadmapService {

  constructor(
    private httpClient: HttpClient,
    private errorHandler: HttpErrorService
  ) { }

  getCompanyRoadmapOverview(companyId: string): Observable<RoadmapByDate> {
    const url = `${env.api.baseUrl}/v${env.api.version}/data/roadmap?companyId=${encodeURIComponent(companyId)}`;

    const headers = { ignoreLoadingBar: '' };

    return this.httpClient.get<RoadmapByDate>(url, { headers }).pipe(
      this.errorHandler.handleHttpError
    );
  }
}
