import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  CompanyDetail,
  CollectionResponse,
  CompanyAccess,
} from '@app/domain/model';
import { environment as env } from '@env';
import { HttpErrorService } from '@app/services/http-error.service';
import { User } from '@app/users/users.model';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CompanyDetailService {
  private companyDetailCache = new Map<
    string,
    { expires: Date; value: Observable<CompanyDetail> }
  >();

  constructor(
    private httpClient: HttpClient,
    private errorHandler: HttpErrorService
  ) {}

  getCompanyById(id: string): Observable<CompanyDetail> {
    const url = `${env.api.baseUrl}/v${
      env.api.version
    }/companies/${encodeURIComponent(id)}`;
    return this.httpClient
      .get<CompanyDetail>(url)
      .pipe(this.errorHandler.handleHttpError);
  }

  getMembersOfCompany(companyId: string): Observable<User[]> {
    const url = `${env.api.baseUrl}/v${
      env.api.version
    }/companies/${encodeURIComponent(companyId)}/users`;
    return this.httpClient.get<CollectionResponse<User>>(url).pipe(
      this.errorHandler.handleHttpError,
      map((res) => res.result)
    );
  }

  setUserCompanyAccess(companyId: string, userIds: string[]): Observable<void> {
    const url = `${env.api.baseUrl}/v${
      env.api.version
    }/companies/${encodeURIComponent(companyId)}/access`;
    return this.httpClient
      .put<void>(url, { userIds })
      .pipe(this.errorHandler.handleHttpError);
  }

  getUserAccess(companyId: string): Observable<CompanyAccess[]> {
    const url = `${env.api.baseUrl}/v${
      env.api.version
    }/companies/${encodeURIComponent(companyId)}/access`;
    return this.httpClient.get<CollectionResponse<CompanyAccess>>(url).pipe(
      this.errorHandler.handleHttpError,
      map((res) => res.result)
    );
  }
}
