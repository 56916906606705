import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ComponentsModule } from '@app/components/components.module';
import { StylesModule } from '@app/styles/styles.module';
import { ProjectsListComponent } from './projects-list/projects-list.component';
import { CheckboxModule } from 'primeng/checkbox';
import { ProjectFormComponent } from './project-form/project-form.component';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { ProjectDetailComponent } from './project-detail/project-detail.component';
import { NewProjectComponent } from './new-project/new-project.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MatTabsModule } from '@angular/material/tabs';
import { IconsModule } from '@app/icons/icons.module';
import {
  HeroIconModule,
  trash,
  searchCircle,
  arrowRight,
  pencilAlt,
  x,
} from 'ng-heroicon';
import { InputNumberModule } from 'primeng/inputnumber';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TooltipModule } from 'primeng/tooltip';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
  declarations: [
    ProjectsListComponent,
    ProjectFormComponent,
    ProjectDetailComponent,
    NewProjectComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ComponentsModule,
    IconsModule,
    StylesModule,
    CheckboxModule,
    CalendarModule,
    DialogModule,
    ConfirmDialogModule,
    BrowserAnimationsModule,
    MatTabsModule,
    InputNumberModule,
    DragDropModule,
    TooltipModule,
    DropdownModule,
    IconsModule,
    HeroIconModule.withIcons({
      trash,
      searchCircle,
      arrowRight,
      pencilAlt,
      x,
    }),
  ],
  exports: [ProjectFormComponent],
})
export class ProjectsModule {}
