export interface TaskExamples {
    lowQuality?: string;
    mediumQuality?: string;
    highQuality?: string;
    excellentQuality?: string;
}

export interface Category {
    id?: string;
    name: string;
}

export interface Group {
    id?: string;
    name: string;
}

export enum Quality {
    NotApplicable = "NotApplicable",
    NotImplemented = "NotImplemented",
    Low = "Low",
    Medium = "Medium",
    High = "High",
    Excellent = "Excellent"
}

export enum Importance {
    Low = "Low",
    Medium = "Medium",
    High = "High"
}

export type TaskRisk = "Unrated" | "VeryLow" | "Low" | "Medium" | "High" | "VeryHigh";

/**
 * @deprecated Use TaskRisk instead
 */
export enum Risk {
    Unrated = "Unrated",
    VeryLow = "VeryLow",
    Low = "Low",
    Medium = "Medium",
    High = "High",
    VeryHigh = "VeryHigh"
}