import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Category } from '@app/benchmark/benchmark.model';
import { environment as env } from '@env';
import { HttpErrorService } from '@app/services/http-error.service';
import { HttpClient } from '@angular/common/http';

interface CreateResponse {
  id: string;
}

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(
    private client: HttpClient,
    private errorHandler: HttpErrorService
  ) { }

  addCategory(name: string): Observable<Category> {
    const url = `${env.api.baseUrl}/v${env.api.version}/categories`;
    let cat: Category = {
      name: name
    }
    return this.client.post<CreateResponse>(url, cat).pipe(
      this.errorHandler.handleHttpError,
      map(res => {
        cat.id = res.id;
        return cat;
      })
    )
  }
}
