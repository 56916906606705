import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Category, Group } from '@app/benchmark/benchmark.model';
import { Task } from '@app/tasks/model';
import { BenchmarkService } from '@app/benchmark/benchmark.service';
import { TaskService } from './task.service';
import { Observable, iif } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { MenuItem } from 'primeng/api';
import { Framework, FrameworkService } from '@app/settings/frameworks/framework.service';

type FrameworkTag = {
  id: string;
  name: string;
}

@Component({
  selector: 'cb-task-detail',
  templateUrl: './task-detail.component.html',
  styles: [
  ]
})
export class TaskDetailComponent implements OnInit {

  id: string = "";
  pageTitle = "New Task";
  importanceOptions = [
    { label: 'Low', value: 'Low' },
    { label: 'Medium', value: 'Medium' },
    { label: 'High', value: 'High' }
  ];

  categories$!: Observable<Category[]>;
  groups$!: Observable<Group[]>;

  breadcrumb: MenuItem[] = [];

  get editMode(): boolean {
    return !!this.id;
  }

  form!: FormGroup;
  showAddGroupForm = false;
  showAddCategoryForm = false;
  frameworks: FrameworkTag[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private benchmarkService: BenchmarkService,
    private service: TaskService,
    private toast: ToastrService,
    private router: Router,
    private frameworkService: FrameworkService
  ) { }

  async ngOnInit(): Promise<void> {
    this.categories$ = this.benchmarkService.categories;
    this.groups$ = this.benchmarkService.groups;

    this.id = this.route.snapshot.params['id'];

    this.breadcrumb = [
      { label: "Settings", routerLink: ["/settings"] },
      { label: "Tasks", routerLink: ["/settings/tasks"] },
      { label: `${this.editMode ? "Edit" : "New"} task` }
    ];

    this.form = this.formBuilder.group({
      title: ['', [Validators.required, Validators.maxLength(100)]],
      level: ['', [Validators.required, Validators.min(1), Validators.max(5)]],
      categoryId: ['', [Validators.required]],
      groupId: ['', [Validators.required]],
      importance: ['', [Validators.required]],
      frameworks: [null],
      metadata: this.formBuilder.group({
        summary: ['', [Validators.required, Validators.maxLength(255)]],
        description: ['', [Validators.required, Validators.maxLength(1000)]],
        videoId: ['']
      }),
      examples: this.formBuilder.group({
        lowQualityExample: ['', Validators.maxLength(255)],
        mediumQualityExample: ['', Validators.maxLength(255)],
        highQualityExample: ['', Validators.maxLength(255)],
        excellentQualityExample: ['', Validators.maxLength(255)]
      })
    });

    if (this.editMode) {
      const task = await this.service.getTaskById(this.id).toPromise();
      this.pageTitle = task.title;
      this.form.patchValue(task);
    }
  }

  async searchFrameworks(event: any) {
    const formValue: FrameworkTag[] = [...this.form.get("frameworks")?.value || []];
    const frameworkIds = new Set(formValue.map(t => t.id));
    const frameworks = await this.frameworkService.getFrameworks(event.query).toPromise();

    this.frameworks = frameworks
      .filter(f => !frameworkIds.has(f.id!))
      .map(f => ({
        id: f.id!,
        name: f.name!
      }));
  }

  saveTask(): void {
    const task: Task = this.form.getRawValue();

    task.tags = task.tags ?? [];
    task.frameworks = task.frameworks ?? [];

    const create$ = this.service.createTask(task);
    const update$ = this.service.updateTask(this.id, task);

    iif(() => this.editMode, update$, create$).subscribe(t => {
      this.toast.success("Task saved");
      this.router.navigate(['/settings/tasks']);
    });
  }

  showAddGroup(): void {
    this.showAddGroupForm = true;
  }

  showAddCategory(): void {
    this.showAddCategoryForm = true;
  }

  groupAdded(group: Group): void {
    this.toast.success("Group saved");
    this.closeGroupForm();
    this.groups$ = this.groups$.pipe(tap(x => x.push(group)));
    this.form.patchValue({
      groupId: group.id
    });
  }

  categoryAdded(category: Category): void {
    this.toast.success("Category saved");
    this.closeCategoryForm();
    this.categories$ = this.categories$.pipe(tap(c => c.push(category)));
    this.form.patchValue({
      categoryId: category.id
    });
  }

  closeGroupForm(): void {
    this.showAddGroupForm = false;
  }

  closeCategoryForm(): void {
    this.showAddCategoryForm = false;
  }

}
