

<ng-container *ngIf="contacts.length; else nocontacts">
    <div class="flex justify-between items center">
        <div>
            <header class="text-xl">
                Select one or more contacts to send the benchmark report to
            </header>
            <p class="text-sm">They will recieve an email with the report attached as a PDF</p>
        </div>
        <div class="pl-6 my-2 flex gap-4 items-center">    
            <button type="button" *ngIf="contacts.length > 1" (click)="selectAll()" class="text-sm text-brand-deep underline hover:underline-none">select all</button>
            <button type="button" *ngIf="selectedContacts.length" (click)="clearSelection()" class="text-sm text-brand-deep underline hover:underline-none">clear</button>
        </div>
    </div>
    <div class="px-6 mt-5 grid grid-cols-2" >
        <div *ngFor="let contact of contacts" class="p-4 flex items-center gap-4 transition hover:bg-bright-gray">
            <div>
                <p-checkbox name="clientGroup" [value]="contact" [inputId]="contact.email" [(ngModel)]="selectedContacts"></p-checkbox>
            </div>
            <label [for]="contact.email" class="block flex-1 cursor-pointer">
                <span class="text-lg">{{contact.name}}</span>
                <span class="text-sm block">({{contact.email}})</span>
            </label>
        </div>
    </div>
    <div class="my-6 px-6">
        <button type="button" cbButton colour="bg-brand-deep" (click)="addNew = true">
            Add new contact
        </button> 
    </div>
</ng-container>
<ng-template #nocontacts>
    <div class="flex items-center justify-center py-10 flex-col gap-4">
        <div class="text-xl flex gap-2 items-center">
            <div>
                <hero-icon name="search" type="outline" class="w-6 h-6"></hero-icon>
            </div>
            <div>
                No contacts yet
            </div>            
        </div>
        <div class="mt-6">
            <p class="mb-5 text-xl ">You can add a new contact here</p>
            <cb-add-contact (onsubmit)="addNewContact($event)"></cb-add-contact>
        </div>
    </div>
    
</ng-template>

<div class="px-6 mt-5" *ngIf="addNew">        
    <cb-add-contact (onsubmit)="addNewContact($event)"></cb-add-contact>
</div>

<footer class="mt-5 flex justify-end gap-4">
    <button type="button" cbButton (click)="shareReport()" [disabled]="!selectedContacts.length">
        <div class="flex justify-between items-center gap-2 py-1">
            <div>
                <hero-icon name="paper-airplane" type="outline" class="h-6 w-6"></hero-icon>
            </div>
            <div>
                Send
            </div>
        </div>
    </button>
    <button type="button" cbButton colour="bg-brand-deep" (click)="cancelShare()">
        <div class="flex justify-between items-center gap-2 py-1">
            <div>
                <hero-icon name="x" type="outline" class="h-6 w-6"></hero-icon>
            </div>
            <div>
                Cancel
            </div>
        </div>
    </button>
</footer>    
