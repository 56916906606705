<cb-list-view>
    <cb-list-header>
        <cb-header-column>Name</cb-header-column>
        <cb-header-column>Email</cb-header-column>
        <cb-header-column>Status</cb-header-column>
        <cb-header-column>Role</cb-header-column>
    </cb-list-header>
    <ng-container *ngIf="data$ | async as users">
        <ng-container *ngIf="users.length;else emptyList">
            <cb-list-row *ngFor="let user of users" [routerLink]="[user.id]">
                <cb-list-column>
                    <div class="flex gap-2 items-center py-2">                
                        <div *ngIf="user.isContact; else isUser" [title]="user.name + ' is a contact and does not have a user account'">
                            <hero-icon name="mail" type="outline" class="w-5 h-5"></hero-icon>
                        </div>
                        <ng-template #isUser>
                            <div [title]="user.name + ' has a user account'">
                                <hero-icon name="identification" type="outline" class="w-5 h-5"></hero-icon>
                            </div>
                        </ng-template>
                        <div [class]="{'line-through': user.state === 'Disabled'}">{{user.name}}</div>
                    </div>            
                    <div class="text-sm text-brand-danger" *ngIf="user.state === 'Active' && user.invitationId">
                        <ng-container *ngIf="user.invitationExpired">
                            <span class="font-bold">Invitation expired</span>
                        </ng-container>                
                        <button *ngIf="!user.invitationExpired && user.status === 'Pending'" type="button" class="underline transition hover:opacity-80" (click)="deleteUserInvite($event, user)">
                            cancel invitation
                        </button>
                    </div>           
                </cb-list-column>
                <cb-list-column [title]="user.email">
                    {{user.email}}
                </cb-list-column>
                <cb-list-column>
                    {{user.state}}
                </cb-list-column>
                <cb-list-column>
                    {{user.roleGroup}}
                </cb-list-column>
            </cb-list-row>
        </ng-container>
    </ng-container>
</cb-list-view>
<ng-template #emptyList>
    <cb-empty-list>
        <div class="text-xl">
            No people yet
        </div>
    </cb-empty-list>
</ng-template>
