import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, of, combineLatest, Subscription, Subject } from 'rxjs';
import { Customer, CustomersService } from '@app/customers/customers.service';
import { HeaderButton, HeaderControl, HeaderSearch } from '@app/components/page-header/controls';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'cb-customers-list',
  templateUrl: './customers-list.component.html',
  styles: [
  ]
})
export class CustomersListComponent implements OnInit, OnDestroy {

  customers$: Observable<Customer[]> = of([]);
  actions: HeaderControl[] = [];
  controls$: Observable<HeaderControl[]> = of([]);
  addCustomerActive = false;

  private addCustomerClick = new EventEmitter<void>();
  private filter = new EventEmitter<string>();
  private refresh = new BehaviorSubject<number>(0);
  private subscriptions: Subscription[] = [];

  constructor(
    private service: CustomersService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngOnInit(): void {
    const paramsSubject = new Subject<Params>();

    this.actions = [
      new HeaderButton(
        "Add Customer",
        "plus-circle",
        () => this.addCustomerActive = true,
        "CustomerManager"
      )
    ];

    this.controls$ = this.route.queryParams.pipe(
      map(({ filter }) => ([
        new HeaderSearch({
          id: "customer-filter",
          changed: this.filter,
          label: "Filter",
          placeholder: "find a customer by name",
          value: filter ?? undefined
        })
      ]))
    );

    this.customers$ = combineLatest([this.route.queryParams, this.refresh]).pipe(
      switchMap(([{ filter }]) => this.service.getCustomers(filter))
    );

    this.subscriptions.push.apply(this.subscriptions, [
      this.addCustomerClick.subscribe(() => this.addCustomerActive = true),
      this.filter.subscribe((filter) => paramsSubject.next({ filter: filter || null })),
      paramsSubject.subscribe((queryParams) => {
        this.router.navigate([], {
          queryParams,
          queryParamsHandling: "merge"
        })
      })
    ]);
  }

  customerAdded(): void {
    this.addCustomerActive = false;
    this.refresh.next(1);
  }

}
