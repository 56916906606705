import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Task } from '@app/tasks/model';
import { environment as env } from '@env';
import { HttpErrorService } from '@app/services/http-error.service';

interface CreateResponse {
  id: string;
}

@Injectable({
  providedIn: 'root'
})
export class TaskService {

  constructor(
    private httpClient: HttpClient,
    private errorHandler: HttpErrorService
  ) { }

  getTaskById(id: string): Observable<Task> {
    const url = `${env.api.baseUrl}/v${env.api.version}/tasks/${encodeURIComponent(id)}`;
    return this.httpClient.get<Task>(url).pipe(
      this.errorHandler.handleHttpError
    );
  }

  createTask(task: Task): Observable<Task> {
    const url = `${env.api.baseUrl}/v${env.api.version}/tasks`;
    return this.httpClient.post<CreateResponse>(url, task).pipe(
      map(res => {
        task.id = res.id;
        return task;
      }),
      this.errorHandler.handleHttpError
    );
  }

  updateTask(id: string, task: Task): Observable<Task> {
    const url = `${env.api.baseUrl}/v${env.api.version}/tasks/${id}`;
    return this.httpClient.put<Task>(url, task).pipe(
      this.errorHandler.handleHttpError
    );
  }
}
