<cb-authorized>
    <cb-body>
        <cb-page-header>
            Add a new contact <span *cbRequiresRole="'UserManager'">or user</span> 
        </cb-page-header>

        <cb-user-form (onsave)="createContact($event)" (oninvite)="inviteUser($event)"></cb-user-form>
    </cb-body>
    
</cb-authorized>
