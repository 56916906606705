import { Component, Input, OnInit } from '@angular/core';
import { AccountStatusResult } from '@app/subscription.service';
import { UserContextService } from '@app/user-context.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { formatNumber } from '@angular/common';

const _format = (input: number): string => formatNumber(input, "en-GB", "0.0-0");

type CountProps = Pick<AccountStatusResult, "clientCount" | "documentCount">;
type TotalProps = Pick<AccountStatusResult, "maxClientsAllowed" | "maxDocumentsAllowed">;

@Component({
  selector: 'cb-subscription-balance',
  templateUrl: './subscription-balance.component.html',
  styles: [
  ]
})
export class SubscriptionBalanceComponent implements OnInit {

  @Input() type?: "clients" | "documents";
  @Input() countProp!: keyof CountProps;
  @Input() totalProp!: keyof TotalProps;

  status$?: Observable<{ count: string, total: string }>;

  constructor(private context: UserContextService) { }

  ngOnInit(): void {
    this.status$ = this.context.accountStatus$.pipe(
      map((status): { count: string, total: string } => {
        return {
          count: _format(status[this.countProp] ?? 0),
          total: _format(status[this.totalProp] ?? 0)
        }
      })
    )
  }

}
