<cb-authorized>
  <cb-body>
    <cb-page-header [actions]="controls$ | async"> Projects </cb-page-header>

    <cb-list-view>
      <cb-list-header>
        <cb-header-column>Project name</cb-header-column>
        <cb-header-column>Type</cb-header-column>
        <cb-header-column>Starting</cb-header-column>
        <cb-header-column>Project budget</cb-header-column>
        <cb-header-column>Number of tasks</cb-header-column>
        <cb-header-column>Status</cb-header-column>
      </cb-list-header>
      <ng-container *ngIf="projects$ | async as projects">
        <ng-container *ngIf="projects.length; else emptyList">
          <cb-list-row
            *ngFor="let project of projects$ | async"
            [routerLink]="[project.id]"
          >
            <cb-list-column>
              <div>{{ project.name }}</div>
              <div class="text-xs" *ngIf="project.ticketNumber">
                {{ project.ticketNumber }}
              </div>
            </cb-list-column>
            <cb-list-column>
              {{ project.projectType ?? "-" }}
            </cb-list-column>
            <cb-list-column>
              {{ project.startDate | date : "MMM YYYY" }}
            </cb-list-column>
            <cb-list-column>
              &pound;{{ project.budget | number : "0.00" }}
            </cb-list-column>
            <cb-list-column>
              {{ project.tasks.length }} task{{ project.tasks.length | plural }}
            </cb-list-column>
            <cb-list-column>
              <div
                class="font-bold text-sm"
                [ngClass]="getProjectStyles(project)"
              >
                {{ project.status | splitWords }}
              </div>
            </cb-list-column>
          </cb-list-row>
        </ng-container>
      </ng-container>

      <ng-template #emptyList>
        <cb-empty-list>
          <div>
            <div class="text-2xl">No projects yet</div>
          </div>
        </cb-empty-list>
      </ng-template>
    </cb-list-view>
  </cb-body>

  <p-dialog
    [(visible)]="newProjectModalOpen"
    [modal]="true"
    position="top"
    header="Projects"
    styleClass="w-2/3"
  >
    <ng-container *ngIf="selectedProject">
      <cb-project-form
        [(project)]="selectedProject"
        (close)="closeForm()"
      ></cb-project-form>
    </ng-container>
  </p-dialog>
</cb-authorized>
