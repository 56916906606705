import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersRoutingModule } from './users-routing.module';
import { UsersListComponent } from './users-list/users-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StylesModule } from '@app/styles/styles.module';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { SidebarModule } from 'primeng/sidebar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import {
  HeroIconModule,
  briefcase,
  paperAirplane,
  check,
  x,
  userAdd,
  refresh,
  ban,
  identification,
  mail,
} from 'ng-heroicon';
import { ComponentsModule } from '@app/components/components.module';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { MessageModule } from 'primeng/message';
import { UsersViewComponent } from './users-view/users-view.component';
import { UserFormComponent } from './user-form/user-form.component';
import { RadioButtonModule } from 'primeng/radiobutton';

@NgModule({
  declarations: [
    UsersListComponent,
    UserDetailComponent,
    CreateUserComponent,
    UserProfileComponent,
    UsersViewComponent,
    UserFormComponent,
  ],
  imports: [
    CommonModule,
    CheckboxModule,
    UsersRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    StylesModule,
    ComponentsModule,
    ToggleButtonModule,
    InputSwitchModule,
    TooltipModule,
    DialogModule,
    ConfirmPopupModule,
    MessageModule,
    SidebarModule,
    ConfirmDialogModule,
    RadioButtonModule,
    HeroIconModule.withIcons({
      briefcase,
      paperAirplane,
      check,
      x,
      userAdd,
      refresh,
      ban,
      identification,
      mail,
    }),
  ],
  exports: [UsersListComponent],
})
export class UsersModule {}
