<ng-container *ngIf="user$ | async as user">
  <div
    class="hidden md:flex px-5 flex-none text-brand-secondary items-start justify-end"
  >
    <div class="flex-1 flex items-center px-2 relative">
      <div
        #menuToggle
        (click)="toggleMenuVisibility()"
        class="group transform transition cursor-pointer hover:scale-105 text-white opacity-75 hover:opacity-100"
      >
        <img
          class="inline object-cover w-8 h-8 rounded-full"
          [src]="user.picture"
          [alt]="user.name + ' profile'"
          [title]="user.name"
        />

        <div
          class="flex justify-center w-full group-hover:opacity-100 opacity-40"
        >
          <hero-icon
            name="chevron-down"
            type="outline"
            class="w-5 h-5"
          ></hero-icon>
        </div>
      </div>
      <div
        #menu
        [@menuTrigger]="menuVisible ? 'open' : 'closed'"
        class="absolute top-11 z-10 -left-36 bg-brand-secondary text-white min-w-max rounded-md border border-bright-gray text-sm shadow-lg"
      >
        <div class="divide-y divide-brand-dark">
          <div class="px-6 py-2 truncate">Signed in as {{ user.name }}</div>
          <div>
            <div
              [routerLink]="['/settings']"
              class="px-6 py-2 flex items-center justify-between gap-2 opacity-75 hover:opacity-100 transition cursor-pointer"
            >
              Settings
            </div>
            <div
              class="px-6 py-2 flex items-center justify-between gap-2 opacity-75 hover:opacity-100 transition cursor-pointer"
            >
              <a
                href="https://support.clearbenchmark.com/"
                title="Help (opens in a new window)"
                target="_new"
              >
                Help
              </a>
            </div>
            <div
              class="px-6 py-2 flex items-center justify-between gap-2 opacity-75 hover:opacity-100 transition cursor-pointer"
            >
              <button
                type="button"
                aria-label="Log Out"
                title="Log out"
                (click)="logout()"
              >
                Log Out
              </button>
            </div>
          </div>

          <div class="text-xs">
            <a
              href="https://clearbenchmark.com/privacy"
              target="_new"
              title="Privacy policy opens in a new tab"
            >
              <div
                class="px-6 py-2 opacity-75 hover:opacity-100 transition cursor-pointer"
              >
                Privacy Policy
              </div>
            </a>
            <a
              href="https://clearbenchmark.com/acceptable"
              target="_new"
              title="Acceptable use policy opens in a new tab"
            >
              <div
                class="px-6 py-2 opacity-75 hover:opacity-100 transition cursor-pointer"
              >
                Acceptable Use Policy
              </div>
            </a>
            <a
              href="https://clearbenchmark.com/terms"
              target="_new"
              title="Terms & conditions opens in a new tab"
            >
              <div
                class="px-6 py-2 opacity-75 hover:opacity-100 transition cursor-pointer"
              >
                Terms & Conditions
              </div>
            </a>
          </div>

          <!-- <div class="px-6 py-2 text-xs">
                        v20221111.1
                    </div> -->
        </div>
      </div>
    </div>
  </div>
  <!-- Mobile Views-->
  <div *ngIf="splitMode === 'top'" class="md:hidden block">
    <div class="text-center text-xs py-2 truncate">
      Signed in as {{ user.name }}
    </div>
    <div class="flex justify-center items-center gap-2 text-sm">
      <a [routerLink]="['/settings']" class="p-2 block"> Settings </a>
      <a
        href="https://clearbenchmark.freshdesk.com/support/home"
        target="_new"
        class="block p-2"
      >
        Help
      </a>
      <button
        type="button"
        class="p-2 block"
        aria-label="Log Out"
        title="Log out"
        (click)="logout()"
      >
        Log Out
      </button>
    </div>
  </div>
  <div
    *ngIf="splitMode === 'bottom'"
    class="md:hidden h-full flex flex-col justify-end"
  >
    <div class="text-xs">
      <a
        href="https://clearbenchmark.com/privacy"
        target="_new"
        title="Privacy policy opens in a new tab"
      >
        <div
          class="px-6 py-2 opacity-75 hover:opacity-100 transition cursor-pointer"
        >
          Privacy Policy
        </div>
      </a>
      <a
        href="https://clearbenchmark.com/acceptable"
        target="_new"
        title="Acceptable use policy opens in a new tab"
      >
        <div
          class="px-6 py-2 opacity-75 hover:opacity-100 transition cursor-pointer"
        >
          Acceptable Use Policy
        </div>
      </a>
      <a
        href="https://clearbenchmark.com/terms"
        target="_new"
        title="Terms & conditions opens in a new tab"
      >
        <div
          class="px-6 py-2 opacity-75 hover:opacity-100 transition cursor-pointer"
        >
          Terms & Conditions
        </div>
      </a>
    </div>
  </div>
</ng-container>
