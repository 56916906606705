<form [formGroup]="form" (submit)="formSubmit()" novalidate>
    <div class="mt-5 flex items-center justify-end" *ngIf="form.get('logoUrl')?.value">
        <img [src]="form.get('logoUrl')?.value" [alt]="form.get('name')?.value" width="200px">
    </div>
    <div class="mt-5">
        <cb-form-label for="name" [form]="form">
            Company name
        </cb-form-label>
        <input id="name" maxlength="105" #nameInput name="name" formControlName="name" type="text" cbInput />
    </div>
    <div class="mt-5">
        <cb-form-label for="domain" [form]="form" example="clearbenchmark.com">
            Domain
        </cb-form-label>
        <input id="domain" maxlength="255" name="domain" formControlName="domain" type="text" cbInput />
    </div>
    <div class="mt-5">
        <cb-form-label for="companyNumber" [optional]="true" [form]="form">
            Company number
        </cb-form-label>
        <input id="companyNumber" maxlength="55" name="companyNumber" formControlName="companyNumber" type="text"
            cbInput />
    </div>
    <div class="mt-5">
        <cb-form-label for="productId" [form]="form">
            Subscription
        </cb-form-label>
        <select requried id="productId" name="productId" formControlName="productId" cbSelect>
            <option *ngFor="let product of productList" [value]="product.value">{{product.text}}</option>
        </select>
    </div>
    <div class="mt-5" *ngIf="form.get('productId')?.dirty && form.get('productId')?.value !== 'Free'">
        <div class="flex gap-2 text-brand-deep">
            <div>
                <hero-icon name="exclamation" type="outline" class="w-5 h-5"></hero-icon>
            </div>
            <div class="text-xs">
                Make sure payment has been set up in Stripe
            </div>

        </div>
    </div>
    <div class="mt-5">
        <cb-form-label for="country" [form]="form">
            Country
        </cb-form-label>
        <select requried id="country" name="country" formControlName="country" cbSelect>
            <option *ngFor="let country of countryList" [value]="country">{{country}}</option>
        </select>
    </div>

    <div class="mt-10 flex justify-end">
        <div class="py-5">
            <button type="submit" cbButton [disabled]="!form.valid">
                Save
            </button>
        </div>
    </div>
</form>