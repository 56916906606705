import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { HttpErrorService } from '@app/services/http-error.service';
import { Observable, of } from 'rxjs';
import { environment as env } from '@env';
import { map } from 'rxjs/operators';

type CustomersByPlan = {
  plan: string;
  customers: number;
}

type PieMetric = {
  labels: string[];
  datasets: {
    data: number[],
    backgroundColor: ["#00ab9e", "#34394b", "#0062AB", "#cc3232", "#e7b416"]
  }[]
}

@Component({
  selector: 'cb-customers-by-plan',
  templateUrl: './customers-by-plan.component.html',
  styles: [
  ]
})
export class CustomersByPlanComponent implements OnInit {

  customersByPlan$: Observable<PieMetric | undefined> = of(undefined);

  pieOptions = {
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
        }
      }
    }
  }

  constructor(
    private httpClient: HttpClient,
    private errorHandler: HttpErrorService
  ) { }

  ngOnInit(): void {
    const { baseUrl } = env.api;
    this.customersByPlan$ = this.httpClient.get<CustomersByPlan[]>(`${baseUrl}/v1/metrics/customers-by-plan`).pipe(
      this.errorHandler.handleHttpError,
      map((metrics) => ({
        labels: metrics.map(({ plan }) => plan),
        datasets: [
          {
            data: metrics.map(({ customers }) => customers),
            backgroundColor: ["#00ab9e", "#34394b", "#0062AB", "#cc3232", "#e7b416"]
          }
        ]
      }))
    );
  }

}
