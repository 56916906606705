import { Pipe, PipeTransform } from '@angular/core';

export interface ISearchable {
  searchQuery(): string[];
}

/** Used to filter generic objects */

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  transform<T extends ISearchable>(value: T[] | null, searchText: string | undefined): T[] | null {
    if(!value){
      return value;
    }
    
    if(!searchText){
      return value;
    }
        
    const result = value.filter(item => {
      const searchable = item as ISearchable;      
      if(!searchable.searchQuery){
        return item;
      }
      return this.flatternQuery(searchable.searchQuery()).toLowerCase().indexOf(searchText.toLowerCase()) > -1
    });

    return result;
  }

  private flatternQuery(searchQuery: string[]): string {
    return searchQuery.join("|").toLowerCase();
  }

}
