import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Contact } from '@app/users/users.service';

@Component({
  selector: 'cb-add-contact',
  templateUrl: './add-contact.component.html',
  styles: [
  ]
})
export class AddContactComponent implements OnInit {

  form!: FormGroup;
  @Output() onsubmit = new EventEmitter<Contact>();

  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(100)]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(255)]],
      save: [false]
    });
  }

  addNewContact(): void {
    const {name, email, save} = this.form.getRawValue();
    const contact: Contact = {name, email, save};
    this.onsubmit.emit(contact);
    this.form.reset();
  }
}
