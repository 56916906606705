import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { claim_types } from '../claim-types';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Role } from '@app/roles';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  constructor(private auth: AuthService) {

  }

  roles$: Observable<Set<string>> = this.auth.user$.pipe(
    map(user => new Set<string>(user![claim_types.ROLE])),
    shareReplay(1)
  );

  userHasRole(role: Role): Observable<boolean> {
    return this.roles$.pipe(map(roles => roles.has(role)));
  }

}
