import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  HeroIconModule,
  officeBuilding,
  chartPie,
  clipboard,
  clipboardCheck,
  clipboardList,
  adjustments,
  documentText,
  questionMarkCircle,
  terminal,
  menuAlt2,
  cog,
  logout,
  x,
  trash,
  plusCircle,
  arrowLeft,
  users,
  presentationChartBar,
  informationCircle,
  chevronDown,
  userCircle,
  arrowCircleLeft,
  lightBulb,
  check,
  chevronRight,
  exclamation,
  user,
  identification,
  briefcase,
  documentReport,
  download,
  plus,
  globe,
  documentAdd,
  upload,
  documentDownload,
  dotsHorizontal,
  filter,
  dotsCircleHorizontal,
  chevronUp,
  arrowUp,
  arrowDown,
  badgeCheck,
  exclamationCircle,
  clock,
  thumbUp,
  thumbDown,
  shieldCheck,
  shieldExclamation,
  pencil,
  save,
  refresh,
  userRemove,
  ban,
  selector,
  share,
  userAdd,
  externalLink
} from 'ng-heroicon';

@NgModule({
  declarations: [],
  exports: [HeroIconModule],
  imports: [
    CommonModule,
    HeroIconModule.withIcons({
      officeBuilding,
      chartPie,
      clipboard,
      clipboardCheck,
      clipboardList,
      adjustments,
      documentText,
      questionMarkCircle,
      terminal,
      menuAlt2,
      cog,
      logout,
      x,
      trash,
      plusCircle,
      arrowLeft,
      users,
      presentationChartBar,
      informationCircle,
      chevronDown,
      userCircle,
      arrowCircleLeft,
      lightBulb,
      check,
      chevronRight,
      exclamation,
      user,
      identification,
      briefcase,
      documentReport,
      download,
      plus,
      globe,
      documentAdd,
      upload,
      documentDownload,
      dotsHorizontal,
      filter,
      dotsCircleHorizontal,
      chevronUp,
      arrowUp,
      arrowDown,
      badgeCheck,
      exclamationCircle,
      clock,
      thumbUp,
      thumbDown,
      shieldCheck,
      shieldExclamation,
      pencil,
      save,
      refresh,
      userRemove,
      ban,
      selector,
      share,
      userAdd,
      externalLink
    })
  ]
})
export class IconsModule { }
