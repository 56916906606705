import { Component, OnInit, EventEmitter } from '@angular/core';
import {
  ProjectsService,
  Project,
  ProjectStatus,
} from '@app/projects/projects.service';
import { UserContextService } from '@app/user-context.service';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import {
  HeaderButton,
  HeaderControl,
  HeaderMultiSelectList,
  HeaderSearch,
  HeaderSelectList,
} from '@app/components/page-header/controls';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { getProjectState } from '../project-status-map';
import { isNotNull } from '@app/shared/utility-functions';
import { projectTypeOptions } from '../project-options';
import { FeaturesService } from '@app/features.service';

type FilterParams = {
  term?: string;
  status?: ProjectStatus[];
  type?: string;
  offset?: number;
  fetch?: number;
};

@Component({
  selector: 'cb-projects-list',
  templateUrl: './projects-list.component.html',
  styles: [],
})
export class ProjectsListComponent implements OnInit {
  projects$!: Observable<Project[]>;
  newProjectModalOpen = false;
  selectedProject: Project | null = null;
  selectedProjects: Project[] = [];
  controls$: Observable<HeaderControl[]> = of([]);
  pushToPsaEnabled$: Observable<boolean>;

  private onSearchChanged = new EventEmitter<string>();
  private onStatusChanged = new EventEmitter<string[]>();
  private onProjectTypeChanged = new EventEmitter<string>();

  private statusOptions$: Observable<{ text: string; value: ProjectStatus }[]>;

  constructor(
    private service: ProjectsService,
    private router: Router,
    private route: ActivatedRoute,
    private context: UserContextService,
    private features: FeaturesService
  ) {
    this.pushToPsaEnabled$ = this.context.myCompany$.pipe(
      switchMap(({ id }) => this.features.enabledForCompany('pushToPsa', id))
    );

    this.statusOptions$ = this.service.projectStatusOptions$;
  }

  ngOnInit(): void {
    const paramsSubject = new Subject<Params>();
    this.onSearchChanged.subscribe((term) => {
      paramsSubject.next({ term: term || null });
    });
    this.onStatusChanged.subscribe((status) => {
      paramsSubject.next({ status: status || null });
    });
    this.onProjectTypeChanged.subscribe((type) => {
      paramsSubject.next({ type: type || null });
    });

    paramsSubject.subscribe((queryParams) => {
      this.router.navigate([], {
        queryParams,
        queryParamsHandling: 'merge',
      });
    });

    const readParams = ({
      term,
      status,
      type,
      offset,
      fetch,
    }: Params): FilterParams => {
      const statusList: ProjectStatus[] = [];

      if (status) {
        if (Array.isArray(status)) {
          statusList.push(...status);
        } else {
          statusList.push(status);
        }
      }

      return {
        term,
        status: statusList.length ? statusList : undefined,
        type,
        offset,
        fetch,
      };
    };

    const filterParams = this.route.queryParams.pipe(map(readParams));

    const company$ = this.context.currentCompany$.pipe(filter(isNotNull));
    this.projects$ = combineLatest([company$, filterParams]).pipe(
      map(([{ id }, { term, status, type, offset, fetch }]) => ({
        companyId: id,
        term,
        type,
        status,
        offset,
        fetch,
      })),
      switchMap((req) => this.service.getProjects(req)),
      shareReplay(1)
    );

    this.controls$ = combineLatest([
      this.route.queryParams,
      this.statusOptions$,
    ]).pipe(
      map(([params, allOptions]) => ({
        ...readParams(params),
        allOptions,
      })),
      map(({ term, status, type, allOptions }) => [
        new HeaderSearch({
          id: 'project-search',
          placeholder: 'Find a project',
          value: term ?? '',
          label: 'Search',
          changed: this.onSearchChanged,
        }),
        new HeaderSelectList(
          'project-type',
          of([
            { text: 'Any', value: '' },
            ...projectTypeOptions.map((type) => ({
              text: type,
              value: type,
            })),
          ]),
          this.onProjectTypeChanged,
          type ?? '',
          'Project Type'
        ),
        new HeaderMultiSelectList(
          'project-status',
          this.statusOptions$,
          this.onStatusChanged,
          status ?? [...allOptions.map(({ value }) => value)],
          'Project Status'
        ),
        new HeaderButton(
          'Add project',
          'plus-circle',
          () =>
            this.router.navigate(['../projects', 'new'], {
              relativeTo: this.route,
            }),
          'ProjectManager'
        ),
      ])
    );
  }

  getProjectStyles(project: Project) {
    return getProjectState(project.status, 'text');
  }

  closeForm(): void {
    this.selectedProject = null;
    this.newProjectModalOpen = false;
  }
}
