import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

interface Dictionary {
  [key:string]: string;
}

@Component({
  selector: 'cb-error',
  templateUrl: './error.component.html',
  styles: [
  ]
})
export class ErrorComponent implements OnInit {

  private errorMessages: Dictionary = {
    "400": "Bad Request",
    "403": "You are not authorized for that action",
    "404": "Nothing here",
    "500": "An unexpected error occurred"
  }

  public errorCode: number = 500;
  public errorText: string = this.errorMessages["500"];  
  
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    const code = this.route.snapshot.paramMap.get("code");

    if(code !== null) {
      const parsed = Number.parseInt(code);
      this.errorCode = !Number.isNaN(parsed) ? parsed : 500;
      this.errorText = this.errorMessages[this.errorCode.toString()];      
    }
  }

}
