import { Injectable, NgModule } from '@angular/core';

import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterModule,
  RouterStateSnapshot,
  Routes,
} from '@angular/router';

import { ErrorComponent } from './pages/error/error.component';

import { CompaniesRoutingModule } from '@app/companies/companies.router';
import { InvitationsRouterModule } from '@app/invitations/invitations-router.module';
import { ReportRoutingModule } from '@app/report/routing.module';
import { DocumentsRoutingModule } from '@app/documents/documents-routing.module';
import { DashboardRoutingModule } from '@app/dashboard/dashboard-routing.module';
import { TasksRoutingModule } from '@app/tasks/router.module';
import { SettingsRoutingModule } from '@app/settings/router.module';
import { ProjectsRouterModule } from '@app/projects/projects.router';
import { RoadmapRouterModule } from '@app/roadmap/roadmap.router';

import { IndexComponent } from '@app/components/index/index.component';
import { UserRoutes } from '@app/users/users-routing.module';
import { JourneyRoutes } from '@app/journey/routing.module';
import { BenchmarkRoutes } from '@app/benchmark/benchmark.router';
import { ReportRoutes } from '@app/report/routing.module';
import { ProjectRoutes } from '@app/projects/projects.router';
import { RoadmapRoutes } from '@app/roadmap/roadmap.router';
import { DocumentRoutes } from '@app/documents/documents-routing.module';
import { CompanyRoutes } from '@app/companies/companies.router';
import { Observable } from 'rxjs';
import { UserContextService } from './user-context.service';
import { CompanyDetailService } from './companies/company-detail/company-detail.service';
import { map } from 'rxjs/operators';
import { makeCompanyId } from './interfaces';
import { SignUpRoutes } from './sign-up/sign-up.router';
import { ClientsRoutingModule } from '@app/clients/clients.router';
import { CustomersRoutingModule } from '@app/customers/customers.router';

@Injectable({
  providedIn: 'root',
})
class CompanyGuard implements CanActivate {
  constructor(
    private context: UserContextService,
    private service: CompanyDetailService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> {
    const companyId = route.paramMap.get('companyId');
    this.context.selectedCompanyId$.next(companyId);

    if (!companyId) {
      this.context.currentCompany$.next(null);
      return false;
    }

    if (this.context.currentCompanySnapshot?.id === companyId) {
      return true;
    }

    return this.service.getCompanyById(companyId).pipe(
      map((company) => {
        if (!company) {
          this.context.currentCompany$.next(null);
          return false;
        }

        this.context.currentCompany$.next({
          name: company.name,
          id: makeCompanyId(company.id),
          type: company.type,
        });

        return true;
      })
    );
  }
}

const routes: Routes = [
  { path: '', component: IndexComponent, pathMatch: 'full' },
  ...SignUpRoutes,
  {
    path: 'c/:companyId',
    canActivate: [CompanyGuard],
    children: [
      ...UserRoutes,
      ...JourneyRoutes,
      ...BenchmarkRoutes,
      ...ReportRoutes,
      ...ProjectRoutes,
      ...RoadmapRoutes,
      ...DocumentRoutes,
      ...CompanyRoutes,
    ],
  },
  { path: 'error/:code', component: ErrorComponent },
  { path: '**', redirectTo: '/error/404', pathMatch: 'full' },
];

@NgModule({
  imports: [
    CompaniesRoutingModule,

    InvitationsRouterModule,
    ReportRoutingModule,
    DocumentsRoutingModule,
    DashboardRoutingModule,
    TasksRoutingModule,
    SettingsRoutingModule,
    ProjectsRouterModule,
    RoadmapRouterModule,
    ClientsRoutingModule,
    CustomersRoutingModule,

    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
