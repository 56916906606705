import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'cb-form-label',
  templateUrl: './form-label.component.html',
  styles: [
  ]
})
export class FormLabelComponent implements OnInit {

  @Input() text?: string;
  @Input() for?: string;
  @Input() optional: boolean = false;
  @Input() form?: FormGroup;
  @Input() example?: string;

  constructor() { }

  ngOnInit(): void {
  }

}
