import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AcceptInviteService, InvitationRequest } from './accept-invite.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap, first, } from 'rxjs/operators';
import { Invitation, InvitationStatus } from '../invitations.model';
import { AuthService } from '@auth0/auth0-angular';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'cb-accept-invite',
  templateUrl: './accept-invite.component.html',
  styles: [
  ]
})
export class AcceptInviteComponent implements OnInit {

  form!: FormGroup;
  invitationId!: string;
  invitation$!: Observable<Invitation>;
  stateIsInvalid = false;

  private passwordPattern = /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private service: AcceptInviteService,
    private auth: AuthService
  ) { }

  ngOnInit(): void {

    this.form = this.fb.group({
      firstName: ['', [Validators.required, Validators.maxLength(100)]],
      lastName: ['', [Validators.required, Validators.maxLength(100)]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.pattern(this.passwordPattern)]],
      terms: ['', [Validators.requiredTrue]],
      privacy: ['', [Validators.requiredTrue]]
    });

    this.invitationId = this.route.snapshot.paramMap.get("id")!;

    this.invitation$ = this.service.getInvitation(this.invitationId).pipe(
      catchError((er) => {
        this.stateIsInvalid = true;
        return throwError(er);
      }),
      first(),
      tap(inv => {
        this.form.patchValue(inv);
      })
    );
  }

  acceptInvitation(): void {
    const invitation = this.form.getRawValue() as InvitationRequest;
    this.service.acceptInvitation(this.invitationId, invitation).subscribe(() => {
      console.log("HERE");
      window.location.href = '/';
    });
  }

}
