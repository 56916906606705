<ng-container *ngIf="loading; else loaded"> 
    <div class="flex justify-center">
        <p-progressSpinner></p-progressSpinner>
    </div>    
</ng-container>
<ng-template #loaded>
    <ng-container *ngIf="dataAvailable; else nodata">
        <ng-container *ngIf="roadmapData">
    
            <p-accordion>
                <p-accordionTab *ngFor="let node of roadmapData.roadmap">
                    <ng-template pTemplate="header">
                        <div class="flex justify-between items-center w-full">
                            <div>{{node.dateKey}}</div>
                            <div *ngIf="node.totalBudget">&pound;{{node.totalBudget | number:'1.2-2'}}</div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <div class="striped">
                            <div *ngFor="let project of node.projects" class="flex justify-between items-center py-2 px-1">
                                <div class="truncate">
                                    <a class="hover:underline" [routerLink]="['../projects', project.projectId]">
                                        {{project.projectName}}
                                    </a>
                                </div>
                                <div class="text-xs">
                                    <a class="hover:underline" [routerLink]="['../projects', project.projectId]" [queryParams]="{'tab': 'tasks'}">
                                        {{project.taskCount | number}} task{{project.taskCount | plural}}
                                    </a>                                    
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </p-accordionTab>
            </p-accordion>
    
            <div *ngIf="true" class="flex justify-end pt-6 font-bold">
                <div class="text-right">
                    <div>total budget</div>
                    <div>&pound;{{roadmapData.totalBudget | number:'1.2-2'}}</div>
                </div>                    
            </div>   
    
        </ng-container>    
        
    </ng-container>
    <ng-template #nodata>
        <cb-no-data></cb-no-data>
    </ng-template>
</ng-template>
