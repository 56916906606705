import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '@app/components/components.module';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { StylesModule } from '@app/styles/styles.module';
import { ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AccordionModule } from 'primeng/accordion';

import { CompaniesListComponent } from './companies-list/companies-list.component';
import { NewCompanyComponent } from './new-company/new-company.component';
import { CompanyFormComponent } from './company-form/company-form.component';
import { CompanyEditComponent } from './company-edit/company-edit.component';
import { CompanyDetailComponent } from './company-detail/company-detail.component';
import { MyCompanyComponent } from './my-company/my-company.component';
import { CompanyDetailCardComponent } from './company-detail/company-detail-card/company-detail-card.component';

import { ChartModule } from 'primeng/chart';
import { HeroIconModule, users, chevronRight, exclamation } from 'ng-heroicon';
import { CompanyLevelComponent } from './company-detail/gauges/company-level/company-level.component';
import { CompanyRoadmapComponent } from './company-detail/gauges/company-roadmap/company-roadmap.component';
import { CompanyProgressComponent } from './company-detail/gauges/company-progress/company-progress.component';
import { RiskHistoryComponent } from './company-detail/gauges/risk-history/risk-history.component';
import { NoDataComponent } from './company-detail/gauges/no-data/no-data.component';
import { TaskStatusComponent } from './company-detail/gauges/task-status/task-status.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RiskScoreComponent } from './company-detail/gauges/risk-score/risk-score.component';

@NgModule({
  declarations: [
    CompaniesListComponent,
    NewCompanyComponent,
    CompanyFormComponent,
    CompanyEditComponent,
    CompanyDetailComponent,
    MyCompanyComponent,
    CompanyDetailCardComponent,
    CompanyLevelComponent,
    CompanyRoadmapComponent,
    CompanyProgressComponent,
    RiskHistoryComponent,
    NoDataComponent,
    TaskStatusComponent,
    RiskScoreComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    CheckboxModule,
    FormsModule,
    RouterModule,
    StylesModule,
    ReactiveFormsModule,
    DialogModule,
    AutoCompleteModule,
    ChartModule,
    AccordionModule,
    ProgressSpinnerModule,
    HeroIconModule.withIcons({
      users,
      chevronRight,
      exclamation,
    }),
  ],
  exports: [TaskStatusComponent],
})
export class CompaniesModule {}
