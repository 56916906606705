import { HttpClient, HttpContext, HttpContextToken } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpErrorService } from '@app/services/http-error.service';
import { environment as env } from '@env';

export interface CompanyProgressReport {
  available: boolean;
  risk: LevelStatus;
  progress: LevelStatus
}

interface LevelStatus {
  level1: number;
  level2: number;
  level3: number;
  level4: number;
  level5: number;
}

@Injectable({
  providedIn: 'root'
})
export class CompanyProgressService {

  constructor(
    private httpClient: HttpClient,
    private errorHandler: HttpErrorService
  ) { }

  getCompanyProgress(companyId: string): Promise<CompanyProgressReport> {
    const url = `${env.api.baseUrl}/v${env.api.version}/data/company-progress?companyId=${encodeURIComponent(companyId)}`;

    const headers = { ignoreLoadingBar: '' };

    return this.httpClient.get<CompanyProgressReport>(url, { headers }).pipe(
      this.errorHandler.handleHttpError
    ).toPromise();
  }
}
