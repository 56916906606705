<div class="max-w-full bg-white px-2 py-2 lg:px-4 lg:py-6 lg:mx-auto lg:max-w-4xl">
    <div class="px-2">                
        <label cbLabel>Company name</label>
        <input type="text" (keyup)="search($event)" placeholder="Start typing..." #companySearch cbInput />
    </div>            
    <ng-container *ngIf="results$ | async as results">
        <ul class="px-2 md:px-4 mt-4 divide-y divide-gray-200">
            <li *ngFor="let result of results" (click)="selectCompany(result)" class="p-4 cursor-pointer transition group">
                <p class="mb-2 transform transition group-hover:scale-105">
                    {{result.title}}
                </p>                
                <small class="text-xs block text-gray-400">
                    Number: {{result.companyNumber}}
                </small>
                <p class="text-xs text-gray-400 truncate">
                    {{result.addressSnippet}}
                </p>
            </li>
        </ul>
    </ng-container>
</div>
