<div
  class="py-6 px-10 bg-white shadow-md rounded-md transform cursor-pointer transition hover:scale-105 text-brand-secondary relative"
>
  <div
    class="absolute top-px right-px text-mid-gray"
    *ngIf="external"
    title="Opens in a new tab or window"
  >
    <hero-icon name="external-link" type="outline" class="w-5 h-5"></hero-icon>
  </div>
  <div
    class="absolute -top-2 -right-2 rounded-full bg-brand-primary shadow-lg px-3 py-1 text-white"
    *ngIf="beta"
    title="This is a new feature that may not be fully functional"
  >
    <div class="flex items-center">
      <div class="pr-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-5 h-5"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M14.25 9.75 16.5 12l-2.25 2.25m-4.5 0L7.5 12l2.25-2.25M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z"
          />
        </svg>
      </div>
      <div class="text-xs">beta</div>
    </div>
  </div>
  <div class="mb-2 flex justify-center">
    <hero-icon [name]="icon" class="w-6 h-6" type="outline"></hero-icon>
  </div>
  <div class="text-xl">
    <ng-content></ng-content>
  </div>
</div>
