<ng-container *ngIf="task">
  <div class="flex justify-center items-center">
    <div>
      <p class="mb-5 text-lg text-center">
        Choose a quality rating for this task
      </p>
      <div class="flex gap-4 justify-center">
        <div *ngFor="let item of qualityRatings">
          <button
            type="button"
            (click)="quality = item.rating"
            class="text-white font-bold rounded-lg shadow-sm border cursor-pointer transition h-12 w-24 text-center text-sm hover:opacity-100 transform hover:-translate-y-1"
            [class]="getButtonStyle(item.rating)"
            [title]="item.label"
          >
            <div class="flex w-full h-full justify-center items-center">
              <hero-check *ngIf="quality === item.rating"></hero-check>
              <ng-container *ngIf="quality !== item.rating">
                {{ item.label }}
              </ng-container>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<p-confirmDialog
  header="Replace notes?"
  icon="pi pi-exclamation-triangle"
  position="bottom"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
  [style]="{ width: '50vw' }"
  acceptLabel="Yes, replace notes"
  rejectLabel="No, leave current notes"
>
</p-confirmDialog>
