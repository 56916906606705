<cb-authorized>
    <div class="max-w-full px-2 py-2 lg:px-4 lg:py-6 lg:mx-auto lg:max-w-4xl">
        <h2 class="text-2xl mb-10">Create a new company</h2>
        <button *ngIf="formActive" type="button" (click)="backToSearch()"
            class="text-sm mb-2 p-2 text-gray-500 flex justify-start items-center gap-2">
            <div>
                <hero-icon name="arrow-left" type="outline" class="w-4 h-4"></hero-icon>
            </div>
            <div>
                Back to search
            </div>
        </button>
        <div class="lg:border lg:border-gray-200 rounded-md shadow-md bg-white lg:p-4">
            <ng-container *ngIf="formActive else searchView">
                <cb-company-form [formTitle]="formTitle" [company]="company" (save)="saveCompany($event)"
                    (cancel)="cancel()" #companyForm>
                </cb-company-form>
            </ng-container>
            <ng-template #searchView>
                <cb-company-search #companySearch (selected)="selectCompany($event)"></cb-company-search>
                <div class="px-2 flex justify-start">
                    <button type="button" (click)="addNewCompany()">
                        <div class="flex justify-start items-center p-2">
                            <hero-icon name="plus-circle" type="outline" class="w-5 h-5"></hero-icon>
                            <div class="pl-2 text-sm">
                                Add new
                            </div>
                        </div>
                    </button>
                </div>
            </ng-template>

        </div>
    </div>
</cb-authorized>