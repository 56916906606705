import { Component, OnInit, Input } from '@angular/core';

export type Level = 0 | 1 | 2 | 3 | 4 | 5;
export type Mode = "break" | "inline";

export type LevelDescription =
  | "Beginning"
  | "Getting Started"
  | "Gaining Control"
  | "Enforcing Policy"
  | "Going Proactive"
  | "Threat Hunting";

export const levels: Map<Level, LevelDescription> = new Map<Level, LevelDescription>([
  [0, "Beginning"],
  [1, "Getting Started"],
  [2, "Gaining Control"],
  [3, "Enforcing Policy"],
  [4, "Going Proactive"],
  [5, "Threat Hunting"]
]);

export function getLevelDescription(level: Level): LevelDescription | undefined {
  return levels.get(level);
}

@Component({
  selector: 'cb-level-label',
  templateUrl: './level-label.component.html'
})
export class LevelLabelComponent implements OnInit {

  @Input() level?: Level | null;
  @Input() textOnly: boolean;
  @Input() mode: Mode;

  constructor() {
    this.textOnly = false;
    this.mode = "break";
  }

  get levelText(): LevelDescription | "" {
    let key: Level = this.level || 0;

    return levels.get(key) ?? "";
  }

  ngOnInit(): void {
  }

}
