<cb-metric-card heading="Projects">
    <ng-container *ngIf="projectMetrics$ | async as metrics; else loading">
        <div *ngFor="let metric of metrics"
            class="p-1 my-1 flex justify-between items-start group transition hover:bg-slate-50 cursor-pointer">
            <div>
                <div class="text-xl transform transition group-hover:scale-105"
                    [ngClass]="getStatusColour(metric.status)">
                    {{metric.status}}
                </div>
                <div class="text-xs text-slate-400">{{metric.projectCount}} project{{metric.projectCount | plural}}
                </div>
            </div>
            <div>
                <div class="text-sm pt-1">
                    <span class="font-bold">£{{metric.totalBudget | number}}</span>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-template #loading>
        <div *ngFor="let i of [1,2,3,4]" class="mb-5">
            <div class="mb-2">
                <p-skeleton width="8rem"></p-skeleton>
            </div>
            <div>
                <p-skeleton width="4rem"></p-skeleton>
            </div>
        </div>
    </ng-template>
</cb-metric-card>