<p-calendar 
    [ngModel]="value" 
    (onSelect)="dateSelected($event)" 
    (onClearClick)="dateCleared()"
    [showTime]="time" 
    dateFormat="d M yy"
    [monthNavigator]="true"
    placeholder="pick a date"
    [showButtonBar]="true"
    styleClass="w-full md:w-auto"    
></p-calendar>
