import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { countries, sectors } from '@data/static';
import { domainRegex } from '@app/shared';
import { ClientsListService } from '../clients-list/clients-list.service';
import { SubscriptionService } from '@app/subscription.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { UK } from '@app/shared';

export type Client = {
  name: string;
  companyNumber: string;
  domain: string;
  sector: string;
  country: string;
}

@Component({
  selector: 'cb-add-client',
  templateUrl: './add-client.component.html',
  styles: [
  ]
})
export class AddClientComponent implements OnInit, OnDestroy {

  @Output() done = new EventEmitter<Client>();

  constructor(
    private fb: FormBuilder,
    private service: ClientsListService,
    private subscriptionsService: SubscriptionService,
    private toastr: ToastrService
  ) { }

  form!: FormGroup;
  countryList = [...countries];
  sectorList = [...sectors];

  private subscriptions: Subscription[] = [];

  private nameElement!: ElementRef<HTMLInputElement>;
  @ViewChild("nameInput", { static: false })
  set content(content: ElementRef<HTMLInputElement>) {
    if (content) {
      this.nameElement = content;
    }
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [null, [Validators.required, Validators.maxLength(100)]],
      companyNumber: [null, [Validators.maxLength(50)]],
      domain: [null, [Validators.required, Validators.maxLength(255), Validators.pattern(domainRegex)]],
      sector: [null, [Validators.required]],
      country: [UK, [Validators.required]]
    });

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  formSubmit(): void {
    if (this.form.valid) {
      const value = this.form.getRawValue() as Client;
      this.addNewClient(value);
    }
  }

  focusCompanyName() {
    setTimeout(() => this.nameElement.nativeElement.focus(), 50);
  }

  clearForm(): void {
    this.form.reset();
    this.form.patchValue({ country: UK });
  }

  private addNewClient(client: Client): void {
    this.subscriptions.push(
      this.service.addClient(client).subscribe(() => {
        this.subscriptionsService.refresh();
        this.toastr.success(`${client.name} created`, "New Client Added");
        this.done.emit(client);
      })
    );
  }

}
