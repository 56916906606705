<cb-authorized>
  <cb-body>
    <cb-page-header [controls]="controls$ | async"> Benchmark </cb-page-header>

    <ng-container *ngIf="tasks$ | async as tasks">
      <div>
        <label for="includecompleted" cbLabel>Include completed tasks</label>
        <p-inputSwitch
          inputId="includecompleted"
          [(ngModel)]="includeCompletedTasks"
          (onChange)="toggleCompleted($event.checked)"
        ></p-inputSwitch>
      </div>
      <ng-container *ngIf="tasks.length; else notasks">
        <ng-container *ngFor="let group of tasks; index as groupIndex">
          <h2 class="text-xl text-brand-deep mb-5 mt-8 px-2">
            {{ group.groupName }}
          </h2>
          <cb-list-view>
            <cb-list-header>
              <cb-header-column>Task</cb-header-column>
            </cb-list-header>

            <cb-list-row
              (click)="displayTask(task.taskId)"
              *ngFor="let task of group.data; index as index"
              [tags]="['Level ' + task.level]"
            >
              <cb-list-column>
                <div class="flex justify-between items-start">
                  <div>
                    <div>{{ task.title }}</div>
                    <div>
                      <small
                        *ngIf="task.lastUpdatedBy && task.lastUpdatedDate"
                        class="text-xs text-slate-400"
                      >
                        Last updated by {{ task.lastUpdatedBy }} on
                        {{ formatModifiedDate(task.lastUpdatedDate) }}
                      </small>
                    </div>
                  </div>
                  <div>
                    <cb-risk-label [risk]="task.risk"></cb-risk-label>
                  </div>
                </div>
              </cb-list-column>
            </cb-list-row>
          </cb-list-view>
        </ng-container>
      </ng-container>

      <p-dialog
        [modal]="true"
        [(visible)]="taskActive"
        position="top"
        (onHide)="close()"
        styleClass="w-full max-w-screen-xl h-screen"
      >
        <div class="px-1 md:px-10 relative">
          <div class="flex gap-4 items-start justify-between">
            <div class="text-2xl flex-none">
              {{ activeTask?.current?.position }} / {{ taskMap?.size }}
            </div>
            <h1 class="text-2xl text-center font-bold">
              {{ currentTask?.title }}
            </h1>
            <div class="flex justify-center">
              <cb-modal-risk-icon [risk]="currentRisk"></cb-modal-risk-icon>
            </div>
          </div>
          <ng-container *ngIf="activeTask">
            <h2 class="mt-5 mb-8 text-center text-sm">
              {{ activeTask.current.task.summary }}
            </h2>

            <div class="flex-1">
              <ng-container *ngIf="mode === 'active'">
                <cb-quality-options
                  (qualitySelected)="qualitySelected($event)"
                  [task]="activeTask.current.task"
                ></cb-quality-options>

                <div class="mt-5 pb-20">
                  <cb-notes-view
                    [task]="activeTask.current.task"
                  ></cb-notes-view>
                </div>
              </ng-container>

              <div class="flex justify-end gap-2"></div>

              <ng-container *ngIf="mode === 'details'">
                <cb-quality-examples
                  [task]="activeTask.current.task"
                ></cb-quality-examples>
              </ng-container>
            </div>
            <div
              class="fixed bottom-1 inset-x-0 py-4 px-6 bg-white shadow-[0_-4px_30px_-17px_rgba(0,0,0,0.3)]"
            >
              <div class="flex justify-between">
                <div class="flex gap-4">
                  <button
                    [disabled]="!activeTask.previous?.task"
                    type="button"
                    cbButton
                    (click)="move(activeTask.previous)"
                  >
                    <div class="flex gap-1">
                      <div>
                        <hero-chevron-left></hero-chevron-left>
                      </div>
                      <div>Previous</div>
                    </div>
                  </button>
                  <button
                    *ngIf="mode !== 'details'"
                    (click)="toggleMode('details')"
                    type="button"
                    cbButton
                    colour="bg-brand-deep"
                  >
                    <div class="flex items-center gap-1">
                      <div>
                        <hero-window></hero-window>
                      </div>
                      <div>View Details</div>
                    </div>
                  </button>
                  <button
                    *ngIf="backButtonEnabled"
                    (click)="resetMode()"
                    type="button"
                    class="hover:bg-gray-100 rounded-md px-2 text-sm"
                  >
                    <div class="flex gap-1 items-center">
                      <div>
                        <hero-arrow-left></hero-arrow-left>
                      </div>
                      <div>Back</div>
                    </div>
                  </button>
                  <cb-chips>
                    {{ activeTask.current.group.name }}
                  </cb-chips>
                  <cb-chips>
                    Level {{ activeTask.current.task.level }}
                  </cb-chips>
                </div>
                <div class="flex gap-4"></div>

                <div class="flex gap-4">
                  <button
                    type="button"
                    cbButton
                    colour="bg-slate-500"
                    (click)="saveAndClose()"
                  >
                    <div class="flex gap-1">
                      <div>
                        <hero-check></hero-check>
                      </div>
                      <div>Save & Close</div>
                    </div>
                  </button>
                  <button
                    [disabled]="!activeTask.next?.task"
                    type="button"
                    cbButton
                    (click)="move(activeTask.next)"
                  >
                    <div class="flex gap-1">
                      <div>Next</div>
                      <div>
                        <hero-chevron-right></hero-chevron-right>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </ng-container>

          <div *ngIf="working" class="absolute top-0 inset-x-0 z-10">
            <div
              class="mx-auto w-min shadow-md bg-brand-primary text-white p-2 rounded-md"
            >
              <div class="flex gap-2 items-center justify-center">
                <hero-spinner></hero-spinner>
                <p class="animate-pulse">Saving...</p>
              </div>
            </div>
          </div>
        </div>
      </p-dialog>
    </ng-container>
    <ng-template #notasks>
      <div class="py-10 flex justify-center items-center gap-2">
        <div>
          <hero-icon
            name="light-bulb"
            type="outline"
            class="w-8 h-8 text-brand-primary"
          ></hero-icon>
        </div>
        <div class="text-xl">No tasks</div>
      </div>
    </ng-template>
  </cb-body>
</cb-authorized>
