import { Component, OnInit } from '@angular/core';
import { DashboardService, MspActivity } from '../dashboard.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'cb-activity',
  templateUrl: './activity.component.html',
  styles: [
  ]
})
export class ActivityComponent implements OnInit {

  activityList$: Observable<MspActivity[]> = of([]);

  constructor(
    private service: DashboardService
  ) { }

  ngOnInit(): void {
    this.activityList$ = this.service.getActivityForMsp().pipe(
      map((projects) => projects.slice(0, 5))
    );
  }

}
