<ng-container *ngIf="loading; else loaded">
    <div class="flex justify-center">
        <p-progressSpinner></p-progressSpinner>
    </div>
</ng-container>
<ng-template #loaded>
    <ng-container *ngIf="dataAvailable; else nodata">
        <p-chart type="line" [data]="categoryRiskData" [options]="chartOptions" [style]="{'max-width': '800px'}"></p-chart>
    </ng-container>
    <ng-template #nodata>
        <cb-no-data></cb-no-data>
    </ng-template>
</ng-template>
