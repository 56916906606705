import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanyProgressService } from './company-progress.service';

@Component({
  selector: 'cb-company-progress',
  templateUrl: './company-progress.component.html',
  styles: [
  ]
})
export class CompanyProgressComponent implements OnInit {

  @Input() companyId!: string;

  dataAvailable = true;
  loading = false;

  levelDescription = [
    "1. Getting Started",
    "2. Gaining Control",
    "3. Enforcing Policy",
    "4. Going Proactive",
    "5. Threat Hunting"
  ];

  riskRadarData: any;

  radarOptions = {
    scales: {
      r: {
        max: 100,
        ticks: {
          beginAtZero: true,          
          stepSize: 25
        },
        pointLabels: {
          color: '#495057',
        },
        grid: {
          color: '#ebedef'          
        },
        angleLines: {
          display: false
        }
      }
    }
  }

  constructor(
    private service: CompanyProgressService
  ) {
    
   }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    const progressData = await this.service.getCompanyProgress(this.companyId);
    this.loading = false;

    this.riskRadarData = {
      labels: this.levelDescription,
      datasets: [
        {
          label: "Risk",
          backgroundColor: 'rgba(204,50,50,0.2)',
          borderColor: 'rgba(204,50,50, 0.8)',
          pointBackgroundColor: 'rgba(204,50,50, 0.8)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(204,50,50, 0.8)',
          data: [
            progressData.risk.level1, 
            progressData.risk.level2,
            progressData.risk.level3,
            progressData.risk.level4, 
            progressData.risk.level5,   
          ]
        },
        {
          label: "Progress",
          backgroundColor: 'rgba(153,193,64,0.2)',
          borderColor: 'rgba(153,193,64, 0.8)',
          pointBackgroundColor: 'rgba(153,193,64, 0.8)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(153,193,64, 0.8)',
          data: [
            progressData.progress.level1, 
            progressData.progress.level2,
            progressData.progress.level3,
            progressData.progress.level4, 
            progressData.progress.level5,
          ]
        }
      ]
    };
  }

}
