import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { HttpErrorService } from '@app/services/http-error.service';
import { Project } from '@app/projects/projects.service';
import { Observable, of } from 'rxjs';
import { environment as env } from '@env';
import { getProjectState } from '@app/projects/project-status-map';

type ProjectStatusMetrics = {
  status: Project["status"],
  totalBudget: number;
  projectCount: number;
}

@Component({
  selector: 'cb-projects-card',
  templateUrl: './projects-card.component.html',
  styles: [
  ]
})
export class ProjectsCardComponent implements OnInit {

  projectMetrics$: Observable<ProjectStatusMetrics[]> = of([]);

  constructor(
    private httpClient: HttpClient,
    private errorHandler: HttpErrorService
  ) { }

  ngOnInit(): void {
    const { baseUrl } = env.api;
    this.projectMetrics$ = this.httpClient.get<ProjectStatusMetrics[]>(`${baseUrl}/v1/metrics/project-status`).pipe(
      this.errorHandler.handleHttpError
    )
  }

  getStatusColour(status: Project["status"]) {
    return getProjectState(status, "text");
  }

}
