import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvitationsRouterModule } from './invitations-router.module';
import { AcceptInviteComponent } from './accept-invite/accept-invite.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StylesModule } from '@app/styles/styles.module';
import { ComponentsModule } from '@app/components/components.module';
import { CheckboxModule } from 'primeng/checkbox';

@NgModule({
  declarations: [
    AcceptInviteComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InvitationsRouterModule,
    StylesModule,
    ComponentsModule,
    CheckboxModule
  ]
})
export class InvitationsModule { }
