import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpErrorService } from '@app/services/http-error.service';
import { Observable, of } from 'rxjs';
import { environment as env } from '@env';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { MetricsRefreshService } from '../metrics-refresh.service';

type Metrics = {
  clients: number;
  benchmarks: number;
  documents: number;
  projects: number;
}

interface MetricsResponse {
  clientCount: number;
  activeBenchmarkCount: number;
  projectCount: number;
  reportCount: number;
}

@Component({
  selector: 'cb-msp-client-metrics',
  templateUrl: './msp-client-metrics.component.html',
  styles: [
  ]
})
export class MspClientMetricsComponent implements OnInit, OnDestroy {

  metrics$: Observable<Metrics | undefined> = of(undefined);

  clientCount$: Observable<number> = of(0);

  constructor(
    private httpClient: HttpClient,
    private errorHandler: HttpErrorService,
    private metricsRefresh: MetricsRefreshService
  ) { }

  ngOnDestroy(): void {
    this.metricsRefresh.clientCount$.next(0);
  }

  ngOnInit(): void {
    const { baseUrl } = env.api;
    const metrics = this.httpClient.get<MetricsResponse>(`${baseUrl}/v1/msp-metrics`).pipe(
      this.errorHandler.handleHttpError,
      shareReplay(1)
    );

    this.clientCount$ = this.metricsRefresh.clientCount$.pipe(
      switchMap((value) => metrics.pipe(map(({ clientCount }) => {
        return clientCount + value;
      })))
    )

    this.metrics$ = metrics.pipe(
      map(({ clientCount, activeBenchmarkCount, projectCount, reportCount }) => ({
        clients: clientCount,
        benchmarks: activeBenchmarkCount,
        projects: projectCount,
        documents: reportCount
      }))
    );
  }

}
