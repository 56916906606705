<cb-authorized>
  <cb-body>
    <cb-page-header [showCurrentCompany]="false" [breadcrumb]="breadcrumb">
      PSA Configuration
    </cb-page-header>
    <form novalidate [formGroup]="form" (ngSubmit)="submit()">
      <div
        class="lg:border lg:border-gray-200 rounded-md shadow-md bg-white px-4 sm:px-6 pb-12 pt-6"
      >
        <div class="flex justify-between">
          <div>
            <h2 class="text-xl">Projects</h2>
            <p class="mt-2 mb-10">
              Add the email address you want projects to be sent to
            </p>
          </div>
          <div *ngIf="deleteEnabled">
            <button
              (click)="deleteSettings()"
              type="button"
              cbButton
              colour="bg-brand-danger"
            >
              Delete
            </button>
          </div>
        </div>

        <div class="mt-5 w-full">
          <div class="w-full sm:max-w-xl">
            <cb-form-label for="projectsEmail" [form]="form">
              Projects Inbox
            </cb-form-label>
            <input
              type="email"
              id="projectsEmail"
              formControlName="projectsEmail"
              cbInput
              placeholder="e.g. projects@my-msp.com"
            />
            <div class="mt-5">
              <cb-form-label for="projectsAutoPush" [form]="form">
                Auto send when project moves to 'Quoting'
              </cb-form-label>
              <p-inputSwitch
                id="projectsAutoPush"
                formControlName="projectsAutoPush"
              ></p-inputSwitch>
            </div>
          </div>
          <div class="flex-1"></div>
        </div>
      </div>
      <div class="flex justify-end mt-10">
        <button type="submit" [disabled]="!form.valid" cbButton>Save</button>
      </div>
    </form>
  </cb-body>
</cb-authorized>

<p-confirmDialog
  header="Are you sure?"
  icon="pi pi-exclamation-triangle"
  position="top"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
  [style]="{ width: '50vw' }"
  acceptLabel="Yes, delete settings"
  rejectLabel="No"
>
</p-confirmDialog>
