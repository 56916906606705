import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cb-empty-list',
  templateUrl: './empty-list.component.html',
  host: {'empty': ''}
})
export class EmptyListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
