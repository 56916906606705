import { Component, OnInit, Input } from '@angular/core';
import { HeroIconName } from 'ng-heroicon/lib/icons/icons-names';

@Component({
  selector: 'cb-link',
  templateUrl: './link.component.html',
  styles: [
  ]
})
export class LinkComponent implements OnInit {

  @Input() route?: string[];
  @Input() icon?: HeroIconName;

  constructor() { }

  ngOnInit(): void {
  }

}
