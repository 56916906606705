<div class="w-full flex justify-between group items-center bg-white transition border-b border-gray-300" [class]="{'hover:bg-gray-50': clickable, 'cursor-pointer': clickable}">
    <div class="w-full px-4 pt-4 pb-2 bg-white transition" [class]="{'group-hover:bg-gray-50': clickable}">
        <div [class]="tableStyles">
            <ng-content></ng-content>
        </div>                        
        <div class="flex gap-2 mt-2">
            <div class="p-2 rounded-md bg-bright-gray text-sm shadow-inner truncate max-w-prose" *ngFor="let tag of tags" [title]="tag">
                {{tag}}
            </div>            
        </div>
    </div>
    <div class="flex-none hidden lg:block px-4 transition group-hover:bg-gray-50" [class]="{'group-hover:bg-gray-50': clickable}">
        <hero-icon
            *ngIf="clickable" 
            name="chevron-right" 
            type="outline" 
            class="w-6 h-6 transition duration-200 ease-in-out transform group-hover:translate-x-2 group-hover:scale-105"
            >
        </hero-icon>
    </div>    
</div>
