import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '@app/users/users.model';
import { CollectionResponse } from '@app/domain/model';
import { environment as env } from '@env';
import { HttpErrorService } from '@app/services/http-error.service';

@Injectable({
  providedIn: 'root',
})
export class UserListService {
  constructor(
    private httpClient: HttpClient,
    private error: HttpErrorService
  ) {}

  getCompanyUsers(
    companyId: string,
    includeAll: boolean = false
  ): Observable<User[]> {
    let url = `${env.api.baseUrl}/v${env.api.version}/${encodeURIComponent(
      companyId
    )}/users`;

    if (includeAll) {
      url += '?includeAll=true';
    }

    return this.httpClient.get<CollectionResponse<User>>(url).pipe(
      this.error.handleHttpError,
      map((res) => res.result)
    );
  }

  deleteInvitation(id: string): Observable<void> {
    const url = `${env.api.baseUrl}/v${
      env.api.version
    }/invitations/${encodeURIComponent(id)}`;
    return this.httpClient.delete<void>(url).pipe(this.error.handleHttpError);
  }
}
