import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cb-validation-error',
  templateUrl: './validation-error.component.html',
  styles: [
  ]
})
export class ValidationErrorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
