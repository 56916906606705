<cb-authorized>
  <cb-body>
    <cb-page-header [showCurrentCompany]="false"> Settings </cb-page-header>
    <div class="grid gap-6 grid-cols-1 md:grid-cols-4 text-center">
      <ng-container *cbRequiresRole="'Administrator'">
        <a
          href="https://billing.stripe.com/p/login/00g2929TY69N8bmbII"
          target="_blank"
        >
          <cb-settings-card icon="credit-card" [external]="true">
            Subscription
          </cb-settings-card>
        </a>
      </ng-container>

      <ng-container *ngIf="pushToPsaEnabled$ | async">
        <cb-settings-card
          icon="cog"
          [beta]="true"
          *cbRequiresRole="'Administrator'"
          [routerLink]="['/settings/psa-inbox']"
        >
          PSA Inbox
        </cb-settings-card>
      </ng-container>

      <cb-settings-card
        icon="office-building"
        [routerLink]="['/settings/customers']"
        *cbRequiresRole="'CustomerManager'"
      >
        Customers
      </cb-settings-card>

      <cb-settings-card
        icon="clipboard"
        [routerLink]="['/settings/tasks']"
        *cbRequiresRole="'TasksManager'"
      >
        Tasks
      </cb-settings-card>

      <cb-settings-card
        icon="cloud"
        [routerLink]="['/settings', 'frameworks']"
        *cbRequiresRole="'FrameworksManager'"
      >
        Frameworks
      </cb-settings-card>
    </div>
  </cb-body>
</cb-authorized>
