import { Injectable } from '@angular/core';
import * as configcat from 'configcat-js';
import { environment as env } from '@env';
import { defer, Observable } from 'rxjs';

const { configCatSdkKey } = env;
const client = configcat.getClient(
  configCatSdkKey,
  configcat.PollingMode.AutoPoll,
  {
    pollIntervalSeconds: 60,
  }
);

export type ClearbenchmarkFeatures = {
  pushToPsa: boolean;
  intercomEnabled: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class FeaturesService {
  constructor() {}

  enabledForCompany(
    feature: keyof ClearbenchmarkFeatures,
    companyId: string
  ): Observable<boolean> {
    const user = new configcat.User(companyId);
    return defer(() => client.getValueAsync(feature, false, user));
  }

  enabled(feature: keyof ClearbenchmarkFeatures): Observable<boolean> {
    return defer(() => client.getValueAsync(feature, false));
  }
}
