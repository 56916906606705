<ng-container *ngIf="loading; else graph">
    <div class="flex justify-center">
        <p-progressSpinner></p-progressSpinner>
    </div>
</ng-container>
<ng-template #graph>
    <ng-container *ngIf="dataAvailable; else nodata">
        <p-chart type="radar" [data]="riskRadarData" [options]="radarOptions"></p-chart>        
    </ng-container>
    <ng-template #nodata>
        <cb-no-data></cb-no-data>
    </ng-template>
</ng-template>



