import { Component, OnInit, Input } from '@angular/core';
import { Risk } from '@app/benchmark/benchmark.model';
import { Task, Project, RoadmapService } from '@app/roadmap/roadmap.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'cb-task-list-item',
  templateUrl: './task-list-item.component.html',
  styleUrls: ['./task-list-item.component.css']
})
export class TaskListItemComponent implements OnInit {

  @Input()
  task!: Task;

  newProject: Project | null = null;

  projectListVisible = false;
  newProjectVisible = false;

  selectedTask: Task | null = null;  

  constructor() { }

  ngOnInit(): void {
    
  }

  borderColour(risk: Risk): string {
    switch(risk){
      case Risk.VeryHigh:
      case Risk.High:
        return "border-rag-red";
      case Risk.Medium:
        return "border-rag-amber";
      default:
        return "border-rag-green";
    }
  }
}
