import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Type,
} from '@angular/core';
import {
  BenchmarkService,
  BenchmarkTask,
  GroupedTask,
} from '../benchmark.service';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  combineLatest,
  of,
} from 'rxjs';
import { UserContextService } from '@app/user-context.service';
import { filter, first, map, shareReplay, switchMap } from 'rxjs/operators';
import { isNotNull } from '@app/shared';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Group, TaskRisk } from '../benchmark.model';
import {
  HeaderControl,
  HeaderSearch,
  HeaderSelectList,
  HeaderSelectListOption,
  levelOptions,
} from '@app/components/page-header/controls';
import { FrameworkService } from '@app/settings/frameworks/framework.service';
import {
  type ActiveTask,
  type LinkedTasks,
  buildLinkedTasks,
} from './linked-tasks';
import { ToastrService } from 'ngx-toastr';
import { ExclamationCircleComponent } from '@app/icons/exclamation-circle/exclamation-circle.component';
import { ExclamationTriangleComponent } from '@app/icons/exclamation-triangle/exclamation-triangle.component';
import { calculateRisk } from '@app/util/risk-calculator';
import dayjs from 'dayjs';
import AdvanceFormat from 'dayjs/plugin/advancedFormat';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';

dayjs.extend(AdvanceFormat);
dayjs.extend(LocalizedFormat);
dayjs.extend(utc);

export type TaskQuality =
  | 'NotApplicable'
  | 'NotImplemented'
  | 'Low'
  | 'Medium'
  | 'High'
  | 'Excellent';

type Mode = 'details' | 'active';
type RiskIconType = ExclamationCircleComponent | ExclamationTriangleComponent;
type RiskIcon = {
  risk: TaskRisk[];
  icon: Type<RiskIconType>;
  colour: 'text-rag-green' | 'text-rag-amber' | 'text-rag-red';
};

const copy = <T>(a: T): T => JSON.parse(JSON.stringify({ a }))?.a;
const changed = <T>(a: T, b: T) => JSON.stringify(a) !== JSON.stringify(b);

@Component({
  selector: 'cb-task-list',
  templateUrl: './tasks-list.component.html',
})
export class TasksListComponent implements OnInit, OnDestroy {
  tasks$: Observable<GroupedTask[]> = of([]);
  controls$: Observable<HeaderControl[]> = of([]);
  includeCompletedTasks: boolean = false;
  inputSwitchToggle = new Subject<boolean>();
  exampleText: string | null = null;
  working = false;
  activeTask: LinkedTasks | undefined;
  taskMap: Map<string, LinkedTasks> | undefined;
  taskMapKey: string | undefined;
  mode: Mode = 'active';

  get taskActive(): boolean {
    return this.activeTask !== undefined;
  }

  set taskActive(value: boolean) {
    if (!value) {
      this.activeTask = undefined;
    }
  }

  get backButtonEnabled(): boolean {
    return this.mode !== 'active';
  }

  get currentTask(): BenchmarkTask | undefined {
    return this.activeTask?.current?.task;
  }

  get currentRisk(): TaskRisk | undefined {
    return this.currentTask?.risk;
  }

  private subscriptions: Subscription[] = [];
  private onLevelChanged = new EventEmitter<string>();
  private onGroupChanged = new EventEmitter<string>();
  private onSearchChanged = new EventEmitter<string>();
  private onFrameworkChanged = new EventEmitter<string>();
  private reload = new BehaviorSubject<boolean>(false);

  constructor(
    private service: BenchmarkService,
    private frameworkService: FrameworkService,
    private context: UserContextService,
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngOnInit(): void {
    const paramsSubject = new Subject<Params>();

    this.subscriptions.push.apply(this.subscriptions, [
      this.onLevelChanged.subscribe((level) =>
        paramsSubject.next({ level: level || null })
      ),
      this.onGroupChanged.subscribe((group) =>
        paramsSubject.next({ group: group || null })
      ),
      this.onSearchChanged.subscribe((query) =>
        paramsSubject.next({ q: query || null })
      ),
      this.onFrameworkChanged.subscribe((framework) =>
        paramsSubject.next({ framework: framework || null })
      ),
      this.inputSwitchToggle.subscribe((enabled) => {
        paramsSubject.next({ completed: enabled || null });
      }),
      paramsSubject.subscribe((params: Params) => {
        this.router.navigate([], {
          queryParams: params,
          queryParamsHandling: 'merge',
        });
      }),
    ]);

    const filterParams = this.route.queryParams.pipe(
      map(({ completed, level, group, q, framework, task }) => ({
        completed,
        level,
        group,
        q,
        framework,
        task,
      }))
    );

    this.tasks$ = combineLatest([
      this.context.currentCompany$.pipe(filter(isNotNull)),
      filterParams,
      this.reload,
    ]).pipe(
      map(
        ([
          { id },
          { completed, level, group, q, framework, task },
          afterClose,
        ]) => {
          this.includeCompletedTasks = completed === 'true';
          return {
            companyId: id,
            includeCompleted: this.includeCompletedTasks,
            framework,
            level,
            group,
            q,
            activeTaskId: task,
            afterClose,
          };
        }
      ),
      switchMap(
        ({
          companyId,
          level,
          group,
          q,
          includeCompleted,
          framework,
          activeTaskId,
          afterClose,
        }) =>
          this.service
            .getTasks({
              companyId,
              level,
              group,
              includeCompleted,
              framework,
              query: q,
            })
            .pipe(
              map((tasks) => ({
                tasks,
                activeTaskId,
                afterClose,
                key: JSON.stringify({
                  level,
                  group,
                  q,
                  includeCompleted,
                  framework,
                }),
              }))
            )
      ),
      map(({ tasks, activeTaskId, afterClose, key }) => {
        if (!this.taskMap || key !== this.taskMapKey) {
          this.taskMap = buildLinkedTasks(tasks);
          this.taskMapKey = key;
        }

        if (activeTaskId && !afterClose) {
          this.displayTask(activeTaskId);
        }

        return tasks;
      }),
      shareReplay(1)
    );

    const groupOptions = this.service.groups.pipe(
      map((groups: Group[]): HeaderSelectListOption[] => {
        const result = groups.map((grp) => ({
          text: grp.name,
          value: grp.name,
        }));
        result.unshift({ text: 'All', value: '' });
        return result;
      })
    );

    const defaultFramework$ = this.frameworkService.selectListOptions$.pipe(
      map((frameworks) => frameworks.find((f) => f.isDefaultValue)),
      map((framework) => framework?.value),
      shareReplay(1)
    );

    this.controls$ = this.route.queryParams.pipe(
      switchMap((params) => {
        return defaultFramework$.pipe(
          map((defaultFramework) => {
            return [
              new HeaderSelectList(
                'benchmark-level',
                levelOptions(true),
                this.onLevelChanged,
                params.level ?? '',
                'Level'
              ),
              new HeaderSelectList(
                'bencharmk-group',
                groupOptions,
                this.onGroupChanged,
                params.group ?? '',
                'Group'
              ),
              new HeaderSelectList(
                'benchmark-framework',
                this.frameworkService.selectListOptions$,
                this.onFrameworkChanged,
                params.framework ?? defaultFramework,
                'Framework'
              ),
              new HeaderSearch({
                id: 'benchmark-task-search',
                label: 'Search',
                placeholder: 'by title',
                changed: this.onSearchChanged,
                value: params.q ?? undefined,
              }),
            ];
          })
        );
      })
    );
  }

  displayTask(id: string): void {
    const task = this.taskMap?.get(id);

    if (!task) {
      this.toastr.warning(
        'If the task is complete, try including completed tasks in the filters',
        'No visible task found'
      );
    }

    this.activeTask = copy(task);
  }

  move(to: ActiveTask | null) {
    if (!this.activeTask) {
      return;
    }

    this.saveIfChanged().then(() => {
      this.mode = 'active';
      if (to?.task.taskId) {
        this.displayTask(to.task.taskId);
      }
    });
  }

  toggleCompleted(checked: boolean) {
    this.inputSwitchToggle.next(checked);
  }

  private saveEntry(
    task: BenchmarkTask
  ): Observable<{ entryId: string | null; risk: TaskRisk }> {
    this.working = true;
    return this.service.putTaskEntry(task).pipe(
      first(),
      map(({ entryId, risk }) => {
        this.working = false;
        return {
          entryId,
          risk,
        };
      })
    );
  }

  private saveIfChanged(): Promise<void> {
    return new Promise((resolve) => {
      if (!this.activeTask) {
        resolve();
        return;
      }

      const activeTask = this.activeTask;
      const { task } = activeTask.current;
      const { taskId } = task;

      const stored = this.taskMap?.get(taskId);

      if (changed(stored?.current.task, this.activeTask.current.task)) {
        this.saveEntry(this.activeTask.current.task).subscribe(({ risk }) => {
          activeTask.current.task = {
            ...task,
            risk,
          };

          this.taskMap?.set(taskId, copy(activeTask));
          resolve();
        });
      } else {
        resolve();
      }
    });
  }

  saveAndClose(): void {
    if (!this.activeTask) {
      return;
    }

    this.saveIfChanged().then(() => {
      this.taskActive = false;
      this.close();
    });
  }

  toggleMode(mode: typeof this.mode): void {
    this.mode = mode;
  }

  resetMode() {
    this.mode = 'active';
  }

  close(): void {
    if (this.route.snapshot.queryParamMap.get('task')) {
      this.router.navigate([], {
        queryParams: {
          task: null,
        },
        queryParamsHandling: 'merge',
        replaceUrl: true,
      });
    }
    this.reload.next(true);
  }

  qualitySelected(task: BenchmarkTask): void {
    this.mode = 'active';
    task.risk = calculateRisk(task.importance, task.quality);
  }

  formatModifiedDate(date: string): string {
    return dayjs(date).local().format('ddd Do MMM YYYY h:mmaZ');
  }
}
