import { NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { FormsModule } from '@angular/forms';
import { IconsModule } from '@app/app-icons.module';
import { IconsModule as HeroIconsModule } from '@app/icons/icons.module';
import { DropdownModule } from 'primeng/dropdown';
import { RouterModule } from '@angular/router';
import { StylesModule } from '@app/styles/styles.module';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { CalendarModule } from 'primeng/calendar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SelectButtonModule } from 'primeng/selectbutton';

import { VideoPlayerComponent } from '@app/components/video-player/video-player.component';
import { SplitWordsPipe } from '@app/components/pipes/split-words.pipe';
import { RequiresRoleDirective } from '@app/components/requires-role/requires-role.directive';
import { SearchFilterPipe } from '@app/components/search-filter/search-filter.pipe';
import { HeaderBarComponent } from '@app/components/header-bar/header-bar.component';
import { LogoComponent } from '@app/components/logo/logo.component';
import { CompanySearchComponent } from '@app/components/company-search/company-search.component';
import { RoadmapComponent } from '@app/components/roadmap/roadmap.component';
import { FormLabelComponent } from '@app/components/form-label/form-label.component';
import { FormButtonsComponent } from '@app/components/form-buttons/form-buttons.component';
import { CompanySelectorComponent } from '@app/components/company-selector/company-selector.component';
import { AuthorizedComponent } from '@app/components/authorized/authorized.component';
import { BodyComponent } from '@app/components/body/body.component';
import { PageHeaderComponent } from '@app/components/page-header/page-header.component';
import { LogoutButtonComponent } from '@app/components/logout-button/logout-button.component';
import { BlockComponent } from './block/block.component';
import { FilterPipe } from './filter/filter.pipe';
import { DatePickerComponent } from '@app/components/date-picker/date-picker.component';
import { ControlListComponent } from '@app/components/page-header/control-list/control-list.component';
import { RiskLabelComponent } from '@app/components/risk-label/risk-label.component';
import { QualityLabelComponent } from '@app/components/quality-label/quality-label.component';
import { PluralPipe } from '@app/components/pipes/plural.pipe';
import { SubNavComponent } from '@app/components/sub-nav/sub-nav.component';
import { IndexComponent } from '@app/components/index/index.component';
import { LevelLabelComponent } from '@app/components/level-label/level-label.component';
import { ListViewComponent } from '@app/components/list-view/list-view.component';
import { ListRowComponent } from '@app/components/list-view/list-row/list-row.component';
import { ListColumnComponent } from '@app/components/list-view/list-row/list-column/list-column.component';
import { ListHeaderComponent } from './list-view/list-header/list-header.component';
import { HeaderColumnComponent } from './list-view/list-header/header-column/header-column.component';
import { EmptyListComponent } from '@app/components/list-view/list-row/empty-list/empty-list.component';
import { BodyCardComponent } from '@app/components/body-card/body-card.component';
import { QualitySelectorComponent } from './quality-selector/quality-selector.component';
import { ValidationErrorComponent } from './validation-error/validation-error.component';
import { SubscriptionBalanceComponent } from './subscription-balance/subscription-balance.component';
import { MultiSelectModule } from 'primeng/multiselect';

const types: Type<any>[] = [
  VideoPlayerComponent,
  SplitWordsPipe,
  RequiresRoleDirective,
  SearchFilterPipe,
  HeaderBarComponent,
  LogoComponent,
  CompanySearchComponent,
  RoadmapComponent,
  FormLabelComponent,
  FormButtonsComponent,
  CompanySelectorComponent,
  AuthorizedComponent,
  BodyComponent,
  PageHeaderComponent,
  LogoutButtonComponent,
  BlockComponent,
  FilterPipe,
  DatePickerComponent,
  ControlListComponent,
  RiskLabelComponent,
  QualityLabelComponent,
  PluralPipe,
  SubNavComponent,
  IndexComponent,
  LevelLabelComponent,
  ListViewComponent,
  ListRowComponent,
  ListColumnComponent,
  ListHeaderComponent,
  HeaderColumnComponent,
  EmptyListComponent,
  BodyCardComponent,
  QualitySelectorComponent,
  ValidationErrorComponent,
  SubscriptionBalanceComponent,
];

@NgModule({
  declarations: types,
  exports: types,
  imports: [
    CommonModule,
    YouTubePlayerModule,
    FormsModule,
    IconsModule,
    DropdownModule,
    RouterModule,
    StylesModule,
    BreadcrumbModule,
    CalendarModule,
    OverlayPanelModule,
    SelectButtonModule,
    HeroIconsModule,
    MultiSelectModule,
  ],
})
export class ComponentsModule {}
