import { Component, OnInit, EventEmitter } from '@angular/core';
import { ProjectsService, Project } from '@app/projects/projects.service';
import { GanttSpacing } from '@app/styles/gantt-span.directive';
import { UserContextService } from '@app/user-context.service';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { RoadmapService, CalendarLength } from '@app/roadmap/roadmap.service';
import {
  HeaderSelectList,
  HeaderControl,
  HeaderSelectListOption,
  HeaderButton,
} from '@app/components/page-header/controls';
import { ActivatedRoute, Router } from '@angular/router';
import { RoleName } from '@app/roles';
import { triggerBrowserDownload } from '@app/util';
import { getProjectState } from '@app/projects/project-status-map';
import { LoggingService } from '@app/logging/logging.service';
import { isNotNull } from '@app/shared';

type Month =
  | 'Jan'
  | 'Feb'
  | 'Mar'
  | 'Apr'
  | 'May'
  | 'Jun'
  | 'Jul'
  | 'Aug'
  | 'Sep'
  | 'Oct'
  | 'Nov'
  | 'Dec';

interface CalendarMonth {
  month: Month;
  year: number;
}

interface QueryParams {
  view?: CalendarLength;
}

const granularityOptions: Observable<HeaderSelectListOption[]> = of([
  {
    text: '12 Month',
    value: '12',
  },
  {
    text: '6 Month',
    value: '6',
  },
  {
    text: '3 Month',
    value: '3',
  },
]);

@Component({
  selector: 'cb-project-board',
  templateUrl: './project-board.component.html',
  styleUrls: ['./project-board.component.css'],
})
export class ProjectBoardComponent implements OnInit {
  projects$: Observable<Project[]>;
  months: Month[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  calendar: CalendarMonth[] = [];
  activeTaskListProjectId: string | undefined = undefined;

  activeProject: Project | null = null;
  projectDetailsVisible = false;

  actions$: Observable<HeaderControl[]> = of([]);
  monthGranularityChanged = new EventEmitter<string>();
  columnLength: CalendarLength = 12;

  get gridCols() {
    // done this way because dynamic classes do not prevent the class being purged during prod build
    return {
      'grid-cols-12': this.columnLength == 12,
      'grid-cols-6': this.columnLength == 6,
      'grid-cols-3': this.columnLength == 3,
    };
  }

  get lower(): Date {
    const { month, year } = this.calendar[0];
    const monthIndex = this.months.indexOf(month);
    return new Date(Date.UTC(year, monthIndex, 1));
  }

  get upper(): Date {
    const { month, year } = this.calendar[this.calendar.length - 1];
    const monthIndex = this.months.indexOf(month);
    return new Date(Date.UTC(year, monthIndex + 1, 0));
  }

  static cachedCalendar: CalendarMonth[];

  downloadButton: HeaderButton | null = null;

  constructor(
    private projectsService: ProjectsService,
    private roadmapService: RoadmapService,
    private context: UserContextService,
    private route: ActivatedRoute,
    private router: Router,
    private logger: LoggingService
  ) {
    this.monthGranularityChanged.subscribe((view) => {
      this.router.navigate([], {
        queryParams: { view },
        queryParamsHandling: 'merge',
      });
    });

    this.route.queryParams.subscribe((query) => {
      const params = <QueryParams>query;
      const view = params.view || 12;

      this.renderCalendar(view);
    });

    this.projects$ = this.context.currentCompany$.pipe(
      filter(isNotNull),
      switchMap(({ id }) =>
        this.projectsService.getProjects({ companyId: id })
      ),
      map((res) =>
        res.map((p) => {
          p.tasks = p.tasks || [];
          return p;
        })
      )
    );
  }

  private downloadRoadmap() {
    this.downloadButton?.startLoading();
    const companyId = this.context.currentCompanySnapshot?.id;

    this.roadmapService
      .downloadRoadmap(companyId!, this.lower, this.upper)
      .subscribe(
        (res: any) => {
          triggerBrowserDownload('Roadmap.pdf', res);
        },
        (error) => {
          this.logger.error('Error downloading Roadmap', error, {
            companyId,
            upper: this.upper,
            lower: this.lower,
          });
        }
      )
      .add(() => {
        this.downloadButton?.stopLoading();
        this.context.reloadSubscription();
      });
  }

  get calendarButtonStyles(): string {
    return 'p-2 border border-mid-gray rounded-md hover:shadow-md hover:bg-mid-gray transition';
  }

  private isCurrentMonth(period: CalendarMonth): boolean {
    const date = new Date();
    const month = this.months[date.getMonth()];
    return period.month === month && period.year === date.getFullYear();
  }

  getColumnStyles(period: CalendarMonth): string {
    return this.isCurrentMonth(period)
      ? 'bg-brand-primary bg-opacity-5 font-bold'
      : '';
  }

  ngOnInit(): void {
    this.downloadButton = new HeaderButton(
      'Download',
      'download',
      () => this.downloadRoadmap(),
      'RoadmapViewer',
      undefined,
      this.context.accountStatus$.pipe(
        map(({ accountChangePending }) => {
          return !accountChangePending;
        })
      )
    );

    this.actions$ = this.route.queryParams.pipe(
      map((params) => {
        const view = params.view || '12';
        return [
          new HeaderSelectList(
            'project-scope',
            granularityOptions,
            this.monthGranularityChanged,
            view,
            'View'
          ),
          this.downloadButton!,
        ];
      })
    );
  }

  private renderCalendar(length: CalendarLength): void {
    this.columnLength = length;
    const now = new Date();
    this.calendar = [];

    for (let i = 0; i < length; i += 1) {
      const date = new Date(now.getFullYear(), now.getMonth(), 1);
      date.setMonth(date.getMonth() + i);
      const month = this.months[date.getMonth()];
      const year = date.getFullYear();
      this.calendar.push({ month, year });
    }

    ProjectBoardComponent.cachedCalendar = [...this.calendar];
  }

  previous() {
    const first = this.calendar[0];
    this.calendar.pop();

    const monthIndex = this.months.indexOf(first.month);

    let date = new Date(first.year, monthIndex, 1);
    date.setMonth(date.getMonth() - 1);

    const month = this.months[date.getMonth()];
    const year = date.getFullYear();

    this.calendar.unshift({ month, year });
  }

  next() {
    const last = this.calendar[this.calendar.length - 1];
    this.calendar.shift();

    const monthIndex = this.months.indexOf(last.month);

    let date = new Date(last.year, monthIndex, 1);
    date.setMonth(date.getMonth() + 1);

    const month = this.months[date.getMonth()];
    const year = date.getFullYear();

    this.calendar.push({ month, year });
  }

  today() {
    this.calendar = [...ProjectBoardComponent.cachedCalendar];
  }

  hasMoved() {
    return (
      this.calendar[0].month !== ProjectBoardComponent.cachedCalendar[0].month
    );
  }

  getProjectColour(project: Project) {
    return getProjectState(project.status);
  }

  getProjectSpacing(project: Project): GanttSpacing | null {
    if (!project.startDate) {
      return null;
    }

    const startDate = new Date(
      Date.UTC(project.startDate.getFullYear(), project.startDate.getMonth())
    );
    const startMonthName = this.months[startDate.getMonth()];

    const endDate = new Date(
      Date.UTC(startDate.getFullYear(), startDate.getMonth() + project.duration)
    );

    const endMonth = this.months[endDate.getMonth()];

    let start = this.calendar.findIndex(
      (el) => el.month === startMonthName && el.year === startDate.getFullYear()
    );
    let end = this.calendar.findIndex(
      (el) => el.month === endMonth && el.year === endDate.getFullYear()
    );

    const fullSpan =
      startDate.getTime() <= this.lower.getTime() &&
      endDate.getTime() >= this.upper.getTime();

    if (start < 0 && end <= 0 && !fullSpan) {
      return null;
    }

    if (start < 0) {
      start = 0;
    }

    if (start >= 0 && (end > this.calendar.length || end < 0)) {
      end = this.calendar.length;
    }

    return { start, end };
  }

  toggleProjectTasks(project: Project) {
    this.activeTaskListProjectId =
      this.activeTaskListProjectId === project.id ? undefined : project.id;
  }

  toggleProjectDetail(project: Project | null) {
    this.activeProject = project;
    this.projectDetailsVisible = !!project;
  }
}
