import { formatCurrency } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { HttpErrorService } from '@app/services/http-error.service';
import { environment as env } from '@env';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

type Response = { label: string, budget: number, projectCount: number }[];

@Component({
  selector: 'cb-budget-card',
  templateUrl: './budget-card.component.html',
  styles: [
  ]
})
export class BudgetCardComponent implements OnInit {

  lineOptions = {
    stacked: false,
    maintainAspectRatio: false,
    aspectRatio: 1.5,
    scales: {
      x: {
        grid: {
          drawBorder: false
        }
      },
      y: {
        beginAtZero: false,
        display: true,
        position: "left",
        grid: {
          drawBorder: false
        },
        ticks: {
          callback: (value: number) => formatCurrency(value, "en-GB", "£", "GBP", "1.0-0"),
          color: "#00ab9e"
        }
      },
      y1: {
        beginAtZero: true,
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false
        },
        ticks: {
          color: "#e7b416",
          callback: (value: number) => {
            return (value % 1 === 0) ? value : undefined;
          }
        }
      }
    }
  };

  projectsData: any;
  graphData$: Observable<any> = of(undefined);

  constructor(
    private httpClient: HttpClient,
    private errorHandler: HttpErrorService
  ) { }

  ngOnInit(): void {
    const { baseUrl } = env.api;
    this.graphData$ = this.httpClient.get<Response>(`${baseUrl}/v1/metrics/budget`).pipe(
      this.errorHandler.handleHttpError,
      map((data) => ({
        labels: data.map(({ label }) => label),
        datasets: [
          {
            type: "line",
            label: 'Total budget',
            fill: false,
            tension: 0.3,
            data: data.map(({ budget }) => budget),
            backgroundColor: "#00ab9e",
            borderColor: "#00ab9e",
            yAxisID: 'y'
          },
          {
            type: "bar",
            label: "Number of projects",
            data: data.map(({ projectCount }) => projectCount),
            backgroundColor: "#e7b416",
            borderColor: "#e7b416",
            yAxisID: 'y1'
          }
        ]
      })),
    );
  }

}
