import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class FiltersActiveService {
    private activeFilters = new Set<string>();

    setActive(name: string): void {
        this.activeFilters.add(name);
    }

    activateAll(controlIds: string[]): void {
        this.activeFilters = new Set<string>(controlIds);
    }

    deactivate(name: string): void {
        this.activeFilters.delete(name);
    }

    hasActiveFilters(): boolean {
        return this.activeFilters.size > 0;
    }
}