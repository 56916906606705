<ng-container *ngIf="authenticated$ | async">
  <div
    (click)="toggleSideBar()"
    [class]="sidebar.isOpen ? 'fixed' : 'hidden'"
    class="z-20 inset-0 bg-black opacity-50 transition-opacity lg:hidden"
  ></div>
  <div
    [class]="
      sidebar.isOpen ? 'translate-x-0 ease-out' : '-translate-x-full ease-in'
    "
    class="fixed z-30 opacity-100 inset-y-0 left-0 w-64 transition duration-200 h-full transform bg-bright-gray lg:translate-x-0 lg:static lg:inset-0"
  >
    <cb-menu></cb-menu>
  </div>
</ng-container>
