import { Observable, Subject, timer } from "rxjs";
import { shareReplay, takeUntil } from "rxjs/operators";

const CACHE_TIMEOUT = 30000;

export class Cacheable<T> {
    private timer$: Observable<number> | null = null;
    private cache$: Observable<T> | null = null;
    private reload$ = new Subject();
    
    constructor(private source: Observable<T>){
    }

    get value(): Observable<T> {
        if(!this.cache$) {
            this.cache$ = this.source.pipe(
                takeUntil(this.reload$),
                shareReplay(1)
            )

            this.timer$ = timer(CACHE_TIMEOUT).pipe(
                takeUntil(this.reload$)
            );

            this.timer$.subscribe(_ => {
                this.cache$ = null;
            });
        }

        return this.cache$;
    }

    invalidate(): void {
        this.reload$.next();
        this.cache$ = null;
        this.timer$ = null;
    }
}