<cb-metric-card heading="Budget">
    <ng-container *ngIf="graphData$ | async as data; else loading">
        <p-chart [data]="data" [options]="lineOptions"></p-chart>
    </ng-container>
    <ng-template #loading>
        <div>
            <p-skeleton width="10rem" height="5rem"></p-skeleton>
        </div>
    </ng-template>

</cb-metric-card>