<ng-container *ngIf="task">
  <div class="flex gap-8 pb-20">
    <div class="flex-1">
      <h3 class="text-xl my-5">Description</h3>
      <p class="text-sm text-justify">
        {{ task.description }}
      </p>
    </div>
    <div class="flex-1">
      <h3 class="text-xl my-5">Quality Examples</h3>
      <dl class="divide-y divide-gray-300">
        <div class="px-4 pb-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt class="text-sm font-medium leading-6 text-rag-red">Poor</dt>
          <dd
            class="mt-1 text-sm text-justify leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
          >
            {{ task.examples?.lowQuality }}
          </dd>
        </div>
        <div class="px-4 pb-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt class="text-sm font-medium leading-6 text-rag-amber">OK</dt>
          <dd
            class="mt-1 text-sm text-justify leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
          >
            {{ task.examples?.mediumQuality }}
          </dd>
        </div>
        <div class="px-4 pb-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt class="text-sm font-medium leading-6 text-rag-green">Good</dt>
          <dd
            class="mt-1 text-sm text-justify leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
          >
            {{ task.examples?.highQuality }}
          </dd>
        </div>
        <div class="px-4 pb-1 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt class="text-sm font-medium leading-6 text-rag-green">
            Excellent
          </dt>
          <dd
            class="mt-1 text-sm text-justify leading-6 text-gray-700 sm:col-span-2 sm:mt-0"
          >
            {{ task.examples?.excellentQuality }}
          </dd>
        </div>
      </dl>
    </div>
  </div>
</ng-container>
