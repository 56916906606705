import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ProjectsService,
  Project,
  ProjectTask,
  ProjectStatus,
} from '@app/projects/projects.service';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  merge,
  Subject,
  combineLatest,
  of,
} from 'rxjs';
import { RoleService } from '@app/auth/roles/role.service';
import { RoleName } from '@app/roles';
import { ToastrService } from 'ngx-toastr';
import {
  map,
  switchMap,
  switchMapTo,
  shareReplay,
  tap,
  debounceTime,
} from 'rxjs/operators';
import { UserContextService } from '@app/user-context.service';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Risk } from '@app/benchmark/benchmark.model';
import { getProjectState, StatusLabel } from '../project-status-map';
import { SettingsService } from '@app/settings/settings.service';
import { FeaturesService } from '@app/features.service';
import { splitWords } from '@app/util/split-words';

interface Dictionary {
  [key: string]: number;
}

type PendingRequestCache = {
  [key: string]: ProjectTask[];
};

const tuple = <T extends Project['status'][]>(...args: T) => args;

@Component({
  selector: 'cb-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.css'],
})
export class ProjectDetailComponent implements OnInit, OnDestroy {
  project$!: Observable<Project>;
  tabIndex!: number;
  tabsActive$: Observable<boolean>;
  planningVisible$?: Observable<boolean>;

  projectStatus?: { text: string; value: ProjectStatus };

  statusValues$: Observable<{ text: string; value: ProjectStatus }[]>;

  taskFilter?: string | null = null;
  taskFilterDebounce = new Subject<string | null>();

  paramSubject = new Subject<{
    q?: string | null;
    tab?: 'tasks' | 'project' | null;
  }>();

  pendingTasks: ProjectTask[] = [];
  private cachedTasks: ProjectTask[] = [];

  private subscriptions: Subscription[] = [];

  private reload = new BehaviorSubject<void>(undefined);
  private reloadPendingTasks = new BehaviorSubject<never>(null as never);

  autoPushEnabled$: Observable<boolean> = of(false);
  pushToPsaEnabled$: Observable<boolean>;

  constructor(
    private route: ActivatedRoute,
    private service: ProjectsService,
    private router: Router,
    private roleService: RoleService,
    private toastr: ToastrService,
    private context: UserContextService,
    private settings: SettingsService,
    private features: FeaturesService
  ) {
    this.pushToPsaEnabled$ = this.context.myCompany$.pipe(
      switchMap(({ id }) => this.features.enabledForCompany('pushToPsa', id)),
      shareReplay(1)
    );

    this.tabsActive$ = this.roleService.userHasRole('RoadmapViewer');
    this.autoPushEnabled$ = this.settings.get('psa').pipe(
      map((x) => x.projectsAutoPush),
      shareReplay(1)
    );
    this.statusValues$ = this.service.projectStatusOptions$;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id')!;

    this.project$ = this.reload.pipe(
      switchMap(() => this.service.getProjectById(id)),
      tap((project) => {
        this.projectStatus = {
          text: splitWords(project.status),
          value: project.status,
        };
      }),
      tap((project) => (this.cachedTasks = [...project.tasks]))
    );

    this.subscriptions.push(
      this.reloadPendingTasks
        .pipe(
          switchMapTo(
            combineLatest([
              this.context.currentCompany$,
              this.route.queryParams,
            ])
          ),
          switchMap(([company, query]) =>
            this.loadPendingTasks(company!.id, query.q)
          ),
          shareReplay(1)
        )
        .subscribe((tasks) => {
          this.pendingTasks = tasks;
        })
    );

    const tabMap: Dictionary = {
      project: 0,
      tasks: 1,
    };

    this.subscriptions.push(
      this.route.queryParamMap.subscribe((params) => {
        const tab = params.get('tab') ?? 'project';
        this.tabIndex = tabMap[tab];
        this.taskFilter = params.get('q');
      })
    );

    this.subscriptions.push(
      this.taskFilterDebounce
        .pipe(debounceTime(500))
        .subscribe((value) => this.paramSubject.next({ q: value ?? null }))
    );

    this.subscriptions.push(
      this.paramSubject.subscribe((params) => {
        this.router.navigate([], {
          queryParams: params,
          queryParamsHandling: 'merge',
        });
      })
    );
  }

  private cache: PendingRequestCache = {};

  private loadPendingTasks(
    companyId: string,
    filterQuery: string | null
  ): Observable<ProjectTask[]> {
    const key = `${companyId}_${filterQuery ?? 'NULL'}`;
    if (key in this.cache) {
      return of(this.cache[key]);
    }

    return this.service.getPendingTasks(companyId, filterQuery).pipe(
      map((tasks) => {
        this.cache[key] = tasks;
        return tasks;
      })
    );
  }

  projectTasksHaveNotChanged(project: Project): boolean {
    var cachedSet = new Set<string>(this.cachedTasks.map((t) => t.entryId));
    var currentSet = new Set<string>(project.tasks.map((t) => t.entryId));
    return (
      cachedSet.size === currentSet.size &&
      [...cachedSet].every((s) => currentSet.has(s))
    );
  }

  resetProjectTasks(project: Project): void {
    project.tasks = [...this.cachedTasks];
    this.reloadPendingTasks.next(null as never);
  }

  saveProjectTasks(project: Project): void {
    this.subscriptions.push(
      this.service
        .updateProjectTasks(
          project.id!,
          project.tasks.map((t) => t.entryId)
        )
        .subscribe(() => {
          this.toastr.success('Tasks updated', 'Project');
          this.cachedTasks = [...project.tasks];
          this.cache = {};
          this.reloadPendingTasks.next(null as never);
        })
    );
  }

  getTasksLabel(project: Project): string {
    const changesMade = !this.projectTasksHaveNotChanged(project);
    return `Tasks (${project.tasks.length}${changesMade ? '*' : ''})`;
  }

  getDynamicTaskStyles(task: ProjectTask): string {
    const styles: string[] = [];

    switch (task.risk) {
      case Risk.VeryHigh:
      case Risk.High:
        styles.push('border-rag-red');
        break;
      case Risk.Medium:
        styles.push('border-rag-amber');
        break;
      default:
        styles.push('border-rag-green');
        break;
    }

    return styles.join(' ');
  }

  tabChange(tabIndex: number): void {
    const tab = tabIndex ? 'tasks' : 'project';
    this.paramSubject.next({ tab });
  }

  done(): void {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  removeTask(entryId: string): void {
    this.service.removeTaskFromProject(entryId).subscribe(() => {
      this.toastr.success('changes have been saved', 'Project updated');
      this.reload.next();
    });
  }

  itemDropped(event: CdkDragDrop<ProjectTask[]>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  assignTask(task: ProjectTask, project: Project) {
    const index = this.pendingTasks.findIndex(
      (t) => t.entryId === task.entryId
    );
    transferArrayItem(this.pendingTasks, project.tasks, index, index);
  }

  unAssignTask(task: ProjectTask, project: Project) {
    const index = project.tasks.findIndex((t) => t.entryId === task.entryId);
    transferArrayItem(project.tasks, this.pendingTasks, index, index);
  }

  taskFilterChanged(value: string, debounce: boolean): void {
    const q = value || null;

    this.cache = {};

    if (debounce) {
      this.taskFilterDebounce.next(q);
    } else {
      this.paramSubject.next({ q });
    }
  }

  selectedStatusState() {
    return getProjectState(this.projectStatus?.value);
  }

  projectModalVisible = false;
  onStatusChanged(project: Project): void {
    // automatic closing of tasks has been parked for now

    // if (this.projectStatus?.label === "Closed") {
    //   this.projectModalVisible = true;
    // }
    // else {
    this.projectModalVisible = false;
    this.saveProjectStatus(project);
    //}
  }

  noteEditing = new Set<string>();

  toggleNoteEditingFor(entryId: string) {
    if (this.noteEditing.has(entryId)) {
      this.noteEditing.delete(entryId);
    } else {
      this.noteEditing.add(entryId);
    }
  }

  isEditing(entryId: string): boolean {
    return this.noteEditing.has(entryId);
  }

  statusChangeDone(): void {
    this.noteEditing.clear();
    this.projectModalVisible = false;
  }

  manualTaskClosing(project: Project): boolean {
    return (
      this.projectStatus?.value === 'Completed' &&
      project.tasks.some((task) => {
        switch (task.risk) {
          case Risk.Low:
          case Risk.VeryLow:
            return false;
          default:
            return true;
        }
      })
    );
  }

  continueStatusChange(project: Project): void {
    this.saveProjectStatus(project);
  }

  sendToPsa(projectId: string): void {
    this.subscriptions.push(
      this.service.sendToPsa(projectId).subscribe(
        () => {
          this.reload.next();
          this.toastr.success('Project quote created');
        },
        (error) => {
          this.toastr.error(error.message, 'Failed to send to PSA');
        }
      )
    );
  }

  private saveProjectStatus(project: Project): void {
    if (!project.id) {
      return;
    }

    if (!this.projectStatus) {
      return;
    }

    this.subscriptions.push(
      this.service
        .updateProjectStatus(project.id, this.projectStatus.value)
        .pipe(tap(() => this.statusChangeDone()))
        .subscribe(
          () => {
            this.reload.next();
            this.toastr.success('Project status updated');
          },
          (error) => {
            this.toastr.error(error.message, 'Failed to save project status');
          }
        )
    );
  }
}
