import { Component, ContentChildren, Input, OnInit, QueryList } from '@angular/core';
import { HeaderColumnComponent } from './header-column/header-column.component';

@Component({
  selector: 'cb-list-header',
  templateUrl: './list-header.component.html',
  styles: [
  ]
})
export class ListHeaderComponent implements OnInit {


  @ContentChildren(HeaderColumnComponent)
  columns!: QueryList<HeaderColumnComponent>;

  @Input() clickable = true;

  get columnCount(): number {
    let counter = 0;
    for(const col of this.columns) {
      counter += col.span ?? 1;      
    }

    return counter;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
