import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[cbControlButton]'
})
export class ControlButtonDirective {

  styles = [
    'flex', 
    'justify-between', 
    'items-center', 
    'transform', 
    'hover:-translate-y-1', 
    'transition', 
    'duration-300',
    'ease-in-out', 
    'hover:opacity-80', 
    'p-2'
  ];

  @Input() colour: string = "brand-deep";

  @HostBinding("class")
  get css(): string {
    let classNames = [...this.styles, `text-${this.colour}`];
    return classNames.join(' ');
  }

  constructor() { }
}
