import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'cb-form-buttons',
  templateUrl: './form-buttons.component.html',
  styles: [
  ]
})
export class FormButtonsComponent implements OnInit {

  @Input() form!: FormGroup;
  @Input() returnTo?: string;
  @Output() cancel = new EventEmitter();

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  handleCancel(): void {
    this.cancel?.emit();

    if(this.returnTo){
      this.router.navigate([this.returnTo]);
    }
  }

}
