<div class="w-full xl:max-w-3xl">
    <form [formGroup]="form" novalidate (submit)="save()">
        <input type="hidden" formControlName="id">

        <div *ngIf="state.isDisabled" class="px-6 mb-10 rounded-md bg-brand-deep text-white shadow-md">
            <div class="flex items-center gap-4 py-6">
                <div>
                    <hero-icon name="exclamation-circle" type="outline" class="h-6 w-6"></hero-icon>
                </div>
                <div>
                    <h3 class="text-xl">Account disabled</h3>
                    <p>This user will not be able to log into their Clearbenchmark account</p>
                </div>
            </div>
        </div>

        <cb-block heading="Contact information">
            <div class="mb-5 flex gap-6">
                <div class="flex-1">
                    <cb-form-label for="name">
                        Name
                    </cb-form-label>
                    <input id="name" type="text" formControlName="name" maxlength="100" autofocus cbInput>
                    <div cbValidationMessage
                        *ngIf="form.get('name')?.errors?.required && (form.get('name')?.dirty || form.get('name')?.touched)">
                        Name is required
                    </div>
                    <div cbValidationMessage
                        *ngIf="form.get('name')?.errors?.maxLength && (form.get('name')?.dirty || form.get('name')?.touched)">
                        Must no more than 100 characters long
                    </div>
                </div>
                <div class="flex-1">
                    <cb-form-label for="position" [optional]="true">
                        Position
                    </cb-form-label>
                    <input id="position" type="text" formControlName="position" maxlength="100" cbInput>
                    <div cbValidationMessage
                        *ngIf="form.get('position')?.errors?.maxLength && (form.get('position')?.dirty || form.get('position')?.touched)">
                        Must no more than 100 characters long
                    </div>
                </div>
            </div>
            <div class="mt-5 mb-10">
                <cb-form-label for="email">
                    Email
                </cb-form-label>
                <input id="email" type="text" inputmode="email" formControlName="email" maxlength="255" cbInput>
                <div cbValidationMessage
                    *ngIf="form.get('email')?.errors?.required && (form.get('email')?.dirty || form.get('email')?.touched)">
                    Email is required
                </div>
                <div cbValidationMessage
                    *ngIf="form.get('email')?.errors?.maxLength && (form.get('email')?.dirty || form.get('email')?.touched)">
                    Must no more than 255 characters long
                </div>
                <div cbValidationMessage
                    *ngIf="form.get('email')?.errors?.email && (form.get('email')?.dirty || form.get('email')?.touched)">
                    Must be a valid email address
                </div>
            </div>
        </cb-block>

        <ng-container *cbRequiresRole="'UserManager'">
            <ng-container *ngIf="state.isContact || state.isCreate">
                <cb-block heading="Account type">
                    <p class="mb-5">Will {{form.get('name')?.value || "they"}} need access to Clearbenchmark?</p>
                    <div class="mb-2">
                        <p-radioButton name="accountType" [value]="false" label="No, contact only"
                            [(ngModel)]="selectedAccountAccess" [ngModelOptions]="{standalone: true}"
                            (onClick)="accountAccessChanged(false)">
                        </p-radioButton>
                    </div>
                    <div>
                        <p-radioButton name="accountType" [value]="true" label="Yes, they will need to log in"
                            [(ngModel)]="selectedAccountAccess" [ngModelOptions]="{standalone: true}"
                            (onClick)="accountAccessChanged(true)">
                        </p-radioButton>
                    </div>

                    <div class="mt-5 p-2">
                        <ng-container *ngIf="state.isUserAccount && (state.isCreate || state.pendingInvite)">
                            <p>An invitation email {{state.pendingInvite ? 'has been' : 'will be'}} sent to
                                {{form.get('email')?.value || 'them'}} containing a link which will allow them to create
                                a new user account.</p>
                        </ng-container>
                        <div class="mt-5" *ngIf="state.pendingInvite">
                            <button (click)="deleteUserInvite()" type="button" cbButton colour="bg-brand-danger">
                                Cancel invitation
                            </button>
                        </div>
                    </div>
                </cb-block>
            </ng-container>

            <ng-container *ngIf="state.isUserAccount">
                <ng-container *ngIf="context.currentCompany$ | async as company">

                    <cb-block *ngIf="company.type !== 'SME'" [disabled]="state.pendingInvite"
                        disabledMessage="Invitation Pending" heading="Company access"
                        subHeading="Here, you can assign access to companies that have already been setup">
                        <div class="mt-2 mb-5 px-2">
                            <div class="flex items-center justify-between">
                                <p>Companies listed here will be available to {{form.get("name")?.value || "them"}}</p>
                                <button type="button" (click)="showCompanyAccessModal()" cbButton
                                    title="Add access policy">
                                    <div class="flex justify-between items-center">
                                        <hero-icon name="plus" type="outline" class="w-5 h-5"></hero-icon>
                                        <span class="sr-only">Add access</span>
                                    </div>
                                </button>
                            </div>
                        </div>
                        <ul class="w-full max-h-60 overflow-y-auto" *ngIf="companies.length">
                            <li *ngFor="let access of companies"
                                class="px-4 py-2 flex justify-between transition group hover:bg-gray-50 cursor-pointer border-b border-b-gray-100">
                                <div class="text-sm">
                                    {{access.name}}
                                </div>
                                <button *cbRequiresRole="'TeamManager'" (click)="removePartnerAccess(access)"
                                    type="button"
                                    class="p-1 transition transform hover:scale-110 hover:text-brand-danger opacity-0 group-hover:opacity-100"
                                    [title]="'Remove access to ' + access.name">
                                    <hero-icon name="x" type="outline" class="w-5 h-5"></hero-icon>
                                    <span class="sr-only">Remove access to {{access.name}}</span>
                                </button>
                            </li>
                        </ul>
                    </cb-block>

                    <cb-block *ngIf="getRoleGroups$(company) | async as roles" [disabled]="state.pendingInvite"
                        disabledMessage="Invitation Pending" heading="Role"
                        [subHeading]="'What role will they have at ' + company.name + '?'">
                        <ng-container *ngIf="roles.length">
                            <div class="my-5 px-2 flex items-center justify-end">
                                <div>
                                    <button type="button" (click)="displayHelpModal()" title="show help information"
                                        class="text-brand-deep inline-block align-text-bottom pt-2 transition transform hover:scale-105">
                                        <hero-icon name="information-circle" type="outline" class="w-5 h-5"></hero-icon>
                                        <span class="sr-only">help</span>
                                    </button>
                                </div>
                            </div>
                            <div class="grid grid-cols-1 gap-6"
                                [class]="roles.length === 3 ? 'md:grid-cols-3' : 'md:grid-cols-2'">
                                <div *ngFor="let role of roles" (click)="selectRole(role)"
                                    class="px-6 py-4 border border-gray-300 transition transform hover:scale-105 rounded-md shadow-md cursor-pointer flex flex-col items-center justify-center"
                                    [ngClass]="{
                                        'bg-brand-primary': selectedRole?.value === role.value,
                                        'text-white': selectedRole?.value === role.value,
                                        'text-brand-secondary': selectedRole?.value !== role.value,
                                        'bg-white': selectedRole?.value !== role.value
                                    }">
                                    <div>
                                        <hero-icon [name]="role.icon" class="w-6 h-6" type="outline"></hero-icon>
                                    </div>
                                    <div>
                                        <h3 class="text-lg">
                                            {{role.value}}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </cb-block>

                </ng-container>
            </ng-container>
        </ng-container>

        <div class="mt-5 px-2 py-4 flex items-center justify-between w-full mx-auto">
            <div class="flex-1 flex gap-2 items-center">
                <div>
                    <button *ngIf="state.canDelete" type="button" cbButton colour="bg-brand-danger"
                        (click)="deleteUser(form.get('id')?.value)">
                        <div class="flex justify-between items-center gap-2 py-1">
                            <div>
                                <hero-icon name="trash" type="outline" class="h-6 w-6"></hero-icon>
                            </div>
                            <div>
                                Delete
                            </div>
                        </div>
                    </button>
                </div>
            </div>
            <div class="flex-1 flex gap-2 items-center justify-end">
                <div>
                    <button type="submit" cbButton [disabled]="!form.valid">
                        <div class="flex justify-between items-center gap-2 py-1">
                            <ng-container
                                *ngIf="!state.pendingInvite && (state.isCreate || state.isContact) && state.isUserAccount; else existingUser">
                                <div>
                                    <hero-icon name="paper-airplane" type="outline" class="h-6 w-6"></hero-icon>
                                </div>
                                <div>
                                    Invite
                                </div>
                            </ng-container>
                            <ng-template #existingUser>
                                <div>
                                    <hero-icon name="check" type="outline" class="h-6 w-6"></hero-icon>
                                </div>
                                <div>
                                    Save
                                </div>
                            </ng-template>
                        </div>
                    </button>
                </div>
                <div>
                    <button type="button" cbButton colour="bg-brand-deep" (click)="cancelClick()">
                        <div class="flex justify-between items-center gap-2 py-1">
                            <div>
                                <hero-icon name="x" type="outline" class="h-6 w-6"></hero-icon>
                            </div>
                            <div>
                                Cancel
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>


    </form>

</div>

<p-confirmDialog icon="pi pi-exclamation-triangle" [style]="{width: '50vw'}" rejectButtonStyleClass="p-button-outlined">
</p-confirmDialog>

<p-sidebar [(visible)]="companyAccessModalOpen" (onShow)="ensureCompaniesInitialised()" styleClass="p-sidebar-md">
    <div class="w-full">
        <h3 class="text-lg md:text-xl text-center mb-5 md:mb-10 text-brand-primary">Select a partner company</h3>
        <div class="px-10 max-h-full overflow-y-auto">
            <div class="mb-2 py-1 md:mb-5 flex items-center justify-center">
                <input type="text" cbInput placeholder="search" [(ngModel)]="partnerFilter">
            </div>
            <ul
                *ngIf="availableCompanies$ | async | filter:'name':partnerFilter | filter:'ownerCompanyId':currentCompanyId as companies">
                <li *ngFor="let partner of companies" (click)="partnerCompanySelected(partner)"
                    class="px-4 py-2 cursor-pointer transition hover:bg-brand-primary hover:text-white">
                    {{partner.name}}
                </li>
            </ul>
            <ng-template>
                <div class="pt-10 text-lg">
                    Loading...
                </div>
            </ng-template>
        </div>
    </div>
</p-sidebar>

<p-dialog [modal]="true" [(visible)]="showHelp" header="Choose the right role">
    <div class="w-full">

        <div class="grid grid-cols-1 md:grid-cols-3">

            <div class="px-6">
                <div class="flex items-center justify-center mb-10">
                    <div>
                        <hero-icon name="user" class="w-6 h-6" type="outline"></hero-icon>
                    </div>
                    <div>
                        <h3 class="text-lg">
                            User
                        </h3>
                    </div>
                </div>
                <div class="w-full px-8">
                    <ul class="list-disc">
                        <li>view the company overview</li>
                        <li>view current benchmark</li>
                        <li>manage current benchmark task entries</li>
                        <li>view the current benchmark report</li>
                        <li>view the road map</li>
                        <li>view document templates</li>
                        <!--todo if MSP-->
                        <li>view and manage benchmarks for companies they have been granted access to (MSP)</li>
                        <li>download benchmark report for companies they have been granted access to (MSP)</li>
                    </ul>
                </div>
            </div>

            <div class="px-6">
                <div class="flex items-center justify-center mb-10">
                    <div>
                        <hero-icon name="office-building" class="w-6 h-6" type="outline"></hero-icon>
                    </div>
                    <div>
                        <h3 class="text-lg">
                            Manager
                        </h3>
                    </div>
                </div>
                <div class="w-full px-8">
                    <ul class="list-disc">
                        <li>everything a user can do plus...</li>
                        <li>manage users</li>
                        <li>view historical benchmark reports</li>
                        <li>download benchmark reports</li>
                        <li>manage the planning road map</li>
                        <li>manage document templates</li>
                        <!--todo if MSP-->
                        <li>add partner companies (MSP)</li>
                        <li>manage users for partner companies (MSP)</li>
                        <li>manage partner companies they have added (MSP)</li>
                        <li>manage partner companies they have been granted access to (MSP)</li>
                        <li>grant access to other team members for companies they own (MSP)</li>
                    </ul>
                </div>
            </div>

            <div class="px-6">
                <div class="flex items-center justify-center mb-10">
                    <div>
                        <hero-icon name="globe" class="w-6 h-6" type="outline"></hero-icon>
                    </div>
                    <div>
                        <h3 class="text-lg">
                            Admin
                        </h3>
                    </div>
                </div>
                <div class="w-full px-8">
                    <ul class="list-disc">
                        <li>everything a manager can do plus...</li>
                        <li>view and manage all information added by team members</li>
                        <!--todo if MSP-->
                        <li>manage all partner companies (MSP)</li>
                        <li>grant access to other team members for all partner companies (MSP)</li>
                    </ul>
                </div>
            </div>

        </div>

    </div>
</p-dialog>