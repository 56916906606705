import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleGuard } from '@app/auth/roles/role.guard';
import { TasksListComponent } from './tasks-list/tasks-list.component';

export const BenchmarkRoutes: Routes = [
  RoleGuard.build('benchmark', TasksListComponent, ['BenchmarkViewer']),
];

@NgModule({
  imports: [RouterModule],
  exports: [RouterModule],
})
export class BenchmarkRoutingModule {}
