import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { CompaniesListService } from '@app/companies/companies-list/companies-list.service';
import { Observable, of, Subscription } from 'rxjs';
import { tap, switchMap, first, startWith } from 'rxjs/operators';
import { Company } from '@app/domain/model';
import { StoredCompany, UserContextService } from '@app/user-context.service';

@Component({
  selector: 'cb-company-selector',
  templateUrl: './company-selector.component.html',
  styles: [
  ]
})
export class CompanySelectorComponent implements OnInit, OnDestroy {

  @Output() contextChanged = new EventEmitter<string | null>();

  companies$!: Observable<Company[]>;
  selectedCompany: StoredCompany | null = null;
  contextSwitchAvailable = false;

  private sub?: Subscription;

  constructor(
    private service: CompaniesListService,
    private context: UserContextService
  ) { }

  ngOnInit(): void {
    this.sub = this.context.currentCompany$.subscribe((company) => {
      this.selectedCompany = company;      
    });

    this.companies$ = this.service.companies$.pipe(
      tap(companies => {
        this.contextSwitchAvailable = companies.length > 1;
      })
    );    
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  switchContext(): void {
    this.contextChanged.emit(this.selectedCompany?.id);

    if(this.selectedCompany){
      this.context.currentCompany$.next(this.selectedCompany);
    }    
  }
}
