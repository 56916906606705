<div class="max-w-xl w-full mx-auto mb-10">
    <cb-logo theme="dark"></cb-logo>
</div>

<ng-template #invalid>
    <div class="p-4 rounded-md shadow-md border border-gray-300 bg-white max-w-xl w-full mx-auto">
        <h2 class="text-xl text-brand-deep mb-5">Invitation Expired</h2>
        <p class="mb-2">It looks like that invitation has expired or can't be found</p>
        <p>Please contact your system administrator to get another invitation sent to you.</p>
    </div>
</ng-template>

<div class="px-1 lg:px-4 py-6" *ngIf="invitation$ | async as invitation; else invalid">
    <div class="w-full px-1 lg:px-6">
        <div class="p-4 rounded-md shadow-md border border-gray-300 bg-white max-w-xl w-full mx-auto">
            <p class="max-w-prose leading-relaxed" *ngIf="invitation.invitedBy">
                Welcome to Clearbenchmark! You have been invited to join as a member of {{invitation.companyName}} by
                {{invitation.invitedBy}}
            </p>
            <p class="max-w-prose leading-relaxed" *ngIf="!invitation.invitedBy">
                Welcome to Clearbenchmark! Your new {{invitation.companyName}} account is waiting for you
            </p>
            <p class="max-w-prose leading-relaxed mt-3">Please set a password and confirm your name to get started</p>
            <form [formGroup]="form" novalidate (ngSubmit)="acceptInvitation()" autocomplete="off">
                <div class="mb-5 mt-10">
                    <label for="name" cbLabel>Name</label>
                    <div class="flex gap-4">
                        <div class="flex-1">
                            <input id="name" placeholder="First name" type="text" formControlName="firstName" cbInput>
                            <cb-validation-error
                                *ngIf="form.get('firstName')?.errors?.required && (form.get('firstName')?.dirty || form.get('firstName')?.touched)">
                                Please enter your first name
                            </cb-validation-error>
                            <cb-validation-error
                                *ngIf="form.get('firstName')?.errors?.maxlength && (form.get('firstName')?.dirty || form.get('firstName')?.touched)">
                                Your first name cannot be more than 100 characters
                            </cb-validation-error>
                        </div>
                        <div class="flex-1">
                            <input id="last_name" placeholder="Last name" type="text" formControlName="lastName"
                                cbInput>
                            <cb-validation-error
                                *ngIf="form.get('lastName')?.errors?.required && (form.get('lastName')?.dirty || form.get('lastName')?.touched)">
                                Please enter your last name
                            </cb-validation-error>
                            <cb-validation-error
                                *ngIf="form.get('lastName')?.errors?.maxlength && (form.get('lastName')?.dirty || form.get('lastName')?.touched)">
                                Your last name cannot be more than 100 characters
                            </cb-validation-error>
                        </div>
                    </div>
                </div>
                <div class="mb-5">
                    <label for="password">Create a password</label>
                    <input type="password" placeholder="Password" autofocus id="password" formControlName="password"
                        cbInput>
                    <cb-validation-error
                        *ngIf="form.get('password')?.errors?.required && (form.get('password')?.dirty || form.get('password')?.touched)">
                        Please enter a password
                    </cb-validation-error>
                    <cb-validation-error
                        *ngIf="form.get('password')?.errors?.minLength && (form.get('password')?.dirty || form.get('password')?.touched)">
                        Your password must be at least 8 charaters
                    </cb-validation-error>
                    <cb-validation-error
                        *ngIf="form.get('password')?.errors?.pattern && (form.get('password')?.dirty || form.get('password')?.touched)">
                        Your password does not match the rules below
                    </cb-validation-error>
                    <div class="mt-5 text-sm text-brand-deep">
                        <p class="mb-2">Passwords must:</p>
                        <ul class="list-disc pl-10">
                            <li>Be at least 8 characters long</li>
                            <li>Contain at least 1 uppercase and lowercase letter</li>
                            <li>Contain at least 1 number or special character</li>
                        </ul>
                    </div>
                </div>
                <div class="mb-5 flex justify-start gap-4">
                    <div class="flex-1">
                        <label for="tandc">
                            I have read and accept the <a class="text-brand-deep underline hover:no-underline"
                                href="https://clearbenchmark.com/terms" target="_blank">terms and conditions</a>
                        </label>
                        <cb-validation-error
                            *ngIf="form.get('terms')?.errors?.pattern && (form.get('terms')?.dirty || form.get('terms')?.touched)">
                            Please accept the terms and conditions
                        </cb-validation-error>
                    </div>
                    <div class="flex-none">
                        <input type="checkbox" class="p-2" id="tandc" formControlName="terms">
                    </div>
                </div>
                <div class="mb-5 flex justify-start gap-4">
                    <div class="flex-1">
                        <label for="privacy">
                            I have read and understood the <a class="text-brand-deep underline hover:no-underline"
                                href="https://clearbenchmark.com/privacy" target="_blank">privacy policy</a>
                        </label>
                        <cb-validation-error
                            *ngIf="form.get('privacy')?.errors?.pattern && (form.get('privacy')?.dirty || form.get('privacy')?.touched)">
                            Please confirm you have read our privacy policy
                        </cb-validation-error>
                    </div>
                    <div class="flex-none">
                        <input type="checkbox" class="p-2" id="privacy" formControlName="privacy">
                    </div>
                </div>
                <div class="flex justify-end items-center">
                    <button type="submit" cbButton colour="bg-brand-primary" [disabled]="!form.valid">Accept</button>
                </div>
            </form>
        </div>
    </div>
</div>