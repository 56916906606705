import { Component, OnInit, Input } from '@angular/core';
import { TastStatusService } from './tast-status.service';
import { Level } from '@app/components/level-label/level-label.component';

@Component({
  selector: 'cb-task-status',
  templateUrl: './task-status.component.html',
  styles: [],
})
export class TaskStatusComponent implements OnInit {
  @Input() companyId!: string;
  @Input() header?: string;

  data: any;
  dataOptions: any;
  dataAvailable = true;
  loading = false;

  constructor(private service: TastStatusService) {}

  async ngOnInit(): Promise<void> {
    this.loading = true;

    const taskByStatusResult = await this.service.getTasksByStatus(
      this.companyId
    );

    this.loading = false;
    this.dataAvailable = taskByStatusResult.available;

    this.data = {
      labels: ['Identified', 'Planned', 'Completed'],
      datasets: [
        {
          data: [
            taskByStatusResult.unplanned,
            taskByStatusResult.planned,
            taskByStatusResult.completed,
          ],
          label: 'Number of tasks',
          backgroundColor: ['#cc3232', '#e7b416', '#99c140'],
        },
      ],
    };

    this.dataOptions = {
      plugins: {
        legend: {
          display: false,
        },
      },
    };
  }
}
