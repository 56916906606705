import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[cbSelect]',
})
export class SelectDirective {
  styles = [
    'block',
    'min-w-full',
    'py-2',
    'px-3',
    'border',
    'border-brand-cool',
    'rounded-md',
    'shadow-sm',
    'focus:outline-none',
    'focus:ring-brand-primary',
    'focus:border-brand-primary',
    'sm:text-sm',
    'disabled:opacity-40',
    'disabled:cursor-not-allowed',
  ];

  constructor() {}

  @HostBinding('class')
  get elementStyle(): string {
    return this.styles.join(' ');
  }
}
