<cb-authorized>
    <cb-body>
        <cb-page-header [showCurrentCompany]="false" [controls]="(controls$ | async)">
            Partners
        </cb-page-header>

        <cb-list-view [items$]="companies$">
            <ng-template let-company>
                <cb-list-row [routerLink]="['../company', company.id]" [tags]="company._listTags">
                    {{company.name}}
                </cb-list-row>
            </ng-template>
        </cb-list-view>

    </cb-body>
</cb-authorized>