import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from '@env';
import { HttpErrorService } from '@app/services/http-error.service';

const { baseUrl } = env.api;
const settingsUrl = `${baseUrl}/v1/settings`;

export type Settings = {
  psa: {
    projectsEmail: string;
    projectsAutoPush: boolean;
  };
};

export type Scope = keyof Settings;

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(
    private httpClient: HttpClient,
    private errorHandler: HttpErrorService
  ) {}

  get(scope: Scope): Observable<Settings[typeof scope]> {
    const url = `${settingsUrl}/${scope}`;
    return this.httpClient
      .get<Settings[typeof scope]>(url)
      .pipe(this.errorHandler.handleHttpError);
  }

  put(scope: Scope, settings: Settings[typeof scope]): Observable<void> {
    const url = `${settingsUrl}/${scope}`;
    return this.httpClient
      .put<void>(url, settings)
      .pipe(this.errorHandler.handleHttpError);
  }

  delete(scope: Scope): Observable<void> {
    const url = `${settingsUrl}/${scope}`;
    return this.httpClient
      .delete<void>(url)
      .pipe(this.errorHandler.handleHttpError);
  }
}
