<div class="py-5 px-2 flex justify-end items-center min-w-full">
    <div class="pr-1">
        <button type="submit" [disabled]="!form.valid" cbButton>
            Save
        </button>
    </div>
    <div class="pl-1">
        <button type="button" (click)="handleCancel()" cbButton colour="bg-brand-deep">
            Cancel
        </button>        
    </div>
</div>
