import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { isNotNull } from '@app/shared';
import { AppInjector } from '@app/app-injector';
import { LoggingService } from '@app/logging/logging.service';
import { UserContextService } from '@app/user-context.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorService {

  constructor(
  ) { }

  handleHttpError<T>(source: Observable<T>): Observable<NonNullable<T>> {
    return source.pipe(
      catchError((err: HttpErrorResponse) => {
        const toastr = AppInjector.get(ToastrService);
        const logger = AppInjector.get(LoggingService);
        const context = AppInjector.get(UserContextService);
        const router = AppInjector.get(Router);

        logger.error("Http error response", err);

        if (err.status === 402) {
          context.accountStatus$.subscribe(({ currentPlanId }) => {
            router.navigate([], {
              queryParams: { upgrade: currentPlanId },
              queryParamsHandling: "merge"
            });
          });
        }
        else {
          toastr.error(err.error?.message || "Something went wrong, sorry!", `Code: ${err.status}`);
        }

        return of("ERR");
      }),
      filter((res) => res !== "ERR"),
      map((res) => res as NonNullable<T>)
    );
  }
}
