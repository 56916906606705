import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { 
  HeroIconModule, 
  exclamation,
  arrowLeft,
  badgeCheck,
  lightBulb,
  paperAirplane,
  search
} from 'ng-heroicon';

@NgModule({
  declarations: [],
  exports: [HeroIconModule],
  imports: [
    CommonModule,
    HeroIconModule.withIcons( {
      exclamation,
      arrowLeft,
      badgeCheck,
      lightBulb,
      paperAirplane,
      search
    })
  ]
})
export class BenchmarkIconsModule { }
