import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpErrorService } from '@app/services/http-error.service';
import { Observable } from 'rxjs';
import { environment as env } from '@env';
import { map } from 'rxjs/operators';
import { ProductId } from '@app/shared/products';

export type Customer = {
  id: string;
  name: string;
  clients: number;
  documents: number;
  lastActivity: {
    lastActive: string;
    fullName: string;
  };
  plan: string;
}

export type CustomerForm = {
  name: string;
  companyNumber?: string;
  domain: string;
  productId: ProductId;
  country: string;
}

@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  constructor(
    private httpClient: HttpClient,
    private errorHandler: HttpErrorService
  ) { }

  getCustomers(filter?: string): Observable<Customer[]> {
    const { baseUrl } = env.api;

    const params = [
      ...(filter ? [`filter=${encodeURIComponent(filter)}`] : [])
    ];

    let url = `${baseUrl}/v1/customers`;

    if (params.length) {
      url += `?${params.join("&")}`;
    }

    return this.httpClient.get<Customer[]>(url).pipe(
      this.errorHandler.handleHttpError
    );
  }

  addCustomer(customer: CustomerForm): Observable<string> {
    const { baseUrl } = env.api;
    const url = `${baseUrl}/v1/customers`;
    return this.httpClient.post<{ id: string }>(url, customer).pipe(
      this.errorHandler.handleHttpError,
      map((res) => res.id)
    )
  }
}
