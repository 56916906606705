import { Component, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  HeaderButton,
  HeaderControl,
} from '@app/components/page-header/controls';

@Component({
  selector: 'cb-users-view',
  templateUrl: './users-view.component.html',
  styles: [],
})
export class UsersViewComponent implements OnInit {
  addButtonEmitter = new EventEmitter<void>();
  controls: HeaderControl[];
  companyId!: string;
  contactDialogueVisible = false;

  constructor(private router: Router, private route: ActivatedRoute) {
    this.controls = [
      new HeaderButton('Add new', 'plus-circle', () =>
        this.router.navigate(['../users/add'], { relativeTo: this.route })
      ),
    ];
  }

  ngOnInit(): void {}
}
