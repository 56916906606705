import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  Category,
  Group,
  Risk,
  Importance,
  Quality,
  TaskRisk,
} from '@app/benchmark/benchmark.model';
import { CollectionResponse } from '@app/domain/model';
import { Observable, throwError } from 'rxjs';
import { map, catchError, shareReplay } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { environment as env } from '@env';
import { CacheService } from '@app/cache.service';
import { Cacheable } from '@app/cacheable';
import { HttpErrorService } from '@app/services/http-error.service';
import { Contact } from '@app/users/users.service';
import { TaskQuality } from './tasks-list/tasks-list.component';

type RiskColour = 'bg-rag-red' | 'bg-rag-amber' | 'bg-rag-green';

export interface BenchmarkTask {
  taskId: string;
  title: string;
  summary: string;
  description: string | null;
  level: number;
  group: string;
  category: string;
  importance: Importance;
  videoId: string;
  tags: string[];
  examples?: TaskExamples | null;
  createdDate: Date;
  entryId: string | null;
  companyId: string | null;
  quality: TaskQuality;
  notes: string | null;
  internalNotes: string | null;
  risk: TaskRisk;
  riskColour: string;
  isComplete: boolean;
  lastUpdatedBy?: string;
  lastUpdatedDate?: string;
}

export interface GroupedTask {
  groupName: string;
  data: BenchmarkTask[];
}

interface TaskEntry {
  entryId: string | null;
  taskId: string;
  companyId?: string;
  quality: TaskQuality;
  notes: string;
  internalNotes?: string | null;
}

type TaskEntryResponse = TaskEntry & {
  risk: TaskRisk;
};

interface TaskExamples {
  lowQuality?: string;
  mediumQuality?: string;
  highQuality?: string;
  excellentQuality?: string;
}

interface CreateResponse {
  id: string;
}

export interface BenchmarkTaskRequest {
  companyId: string;
  level?: string | number | null;
  group?: string | null;
  query?: string | null;
  includeCompleted?: boolean;
  framework?: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class BenchmarkService {
  constructor(
    private httpClient: HttpClient,
    private toastr: ToastrService,
    private cacheService: CacheService,
    private errorService: HttpErrorService
  ) {
    this.cacheService.add('groups', this.groupCache);
    this.cacheService.add('categories', this.categoryCache);
  }

  private groupCache = new Cacheable(this.requestGroups());
  private categoryCache = new Cacheable(this.requestCategories());

  get groups(): Observable<Array<Group>> {
    return this.groupCache.value;
  }

  get categories(): Observable<Array<Category>> {
    return this.categoryCache.value;
  }

  private requestGroups(): Observable<Array<Group>> {
    return this.httpClient
      .get<CollectionResponse<Group>>(
        `${env.api.baseUrl}/v${env.api.version}/groups`
      )
      .pipe(
        map((x) => x.result),
        catchError(this.handleError)
      );
  }

  private requestCategories(): Observable<Array<Category>> {
    return this.httpClient
      .get<CollectionResponse<Category>>(
        `${env.api.baseUrl}/v${env.api.version}/categories`
      )
      .pipe(
        map((x) => x.result),
        catchError(this.handleError)
      );
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    this.toastr.error(err.message, err.name);
    return throwError(err);
  }

  addCategory(name: string): Observable<Category> {
    const url = `${env.api.baseUrl}/v${env.api.version}/management/categories`;
    let cat: Category = {
      name: name,
    };
    return this.httpClient.post<CreateResponse>(url, cat).pipe(
      catchError(this.handleError),
      map((res) => {
        cat.id = res.id;
        return cat;
      })
    );
  }

  addGroup(group: Group): Observable<Group> {
    const url = `${env.api.baseUrl}/v${env.api.version}/management/groups`;
    return this.httpClient.post<CreateResponse>(url, group).pipe(
      catchError(this.handleError),
      map((res) => {
        group.id = res.id;
        return group;
      })
    );
  }

  putTaskEntry(task: BenchmarkTask): Observable<TaskEntryResponse> {
    const url = `${env.api.baseUrl}/v${env.api.version}/entries`;
    const { entryId, taskId, quality, notes, internalNotes } = task;

    const entry: TaskEntry = {
      entryId,
      taskId,
      quality,
      notes: notes || '',
      internalNotes,
    };

    return this.httpClient
      .put<TaskEntryResponse>(url, entry)
      .pipe(this.errorService.handleHttpError);
  }

  getTasks(req: BenchmarkTaskRequest): Observable<GroupedTask[]> {
    const { companyId, level, group, query, includeCompleted, framework } = req;
    let url = `${env.api.baseUrl}/v1/benchmark`;

    let params: string[] = [
      `companyId=${encodeURIComponent(companyId)}`,
      `complete=${includeCompleted ?? false}`,
    ];

    if (level) {
      params.push(`level=${encodeURIComponent(level)}`);
    }

    if (group) {
      params.push(`group=${encodeURIComponent(group)}`);
    }

    if (query) {
      params.push(`q=${encodeURIComponent(query)}`);
    }

    if (framework) {
      params.push(`framework=${encodeURIComponent(framework)}`);
    }

    if (params.length) {
      url += `?${params.join('&')}`;
    }

    return this.httpClient.get<CollectionResponse<GroupedTask>>(url).pipe(
      this.errorService.handleHttpError,
      map((response) => response.result),
      map((group) =>
        group.map((grp) => {
          grp.data = grp.data.map((item) => {
            if (item.risk) {
              item.riskColour = this.getRiskDisplayColour(item.risk);
            }
            return item;
          });
          return grp;
        })
      )
    );
  }

  private getRiskDisplayColour(risk: TaskRisk): string {
    if (risk === 'High' || risk === 'VeryHigh') {
      return 'bg-rag-red';
    }

    if (risk === 'Medium') {
      return 'bg-rag-amber';
    }

    return 'bg-rag-green';
  }

  shareReport(companyId: string, contacts: Contact[]): Observable<void> {
    const { baseUrl } = env.api;
    const url = `${baseUrl}/v1/${encodeURIComponent(companyId)}/report/share`;
    return this.httpClient
      .post<void>(url, { contacts })
      .pipe(this.errorService.handleHttpError);
  }
}
