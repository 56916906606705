<div cdkDrag class="cb-task-item px-4 py-2 bg-white border rounded-md cursor-pointer hover:shadow-md" [class]="borderColour(task.risk)">
    <div class="flex justify-between items-start">
        <div class="text-xs">level {{task.level}}</div>
        <cb-risk-label [risk]="task.risk" [iconOnly]="true"></cb-risk-label>
    </div>

    <h3 class="text-lg">
        {{task.title}}
    </h3>      
    
</div>