<ng-template #dash>
    &ndash;
</ng-template>
<cb-authorized>
    <cb-body *ngIf="context.myCompany$ | async as company">

        <cb-page-header [actions]="controls$ | async" [showCurrentCompany]="false" [spaceBelow]="false"
            subHeading="Dashboard">
            {{company.name}}
        </cb-page-header>
        <cb-subscription-balance type="clients" countProp="clientCount"
            totalProp="maxClientsAllowed"></cb-subscription-balance>
        <div class="grid grid-cols-3 gap-6">
            <div class="col-span-3">
                <cb-body-card>
                    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
                        <div class="lg:col-span-1">
                            Pie here
                        </div>
                        <div class="lg:col-span-3">
                            <mat-tab-group mat-align-tabs="left" dynamicHeight animationDuration="350ms">
                                <mat-tab label="Latest Clients">

                                    <div class="flex items-center justify-end mb-6 p-4">
                                        <div>
                                            <input id="client_search" [ngModel]="filter | async"
                                                (ngModelChange)="filter.next($event)" type="search"
                                                placeholder="filter by client name" cbInput>
                                        </div>
                                    </div>

                                    <div>

                                    </div>
                                </mat-tab>
                                <mat-tab label="Recent Activity">
                                    <div class="py-6">
                                        <div class="flex items-center mb-4 px-4">
                                            <!-- TODO <div>
                                                                    <label cbLabel>Filter by client</label>
                                                                    <select cbSelect>
                                                                        <option>All</option>                                            
                                                                    </select>
                                                                </div> -->
                                        </div>
                                        <cb-list-view mode="embedded">
                                            <cb-list-header [clickable]="false">
                                                <cb-header-column>Activity</cb-header-column>
                                                <cb-header-column>Client</cb-header-column>
                                                <cb-header-column>Date</cb-header-column>
                                            </cb-list-header>
                                            <ng-container *ngIf="activityList$ | async as activity">
                                                <ng-container *ngIf="activity.length; else emptyActivity">
                                                    <cb-list-row [clickable]="false" *ngFor="let activity of activity">
                                                        <cb-list-column>
                                                            <div>
                                                                <span *ngIf="activity.mood === 'Celebration'">
                                                                    🎉
                                                                </span>
                                                                {{activity.title}}
                                                            </div>
                                                            <div *ngIf="activity.riskScore" class="text-sm">
                                                                Score: {{activity.riskScore}}
                                                            </div>
                                                            <div class="text-xs">
                                                                {{activity.detail}}
                                                            </div>
                                                        </cb-list-column>
                                                        <cb-list-column>{{activity.clientName}}</cb-list-column>
                                                        <cb-list-column>{{activity.createdDate | date:'dd MMM YYYY'}}
                                                        </cb-list-column>
                                                    </cb-list-row>
                                                </ng-container>
                                            </ng-container>
                                            <ng-template #emptyActivity>
                                                <cb-empty-list>
                                                    <div class="text-xl">
                                                        No activity recorded yet
                                                    </div>
                                                </cb-empty-list>
                                            </ng-template>

                                        </cb-list-view>
                                    </div>
                                </mat-tab>
                            </mat-tab-group>
                        </div>
                    </div>


                </cb-body-card>
            </div>

            <!-- <cb-body-card>
                <div class="mb-4 text-center text-2xl">
                    69 clients
                </div>
                <div>
                    <p-chart type="pie" [data]="clientsPieData"></p-chart>
                </div>
            </cb-body-card>

            <div class="col-span-2">
                <cb-body-card>
                    <div class="flex justify-center items-center">
                        <div class="text-xl">
                            Total projects budget for all clients by month
                        </div>
                    </div>
                    <p-chart type="bar" [data]="budgetChartData"></p-chart>                
                </cb-body-card>            
            </div> -->
        </div>

    </cb-body>
</cb-authorized>