import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Level } from '@app/components/level-label/level-label.component';
import { HttpErrorService } from '@app/services/http-error.service';
import { environment as env } from '@env';
import { Observable } from 'rxjs';
import { Client } from '../add-client/add-client.component';
import { map } from 'rxjs/operators';

export type MspClient = {
  id: string;
  name: string;
  level: Level | null;
  roadmapBudget: number;
  score: number;
  lastMeetingAt?: string;
  accountManager?: string;
  activeBenchmark: boolean;
  lastModified: string;
}

@Injectable({
  providedIn: 'root'
})
export class ClientsListService {

  constructor(
    private httpClient: HttpClient,
    private errorHandler: HttpErrorService
  ) { }

  getClients(query?: string, level?: string): Observable<MspClient[]> {
    const { baseUrl } = env.api;

    const params = [
      ...(query ? [`filter=${encodeURIComponent(query)}`] : []),
      ...(level ? [`level=${encodeURIComponent(level)}`] : [])
    ];

    let url = `${baseUrl}/v1/msp/dashboard`;

    if (params.length) {
      url += `?${params.join('&')}`;
    }

    return this.httpClient.get<MspClient[]>(url).pipe(
      this.errorHandler.handleHttpError,
    );
  }

  addClient(client: Client): Observable<string> {
    const { baseUrl } = env.api;
    const url = `${baseUrl}/v1/clients`;
    return this.httpClient.post<{ id: string }>(url, client).pipe(
      this.errorHandler.handleHttpError,
      map((res) => res.id)
    );
  }
}
