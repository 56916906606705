import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ComponentsModule } from '@app/components/components.module';
import { StylesModule } from '@app/styles/styles.module';
import { SettingsViewComponent } from './settings-view/settings-view.component';
import { SettingsCardComponent } from './settings-view/settings-card/settings-card.component';
import { FrameworksComponent } from './frameworks/frameworks.component';
import {
  HeroIconModule,
  officeBuilding,
  clipboard,
  cloud,
  x,
  creditCard,
  cog,
} from 'ng-heroicon';
import { NewFrameworkComponent } from './frameworks/new-framework/new-framework.component';
import { FrameworkFormComponent } from './frameworks/framework-form/framework-form.component';
import { FrameworkDetailComponent } from './frameworks/framework-detail/framework-detail.component';
import { SidebarModule } from 'primeng/sidebar';
import { DialogModule } from 'primeng/dialog';
import { PsaConfigurationComponent } from './psa-configuration/psa-configuration.component';
import { InputSwitchModule } from 'primeng/inputswitch';

@NgModule({
  declarations: [
    SettingsViewComponent,
    SettingsCardComponent,
    FrameworksComponent,
    NewFrameworkComponent,
    FrameworkFormComponent,
    FrameworkDetailComponent,
    PsaConfigurationComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    ConfirmDialogModule,
    SidebarModule,
    DialogModule,
    InputSwitchModule,
    HeroIconModule.withIcons({
      officeBuilding,
      clipboard,
      cloud,
      x,
      creditCard,
      cog,
    }),
    StylesModule,
  ],
})
export class SettingsModule {}
