import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpErrorService } from '@app/services/http-error.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment as env } from '@env';
import { CollectionResponse } from '@app/domain/model';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { HeaderSelectListOption } from '@app/components/page-header/controls';

export interface Framework {
  id?: string;
  name?: string;
  shortName?: string;
  version?: string;
  link?: string;
  description?: string;
  country?: string;
  organisation?: string;
  taskCount?: number;
  isDefault?: boolean;
}

interface CreateFrameworkResponse {
  id: string;
}

@Injectable({
  providedIn: 'root'
})
export class FrameworkService {

  private reload = new BehaviorSubject(0);

  constructor(
    private httpClient: HttpClient,
    private errorHandler: HttpErrorService
  ) { }

  selectListOptions$: Observable<HeaderSelectListOption[]> = this.reload.pipe(
    switchMap(() => this.getFrameworks()),
    map((frameworks) => {
      const defaultFramework = frameworks.find((framework) => framework.isDefault);

      const isNotDefault = (framework: Framework) => framework.isDefault === false;

      const list = frameworks.filter(isNotDefault).map((framework): HeaderSelectListOption => ({
        text: framework.name ?? "",
        value: framework.id ?? "",
        isDefaultValue: false
      }));

      list.unshift({ text: defaultFramework?.name ?? "", value: defaultFramework?.id ?? "", isDefaultValue: true });

      return list;
    }),
    shareReplay(1),
  );

  refreshOptions(): void {
    this.reload.next(1);
  }

  createFramework(framework: Framework): Observable<CreateFrameworkResponse> {
    const url = `${env.api.baseUrl}/v${env.api.version}/frameworks`;
    return this.httpClient.post<CreateFrameworkResponse>(url, framework).pipe(
      this.errorHandler.handleHttpError
    );
  }

  updateFramework(id: string, framework: Framework): Observable<Framework> {
    const url = `${env.api.baseUrl}/v${env.api.version}/frameworks/${encodeURIComponent(id)}`;
    return this.httpClient.put<Framework>(url, framework).pipe(
      this.errorHandler.handleHttpError
    );
  }

  getFrameworks(filter: string | null = null): Observable<Framework[]> {
    let url = `${env.api.baseUrl}/v${env.api.version}/frameworks`;
    const params = [];

    if (filter) {
      params.push(`q=${encodeURIComponent(filter)}`);
    }

    if (params.length) {
      url += `?${params.join("&")}`;
    }

    return this.httpClient.get<CollectionResponse<Framework>>(url).pipe(
      this.errorHandler.handleHttpError,
      map(res => res.result)
    );
  }

  getFrameworkById(id: string): Observable<Framework> {
    const url = `${env.api.baseUrl}/v${env.api.version}/frameworks/${encodeURIComponent(id)}`;
    return this.httpClient.get<Framework>(url).pipe(
      this.errorHandler.handleHttpError
    );
  }

  deleteFramework(id: string): Observable<void> {
    const url = `${env.api.baseUrl}/v${env.api.version}/frameworks/${encodeURIComponent(id)}`;
    return this.httpClient.delete<void>(url).pipe(
      this.errorHandler.handleHttpError
    );
  }
}
