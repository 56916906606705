import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Role, RoleName } from '@app/roles';
import { StoredCompany, UserContextService } from '@app/user-context.service';
import { HeroIconName } from 'ng-heroicon/lib/icons/icons-names';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { RoleGroup, UserCompany, UserIdentity, UserState } from '../users.model';
import { UserModel, UsersService } from '@app/users/users.service';
import { ConfirmationService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { InvitationStatus } from '@app/invitations/invitations.model';
import { Company } from '@app/domain/model';
import { CompaniesListService } from '@app/companies/companies-list/companies-list.service';
import { isNotNull } from '@app/shared/utility-functions';

interface AccountAccess {
  label: string;
  value: boolean;
}

interface RoleGroupItem {
  value: RoleGroup;
  icon: HeroIconName;
}

interface State {
  isUserAccount: boolean;
  isDisabled: boolean;
  canDelete: boolean;
  isCreate: boolean;
  isContact: boolean;
  pendingInvite: boolean;
}

@Component({
  selector: 'cb-user-form',
  templateUrl: './user-form.component.html',
  providers: [ConfirmationService]
})
export class UserFormComponent implements OnInit, OnChanges {

  @Input() user$?: Observable<UserIdentity | null>;
  @Input() user?: UserIdentity | null;
  @Input() reload = new Subject<string>();

  @Output() onsave = new EventEmitter<UserModel>();
  @Output() ondelete = new EventEmitter<string>();
  @Output() oninvite = new EventEmitter<UserModel>();
  @Output() oncompanyadded = new EventEmitter<string>();
  @Output() oncompanyremoved = new EventEmitter<string>();

  private userSubject: Subject<UserIdentity | null | undefined> = new Subject<UserIdentity | null | undefined>();

  accountAccessOptions: AccountAccess[] = [
    { label: "Yes, they will need to log in", value: true },
    { label: "No, they are a contact only", value: false }
  ];

  selectedAccountAccess?: boolean;

  state: State = UserFormComponent.defaultState;
  companyAccess: UserCompany[] = [];
  form: FormGroup;
  showHelp = false;
  selectedRole: RoleGroupItem | null = null;

  availableCompanies$?: Observable<Company[]>;
  companies: UserCompany[] = [];
  companyAccessModalOpen = false;

  partnerFilter: string = "";
  currentCompanyId: string = "";
  firstName?: string;

  private subscriptions: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    public context: UserContextService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private service: UsersService,
    private companyService: CompaniesListService,
    private route: ActivatedRoute
  ) {
    this.form = this.fb.group({
      id: [""],
      invitationId: [""],
      name: ["", [Validators.required, Validators.maxLength(100)]],
      position: ["", [Validators.maxLength(100)]],
      email: ["", [Validators.required, Validators.email, Validators.maxLength(255)]],
      roleGroup: ["Contact", [Validators.required]]
    });
  }

  ngOnChanges(): void {
    this.userSubject.next(this.user);
  }

  private static defaultState: State = {
    isUserAccount: false,
    isDisabled: false,
    canDelete: false,
    isCreate: true,
    isContact: false,
    pendingInvite: false
  };

  ngOnInit(): void {

    if (this.user$ && this.user) {
      throw new Error("Cannot use both [user$] (observable) and [user] object at the same time");
    }

    this.userSubject.subscribe((user) => {
      if (user) {
        this.buildUser(user);
      }
      else {
        this.form.reset();
      }
    });

    if (this.user$ !== undefined) {
      this.subscriptions.push(
        this.user$.subscribe(user => this.userSubject.next(user))
      );
    }
    else if (this.user !== undefined) {
      this.userSubject.next(this.user);
    }

  }

  getRoleGroups$(company: StoredCompany): Observable<RoleGroupItem[]> {
    return this.context.user$.pipe(
      map(user => {
        const userRoles = new Set<Role>(user?.roles as Role[]);
        const groups: RoleGroupItem[] = [];

        if (userRoles.has("Administrator")) {
          groups.push(this.buildRoleGroupItem("Admin"));
        }

        if (userRoles.has("TeamManager")) {
          groups.push(this.buildRoleGroupItem("Manager"));
          groups.push(this.buildRoleGroupItem("User"));
        }

        if (userRoles.has("UserManager") && !groups.length && user?.roleGroup !== "Contact") {
          this.form.patchValue({
            roleGroup: "User",
          });
        }

        return groups;
      }),
      shareReplay(1)
    )
  }

  private buildUser(user: UserIdentity): void {
    const pendingInvite = user.status === InvitationStatus.Pending && !user.invitationExpired;
    const roleGroup = pendingInvite && user.invitedToRoleGroup || user.roleGroup;

    this.form.patchValue({
      ...user,
      roleGroup
    });

    this.selectedRole = this.buildRoleGroupItem(roleGroup);

    this.state = {
      isUserAccount: pendingInvite || user?.roleGroup !== "Contact",
      isDisabled: user?.state === UserState.Disabled,
      canDelete: !!user?.id,
      isCreate: false,
      isContact: user.roleGroup === "Contact",
      pendingInvite
    }

    this.selectedAccountAccess = this.state.isUserAccount;

    this.companies = user.companies ?? [];
  }

  private buildRoleGroupItem(role: RoleGroup): RoleGroupItem {
    return { icon: this.roleGroupIcon(role), value: role };
  }

  private roleGroupIcon(role: RoleGroup): HeroIconName {
    switch (role) {
      case "User":
      case "Contact":
        return "user-circle";
      case "Manager":
        return "office-building";
      case "Admin":
        return "globe";
    }
  }

  get shouldSendInvite(): boolean {
    return !this.state.pendingInvite && (this.state.isCreate || this.state.isContact) && this.state.isUserAccount;
  }

  save(): void {
    const user = this.form.getRawValue() as UserModel;

    user.companies = [...this.companies];
    user.roleGroup = !this.state.isUserAccount ? "Contact" : this.selectedRole!.value;

    this.shouldSendInvite ? this.oninvite.emit(user) : this.onsave.emit(user);
  }

  showCompanyAccessModal(): void {
    this.companyAccessModalOpen = true;
  }

  partnerCompanySelected(company: Company): void {
    this.companyAccessModalOpen = false;

    if (!this.companies.find(c => c.id === company.id)) {
      this.companies.push({ ...company });
      this.oncompanyadded.emit(company.id);
    }
  }

  removePartnerAccess(company: UserCompany): void {
    const index = this.companies.findIndex(c => c.id === company.id);

    if (index > -1) {
      this.companies.splice(index, 1);
      this.oncompanyremoved.emit(company.id);
    }
  }

  displayHelpModal(): void {
    this.showHelp = true;
  }

  selectRole(role: RoleGroupItem): void {
    this.selectedRole = role;
    const roleGroup: RoleGroup = role.value;
    this.form.patchValue({ roleGroup });
  }

  deleteUser(id: string): void {
    const user = this.form.get("name")?.value;
    const roleGroup = this.form.get("roleGroup")?.value as RoleGroup;

    const message = roleGroup === "Contact"
      ? "This contact will no longer be available"
      : "Their account will be archived and they will no longer be able to log in";

    this.confirmationService.confirm({
      message,
      header: `Do you want to delete ${user}?`,
      accept: () => {
        this.ondelete.emit(id);
      }
    })
  }

  cancelClick(): void {
    this.router.navigate(['../../users'], { relativeTo: this.route });
  }

  accountAccessChanged(value: boolean): void {
    this.state.isUserAccount = value;

    if (!value) {
      this.form.patchValue({ roleGroup: "Contact" });
    }

    // changing from a contact to a user account
    if (value && this.state.isContact) {
      this.form.patchValue({ roleGroup: "" });
    }
  }

  deleteUserInvite(): void {
    this.confirmationService.confirm({
      message: "Do you want to cancel their invitation?",
      header: `Cancel Invitation`,
      accept: async () => {
        await this.service.deleteInvitation(this.form.get("invitationId")?.value).toPromise();
        this.router.navigate(['/users']);
      }
    });
  }

  ensureCompaniesInitialised(): void {
    if (!this.availableCompanies$) {
      this.availableCompanies$ = this.context.currentCompany$.pipe(
        filter(isNotNull),
        switchMap((company) => this.companyService.getCompaniesOwnedBy(company.id)),
        shareReplay(1)
      );
    }
  }

}
