import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { UserContextService } from "./user-context.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { RouteSubscriptionKind } from "./interfaces";

type ActiveRoute = Omit<ActivatedRouteSnapshot, "data"> & {
    data: {
        kind: RouteSubscriptionKind
    }
}

@Injectable({
    providedIn: 'root'
})
export class CheckSubscriptionActivator implements CanActivate {

    constructor(
        private context: UserContextService,
        private router: Router
    ) { }

    canActivate(
        route: ActiveRoute,
        snapshot: RouterStateSnapshot
    ): Observable<boolean> {
        return this.canActivateFor(route.data.kind, snapshot.url, "redirectOnReject");
    }

    canActivateFor(kind: RouteSubscriptionKind, url?: string, mode: "redirectOnReject" | undefined = undefined): Observable<boolean> {
        return this.context.accountStatus$.pipe(
            map(({ canAddClients, accountChangePending, currentPlanId }) => {
                if (kind === "client" && !canAddClients) {
                    if (mode === "redirectOnReject") {
                        this.router.navigate([], {
                            queryParams: {
                                upgrade: currentPlanId,
                                referrer: url ? encodeURIComponent(url) : null,
                                pending: accountChangePending ? 1 : undefined
                            }
                        });
                    }
                    return false;
                }

                return true;
            })
        );
    }
}