import { Component, Input, OnInit } from '@angular/core';
import { BenchmarkTask } from '@app/benchmark/benchmark.service';

@Component({
  selector: 'cb-quality-examples',
  templateUrl: './quality-examples.component.html',
  styles: [],
})
export class QualityExamplesComponent implements OnInit {
  @Input() task: BenchmarkTask | undefined;

  constructor() {}

  ngOnInit(): void {}
}
