import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '@env';
import { HttpErrorService } from '@app/services/http-error.service';
import { Observable } from 'rxjs';
import { Activity } from './journey.module';

@Injectable({
  providedIn: 'root'
})
export class JourneyService {

  constructor(
    private httpClient: HttpClient,
    private errorHandler: HttpErrorService
  ) { }

  getActivity(companyId: string, fromDate?: Date): Observable<Activity[]> {
    const { baseUrl } = env.api;
    let url = `${baseUrl}/v1/${encodeURIComponent(companyId)}/activity`;

    if (fromDate) {
      url += `?from=${encodeURIComponent(fromDate.toISOString())}`;
    }

    return this.httpClient.get<Activity[]>(url).pipe(
      this.errorHandler.handleHttpError
    );
  }
}
