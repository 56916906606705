import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { BenchmarkIconsModule } from '@app/benchmark/benchmark-icons.module';

import { AccordionModule } from 'primeng/accordion';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FileUploadModule } from 'primeng/fileupload';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { SkeletonModule } from 'primeng/skeleton';
import { SidebarModule } from 'primeng/sidebar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MessageModule } from 'primeng/message';

import { ComponentsModule } from '@app/components/components.module';
import { NavigationModule } from '@app/navigation/navigation.module';

import { StylesModule } from '@app/styles/styles.module';
import { SharedModule } from '@app/shared/shared.module';
import { ReportShareComponent } from './report-share/report-share.component';
import { AddContactComponent } from './report-share/add-contact/add-contact.component';
import { TasksListComponent } from './tasks-list/tasks-list.component';
import { QualityOptionsComponent } from './tasks-list/quality-options/quality-options.component';
import { EditNotesComponent } from './tasks-list/edit-notes/edit-notes.component';
import { IconsModule } from '@app/icons/icons.module';
import { QualityExamplesComponent } from './tasks-list/quality-examples/quality-examples.component';
import { ChipsComponent } from './tasks-list/chips/chips.component';
import { TabViewModule } from 'primeng/tabview';
import { NotesViewComponent } from './tasks-list/notes-view/notes-view.component';
import { TagModule } from 'primeng/tag';
import { InplaceModule } from 'primeng/inplace';
import { ModalRiskIconComponent } from './tasks-list/modal-risk-icon/modal-risk-icon.component';

@NgModule({
  declarations: [
    ReportShareComponent,
    AddContactComponent,
    TasksListComponent,
    QualityOptionsComponent,
    EditNotesComponent,
    QualityExamplesComponent,
    ChipsComponent,
    NotesViewComponent,
    ModalRiskIconComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    StylesModule,
    BenchmarkIconsModule,
    AccordionModule,
    SelectButtonModule,
    InputTextareaModule,
    FileUploadModule,
    DialogModule,
    SkeletonModule,
    ComponentsModule,
    CheckboxModule,
    NavigationModule,
    SidebarModule,
    RouterModule,
    ConfirmDialogModule,
    ToggleButtonModule,
    InputSwitchModule,
    MessageModule,
    SharedModule,
    IconsModule,
    TabViewModule,
    TagModule,
    InplaceModule,
  ],
})
export class BenchmarkModule {}
