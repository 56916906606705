import { Directive, HostBinding } from '@angular/core';

@Directive({
  selector: '[cbSecondaryButton]'
})
export class SecondaryButtonDirective {

  styles = [
    "px-6",
    "py-2", 
    "transition", 
    "hover:opacity-90", 
    "bg-white", 
    "text-brand-deep", 
    "border", 
    "border-brand-deep", 
    "rounded-md", 
    "focus:outline-black", 
    "focus:ring-brand-deep", 
    "hover:shadow-md"
  ];

  @HostBinding("class")
  get elementStyles(): string {
    return this.styles.join(' ');
  }

  constructor() { }

}
