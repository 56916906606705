<header *ngIf="context$ | async as user" class="p-0">
    <div class="p-2 bg-brand-secondary flex items-center justify-between">
        <div class="flex-1 lg:flex-none">
            <div class="flex items-center">
                <button (click)="sidebar.toggle()" class="lg:hidden text-gray-500 focus:outline-none">
                    <hero-icon name="menu-alt-2" type="outline" class="w-6 h-6"></hero-icon>
                </button>
                <div class="p-2 max-w-sm object-right-top">
                    <cb-logo size="verysmall" theme="light"></cb-logo>
                </div>
            </div>
        </div>
        <cb-sub-nav class="hidden md:flex"></cb-sub-nav>
    </div>
</header>