import { Directive, OnInit, OnDestroy, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

@Directive({
  selector: '[cbDebounceClick]'
})
export class DebounceClickDirective implements OnInit, OnDestroy {

  @Input() 
  delay = 500;

  @Output()
  deClick = new EventEmitter<Event>();

  private clicks = new Subject<Event>();
  private subscription?: Subscription;

  constructor() { }

  ngOnInit(): void {
    this.subscription = this.clicks.pipe(
      throttleTime(this.delay)
    ).subscribe(ev => this.deClick.emit(ev));
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  @HostListener("click", ["$event"])
  clickHandle(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.clicks.next(event);
  }

}
