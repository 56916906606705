import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '@app/components/components.module';
import { IconsModule } from '@app/app-icons.module';
import { StylesModule } from '@app/styles/styles.module';
import { TableModule } from 'primeng/table';
import { ChartModule } from 'primeng/chart';
import { ProgressBarModule } from 'primeng/progressbar';
import { CompaniesModule } from '@app/companies/companies.module';
import { MspDashboardComponent } from './msp-dashboard/msp-dashboard.component';
import { MatTabsModule } from '@angular/material/tabs';
import { SidebarModule } from 'primeng/sidebar';
import { DashboardComponent } from './dashboard.component';
import { MetricCardComponent } from './metric-card/metric-card.component';
import { CustomerMetricsComponent } from './customer-metrics/customer-metrics.component';
import { MspClientMetricsComponent } from './msp-client-metrics/msp-client-metrics.component';
import { MspSubscriptionComponent } from './msp-subscription/msp-subscription.component';
import { ProjectsCardComponent } from './projects-card/projects-card.component';
import { BudgetCardComponent } from './budget-card/budget-card.component';
import { ClientsByLevelComponent } from './clients-by-level/clients-by-level.component';
import { ActivityComponent } from './activity/activity.component';
import { SkeletonModule } from 'primeng/skeleton';
import { MetricSkeletonComponent } from './metric-skeleton/metric-skeleton.component';
import { MetricValueComponent } from './metric-value/metric-value.component';
import { CustomersByPlanComponent } from './customers-by-plan/customers-by-plan.component';
import { ClientsModule } from '@app/clients/clients.module';
import { CustomersModule } from '@app/customers/customers.module';

@NgModule({
  declarations: [
    MspDashboardComponent,
    DashboardComponent,
    MetricCardComponent,
    CustomerMetricsComponent,
    MspClientMetricsComponent,
    MspSubscriptionComponent,
    ProjectsCardComponent,
    BudgetCardComponent,
    ClientsByLevelComponent,
    ActivityComponent,
    MetricSkeletonComponent,
    MetricValueComponent,
    CustomersByPlanComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ComponentsModule,
    IconsModule,
    StylesModule,
    TableModule,
    ChartModule,
    ProgressBarModule,
    CompaniesModule,
    MatTabsModule,
    SidebarModule,
    SkeletonModule,
    ClientsModule,
    CustomersModule
  ]
})
export class DashboardModule { }
