import { Component, OnInit, Input } from '@angular/core';
import { Quality } from '@app/benchmark/benchmark.model';

type QualityColour = "text-rag-red" | "text-rag-amber" | "text-rag-green";
type QualityIcon = "shield-check" | "shield-exclamation" | "exclamation-circle";

@Component({
  selector: 'cb-quality-label',
  templateUrl: './quality-label.component.html',
  styles: [
  ]
})
export class QualityLabelComponent implements OnInit {

  @Input() quality: Quality | null;
  includeSuffix: boolean = true;
  colour: QualityColour = "text-rag-red";
  icon: QualityIcon = "shield-exclamation";

  constructor() {
    this.quality = Quality.NotImplemented;
  }

  ngOnInit(): void {
    if(!this.quality){
      this.changeQuality("text-rag-red", "shield-exclamation", false);
      return;
    }

    switch(this.quality){
      case Quality.Excellent:        
      case Quality.High:
        this.changeQuality("text-rag-green", "shield-check");
        break;
      case Quality.Medium:
        this.changeQuality("text-rag-amber", "exclamation-circle");
        break;
      case Quality.Low:
        this.changeQuality("text-rag-amber", "shield-exclamation");
        break;      
      case Quality.NotImplemented:
        this.changeQuality("text-rag-red", "shield-exclamation", false);
        break;
      
    }
  }

  private changeQuality(colour: QualityColour, icon: QualityIcon, suffix: boolean = true) {
    this.colour = colour;
    this.icon = icon;
    this.includeSuffix = suffix;
  }

}
