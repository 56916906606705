import { Component, EventEmitter, OnInit, OnDestroy, Output } from '@angular/core';
import { Contact, UsersService } from '@app/users/users.service';
import { UserContextService } from '@app/user-context.service';
import { BenchmarkService } from '../benchmark.service';
import { filter, shareReplay, switchMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { isNotNull } from '@app/shared/utility-functions';

@Component({
  selector: 'cb-report-share',
  templateUrl: './report-share.component.html',
  styles: [
  ]
})
export class ReportShareComponent implements OnInit, OnDestroy {

  selectedContacts: Contact[] = [];
  contacts: Contact[] = [];
  addNew = false;

  @Output() onclose = new EventEmitter<void>();

  private subscriptions: Subscription[] = [];

  constructor(
    private context: UserContextService,
    private usersService: UsersService,
    private benchmarkService: BenchmarkService
  ) {
  }

  ngOnDestroy(): void {
    this.selectedContacts = [];
    this.contacts = [];
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngOnInit(): void {
    const contacts$ = this.context.selectedCompanyId$.pipe(
      filter(isNotNull),
      switchMap((id) => this.usersService.getContacts(id)),
      shareReplay(1)
    );

    this.subscriptions.push(
      contacts$.subscribe((contacts) => {
        this.contacts = contacts;
      })
    );
  }

  addNewContact(contact: Contact): void {
    const existing = this.contacts.find(c => c.email === contact.email);

    if (!existing) {
      this.contacts = [
        ...this.contacts,
        contact
      ];
    }

    this.selectedContacts = [
      ...this.selectedContacts,
      existing || contact
    ];

    this.addNew = false;
  }

  selectAll(): void {
    this.selectedContacts = [...this.contacts];
  }

  clearSelection(): void {
    this.selectedContacts = [];
  }

  shareReport(): void {
    const obs$ = this.context.selectedCompanyId$.pipe(
      filter(isNotNull),
      switchMap((id) => this.benchmarkService.shareReport(
        id,
        [...this.selectedContacts]
      ))
    );

    this.subscriptions.push(
      obs$.subscribe(() => {
        this.selectedContacts = [];
        this.onclose.emit();
      })
    );
  }

  cancelShare(): void {
    this.selectedContacts = [];
    this.addNew = false;
    this.onclose.emit();
  }
}
