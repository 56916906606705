import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from '@env';
import { HttpErrorService } from '@app/services/http-error.service';
import { Observable } from 'rxjs';
import { Risk } from '@app/benchmark/benchmark.model';
import { map } from 'rxjs/operators';
import { BenchmarkTask } from '@app/benchmark/benchmark.service';

export interface Report {
  clientName: string;
  consultant: string;
  level: number;
  targetLevel: number;
  reportDate: Date;
  companyName: string;
  serviceProviderName: string;
  serviceProviderLogoUrl: string;
  taskGroups: ReportTaskGroup[];
}

export interface ReportTaskGroup {
  title: string;
  pendingTasksCount: number;
  completeTasksCount: number;
  percentageComplete: number;
  risk: Risk;
  totalTasksCount: number;
  details: BenchmarkTask[];
  _expanded: boolean;
}

export interface ReportParams {
  companyId: string;
  level?: number | null;
  date?: Date | null;
  download?: boolean;
}

export type DocumentDownloadResponse = {
  fileUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(
    private httpClient: HttpClient,
    private errorHandler: HttpErrorService
  ) { }

  formatReportDate(date: Date): string {
    // dates in JS use zero base for month - why? nobody knows.       
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  getReport(args: ReportParams): Observable<Report> {
    const url = this.buildUrlFromArgs(args);

    return this.httpClient.get<Report>(url).pipe(
      this.errorHandler.handleHttpError,
      map(res => {
        res.taskGroups = res.taskGroups.map(d => {
          d._expanded = false;
          return d;
        });
        return res;
      })
    )
  }

  downloadReport(args: ReportParams): Observable<any> {
    const url = this.buildUrlFromArgs({ ...args, download: true });
    return this.httpClient.get(url, { responseType: 'blob' }).pipe(
      this.errorHandler.handleHttpError
    );
  }

  private buildUrlFromArgs(args: ReportParams): string {
    const { companyId, level, date, download } = args;
    let params: string[] = [];
    let url = `${env.api.baseUrl}/v${env.api.version}/companies/${companyId}/report`;

    if (download === true) {
      url += "/pdf";
    }

    if (level) {
      params.push(`level=${level}`);
    }

    if (date) {
      params.push(`date=${this.formatReportDate(date)}`);
    }

    if (params.length) {
      url += `?${params.join("&")}`;
    }

    return url;
  }
}
