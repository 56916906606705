import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpErrorService } from '@app/services/http-error.service';
import { map } from 'rxjs/operators';
import { environment as env } from '@env';

interface RiskScoreResult {
  score: number;
}

@Injectable({
  providedIn: 'root'
})
export class RiskScoreService {

  constructor(
    private httpClient: HttpClient,
    private errorHandler: HttpErrorService
  ) { }

  getOverallRiskScore(companyId: string): Promise<number> {
    const url = `${env.api.baseUrl}/v${env.api.version}/risk-score?companyId=${encodeURIComponent(companyId)}`;

    const headers = { ignoreLoadingBar: '' };

    return this.httpClient.get<RiskScoreResult>(url, { headers }).pipe(
      this.errorHandler.handleHttpError,
      map(res => res.score)
    ).toPromise();
  }
}
