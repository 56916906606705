import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleGuard } from '@app/auth/roles/role.guard';
import { CompanyManager, RoleName } from '@app/roles';
import { NewCompanyComponent } from './new-company/new-company.component';
import { CompanyDetailComponent } from './company-detail/company-detail.component';
import { CompanyEditComponent } from './company-edit/company-edit.component';
import { CheckSubscriptionActivator } from '@app/subscription-activator';

const routes: Routes = [
    RoleGuard.build(
        "companies/add",
        NewCompanyComponent,
        [CompanyManager],
        { kind: "client" },
        [CheckSubscriptionActivator]
    ),
    RoleGuard.build("companies/edit/:id", CompanyEditComponent, [CompanyManager]),
    { path: "partners", redirectTo: "/dashboard" },
    { path: "company", redirectTo: "/dashboard" },
    { path: "company/edit", redirectTo: "/dashboard" },
    { path: "company/:id", redirectTo: "/c/:id/overview" }
];

export const CompanyRoutes: Routes = [
    RoleGuard.build("overview", CompanyDetailComponent, [RoleName.CompanyViewer])
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CompaniesRoutingModule { }

