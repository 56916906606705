import { Component, OnInit } from '@angular/core';
import { Activity } from "@app/journey/journey.module";
import { UserContextService } from '@app/user-context.service';
import { HeroIconName } from 'ng-heroicon/lib/icons/icons-names';
import { Observable, of } from 'rxjs';
import { map, reduce, switchMap } from 'rxjs/operators';
import { JourneyService } from '../journey.service';

type Trend = {
  icon: HeroIconName;
  up?: true;
  down?: true;
  description: "trending up" | "trending down";
}

@Component({
  selector: 'cb-company-journey',
  templateUrl: './company-journey.component.html',
  styles: [
  ]
})
export class CompanyJourneyComponent implements OnInit {

  activity$!: Observable<Activity[]>;
  private cachedScore: number | null = null;

  constructor(
    private service: JourneyService,
    private context: UserContextService
  ) { }

  ngOnInit(): void {
    this.activity$ = this.context.currentCompany$.pipe(
      switchMap(company => this.service.getActivity(company?.id!))
    );
  }  

  getRiskScoreTrend(activity: Activity): Trend | null {
    if(!this.cachedScore) {
      this.cachedScore = activity.riskScore;
      return null;
    }

    let trend: Trend | null = null;

    if(activity.riskScore < this.cachedScore) {
      trend = {
        icon: "arrow-down",
        description: "trending down",
        down: true
      }
    }

    if(activity.riskScore > this.cachedScore) {
      trend = {
        icon: "arrow-up",
        description: "trending up",
        up: true
      }
    }

    this.cachedScore = activity.riskScore ?? this.cachedScore;

    return trend;
  }

}
