<cb-authorized>
    <cb-body>
        <div class="flex justify-end">
            <cb-subscription-balance type="clients" countProp="clientCount"
                totalProp="maxClientsAllowed"></cb-subscription-balance>
        </div>
        <cb-page-header [showCurrentCompany]="false" [spaceBelow]="false" [actions]="actions"
            [controls]="controls$ | async">
            Clients
        </cb-page-header>

        <cb-list-view mode="embedded" *ngIf="clients$ | async as clients">
            <cb-list-header>
                <cb-header-column>Name</cb-header-column>
                <cb-header-column>Risk Score</cb-header-column>
                <cb-header-column>Level</cb-header-column>
                <cb-header-column>Total Budget</cb-header-column>
                <cb-header-column>Account Manager</cb-header-column>
                <cb-header-column>Last Modified</cb-header-column>
            </cb-list-header>
            <ng-container *ngIf="clients.length;else emptyList">
                <cb-list-row *ngFor="let client of clients" [routerLink]="['/c', client.id, 'overview']">
                    <cb-list-column>
                        {{client.name}}

                    </cb-list-column>
                    <cb-list-column>
                        {{client.score}}
                    </cb-list-column>
                    <cb-list-column>
                        <span>
                            {{client.level ?? '0'}}.&nbsp;
                            <cb-level-label [textOnly]="true" [level]="client.level">
                            </cb-level-label>
                        </span>
                        <div class="text-xs text-brand-primary" *ngIf="client.activeBenchmark">
                            Active benchmark
                        </div>
                    </cb-list-column>
                    <cb-list-column>
                        <span *ngIf="client.roadmapBudget">
                            &pound;{{(client.roadmapBudget || '-') | number:'0.00'}}
                        </span>

                    </cb-list-column>
                    <cb-list-column>
                        <span *ngIf="client.accountManager">
                            {{client.accountManager || '-'}}
                        </span>
                    </cb-list-column>
                    <cb-list-column>
                        <div class="text-sm">{{client.lastModified | date:'dd MMM YYYY'}}</div>
                        <div class="text-xs">at {{client.lastModified | date:'h:mm a'}}
                        </div>
                    </cb-list-column>
                </cb-list-row>
            </ng-container>
            <ng-template #emptyList>
                <cb-empty-list>
                    <div class="text-2xl">
                        No clients yet
                    </div>
                </cb-empty-list>
            </ng-template>
        </cb-list-view>
    </cb-body>

</cb-authorized>

<p-sidebar [(visible)]="addClientActivated" position="right" appendTo="body" [closeOnEscape]="true"
    (onShow)="addClientComponent.focusCompanyName()" (onHide)="addClientComponent.clearForm()">
    <cb-add-client #addClientComponent (done)="clientAdded()"></cb-add-client>
</p-sidebar>