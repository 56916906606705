import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { countries } from '@data/static';
import { products } from '@app/shared/products';
import { domainRegex, UK } from '@app/shared';
import { Subscription } from 'rxjs';
import { CustomersService, CustomerForm } from '@app/customers/customers.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'cb-add-customer',
  templateUrl: './add-customer.component.html',
  styles: [
  ]
})
export class AddCustomerComponent implements OnInit, OnDestroy {

  form!: FormGroup;
  countryList = [...countries];
  productList = products.toArray().map((product) => ({ text: `Clear ${product.id}`, value: product.id }));

  @Output() done = new EventEmitter<string>();

  private nameElement!: ElementRef<HTMLInputElement>;
  @ViewChild("nameInput", { static: false })
  set content(content: ElementRef<HTMLInputElement>) {
    if (content) {
      this.nameElement = content;
    }
  }

  private subscriptions: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private service: CustomersService,
    private toastr: ToastrService,

  ) {
    this.form = this.fb.group({
      name: [null, [Validators.required, Validators.maxLength(100)]],
      companyNumber: [null, [Validators.maxLength(50)]],
      domain: [null, [Validators.required, Validators.maxLength(255), Validators.pattern(domainRegex)]],
      productId: ["Free", [Validators.required]],
      country: [UK, [Validators.required]]
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngOnInit(): void {

  }

  formSubmit(): void {
    const customer = this.form.getRawValue() as CustomerForm;
    this.addNewCustomer(customer);
  }

  focusName(): void {
    setTimeout(() => this.nameElement.nativeElement.focus(), 50);
  }

  clearForm(): void {
    this.form.reset();
    this.form.patchValue({ country: UK });
  }

  addNewCustomer(customer: CustomerForm): void {
    this.subscriptions.push(
      this.service.addCustomer(customer).subscribe((id) => {
        this.toastr.success(`${customer.name} created`, "New Customer Added");
        this.done.emit(id);
      })
    )
  }

}
