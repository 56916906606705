import { Component, Input, OnInit } from '@angular/core';
import { BenchmarkTask } from '@app/benchmark/benchmark.service';

@Component({
  selector: 'cb-edit-notes',
  templateUrl: './edit-notes.component.html',
  styleUrls: ['./edit-notes.component.css']
})
export class EditNotesComponent implements OnInit {

  @Input() task: BenchmarkTask | undefined;

  constructor() { }

  ngOnInit(): void {
  }

}
