<cb-authorized>
    <cb-body>
        <h3 class="text-2xl mb-5">
            Add a new framework
        </h3>
        <div class="lg:border lg:border-gray-200 rounded-md shadow-md bg-white lg:p-4">                
            <cb-framework-form [framework]="framework" (done)="formClosed()" (cancel)="formClosed()"></cb-framework-form>
        </div> 
    </cb-body>
</cb-authorized>
