import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CompanySearchComponent } from '@components/company-search/company-search.component';
import { CompanySearchResultItem } from '@components/company-search/company-search.model';
import { NewCompanyService } from './new-company.service';
import { CreateCompanyRequest } from '@app/domain/model';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CompaniesListService } from '@app/companies/companies-list/companies-list.service';
import { UserContextService } from '@app/user-context.service';
import { makeCompanyId } from '@app/interfaces';
import { SubscriptionService } from '@app/subscription.service';

@Component({
  selector: 'cb-new-company',
  templateUrl: './new-company.component.html'
})
export class NewCompanyComponent implements OnInit, AfterViewInit, OnDestroy {

  private companySearch!: CompanySearchComponent;
  @ViewChild("companySearch", { static: false })
  set companySearchElementContent(content: CompanySearchComponent) {
    if (content) {
      this.companySearch = content;
    }
  }

  formActive = false;
  formTitle?: string;
  company?: CreateCompanyRequest;

  private sub?: Subscription;

  constructor(
    private service: NewCompanyService,
    private router: Router,
    private toast: ToastrService,
    private listService: CompaniesListService,
    private context: UserContextService,
    private subscriptions: SubscriptionService
  ) { }

  ngOnInit(): void {
    this.formActive = false;
  }

  ngAfterViewInit(): void {
    if (!this.formActive) {
      this.companySearch.focus();
    }
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  selectCompany(result: CompanySearchResultItem): void {
    const address = result.address;
    this.company = {
      displayName: result.title,
      address: {
        line1: [address.premises, address.line1].join(" ").trim(),
        line2: address.line2,
        country: "United Kingdom",
        locality: address.locality,
        postcode: address.postcode,
        region: address.region || address.country || address.locality
      },
      registeredName: result.title,
      registeredNumber: result.companyNumber,
      sector: '',
      type: '',
      size: ''
    };

    this.activateForm(result.title);
  }

  addNewCompany(): void {
    this.activateForm("New Company");
  }

  activateForm(title: string): void {
    this.formActive = true;
    this.formTitle = title;
  }

  saveCompany(company: CreateCompanyRequest): void {
    this.sub = this.service.createCompany(company).subscribe(result => {
      this.toast.success(`${company.displayName} created successfully!`, "Company Created");
      this.context.currentCompany$.next({
        name: company.displayName,
        id: makeCompanyId(result.id),
        type: this.getCompanyType(company.type)
      })
      this.router.navigate(["/c", result.id, "overview"]);
      this.listService.refresh();
      this.subscriptions.refresh();
    });
  }

  private getCompanyType(type: string | undefined): "MSP" | "SME" | undefined {
    if (type === "MSP" || type === "SME") {
      return type;
    }

    return undefined;
  }

  cancel(): void {
    this.router.navigate(['/partners']);
  }

  backToSearch(): void {
    this.formActive = false;
    setTimeout(() => this.companySearch.focus());
  }
}
