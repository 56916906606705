import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[cbButton]'
})
export class ButtonDirective {

  private styles = [
    "px-6",
    "py-2",
    "transition",
    "transform",
    "hover:scale-105",
    "cursor-pointer",
    "hover:opacity-90",
    "text-white",
    "rounded-md",
    "shadow-md",
    "focus:outline-black",
    "focus:ring-brand-primary",
    "hover:shadow-md",
    "disabled:opacity-30",
    "min-w-content"
  ];

  @Input() colour?: string;

  @HostBinding("class")
  get elementStyle(): string {
    const classes = [...this.styles];
    classes.push(this.colour || "bg-brand-primary");
    return classes.join(" ");
  }

  constructor() { }
}
