import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'cb-list-column',
  templateUrl: './list-column.component.html'
})
export class ListColumnComponent implements OnInit {

  @Input() span?: number;

  @HostBinding("class") get cssClass(): string {
    const classes = ["flex-1"];
    if(this.span) {
      classes.push(`col-span-${this.span}`);
    }

    return classes.join(" ");
  }

  constructor() { }

  ngOnInit(): void {
  }

}
