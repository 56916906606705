<cb-authorized>
  <ng-container *ngIf="user$ | async as user">
    <cb-body>
      <div class="w-full xl:max-w-3xl">
        <cb-page-header
          [actions]="controls"
          [subHeading]="user.email"
          [showCurrentCompany]="false"
        >
          {{ user.name }}
        </cb-page-header>
      </div>

      <cb-user-form
        [user]="user"
        [reload]="userSubject"
        (onsave)="saveUser($event)"
        (oninvite)="inviteUser($event)"
        (ondelete)="deleteUser($event)"
        (oncompanyadded)="companyAccessAdded($event)"
        (oncompanyremoved)="companyAccessRemoved($event)"
      >
      </cb-user-form>
    </cb-body>
  </ng-container>
</cb-authorized>
