import { Component, OnInit, Input, ContentChildren, QueryList } from '@angular/core';
import { ListColumnComponent } from './list-column/list-column.component';

@Component({
  selector: 'cb-list-row',
  templateUrl: './list-row.component.html',
  styles: [
  ]
})
export class ListRowComponent implements OnInit {

  private _tags: string[] = [];

  @Input()
  set tags(value: string[]) {
    this._tags = value.filter(t => !!t);
  }

  @Input() clickable = true;

  @ContentChildren(ListColumnComponent)
  columns!: QueryList<ListColumnComponent>;

  get tags(): string[] {
    return this._tags.filter((tag) => !!tag);
  }

  get columnCount(): number {
    let counter = 0;
    for (const col of this.columns) {
      counter += col.span ?? 1;
    }

    return counter;
  }

  get tableStyles(): string {
    const styles: string[] = [
      "w-full"
    ];

    if (this.columnCount <= 1) {
      styles.push("flex");
      styles.push("items-start");
    }
    else {
      /**
      grid-cols-2
      grid-cols-3
      grid-cols-4
      grid-cols-5
      grid-cols-6
      */
      styles.push("grid");
      styles.push("gap-4");
      styles.push(`grid-cols-${this.columnCount}`);
    }

    return styles.join(" ");
  }

  constructor() { }

  ngOnInit(): void {
  }

}
