import { Component, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CompanyDetailService } from './company-detail.service';
import { Observable, iif, Subscription } from 'rxjs';
import { CompanyDetail } from '@app/domain/model';
import { UserContextService } from '@app/user-context.service';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { User } from '@app/users/users.model';
import { Administrator, RoleName } from '@app/roles';
import { HeaderButton, HeaderControl } from '@app/components/page-header/controls';
import { Risk, TaskRisk } from '@app/benchmark/benchmark.model';
import { isNotNull } from '@app/shared/utility-functions';


interface TeamMember {
  id: string;
  name: string;
}

interface TodoItem {
  title: string;
  done: boolean;
}

interface PrimeAutoCompleteEvent {
  query: string;
}

@Component({
  selector: 'cb-company-detail',
  templateUrl: './company-detail.component.html'
})
export class CompanyDetailComponent implements OnInit, OnDestroy {

  company$!: Observable<CompanyDetail>;
  editMode = false;

  controls: HeaderControl[];

  userAccessOpen = false;
  showAddAnother = false;

  users$!: Observable<TeamMember[]>;
  grants$?: Observable<TeamMember[]>;
  selectedUsers: TeamMember[] = [];
  userResults: TeamMember[] = [];

  tempRisk: TaskRisk = "High";

  private subscriptions: Subscription[] = [];

  constructor(
    private service: CompanyDetailService,
    private router: Router,
    public context: UserContextService
  ) {
    this.controls = [
      new HeaderButton(
        "Settings",
        "cog",
        () => this.router.navigate(['/companies', 'edit', this.context.currentCompanySnapshot?.id]),
        "CompanyManager"
      )
    ];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  ngOnInit(): void {
    this.company$ = this.context.selectedCompanyId$.pipe(
      switchMap((id) => this.service.getCompanyById(id ?? ""))
    );

    const team$ = this.context.myCompany$.pipe(
      switchMap(company => this.service.getMembersOfCompany(company.id)),
      map(users => users.map((user: User): TeamMember => ({ id: user.id!, name: user.name }))),
      shareReplay(1)
    );

    const userAccess$ = (id: string) => this.service.getUserAccess(id).pipe(
      shareReplay(1)
    );

    const grants$ = this.context.selectedCompanyId$.pipe(
      filter(isNotNull),
      switchMap((id) => userAccess$(id)),
      map(grants => grants.map(g => ({ id: g.userId, name: g.name }))),
      shareReplay(1)
    );

    const userRoles$ = this.context.user$.pipe(
      map(user => user?.roles)
    );

    const isAdmin = <T>(roles: string[] | undefined, ob: Observable<T>) =>
      iif(() => roles?.includes(Administrator) || false, ob);

    this.users$ = userRoles$.pipe(
      switchMap(roles => isAdmin(roles, team$))
    );

    this.grants$ = userRoles$.pipe(
      switchMap(roles => isAdmin(roles, grants$))
    );

    this.grants$.subscribe(team => this.selectedUsers = team);
  }

  toggleEditMode(): void {
    this.editMode = !this.editMode;
  }

  toggleTask(task: TodoItem): void {
    task.done = !task.done;
  }

  openUserAccessModal(): void {
    this.userAccessOpen = true;
  }

  addUser(): void {
    this.showAddAnother = true;
  }

  searchUsers(event: PrimeAutoCompleteEvent): void {
    const obvs = this.users$.pipe(
      map(users => users.filter(user => user.name.toLowerCase().indexOf(event.query.toLowerCase()) > -1)),
    );

    obvs.subscribe(users => {
      this.userResults = users;
    });
  }

  saveUserAccess(): void {
    const obs$ = this.context.selectedCompanyId$.pipe(
      filter(isNotNull),
      switchMap((id) => this.service.setUserCompanyAccess(id, this.selectedUsers.map(u => u.id)))
    );

    this.subscriptions.push(
      obs$.subscribe(_ => {
        this.userAccessOpen = false;
      })
    );
  }
}

