import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, shareReplay, take } from 'rxjs/operators';
import { claim_types } from '@app/auth/claim-types';
import { Company } from './domain/model';
import { MyCompanyService } from './companies/my-company/my-company.service';
import { RoleGroup } from '@app/users/users.model';
import { CompanyId, makeCompanyId } from './interfaces';
import {
  AccountStatusResult,
  SubscriptionService,
} from './subscription.service';
import { Role } from './roles';
import { isNotNull } from './shared';

export interface UserContext {
  userId: string;
  companyId: CompanyId;
  roles: string[];
  picture?: string;
  name: string;
  email?: string;
  roleGroup: RoleGroup;
  features: Set<Feature>;

  hasFeature(feature: Feature | undefined): boolean;
  hasRole(role: Role): boolean;
}

export type Feature =
  | 'Journey'
  | 'ShareBenchmark'
  | 'MspDashboard'
  | 'BenchmarkUI';

interface Auth0User {
  [claim: string]: any;
}

/// @deprecated
export const storageKey = 'cb-company-scope';
export const COMPANY_KEY = 'cb:selected:company';

export type StoredCompany = {
  id: CompanyId;
  name: string;
  type: 'SME' | 'MSP' | 'SYS' | undefined;
};

const userCache = new Map<string, UserContext>();

@Injectable({
  providedIn: 'root',
})
export class UserContextService {
  myCompany$: Observable<Company>;
  accountStatus$: Observable<AccountStatusResult>;

  user$: Observable<UserContext>;
  userSnapshot: UserContext | null = null;

  currentCompany$ = new BehaviorSubject<StoredCompany | null>(null);
  currentCompanySnapshot: StoredCompany | null = null;

  accountStatusSnapshot: AccountStatusResult | null = null;

  selectedCompanyId$ = new BehaviorSubject<string | null>(null);

  constructor(
    private auth0: AuthService,
    private myCompanyService: MyCompanyService,
    private subscriptionService: SubscriptionService
  ) {
    this.user$ = this.auth0.user$.pipe(
      map(this.mapUser),
      filter(isNotNull),
      shareReplay(1)
    );

    this.auth0.isLoading$.subscribe(() => {
      userCache.clear();
    });

    this.user$.subscribe((user) => {
      this.userSnapshot = user;
    });

    this.currentCompany$.subscribe((company) => {
      this.currentCompanySnapshot = company;

      if (company) {
        localStorage.setItem(COMPANY_KEY, JSON.stringify(company));
      }
    });

    this.myCompany$ = this.myCompanyService.myCompany$;
    this.accountStatus$ = this.subscriptionService.accountStatus$;
  }

  reloadSubscription(): void {
    this.subscriptionService.refresh();
  }

  clear(): void {
    localStorage.removeItem(COMPANY_KEY);
  }

  private mapUser(user: Auth0User | null | undefined): UserContext | null {
    if (!user) {
      return null;
    }

    const cachedUser = userCache.get(user.userId);

    if (cachedUser) {
      return cachedUser;
    }

    const features = new Set<Feature>(user[claim_types.FEATURES]);
    const roles: string[] = user[claim_types.ROLE] || [];
    const userId = user[claim_types.USER_ID];
    const companyId = makeCompanyId(user[claim_types.COMPANY]);

    window.analytics?.identify(userId, {
      name: user.name,
      email: user.email,
      companyId,
    });

    const userContext: UserContext = {
      userId,
      companyId,
      roles,
      name: user.name,
      email: user.email,
      picture: user.picture,
      roleGroup: user[claim_types.ROLE_GROUP] || 'User',
      features,
      hasFeature: (feature) => (feature ? features.has(feature) : false),
      hasRole: (role) => roles.includes(role),
    };

    userCache.set(userId, userContext);

    return userContext;
  }
}
