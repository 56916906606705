<cb-metric-card>
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:divide-x">
        <ng-container *ngIf="metrics$ | async as metrics; else loading">
            <ng-container *ngIf="(clientCount$ | async) as clients; else zeroClients">
                <cb-metric-value [routerLink]="['/clients']" [metric]="clients" text="Client"></cb-metric-value>
            </ng-container>
            <ng-template #zeroClients>
                <cb-metric-value [routerLink]="['/clients']" [metric]="0" text="Client"></cb-metric-value>
            </ng-template>

            <cb-metric-value [metric]="metrics.benchmarks" text="Active benchmark"></cb-metric-value>
            <cb-metric-value [metric]="metrics.documents" text="Document" suffixText="this month"></cb-metric-value>
            <cb-metric-value [metric]="metrics.projects" text="Project"></cb-metric-value>
        </ng-container>
        <ng-template #loading>
            <cb-metric-skeleton></cb-metric-skeleton>
            <cb-metric-skeleton></cb-metric-skeleton>
            <cb-metric-skeleton></cb-metric-skeleton>
            <cb-metric-skeleton></cb-metric-skeleton>
        </ng-template>
    </div>
</cb-metric-card>