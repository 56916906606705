<cb-authorized>
    <cb-body>
        <ng-container *ngIf="framework$ | async as framework">
            <cb-page-header *ngIf="breadcrumb$ | async as breadcrumb" [breadcrumb]="breadcrumb"
                [showCurrentCompany]="false" [subHeading]="subHeading$ | async">
                {{framework.name}}
            </cb-page-header>
            <div class="max-w-full px-2 py-2">
                <div class="lg:border lg:border-gray-200 rounded-md shadow-md bg-white lg:p-4">
                    <cb-framework-form [framework]="framework" (done)="formClosed()"
                        (cancel)="formClosed()"></cb-framework-form>
                </div>
            </div>
        </ng-container>

    </cb-body>
</cb-authorized>