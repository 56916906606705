import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { environment as env } from '@env';
import { HttpErrorService } from '@app/services/http-error.service';
import { Settings, SettingsService } from '../settings.service';
import { switchMapTo } from 'rxjs/operators';

type ProjectSettings = Settings['psa'];

const { baseUrl } = env.api;
const url = `${baseUrl}/v1/settings/psa`;

@Component({
  selector: 'cb-psa-configuration',
  templateUrl: './psa-configuration.component.html',
  styles: [],
  providers: [ConfirmationService],
})
export class PsaConfigurationComponent implements OnInit {
  form!: FormGroup;

  breadcrumb: MenuItem[] = [
    { label: 'Settings', routerLink: ['/settings'] },
    { label: 'PSA Inbox', routerLink: ['/settings', 'psa-inbox'] },
  ];

  deleteEnabled = false;

  constructor(
    private fb: FormBuilder,
    private confirmService: ConfirmationService,
    private toastr: ToastrService,
    private settingsService: SettingsService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      projectsEmail: [
        '',
        [Validators.required, Validators.email, Validators.maxLength(255)],
      ],
      projectsAutoPush: [false],
    });

    this.settingsService.get('psa').subscribe((psaSettings) => {
      if (psaSettings) {
        this.deleteEnabled = true;
        this.form.patchValue(psaSettings);
      }
    });
  }

  submit() {
    const settings = this.form?.getRawValue() as ProjectSettings;
    this.settingsService.put('psa', settings).subscribe(
      () => {
        this.deleteEnabled = true;
        this.toastr.success('Settings updated!');
      },
      (error) => {
        this.toastr.error('Failed to save settings', error.message);
      }
    );
  }

  deleteSettings() {
    this.confirmService.confirm({
      message: 'Deleting settings cannot be undone, do you want to continue?',
      accept: () => {
        this.settingsService.delete('psa').subscribe(() => {
          this.deleteEnabled = false;
          this.form.reset();
        });
      },
    });
  }
}
