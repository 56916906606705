import { Component, OnInit, Input } from '@angular/core';
import { RiskScoreService } from './risk-score.service';

@Component({
  selector: 'cb-risk-score',
  templateUrl: './risk-score.component.html',
  styles: [
  ]
})
export class RiskScoreComponent implements OnInit {

  @Input() companyId!: string;

  score?: number;
  loading = false;

  constructor(
    private service: RiskScoreService
  ) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    try {
      this.score = await this.service.getOverallRiskScore(this.companyId);
    }
    finally {
      this.loading = false;
    }    
  }

  get scorePosition(): {left: number, right: number} {
    if(!this.score){
      return {left: 0, right: 0};
    }

    const percent = Math.floor((this.score / 1000)*100);
    return {left: 100 - percent, right: percent };
  }
}
