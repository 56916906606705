import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  HeaderButton,
  HeaderControl,
} from '@app/components/page-header/controls';
import { UserContextService } from '@app/user-context.service';
import { Observable, Subscription, of } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { DashboardService, MspDashboardClient } from './dashboard.service';
import { CheckSubscriptionActivator } from '@app/subscription-activator';
import { MetricsRefreshService } from './metrics-refresh.service';

@Component({
  selector: 'cb-dashboard',
  templateUrl: './dashboard.component.html',
  styles: [],
})
export class DashboardComponent implements OnInit, OnDestroy {
  actions$: Observable<HeaderControl[]> = of([]);
  projectsData: any;
  clientLevelData: any;
  addClientActivated = false;
  addCustomerActivated = false;

  private subscriptions: Subscription[] = [];
  private newClientCount = 0;
  private newCustomerCount = 0;

  constructor(
    public context: UserContextService,
    private activator: CheckSubscriptionActivator,
    private refreshService: MetricsRefreshService
  ) {}

  ngOnInit(): void {
    const addClientClick = () => {
      this.activator
        .canActivateFor('client', undefined, 'redirectOnReject')
        .pipe(
          filter((canActivate) => canActivate),
          first()
        )
        .subscribe(() => {
          this.addClientActivated = true;
        });
    };

    const mspUser$ = this.context.accountStatus$.pipe(
      map((status) => [
        new HeaderButton(
          'Add Client',
          'plus-circle',
          addClientClick,
          'CompanyManager',
          undefined,
          of(!status.accountChangePending || status.canAddClients)
        ),
      ])
    );

    const cbUser$ = of([
      new HeaderButton(
        'Add Customer',
        'plus-circle',
        () => (this.addCustomerActivated = true),
        'CustomerManager'
      ),
    ]);

    this.actions$ = this.context.user$.pipe(
      switchMap((user) =>
        user?.hasRole('CustomerManager') ? cbUser$ : mspUser$
      )
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  customerAdded(): void {
    this.addCustomerActivated = false;
    this.newCustomerCount += 1;
    this.refreshService.customerCount$.next(this.newCustomerCount);
  }

  clientAdded(): void {
    this.addClientActivated = false;
    this.newClientCount += 1;
    this.refreshService.clientCount$.next(this.newClientCount);
  }
}
