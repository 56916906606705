import { Injectable } from '@angular/core';
import { Cacheable } from '@app/cacheable';

interface Cache {
  [key: string]: Cacheable<any>[];
}

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  private cache: Cache = {};  

  constructor() { }

  add<T>(key: string, cache: Cacheable<T>): void {
    if(!this.cache[key]) {
      this.cache[key] = [];
    }

    this.cache[key].push(cache);
  }
  
  destroy(key: string): void {
    if(key in this.cache) {
      for(let c of this.cache[key]) {
        c.invalidate();        
      }
      delete this.cache[key];
    }
  }  
}
