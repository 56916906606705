import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Quality } from '@app/benchmark/benchmark.model';
import { TaskQuality } from '@app/benchmark/tasks-list/tasks-list.component';

type QualityClickArgs = {
  index: number;
  quality: TaskQuality;
};

@Component({
  selector: 'cb-quality-selector',
  templateUrl: './quality-selector.component.html',
  styles: [],
})
/// @deprecated
export class QualitySelectorComponent implements OnInit {
  qualityOptions = [
    { label: 'N/A', value: Quality.NotApplicable },
    { label: 'Not implemented', value: Quality.NotImplemented },
    { label: 'Low', value: Quality.Low },
    { label: 'Medium', value: Quality.Medium },
    { label: 'High', value: Quality.High },
    { label: 'Excellent', value: Quality.Excellent },
  ];

  model?: TaskQuality;

  @Input()
  quality?: TaskQuality;

  @Output() qualityChange = new EventEmitter<TaskQuality>();
  @Output() onQualityClick = new EventEmitter<QualityClickArgs>();

  constructor() {}

  ngOnInit(): void {
    this.model = this.quality;
  }

  onOptionChanged() {
    this.qualityChange.emit(this.model);
    const index = this.qualityOptions.findIndex((q) => q.value === this.model);
    this.onQualityClick.emit({
      index,
      quality: this.model!,
    });
  }
}
