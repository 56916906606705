<ng-container *ngIf="level$ | async as level; else loading">
    <div class="mb-10 text-center">
        <cb-level-label [level]="level.value"></cb-level-label>      
    </div>
    
    <div class="mt-6">
        <cb-task-status header="Task status" [companyId]="companyId"></cb-task-status>    
    </div>     
</ng-container>
<ng-template #loading>
    <div class="flex justify-center">
        <p-progressSpinner></p-progressSpinner>
    </div>
</ng-template>


