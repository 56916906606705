<ngx-loading-bar color="#00ab9e" height="8px"></ngx-loading-bar>

<ng-container *ngIf="auth.isLoading$ | async; else loaded">
    <div class="min-w-full h-screen bg-brand-secondary">
        <div class="flex items-center mx-auto h-screen max-w-xl">
            <div class="px-4 flex flex-col gap-4 animate-pulse">
                <cb-logo></cb-logo>
                <div class="text-white text-center text-lg sm:text-2xl">
                    Loading...
                </div>
            </div>
        </div>
    </div>
</ng-container>


<ng-template #loaded>
    <div class="fixed min-w-full z-30">
        <div *ngIf="upgradePending" class="p-2 bg-brand-primary text-white text-center font-bold">
            Account upgrade is in progress
        </div>
        <app-header-bar></app-header-bar>
    </div>

    <div class="flex p-0 h-screen">
        <div class="mt-20 pt-1" [ngClass]="{'mt-20': !upgradePending}"
            [ngStyle]="{'margin-top': upgradePending ? '7.5rem' : undefined}">
            <cb-side-bar></cb-side-bar>
        </div>

        <div [ngClass]="{ 'mt-20': !upgradePending && (auth.isAuthenticated$ | async) }"
            [ngStyle]="{'margin-top': upgradePending ? '7.5rem': undefined}"
            class="flex-1 pt-2 md:pt-1 flex flex-col overflow-hidden">
            <main class="flex-1 pt-2 overflow-y-auto bg-bright-gray">
                <div class="mx-auto py-0 px-2 relative sm:px-6 sm:py-8">
                    <router-outlet></router-outlet>
                </div>
            </main>
        </div>
    </div>

    <p-dialog [modal]="true" [(visible)]="showUpgradeModal" [closable]="false" position="top"
        styleClass="w-full max-w-screen-lg">
        <div class="w-full">
            <div class="flex justify-center">
                <h2 class="text-brand-deep text-3xl">
                    Upgrade to continue
                </h2>
            </div>
            <div class="mt-2 flex justify-center">
                <div class="text-lg mt-2">
                    <ng-container *ngIf="getUpgradePlan() as plan; else cheeky">
                        <div class="grid grid-cols-1 md:grid-cols-2 gap-6 justify-center">
                            <div class="border border-brand-primary rounded-lg p-6 h-full">
                                <h3 class="text-center text-brand-primary text-2xl">
                                    Clear {{plan.data.id}}
                                </h3>
                                <div class="mb-10 text-center text-sm text-gray-400">
                                    current plan
                                </div>

                                <div class="border border-gray-200 rounded-sm">
                                    <dl>
                                        <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-500">Clients</dt>
                                            <dd class="mt-1 text-right text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                {{plan.data.detail.maxClients}}
                                            </dd>
                                        </div>
                                        <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                            <dt class="text-sm font-medium text-gray-500">
                                                <div>Documents</div>
                                                <div class="text-xs">per month</div>
                                            </dt>
                                            <dd class="mt-1 text-right text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                {{plan.data.detail.maxDocuments}}
                                            </dd>
                                        </div>
                                    </dl>
                                </div>
                                <div class="text-center my-5">
                                    <div>{{plan.data.detail.pricing.amount}}</div>
                                    <div class="text-sm text-gray-400">
                                        <span *ngIf="plan.data.detail.pricing.amount !== 'Free'">per</span>
                                        {{plan.data.detail.pricing.schedule}}
                                    </div>
                                </div>
                                <div class="mt-10 flex justify-center">
                                    <button type="button" class="text-brand-deep hover:underline px-6 py-2 text-sm"
                                        (click)="goToDashboard()">
                                        Maybe later
                                    </button>
                                </div>
                            </div>
                            <div class="border border-brand-primary rounded-lg p-6 h-full">

                                <ng-container *ngIf="plan.next; else talkToUs">
                                    <h3 class="text-center text-brand-primary text-2xl">
                                        Clear {{plan.next.data.id}}
                                    </h3>
                                    <div class="mb-10 text-center text-sm text-gray-400">
                                        next plan
                                    </div>

                                    <div class="border border-gray-200 rounded-sm">
                                        <dl>
                                            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt class="text-sm font-medium text-gray-500">Clients</dt>
                                                <dd class="mt-1 text-right text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                    {{plan.next.data.detail.maxClients}}
                                                </dd>
                                            </div>
                                            <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt class="text-sm font-medium text-gray-500">
                                                    <div>Documents</div>
                                                    <div class="text-xs">per month</div>
                                                </dt>
                                                <dd class="mt-1 text-right text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                    {{plan.next.data.detail.maxDocuments}}
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                    <div class="text-center my-5">
                                        <div>{{plan.next.data.detail.pricing.amount}}</div>
                                        <div class="text-sm text-gray-400">
                                            per {{plan.next.data.detail.pricing.schedule}}
                                        </div>
                                    </div>
                                    <div class="mt-10 flex justify-center">
                                        <ng-container *ngIf="currentUserIsAdmin$ | async; else notadmin">
                                            <button type="button" cbButton class="text-sm" (click)="upgradePlan()">
                                                Upgrade now
                                            </button>
                                        </ng-container>
                                        <ng-template #notadmin>
                                            <span class="text-sm text-gray-400">
                                                Ask your system administrator to upgrade
                                            </span>
                                        </ng-template>

                                    </div>
                                </ng-container>
                                <ng-template #talkToUs>
                                    <h3 class="text-center text-brand-primary text-xl">
                                        Enterprise
                                    </h3>
                                    <div class="border border-gray-200 rounded-sm">
                                        <dl>
                                            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt class="text-sm font-medium text-gray-500">Clients</dt>
                                                <dd class="mt-1 text-right text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                    no limit
                                                </dd>
                                            </div>
                                            <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                                <dt class="text-sm font-medium text-gray-500">Documents</dt>
                                                <dd class="mt-1 text-right text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                                    no limit
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                                    <div class="mt-10 text-md text-center">
                                        talk to us
                                    </div>
                                </ng-template>


                            </div>
                        </div>
                    </ng-container>
                    <ng-template #cheeky>
                        ooop, you cheeky little hacker you 😛
                    </ng-template>
                </div>

            </div>

        </div>
    </p-dialog>
</ng-template>