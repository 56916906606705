import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment as env } from '@env';
import { HttpClient } from '@angular/common/http';
import { HttpErrorService } from '@app/services/http-error.service';

type SignUpParams = {
  name: string;
  email: string;
  mspName: string;
  sector: string;
}

@Injectable({
  providedIn: 'root'
})
export class SignUpService {

  constructor(private httpClient: HttpClient, private errorService: HttpErrorService) { }

  signup({ name: accountOwnerName, email, mspName, sector }: SignUpParams): Observable<void> {
    const { baseUrl } = env.api;
    return this.httpClient.post<void>(
      `${baseUrl}/v1/signup`,
      {
        accountOwnerName,
        email,
        mspName,
        sector
      }
    ).pipe(
      this.errorService.handleHttpError
    )
  }
}
