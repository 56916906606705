import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AcceptInviteComponent } from './accept-invite/accept-invite.component';

const routes: Routes = [
    { path: "invitations/:id/accept", component: AcceptInviteComponent }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class InvitationsRouterModule {}