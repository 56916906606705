import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { HttpErrorService } from '@app/services/http-error.service';
import { environment as env } from '@env';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Level, getLevelDescription } from '@app/components/level-label/level-label.component';

type Response = {
  level: Level;
  count: number;
}

@Component({
  selector: 'cb-clients-by-level',
  templateUrl: './clients-by-level.component.html',
  styles: [
  ]
})
export class ClientsByLevelComponent implements OnInit {

  clientLevelData$: Observable<any> = of(undefined);

  pieOptions = {
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
        }
      }
    }
  }

  constructor(
    private httpClient: HttpClient,
    private errorHandler: HttpErrorService
  ) { }

  ngOnInit(): void {
    const { baseUrl } = env.api;

    this.clientLevelData$ = this.httpClient.get<Response[]>(`${baseUrl}/v1/metrics/clients-by-level`).pipe(
      this.errorHandler.handleHttpError,
      map((response) => ({
        labels: response.map(({ level }) => `Level ${level} - ${getLevelDescription(level)}`),
        datasets: [
          {
            data: response.map(({ count }) => count),
            backgroundColor: ["#00ab9e", "#34394b", "#0062AB", "#cc3232", "#e7b416"]
          }
        ]
      }))
    );
  }

}
