import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SidebarService } from '../side-bar/sidebar.service';
import { UserContext, UserContextService } from '@app/user-context.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html'
})
export class HeaderBarComponent implements OnInit {

  context$!: Observable<UserContext | null>;

  constructor(
    public sidebar: SidebarService,    
    private userContext: UserContextService
  ) {  }

  ngOnInit(): void {
    this.context$ = this.userContext.user$;    
  }  
}
