import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CollectionResponse } from '@app/domain/model';
import { HttpErrorService } from '@app/services/http-error.service';
import { environment as env } from '@env';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { RoleGroup, UserCompany, UserIdentity } from './users.model';
import { isNotNull } from '@app/shared';

export interface Response {
  email: string;
  invitationId: string;
}

export interface UserModel {
  id?: string;
  name: string;
  position?: string;
  email: string;
  roleGroup: RoleGroup;
  companyId?: string;
  companies?: UserCompany[];
}

export interface UpdateProfileRequest {
  name: string;
  roleGroup: RoleGroup,
  accessToAdd?: string[];
  accessToRemove?: string[];
  position?: string
}

export interface Contact {
  id?: string;
  name: string;
  email: string;
  position?: string;
  save?: boolean;
  isUser?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private httpClient: HttpClient,
    private errorService: HttpErrorService
  ) { }

  inviteUser(user: UserModel): Observable<Response> {
    let url = `${env.api.baseUrl}/v${env.api.version}/invitations`;

    if (user.id) {
      url += `?userId=${encodeURIComponent(user.id)}`;
    }

    return this.httpClient.post<Response>(url, user).pipe(
      this.errorService.handleHttpError
    );
  }

  getUserById(id: string): Observable<UserIdentity> {
    const url = `${env.api.baseUrl}/v${env.api.version}/users/${encodeURIComponent(id)}`;
    return this.httpClient.get<UserIdentity>(url).pipe(
      this.errorService.handleHttpError
    );
  }

  updateProfile(id: string, request: UpdateProfileRequest): Observable<void> {
    const url = `${env.api.baseUrl}/v${env.api.version}/users/${encodeURIComponent(id)}`;
    return this.httpClient.put<void>(url, request).pipe(
      this.errorService.handleHttpError
    );
  }

  deleteUser(id: string): Observable<void> {
    const url = `${env.api.baseUrl}/v${env.api.version}/users/${encodeURIComponent(id)}`;
    return this.httpClient.delete<void>(url).pipe(
      this.errorService.handleHttpError
    );
  }

  resetPassword(id: string): Observable<void> {
    const url = `${env.api.baseUrl}/v${env.api.version}/users/${encodeURIComponent(id)}`;
    return this.httpClient.patch<void>(url, {}).pipe(
      this.errorService.handleHttpError
    );
  }

  blockUser(id: string): Observable<void> {
    const url = `${env.api.baseUrl}/v${env.api.version}/users/${encodeURIComponent(id)}/access`;
    return this.httpClient.delete<void>(url).pipe(
      this.errorService.handleHttpError
    );
  }

  reActivateAccount(userId: string): Observable<UserIdentity> {
    const url = `${env.api.baseUrl}/v${env.api.version}/users/${encodeURIComponent(userId)}/access`;
    return this.httpClient.put<UserIdentity>(url, {}).pipe(
      this.errorService.handleHttpError
    );
  }

  createContactForCompany(companyId: string, contact: UserModel): Observable<void> {
    const url = `${env.api.baseUrl}/v1/${encodeURIComponent(companyId)}/contacts`;
    return this.httpClient.post<void>(url, contact).pipe(
      this.errorService.handleHttpError
    );
  }

  updateContact(companyId: string, contact: UserModel): Observable<void> {
    const url = `${env.api.baseUrl}/v1/${encodeURIComponent(companyId)}/contacts/${encodeURIComponent(contact.id!)}`;
    return this.httpClient.put<void>(url, contact).pipe(
      this.errorService.handleHttpError
    );
  }

  deleteInvitation(id: string): Observable<void> {
    const url = `${env.api.baseUrl}/v${env.api.version}/invitations/${encodeURIComponent(id)}`;
    return this.httpClient.delete<void>(url).pipe(
      this.errorService.handleHttpError
    );
  }

  getContacts(companyId: string): Observable<Contact[]> {
    const { baseUrl } = env.api;
    const url = `${baseUrl}/v1/${encodeURIComponent(companyId)}/contacts`;
    return this.httpClient.get<CollectionResponse<Contact>>(url).pipe(
      this.errorService.handleHttpError,
      map(res => res.result)
    )
  }
}
