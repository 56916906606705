<cb-authorized>
    <ng-container *ngIf="company$ | async as company; else loading">
        <div class="max-w-full px-2 py-2 lg:px-4 lg:py-6 lg:mx-auto lg:max-w-4xl">
            <div class="lg:border lg:border-gray-200 rounded-md shadow-md bg-white lg:p-4">
                <cb-company-form 
                    formTitle="Edit company"
                    [company]="company"
                    (save)="saveCompany($event)"
                    (cancel)="cancel()">
                </cb-company-form>
            </div>
        </div>
    </ng-container>
</cb-authorized>

<ng-template #loading>
    Loading...
</ng-template>

