<div class="shadow-md bg-white rounded-md px-4 pt-2 pb-6 w-full md:h-full">
    <h3 class="mb-6 text-2xl text-center">{{heading}}</h3>
    <ng-content></ng-content>
    <div *ngIf="buttonText" class="pt-2 pb-6 py-4 flex justify-end items-end">
        <button (click)="handleButtonClick($event)" type="button" cbButton class="flex justify-between items-center">
            <div>
                <hero-icon name="save" type="outline" class="w-5 h-5"></hero-icon>
            </div>
            <div class="ml-2">
                Save
            </div>
        </button>
    </div>
</div>
