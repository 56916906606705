import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from '@env';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpErrorService } from './services/http-error.service';
import { shareReplay, switchMap, switchMapTo } from 'rxjs/operators';

type SubscriptionUpgradeResponse = {
  upgradeResult: "Pending" | "PaymentRequired" | "Failed";
  redirectTo: string | null;
}

export type AccountStatusResult = {
  canAddClients: boolean;
  canGenerateDocuments: boolean;
  currentPlanId: string | null;
  clientCount: number | null;
  documentCount: number | null;
  maxDocumentsAllowed: number | null;
  maxClientsAllowed: number | null;
  accountChangePending: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  private reload = new BehaviorSubject<number>(0);

  accountStatus$: Observable<AccountStatusResult> = this.reload.pipe(
    switchMap((code) => this.getAccountStatus(code === 1)),
    shareReplay(1)
  );

  constructor(
    private httpClient: HttpClient,
    private errorHandler: HttpErrorService
  ) { }

  startSubscriptionUpgrade(productId: string): Observable<SubscriptionUpgradeResponse> {
    const { baseUrl } = env.api;
    const url = `${baseUrl}/v1/subscription`;
    return this.httpClient.post<SubscriptionUpgradeResponse>(url, { productId }).pipe(
      this.errorHandler.handleHttpError
    )
  }

  refresh(): void {
    this.reload.next(1);
  }

  private getAccountStatus(force: boolean): Observable<AccountStatusResult> {
    const { baseUrl } = env.api;
    let url = `${baseUrl}/v1/status`;
    if (force) {
      url += '?force=1';
    }
    return this.httpClient.get<AccountStatusResult>(url);
  }
}
